import { CATALOGUE_MODE } from "@constants";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { MemoizedCartLayout } from "@components/OrderTaking/Components/Cart/CartLayout";
import { CategoriesSwiperWrapperMemoised } from "@components/OrderTaking/Components/Categories/CategoriesSwiperWrapper";
import AllergensDialog from "@components/OrderTaking/Components/Dialogs/Allergen/AllergensDialog";
import { MemoisedOrderTakingTopBanner } from "@components/OrderTaking/Components/OrderTakingTopBanner";
import ProductWrapper from "@components/OrderTaking/Components/Products/ProductWrapper";
import { TagsSwiperWrapperMemoised } from "@components/OrderTaking/Components/Tags/TagsSwiperWrapper";

import { kioskStore, setIsAnimationTimeOut } from "@pages/Kiosk/store";

import CategoriesBreadCrumb from "./CategoriesBreadCrumb";
import { CategoryTitle } from "./categoryTitle";
import { HelloUser } from "./helloUser";
import "./index.css";

import "swiper/swiper.min.css";

const parentVariant = {
    initial: { opacity: 1 },
    animate: { opacity: 1, transition: { staggerChildren: 3 } },
};

export default function OrderTakingScreens() {
    const [
        isAllergenDialogOpened,
        setIsAllergenDialogOpened = { setIsAllergenDialogOpened },
    ] = React.useState(false);

    const {
        customerLanguage,
        project: { files: images },
    } = useSnapshot(kioskStore);
    const { isProductBreadcrumbTrailActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    const { isSelectedCategoryActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );

    const {
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        userAccountMessage,
        globalColor,
        isHeaderActive,
        isBackgroundActive,
    } = useSelector((state) => state.settingSlice.generalSetting, shallowEqual);
    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );

    const backgroundImage = Object.values(images).find((image) => {
        return image.key === "background" && image.name === "orderTaking";
    });

    const imageSrc = getImageContentById(backgroundImage?.id);

    const handleAllergenDialogOnCLickEvent = () => {
        setIsAllergenDialogOpened(!isAllergenDialogOpened);
    };

    React.useEffect(() => {
        setIsAnimationTimeOut(false);
        const timer = setTimeout(() => {
            setIsAnimationTimeOut(true);
        }, 600);
        return () => clearTimeout(timer);
    }, []);

    return (
        <AnimatePresence>
            <div>
                {isAllergenDialogOpened && (
                    <AllergensDialog
                        open={isAllergenDialogOpened}
                        onClose={handleAllergenDialogOnCLickEvent}
                        globalColor={globalColor}
                    />
                )}

                <OrderTakingBackgroundWrapper
                    filename={imageSrc}
                    isBackgroundActive={isBackgroundActive}
                >
                    <motion.div
                        variants={parentVariant}
                        animate="animate"
                        className={classNames({
                            "order-taking-container order-taking-layout": !(
                                process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ),
                            "order-taking-container__web order-taking-layout":
                                process.env.REACT_APP_MODE === CATALOGUE_MODE,
                        })}
                    >
                        {isOrderTakingTopBannerActive ? (
                            <header className="order-taking-header">
                                <MemoisedOrderTakingTopBanner />
                            </header>
                        ) : null}
                        {isHeaderActive && (
                            <div
                                className="order-taking-user"
                                style={{
                                    borderBottom:
                                        isBackgroundActive === false ||
                                        (imageSrc === "" &&
                                            "solid 1px #ccc2c2"),
                                }}
                            >
                                <HelloUser
                                    userAccountMessage={
                                        userAccountMessage.languages[
                                            customerLanguage.name
                                        ].content
                                    }
                                    style={{ gridArea: "user user" }}
                                    handleAllergenDialogOnCLickEvent={
                                        handleAllergenDialogOnCLickEvent
                                    }
                                />
                            </div>
                        )}
                        <nav
                            id="categories"
                            style={{ gridArea: "categories" }}
                            className="h-100"
                        >
                            <CategoriesSwiperWrapperMemoised />
                        </nav>
                        <div style={{ gridArea: "headertitle" }}>
                            {isProductBreadcrumbTrailActive &&
                                nestedCategories.length > 1 && (
                                    <div
                                        style={{
                                            width: "100%",
                                            maxWidth: "calc( 100vw - 200px)",
                                        }}
                                    >
                                        <CategoriesBreadCrumb />
                                        {isBackgroundActive === false ||
                                        imageSrc === "" ? (
                                            <hr className="separation-line mx-1" />
                                        ) : null}
                                    </div>
                                )}

                            {isSelectedCategoryActive && (
                                <>
                                    <CategoryTitle />

                                    {isBackgroundActive === false ||
                                    imageSrc === "" ? (
                                        <hr className="separation-line mx-1" />
                                    ) : null}
                                </>
                            )}
                            {isOrderTakingTagsActive ? (
                                <TagsSwiperWrapperMemoised />
                            ) : null}
                        </div>

                        <section id="products" style={{ gridArea: "products" }}>
                            <ProductWrapper />
                        </section>

                        <footer
                            id="usual-footer"
                            style={{ backgroundColor: "transparent" }}
                        >
                            <MemoizedCartLayout />
                        </footer>
                    </motion.div>
                </OrderTakingBackgroundWrapper>
            </div>
        </AnimatePresence>
    );
}
