/* eslint-disable @typescript-eslint/no-explicit-any */
import { proxy, subscribe } from "valtio";

import { KeyboardInput } from "react-simple-keyboard";

type StoreType = {
    customerLanguage: LanguagesItemType;
    isPrm: boolean;
    isHelpModalOpened: boolean;
    isUserAccountActive: boolean;
    isLanguagesActive: boolean;
    activeLanguages: LanguagesItemType[];
    isConfigOptionsActive: boolean;
    activeConfigOptions: OptionsItemType[];
    isLogoActive: boolean;
    isActionButtonsActive: boolean;
    isInformationMessageActive: boolean;
    actionsButtonsItems: ActionsButtonsDesignItemType[];
    mainContentItems:
        | ProjectMainContentItemType[]
        | PaymentMainContentItemType[]
        | printerOptionsItemsType[];

    saleMethodsNumber: number;
    regulationModesNumber: number;
    informationMessageItems: HeaderLanguageItemsType;
    subStepInformationMessageItems: HeaderLanguageItemsType;
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    userAccountMessageItems: HeaderLanguageItemsType;
    activeStandbyScreenItems: StandbyScreenItemType[];
    timeout: number;
    activeActionsButtons: ActionsButtonsDesignItemType[];
    initialStepTimeout: number;
    isUserAccountModalOpened: boolean;
    selectedAuthMode: string;
    chosenTransition: TransitionType;
    isAnimationIn: boolean;
    selectedSaleModeValue: string;
    kioskWays: WaysType[];
    selectedInformationMode: string;
    isSubStepIconActive: boolean;
    isSubStepTopBannerActive: boolean;
    isSubStepLogoActive: boolean;
    isSubStepInformationMessageActive: boolean;
    isSubStepActionButtonsActive: boolean;
    navigationIndex: number;
    isProjectMustBeUpdated: { setting: boolean; peripheral: boolean };
    authModalStepIndex: number;
    project: ProjectType;
    subStepActionButtons:
        | ActionsButtonsDesignItemType[]
        | ActionsButtonsItemV2Type[];
    isUserAuthenticated: boolean;
    subStepIconId: string;
    isIDontIdentifyMySelfClicked: boolean;
    selectedRegulationModeValue: string;
    isConfirmationModalOpened: boolean;
    numericKeyboardInputValue: string;
    isAuthenticationErrorModalOpened: boolean;
    keyboardInputsValues: KeyboardInput;
    logo: GeneralDesignLogoType;
    ticketNumberMessageFooter: HeaderLanguageItemsType;
    isTicketNumberMessageFooterActive: boolean;
    finalMessageFooter: HeaderLanguageItemsType;
    isFinalMessageFooterActive: boolean;
    finalMessage: string;
    finalMessageSelectedInformationMode: string;
    finalMessageSelectedRegulationMode: string;
    totalOrderAfterPaidWithLoyaltyAccountBalance: number;
    amountDeposited: number;
    changeMachineBalance: number;
    isRegulationModesModalOpened: boolean;
    creditCardPaymentChoice: string;
    isBackWithoutCreditCardPaymentChoice: boolean;
    isTpaValinaIconActive: boolean;
    tpaValinaIconId: string;
    isTpaValinaInformationMessageActive: boolean;
    tpaValinaInformationMessageItems: HeaderLanguageItemsType;
    tpaValinaActionButtons: ActionsButtonsDesignItemType[];
    isTpaValinaActionButtonsActive: boolean;
    isAuthenticationAccepted: boolean;
    isOrderPaidWithLoyaltyAccountBalance: boolean;
    isOrderPartialPaidWithLoyaltyAccountBalance: boolean;
    isLocalSettingModalOpened: boolean;
    isAuthenticationModalOpened: boolean;
    isLocalConfigModalOpened: boolean;
    isScanQrCodeModalOpened: boolean;
    activeTabTitle: string;
    localProject: ProjectType;
    isMenuModalOpened: boolean;
    activeStateScreenItems: StandbyScreenItemType[];
    standbyStateScreenItems: StandbyScreenItemType[];
    standbyScreenItems: StandbyScreenItemType[];
    isUsersListModalOpened: boolean;
    leftToPay: number;
    renderAmount: number;
    orderNumber: string;
    isAbortTransactionModalOpened: boolean;
    regulationModesModalKey: string;
    leftToPayAfterCashMachineValidation: number;
    qrCodeValue: string;
    selectedSaleModeId: string;
    selectedSaleModeOrderTaking: string;
    user: UserType;
    pseudoNameValue: string;
    chosenInformationMode: InformationModeType;
    paymentModes: PaymentModesType;
    cashUuID: string;
    creditCardUuId: string;
    loyaltyUuId: string;
    isCreatingVoucherAccepted: boolean;
    isShutdownModalOpened: boolean;
    isCloseAppModalOpened: boolean;
    isSelectedSaleModeWithoutInformationModes: boolean;
    isBackButtonDisabled: boolean;
    isAnotherPaymentModeButtonDisabled: boolean;
    isFenceProblemDetected: boolean;
    technicalProblemKey: string;
    shopOpeningHour: any[];
    totalAmountDeposited: number;
    isTransactionCanceled: boolean;
    isPaymentRefused: boolean;
    voucherAmount: number;
    isCreationVoucherCanceled: boolean;
    pagesItems: ItemsPagesType;
    isCashTransactionValidated: boolean;
    isAnotherPaymentModeButtonCBDisabled: boolean;
    isPaymentTechnicalProblemModalOpened: boolean;
    isPaymentTechnicalProblemDetected: boolean;
    isCommandInsertionProblemModalOpened: boolean;
    finalMessageCounter: number;
    isAmountReturned: boolean;
    orderItemsLength: number;
    isInformationModesModalOpened: boolean;
    isInformationModesConsommationModalOpened: boolean;
    // eslint-disable-next-line
    saleMode: any;
    orderModificationMode: boolean;
    chosenLocalInformationMode: InformationModeType;
    localSelectedInformationMode: string;
    isOrderTakingPreviousStep: boolean;
    isOrderInsertedSuccessfully: boolean;
    returnedAmount: number;
    isOrderInsertionRefused: boolean;
    isLogoutProblemModalOpened: boolean;
    isAcceptAbortCBTransactionButtonDisabled: boolean;
    isCashMachineTransactionCanceled: boolean;
    isOrderLoading: boolean;
    isWorkflowLoading: boolean;
    isAnimationTimeOut: boolean;
    isRegulationModeModalButtonClicked: boolean;
    isEventStreamProblem: boolean;
    changeMachineConfigurationDate: string;
    localFinalMessageSelectedInformationMode: string;
    paymentStepCounter: number;
    isKeyboardOpened: boolean;
    userAuthInfo: AuthModeInfoType;
    isGlobalSummaryOrderOpened: boolean;
    isTpeNotAvailable: boolean;
    isTpeWaitingTimeReached: boolean;
    isButtonClickedOnCBPaymentMode: {
        isAnotherPaymentModeButtonClicked: boolean;
        isBackPaymentModeButtonClicked: boolean;
    };
    promoValue: number;
    isTcposOrderValidated: boolean;
    isLoadingModalOpened: boolean;
    isTcposCancelOrderOngoing: boolean;
    isAccessNotAllowed: boolean;
    isTcposOrderRefreshDone: boolean;
    isSendEmailConfirmed: boolean;
    isPrintTicket: boolean;
    emailTicket: string;
    isValidEmail: boolean;
    isSendMailWithPdfActive: boolean;
    isSendedEmailWithSuccessModalOpened: boolean;
    isTicketNumberActive: boolean;
    isLedActive: boolean;
    connectedLedPort: string;
    isClickAndCollect: boolean;
    isClickAndCollectOrderPaid: boolean;
    orderTotalPrice: number;
    clickAndCollectOrderId: string;
    clickAndCollectOrderSalesMode: string;
    isInformationModeActivated: boolean;
    isOrderPaid: boolean;
    isRegulationModeModalGiveUpButtonClicked: boolean;
    informationModeValueTcpos: string;
    isCustomerBenefitOpen: boolean;
    isCustomerBenefitOnClick: boolean;
    isPayButtonClickedReward: boolean;
    devise: DeviseType;
    localShopCartOpened: boolean;
    isKeyBoardOpened: boolean;
    cashMachineStatus: {
        isOpened: boolean;
        isNotAvailable: boolean;
        isBillRefund: boolean;
        isRefund: boolean;
        isNetworkLost: boolean;
        isRefundProblem: boolean;
        isOccupied: boolean;
        isNetworkProblem: boolean;
    };
    isCreateAnAccountSelected: boolean;
    loyaltyType: string;
};

export const kioskStore = proxy<StoreType>({
    customerLanguage: {
        id: "",
        flag: "",
        name: "fr",
        title: "",
        language: "",
        active: false,
        isDefault: false,
    },
    isPrm: false,
    isHelpModalOpened: false,
    isInformationMessageActive: false,
    isLanguagesActive: false,
    activeLanguages: [],
    isConfigOptionsActive: false,
    activeConfigOptions: [],
    isLogoActive: false,
    isActionButtonsActive: false,
    isUserAccountActive: false,
    actionsButtonsItems: [],
    mainContentItems: [],
    saleMethodsNumber: 0,
    regulationModesNumber: 0,
    informationMessageItems: {},
    subStepInformationMessageItems: {},
    isTopBannerActive: false,
    isHeaderActive: false,
    userAccountMessageItems: {},
    activeStandbyScreenItems: [],
    timeout: 0,
    activeActionsButtons: [],
    initialStepTimeout: 0,
    isUserAccountModalOpened: false,
    selectedAuthMode: "",
    chosenTransition: { prettyName: "", timeout: 0, className: "" },
    isAnimationIn: false,
    selectedSaleModeValue: "",
    kioskWays: [],
    selectedInformationMode: "",
    isSubStepIconActive: false,
    isSubStepTopBannerActive: false,
    isSubStepLogoActive: false,
    isSubStepInformationMessageActive: false,
    isSubStepActionButtonsActive: false,
    navigationIndex: 0,
    isProjectMustBeUpdated: { setting: false, peripheral: false },
    authModalStepIndex: 0,
    // eslint-disable-next-line
    project: JSON.parse(localStorage.getItem("project")!) || {
        id: 0,
        name: "",
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [],
                },
                languages: {
                    active: true,
                    items: [],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
        },
        files: [],
        note: "",
        designation: "",
        hoursOfUse: [],
    },
    subStepActionButtons: [],
    isUserAuthenticated: false,
    subStepIconId: "",
    isIDontIdentifyMySelfClicked: false,
    selectedRegulationModeValue: "",
    isConfirmationModalOpened: false,
    numericKeyboardInputValue: "",
    isAuthenticationErrorModalOpened: false,
    keyboardInputsValues: {},
    logo: { name: "", content: "", id: "" },
    ticketNumberMessageFooter: {},
    isTicketNumberMessageFooterActive: false,
    finalMessageFooter: {},
    isFinalMessageFooterActive: false,
    finalMessage: "",
    finalMessageSelectedInformationMode: "none",
    finalMessageSelectedRegulationMode: "At the counter",
    amountDeposited: 0,
    totalOrderAfterPaidWithLoyaltyAccountBalance: 0,
    changeMachineBalance: 30,
    isRegulationModesModalOpened: false,
    creditCardPaymentChoice: "",
    isBackWithoutCreditCardPaymentChoice: true,
    isTpaValinaIconActive: false,
    tpaValinaIconId: "",
    isTpaValinaInformationMessageActive: false,
    tpaValinaInformationMessageItems: {},
    tpaValinaActionButtons: [],
    isTpaValinaActionButtonsActive: false,
    isAuthenticationAccepted: false,
    isOrderPaidWithLoyaltyAccountBalance: false,
    isOrderPartialPaidWithLoyaltyAccountBalance: false,
    isLocalSettingModalOpened: false,
    isAuthenticationModalOpened: false,
    isLocalConfigModalOpened: false,
    activeTabTitle: "",
    localProject: {
        id: 0,
        name: "",
        template: {
            id: "",
            name: "",
            coverImage: "",
            pages: {
                elements: {},
                ways: {},
                subSteps: {},
            },
            content: {
                salesMethods: { items: [] },
                meansOfPayment: { items: [] },
                informationModes: { items: [] },
                options: { active: true, items: [] },
                actionsButtons: {
                    active: true,
                    items: [
                        {
                            id: "",
                            name: "",
                            active: true,
                            languages: {
                                fr: {
                                    content: "",
                                },
                                en: {
                                    content: "",
                                },
                            },
                            role: 1,
                        },
                    ],
                },
                languages: {
                    active: true,
                    items: [
                        {
                            id: "",
                            flag: "",
                            name: "",
                            title: "",
                            language: "",
                            active: true,
                            isDefault: false,
                        },
                    ],
                },
                generalDesign: {
                    logo: {
                        id: "",
                        name: "",
                        content: "l",
                    },
                    prm: {
                        id: "",
                        name: "",
                        content: "l",
                        defaultContent: "",
                        type: "",
                        timeout: 0,
                    },
                    colors: [],
                    fonts: [],
                },
            },
            timeout: 15,
            initialStepTimeout: 10,
            keyboard: "azerty",
        },
        files: {},
        note: "",
        designation: "",
        hoursOfUse: [],
    },
    isMenuModalOpened: false,
    activeStateScreenItems: [],
    standbyStateScreenItems: [],
    standbyScreenItems: [],
    isUsersListModalOpened: false,
    isScanQrCodeModalOpened: false,
    leftToPay: 0,
    renderAmount: 0,
    orderNumber: "",
    isAbortTransactionModalOpened: false,
    regulationModesModalKey: "",
    leftToPayAfterCashMachineValidation: 0,
    qrCodeValue: "",
    selectedSaleModeId: "",
    user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        loyaltyAccount: 0,
        loyaltyAmount: 0,
        loyaltyType: "",
        amountRatio: 0,
    },
    pseudoNameValue: "",
    chosenInformationMode: { type: "", data: { infoModeUuId: "", value: "" } },
    paymentModes: [],
    cashUuID: "",
    creditCardUuId: "",
    loyaltyUuId: "",
    isCreatingVoucherAccepted: false,
    isShutdownModalOpened: false,
    isCloseAppModalOpened: false,
    isSelectedSaleModeWithoutInformationModes: false,
    isBackButtonDisabled: true,
    isAnotherPaymentModeButtonDisabled: true,
    isFenceProblemDetected: false,
    technicalProblemKey: "",
    shopOpeningHour: [],
    totalAmountDeposited: 0,
    isTransactionCanceled: false,
    isPaymentRefused: false,
    voucherAmount: 0,
    isCreationVoucherCanceled: false,
    pagesItems: {
        elements: {},
        ways: {},
        subSteps: {},
    },
    isCashTransactionValidated: false,
    selectedSaleModeOrderTaking: "",
    isAnotherPaymentModeButtonCBDisabled: true,
    isPaymentTechnicalProblemModalOpened: false,
    isPaymentTechnicalProblemDetected: false,
    isCommandInsertionProblemModalOpened: false,
    finalMessageCounter: 0,
    isAmountReturned: false,
    orderItemsLength: 0,
    isInformationModesModalOpened: false,
    isInformationModesConsommationModalOpened: false,
    saleMode: {},
    orderModificationMode: false,
    chosenLocalInformationMode: {
        type: "",
        data: { infoModeUuId: "", value: "" },
    },
    localSelectedInformationMode: "",
    isOrderTakingPreviousStep: false,
    isOrderInsertedSuccessfully: false,
    returnedAmount: 0,
    isOrderInsertionRefused: false,
    isLogoutProblemModalOpened: false,
    isAcceptAbortCBTransactionButtonDisabled: false,
    isCashMachineTransactionCanceled: false,
    isOrderLoading: false,
    isWorkflowLoading: false,
    isAnimationTimeOut: false,
    isRegulationModeModalButtonClicked: false,
    isEventStreamProblem: false,
    changeMachineConfigurationDate: "",
    localFinalMessageSelectedInformationMode: "",
    paymentStepCounter: 0,
    isKeyboardOpened: false,
    userAuthInfo: {},
    isGlobalSummaryOrderOpened: false,
    isTpeNotAvailable: false,
    isTpeWaitingTimeReached: false,
    isButtonClickedOnCBPaymentMode: {
        isAnotherPaymentModeButtonClicked: false,
        isBackPaymentModeButtonClicked: false,
    },
    promoValue: 0,
    isTcposOrderValidated: false,
    isLoadingModalOpened: false,
    isTcposCancelOrderOngoing: false,
    isAccessNotAllowed: false,
    isTcposOrderRefreshDone: false,
    isSendEmailConfirmed: false,
    isPrintTicket: true,
    emailTicket: "",
    isValidEmail: false,
    isSendMailWithPdfActive: false,
    isSendedEmailWithSuccessModalOpened: false,
    isTicketNumberActive: false,
    isLedActive: false,
    connectedLedPort: "",
    isClickAndCollect: false,
    isClickAndCollectOrderPaid: false,
    orderTotalPrice: 0,
    clickAndCollectOrderId: "",
    clickAndCollectOrderSalesMode: "",
    isInformationModeActivated: true,
    isOrderPaid: false,
    isRegulationModeModalGiveUpButtonClicked: false,
    informationModeValueTcpos: "",
    isCreateAnAccountSelected: false,
    loyaltyType: "zerosix",
    devise: localStorage.getItem("devise")
        ? (JSON.parse(localStorage.getItem("devise") as any) as DeviseType)
        : { deviseSymbole: "€", decimalPrice: 2 },
    localShopCartOpened: false,
    isKeyBoardOpened: false,
    isCustomerBenefitOpen: false,
    isPayButtonClickedReward: false,
    isCustomerBenefitOnClick: false,
    cashMachineStatus: {
        isOpened: false,
        isNotAvailable: false,
        isBillRefund: false,
        isRefund: false,
        isRefundProblem: false,
        isOccupied: false,
        isNetworkLost: false,
        isNetworkProblem: false,
    },
});

subscribe(kioskStore, () => {
    localStorage.setItem("devise", JSON.stringify(kioskStore.devise));
    localStorage.setItem(
        "ledConfig",
        JSON.stringify({
            isLedActive: kioskStore.isLedActive,
            connectedLedPort: kioskStore.connectedLedPort,
        })
    );
});

export {
    setCustomerLanguage,
    setIsPrm,
    setIsHelpModalOpened,
    setSaleMethodsNumber,
    setRegulationModesNumber,
    setIsTopBannerActive,
    setIsInformationMessageActive,
    setIsUserAccountActive,
    setIsLanguagesActive,
    setActiveLanguages,
    setIsConfigOptionsActive,
    setActiveConfigOptions,
    setIsLogoActive,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setActionsButtonsItems,
    setMainContentItems,
    setInformationMessageItems,
    setUserAccountMessageItems,
    setActiveStandbyScreenItems,
    setProjectTimeout,
    setActiveActionsButtons,
    setInitialStepTimeout,
    setIsUserAccountModalOpened,
    setSelectedAuthMode,
    setChosenTransition,
    setIsAnimationIn,
    setSelectedSaleModeValue,
    setKioskWays,
    setSelectedInformationMode,
    setIsSubStepIconActive,
    setIsSubStepTopBannerActive,
    setIsSubStepLogoActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepActionButtonsActive,
    setNavigationIndex,
    setIsProjectMustBeUpdated,
    setAuthModalStepIndex,
    setProject,
    setSubStepActionButtons,
    setIsUserAuthenticated,
    setSubStepInformationMessageItems,
    setSubStepIconId,
    setSelectedRegulationModeValue,
    setIsConfirmationModalOpened,
    setNumericKeyboardInputValue,
    setIsAuthenticationErrorModalOpened,
    setKeyboardInputsValues,
    setLogo,
    setIsTicketNumberMessageFooterActive,
    setTicketNumberMessageFooter,
    setFinalMessageFooter,
    setIsFinalMessageFooterActive,
    setFinalMessage,
    setFinalMessageSelectedRegulationMode,
    setFinalMessageSelectedInformationMode,
    setTotalOrderAfterPaidWithLoyaltyAccountBalance,
    setAmountDeposited,
    setChangeMachineBalance,
    initStoreVariablesInStandbyScreenStep,
    setIsRegulationModesModalOpened,
    setCreditCardPaymentChoice,
    setIsBackWithoutCreditCardPaymentChoice,
    setIsAuthenticationAccepted,
    setIsOrderPaidWithLoyaltyAccountBalance,
    setIsTpaValinaActionButtonsActive,
    setIsTpaValinaIconActive,
    setIsTpaValinaInformationMessageActive,
    setTpaValinaActionButtons,
    setTpaValinaIconId,
    setTpaValinaInformationMessageItems,
    setIsLocalSettingModalOpened,
    setIsAuthenticationModalOpened,
    setIsLocalConfigModalOpened,
    setActiveTabTitle,
    setLocalProjectItems,
    setLocalProject,
    setIsMenuModalOpened,
    setConfigInformationModes,
    setActiveStateScreenItems,
    setStandbyStateScreenItems,
    setStandbyScreenItems,
    setIsUsersListModalOpened,
    setIsScanQrCodeModalOpened,
    setLeftToPay,
    setRenderAmount,
    setOrderNumber,
    setIsAbortTransactionModalOpened,
    setRegulationModesModalKey,
    setLeftToPayAfterCashMachineValidation,
    setQrCodeValue,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setUser,
    setIsOrderPartialPaidWithLoyaltyAccountBalance,
    setPseudoNameValue,
    setChosenInformationMode,
    setPaymentModes,
    setCashUuID,
    setCreditCardUuId,
    setLoyaltyUuId,
    setIsCreatingVoucherAccepted,
    setIsShutdownModalOpened,
    setIsCloseAppModalOpened,
    setIsSelectedSaleModeWithoutInformationModes,
    setIsBackButtonDisabled,
    setIsAnotherPaymentModeButtonDisabled,
    setIsFenceProblemDetected,
    setTechnicalProblemKey,
    setShopOpeningHour,
    setTotalAmountDeposited,
    setIsTransactionCanceled,
    setIsPaymentRefused,
    setVoucherAmount,
    setIsCreationVoucherCanceled,
    setPagesItems,
    setIsCashTransactionValidated,
    setIsAnotherPaymentModeButtonCBDisabled,
    setIsPaymentTechnicalProblemModalOpened,
    setIsPaymentTechnicalProblemDetected,
    setIsCommandInsertionProblemModalOpened,
    setFinalMessageCounter,
    setIsAmountReturned,
    setOrderItemsLength,
    setIsInformationModesModalOpened,
    setIsInformationModesConsommationModalOpened,
    setSaleMode,
    setOrderModificationMode,
    setChosenLocalInformationMode,
    setLocalSelectedInformationMode,
    setIsOrderTakingPreviousStep,
    setIsOrderInsertedSuccessfully,
    setReturnedAmount,
    setIsOrderInsertionRefused,
    setIsLogoutProblemModalOpened,
    setIsAcceptAbortCBTransactionButtonDisabled,
    setIsCashMachineTransactionCanceled,
    setIsOrderLoading,
    setIsWorkflowLoading,
    setIsAnimationTimeOut,
    setIsRegulationModesModalButtonClicked,
    setIsEventStreamProblem,
    setChangeMachineConfigurationDate,
    setLocalFinalMessageSelectedInformationMode,
    setPaymentStepCounter,
    setIsKeyboardOpened,
    setUserAuthInfo,
    setIsGlobalSummaryOrderOpened,
    setIsTpeNotAvailable,
    setIsTpeWaitingTimeReached,
    setIsButtonClickedOnCBPaymentMode,
    setPromoValue,
    setIsTcposOrderValidated,
    setIsLoadingModalOpened,
    setTcposCancelOrderStatus,
    setIsAccessNotAllowed,
    setIsTcposOrderRefreshDone,
    setIsSendEmailConfirmed,
    setIsPrintTicket,
    setEmailTicket,
    setIsValidEmail,
    setIsSendMailWithPdfActive,
    setIsSendedEmailWithSuccessModalOpened,
    setIsTicketNumberActive,
    setIsLedActive,
    setConnectedLedPort,
    setIsClickAndCollect,
    setIsClickAndCollectOrderPaid,
    setOrderTotalPrice,
    setClickAndCollectOrderId,
    setClickAndCollectOrderSalesMode,
    setIsInformationModeActivated,
    setIsRegulationModeModalGiveUpButtonClicked,
    setInformationModeValueTcpos,
    setIsCustomerBenefitOpen,
    setIsCustomerBenefitOnClick,
    setDevise,
    setIsSkippedStepInformationModes,
    setIsSkippedStep,
    setLocalShopCartOpened,
    setIsKeyBoardOpened,
    setIsCreateAnAccountSelected,
    setLoyaltyType,
} from "./actions";
