import React from "react";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

type OrderCellPropsType = { row: { index: number } };

export function OrderCell({ row }: OrderCellPropsType): JSX.Element {
    return (
        <FlexboxGrid
            alignItemsCentered={true}
            styles={{ marginRight: "100px" }}
        >
            <span>{row.index + 1}</span>
        </FlexboxGrid>
    );
}
