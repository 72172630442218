import { CATALOGUE_MODE, TCPOS } from "@constants";
import { Dialog } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import WorkflowAlert from "@components/OrderTaking/common/WorkflowAlert";
import {
    addItemCommandTcPos,
    GetWorkflowData,
} from "@components/OrderTaking/Helpers";
import {
    addToOrder,
    modifyOrder,
    selectedAdditionalItems,
    selectUserRewards,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { CommentKeyboard } from "../../../common/CommentKeyboard";
import "./index.css";
import WorkflowShopCarts from "./ShopCard/.";
import WorkflowFooter from "./WorkflowFooter";
import WorkflowHeader from "./WorkflowHeader";
import WorkflowProduct from "./WorkflowProduct";
import WorkflowTree from "./WorkflowTree";

const parentVariant = {
    initial: { opacity: 1 },
    animate: { opacity: 1, transition: { staggerChildren: 1 } },
};
const childrenVariant = {
    initial: { opacity: 1, scale: 0.5 },
    animate: { opacity: 1, scale: 1 },
    transition: { type: "spring", stiffness: 100 },
};

export function WorkflowDialog({
    open,
    onClose,
    setIsShowDetailsOpened,
    isShowDetailsOpened,
    setIsLocalKeyBoardOpened,
}) {
    const dispatchStore = useDispatch();

    const [isAlertOpened, setIsAlertOpened] = React.useState(false);

    const { posEditor } = useSnapshot(store);
    const { isPrm, selectedSaleModeValue, isCustomerBenefitOpen } =
        useSnapshot(kioskStore);

    const { isBasketActive } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);
    const product = useSelector((state) => state.ProductSlice.product);

    const GetStepInCategory = React.useCallback(() => {
        const workflow = GetWorkflowData(
            product,
            workflowData,
            globalcard,
            orderWorkflowItemSlice,
            selectedSaleModeValue
        );

        if (workflow.length === 0) {
            onClose();
            return;
        }

        dispatchStore(
            setWorkflowData([
                ...workflowData,
                {
                    workflow: workflow,
                    step: workflow.length === 1 ? 0 : workflow.length - 1,
                    index: 0,
                },
            ])
        );
    }, [product]);

    function handleAlertOnCLickEvent() {
        Object.keys(orderWorkflowItemSlice.item.shopCart).length !== 0 &&
        !orderWorkflowItemSlice.isModification
            ? setIsAlertOpened(!isAlertOpened)
            : onClose();
    }
    function onCloseWorkflowOnClickEvent() {
        handleAlertOnCLickEvent();
        onClose();
    }
    React.useEffect(() => {
        if (product.haveBasicComposition || product?.haveWorkflow) {
            GetStepInCategory();
        }
        if (Object.keys(product).length === 0 && workflowData.length === 0) {
            const itemAdded = {
                ...orderWorkflowItemSlice.item,
                isModification: Boolean(orderWorkflowItemSlice?.isModification),
            };

            dispatchStore(
                orderWorkflowItemSlice.item.isAdditionnalProduct &&
                    !orderWorkflowItemSlice?.isModification
                    ? selectedAdditionalItems(orderWorkflowItemSlice.item)
                    : posEditor === TCPOS
                    ? addItemCommandTcPos(itemAdded)
                    : orderWorkflowItemSlice.item.isReward === true &&
                      isCustomerBenefitOpen
                    ? selectUserRewards(orderWorkflowItemSlice.item)
                    : !orderWorkflowItemSlice?.isModification
                    ? addToOrder(orderWorkflowItemSlice.item)
                    : modifyOrder(orderWorkflowItemSlice.item)
            );

            onClose();
        }
    }, [product]);

    return (
        <>
            {isAlertOpened && (
                <WorkflowAlert
                    open={isAlertOpened}
                    onClose={handleAlertOnCLickEvent}
                    onCloseWorkflowOnClickEvent={onCloseWorkflowOnClickEvent}
                    message={t(
                        "Do you want to abandon the composition of your article ?"
                    )}
                />
            )}

            <motion.div
                key="modelmain"
                initial="initial"
                animate="animate"
                variants={parentVariant}
            >
                <Dialog
                    id="workflow_dialog"
                    fullScreen={
                        workflowData.length >= 2 && isPrm ? false : true
                    }
                    open={open}
                    onClose={onClose}
                    keepMounted
                    container={() => document.querySelector(".fullscreen")}
                    hideBackdrop={isPrm}
                    // TransitionComponent={Transition}
                    PaperProps={{
                        sx: {
                            display: "grid!important",
                            gridTemplateRows: "auto 1fr auto !important",
                            alignItems: "center!important",
                            flexDirection: "column!important",
                            paddingTop: "0px!important",
                            paddingBottom: "0px!important",
                            color: "black!important",
                            backdropFilter: isPrm ? "" : "blur(5px)",
                            width:
                                workflowData.length > 1
                                    ? " 98%!important"
                                    : "100%",
                            height: isPrm
                                ? "51% !important"
                                : "100% !important",
                            maxWidth: "1080px!important",
                            maxHeight: "unset",
                            backgroundColor: "#FFFFFF!important",
                            borderRadius:
                                workflowData.length > 1
                                    ? "70px 70px 0px 0px!important"
                                    : "0px!important",
                            borderLeft: "1px solid #707070!important",
                            borderRight: "1px solid #707070!important",
                            borderBottom: "1px solid #707070!important",
                            // borderTop: `15px solid ${globalColor}!important`,
                            top: isPrm
                                ? "28% !important"
                                : workflowData.length > 1
                                ? "7px"
                                : "unset !important",
                            right: "unset!important",
                            left: "unset!important",
                            margin: "unset!important",
                            overflowY: "auto!important",
                            scrollbarWidth: "none!important",
                            zoom: `${
                                process.env.REACT_APP_MODE === CATALOGUE_MODE
                                    ? "50%"
                                    : "100%"
                            }`,
                        },
                    }}
                >
                    <motion.div
                        key="header"
                        className="child"
                        variants={childrenVariant}
                    >
                        <WorkflowHeader onClose={handleAlertOnCLickEvent} />
                        <WorkflowTree />
                    </motion.div>

                    {workflowData[workflowData.length - 1]?.workflow[
                        workflowData[workflowData.length - 1]?.index
                    ]?.Items && (
                        <WorkflowProduct
                            listProducts={
                                workflowData[workflowData.length - 1].workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ].Items
                            }
                            orderWorkflowItemSlice={orderWorkflowItemSlice}
                            workflowData={workflowData}
                            currentWorkflow={
                                workflowData[workflowData.length - 1]
                            }
                        />
                    )}
                    <motion.div
                        key="footer"
                        className="child"
                        variants={childrenVariant}
                    >
                        {isBasketActive && <WorkflowShopCarts />}

                        <WorkflowFooter
                            listworkflow={workflowData[workflowData.length - 1]}
                            onClose={handleAlertOnCLickEvent}
                            handleAlertOnCLickEvent={handleAlertOnCLickEvent}
                            setIsShowDetailsOpened={setIsShowDetailsOpened}
                            isShowDetailsOpened={isShowDetailsOpened}
                        />
                        <CommentKeyboard
                            setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                        />
                    </motion.div>
                </Dialog>
            </motion.div>
        </>
    );
}
WorkflowDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setIsShowDetailsOpened: PropTypes.func,
    isShowDetailsOpened: PropTypes.bool,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
