import { useSnapshot } from "valtio";

import React from "react";
import ReactSwitch from "react-switch";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore, setStandbyStateScreenItems } from "@pages/Kiosk/store";

type ActionsCellPropsType = {
    row: { id: number; original: { active: boolean } };
};

export function ActionsCell({ row }: ActionsCellPropsType): JSX.Element {
    const { standbyStateScreenItems } = useSnapshot(kioskStore);

    const activeScreens: StandbyScreenItemType[] =
        standbyStateScreenItems.filter(
            (item: StandbyScreenItemType) => item.active
        );

    return (
        <FlexboxGrid
            alignItems-centered={true}
            styles={{ marginLeft: "100px" }}
        >
            <ReactSwitch
                disabled={
                    activeScreens.length === 1 && row.original.active
                        ? true
                        : false
                }
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={26}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                checked={row.original.active}
                onChange={() => {
                    const localData = [...standbyStateScreenItems];
                    localData[row.id] = {
                        ...localData[row.id],
                        active: !row.original.active,
                    };

                    setStandbyStateScreenItems(localData);
                }}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={55}
                height={20}
            />
        </FlexboxGrid>
    );
}
