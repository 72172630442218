import { useLocalWays } from "@hooks/useLocalWays";

import {
    setActiveConfigOptions,
    setActiveLanguages,
    setCustomerLanguage,
    setInitialStepTimeout,
    setIsConfigOptionsActive,
    setIsLanguagesActive,
    setKioskWays,
    setLogo,
    setProjectTimeout,
} from "@pages/Kiosk/store";

export function useUpdateProjectSteps(project: ProjectType): void {
    const localKioskWays = useLocalWays(project);
    setKioskWays(localKioskWays);

    setProjectTimeout(project.template.timeout);

    setIsConfigOptionsActive(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).active
    );

    setActiveConfigOptions(
        (
            (project.template.content as ProjectContentType)
                .options as OptionsType
        ).items.filter((option) => option.active === true)
    );

    setIsLanguagesActive(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).active &&
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        )?.items.filter((option) => option.active === true).length > 1
    );

    setActiveLanguages(
        (
            (project.template.content as ProjectContentType)
                .languages as LanguagesType
        ).items.filter((language) => language.active === true)
    );

    setCustomerLanguage(
        (project.template.content.languages as LanguagesType).items.filter(
            (language) => language.isDefault === true
        )[0]
    );

    setInitialStepTimeout(project.template.initialStepTimeout as number);

    setLogo((project.template.content.generalDesign as GeneralDesignType).logo);
}
