import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    product: {},
};

export const ProductSlice = createSlice({
    name: "ProductSlice",
    initialState,
    reducers: {
        SelectProduct: (state, action) => {
            state.product = action.payload;
        },
    },
});

export const { SelectProduct } = ProductSlice.actions;
export default ProductSlice.reducer;
