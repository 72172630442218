import { BASIC_COMPOSITION, FIXED_COMPOSITION_TYPE } from "@constants";

import React from "react";

import CardBasicComposition from "./CardBasicComposition";
import CardProductSimple from "./CardProductSimple";
import "./index.css";

export default function ComponentRender({
    product,
    productType,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    handleProductSizeDialogOnCloseEvent,
    handleRemoveProductFromOrder,
    opacity,
    quantity,
    handleNutritionalInformationDialogOnCloseEvent,
}) {
    let Component = null;

    switch (productType) {
        case BASIC_COMPOSITION || FIXED_COMPOSITION_TYPE:
            Component = (
                <CardBasicComposition
                    product={product}
                    isAllergenIconActive={isAllergenIconActive}
                />
            );
            break;

        default:
            Component = (
                <CardProductSimple
                    product={product}
                    opacity={opacity}
                    quantity={quantity}
                    isAllergenIconActive={isAllergenIconActive}
                    setIsAllergenConfirmationDialogOpened={
                        setIsAllergenConfirmationDialogOpened
                    }
                    handleAddProductClick={handleAddProductClick}
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    handleProductSizeDialogOnCloseEvent={
                        handleProductSizeDialogOnCloseEvent
                    }
                    handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                />
            );
            break;
    }
    return Component;
}
