import PropTypes from "prop-types";

import React from "react";

import { useVideoReplay } from "@hooks/useReplayVideo";

export function OrderTakingBackgroundWrapper({
    children,
    filename,
    isBackgroundActive,
    isPrm,
}) {
    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv", "webm"];
    const extension = filename.split(".")[filename.split(".").length - 1];
    const refVideo = React.useRef(null);
    useVideoReplay(refVideo, [refVideo, filename]);

    return (
        <React.Fragment>
            {isBackgroundActive && !isPrm ? (
                possibleExtensions.includes(extension) ? (
                    <React.Fragment>
                        <video
                            autoPlay
                            muted
                            ref={refVideo}
                            style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                position: "fixed",
                            }}
                        >
                            <source src={filename} />
                        </video>
                        {children}
                    </React.Fragment>
                ) : (
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{
                            backgroundImage: `url('${filename}')`,
                            backgroundSize: "100% 100%",
                            height: "100%",
                            width: "100%",
                            overflow: "auto",
                            scrollbarWidth: "none",
                        }}
                    >
                        {children}
                    </div>
                )
            ) : (
                <React.Fragment> {children}</React.Fragment>
            )}
        </React.Fragment>
    );
}
OrderTakingBackgroundWrapper.propTypes = {
    filename: PropTypes.string,
    children: PropTypes.object,
    isBackgroundActive: PropTypes.bool,
    isPrm: PropTypes.bool,
};
