import { useSnapshot } from "valtio";

import React from "react";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/PrmModeWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isUserAccountActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        userAccountMessageItems,
        customerLanguage,
        actionsButtonsItems,
        isUserAuthenticated,
        user,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PreviewBackgroundWrapper>
                <div style={{ minHeight: "52%", maxHeight: "100%" }}>
                    <PrmBackgroundWrapper />
                </div>
                <div
                    style={{
                        height: "85VW",
                    }}
                >
                    <PrmModeWrapper
                        isTopBannerActive={isTopBannerActive}
                        isHeaderActive={isHeaderActive}
                        isLanguagesActive={isLanguagesActive}
                        isConfigOptionsActive={isConfigOptionsActive}
                        isActionButtonsActive={isActionButtonsActive}
                    >
                        <LogoWrapper />
                        {isHeaderActive ? (
                            <div
                                style={{ display: "grid" }}
                                className="justify-content-center align-items-center"
                            >
                                {isUserAccountActive ? (
                                    isUserAuthenticated ? (
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{ gap: "20px" }}
                                        >
                                            <img
                                                src="./images/man-head-header.png"
                                                style={{
                                                    height: "35px",
                                                    width: "35px",
                                                }}
                                                alt="man head"
                                            />
                                            <span style={{ fontSize: "35px" }}>
                                                {
                                                    userAccountMessageItems[
                                                        customerLanguage.name
                                                    ].content
                                                }{" "}
                                                {user.firstName === ""
                                                    ? user.lastName
                                                    : user.firstName}
                                            </span>
                                        </div>
                                    ) : null
                                ) : null}

                                {isInformationMessageActive ? (
                                    <div className="d-flex justify-content-center align-items-center p-2">
                                        <span
                                            style={{
                                                fontSize: "55px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {
                                                informationMessageItems[
                                                    customerLanguage.name
                                                ].content
                                            }
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <MainContentWrapper />

                        {isActionButtonsActive ? (
                            <PrmActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}

                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper />

                            {isConfigOptionsActive ? (
                                <ConfigOptionsWrapper />
                            ) : null}
                        </div>
                    </PrmModeWrapper>
                </div>
            </PreviewBackgroundWrapper>
        </GlobalPrmModeWrapper>
    );
}
