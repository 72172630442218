import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function ShopCartHeight(): JSX.Element | boolean {
    const { localShopCartOpened } = useSnapshot(kioskStore);
    return localShopCartOpened === true ? (
        <div
            style={{
                width: "100%",
                height: "250px",
            }}
        ></div>
    ) : (
        false
    );
}
