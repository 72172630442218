import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const AddToOrder = (
    props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461 87" {...props}>
            <g
                id="Groupe_62"
                data-name="Groupe 62"
                transform="translate(1767 4015)"
            >
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width={props.width}
                    height={props.height}
                    rx="9"
                    transform="translate(-1767 -4015)"
                    fill="#141414"
                />
                <text
                    id="Ajouter_à_la_commande"
                    data-name="Ajouter à la commande"
                    transform="translate(-1739 -3959)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                >
                    <tspan x="0" y="0">
                        {t("Add to order", { lng: customerLanguage.name })}
                    </tspan>
                </text>
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="461"
                    height="87"
                    rx="9"
                    transform="translate(-1767 -4015)"
                    fill="#141414"
                />
                <text
                    id="Ajouter_à_la_commande-2"
                    data-name="Ajouter à la commande"
                    transform="translate(-1536 -3959)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Add to order", { lng: customerLanguage.name })}
                </text>
            </g>
        </svg>
    );
};
AddToOrder.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
