/* eslint-disable @typescript-eslint/no-explicit-any */
import { AGENT_LOCAL_API_URL, CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsButtonClickedOnCBPaymentMode,
    setIsCashMachineTransactionCanceled,
    setIsCashTransactionValidated,
    setIsTransactionCanceled,
    setLeftToPayAfterCashMachineValidation,
    setPaymentModes,
    setPaymentStepCounter,
} from "@pages/Kiosk/store";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function AbortTransactionModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isAbortTransactionModalOpened,
        selectedRegulationModeValue,
        isTransactionCanceled,
        isAcceptAbortCBTransactionButtonDisabled,
        amountDeposited,
        isBackButtonDisabled,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        leftToPayAfterCashMachineValidation,
        orderTotalPrice,
        paymentModes,
    } = useSnapshot(kioskStore);
    const { middlewareApiUrl } = useSnapshot(store);
    const { peripheralShopApp, machineIpAddress, selectedShopApp } =
        useSnapshot(syncStore);

    function handleAbortTransaction() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const timestamp = new Date().getTime();
        if (isTransactionCanceled === false) {
            console.log("The transaction canceling is accepted!!!");
            if (
                selectedRegulationModeValue === "changeMachine" &&
                Object.values(peripheralShopApp.cash_managements as any)
                    .length > 0
            ) {
                if (isBackButtonDisabled === false) {
                    const { ip: cashMachineIpAddress, cashMachineApiUrl } =
                        Object.values(
                            peripheralShopApp.cash_managements as any
                        )[0] as any;
                    const url = `http://${cashMachineApiUrl}:5011/cancelOperation?timestamp=${timestamp}`;

                    fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            cashMachineIpAddress,
                            clientIP: machineIpAddress,
                            userId: selectedShopApp.name,
                            userPwd: selectedShopApp.passcode,
                        }),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response.code === 2) {
                                console.log(
                                    "The cash machine aborted successfully"
                                );
                                const localPaymentModes = paymentModes.filter(
                                    (mode) => mode.paymentMode === "loyalty"
                                );

                                setPaymentModes(localPaymentModes);
                                setIsAbortTransactionModalOpened(false);
                                setIsTransactionCanceled(true);

                                setIsCashMachineTransactionCanceled(false);
                            }
                        })
                        .catch((error) => {
                            console.log(
                                `Error while cancelling the ${selectedRegulationModeValue} transaction, with the message: ${error}`
                            );
                        });
                } else {
                    setIsCashTransactionValidated(true);
                    const validCashMachineTransactionAmount =
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation -
                              amountDeposited
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance -
                              amountDeposited
                            : orderTotalPrice - amountDeposited;
                    setLeftToPayAfterCashMachineValidation(
                        validCashMachineTransactionAmount
                    );
                }

                setPaymentStepCounter(0);
            } else {
                setIsBackButtonDisabled(true);
                setIsAnotherPaymentModeButtonDisabled(true);
                setIsTransactionCanceled(true);
                let url = "";
                const monetics = peripheralShopApp?.monetics;
                const moneticsConfig = monetics
                    ? Object.values(monetics as any)
                    : [];
                const mark = (moneticsConfig[0] as any)?.mark;

                if (mark === "Valina") {
                    url = `${AGENT_LOCAL_API_URL}/AbortValina?timestamp=${timestamp}`;
                } else {
                    url = `${middlewareApiUrl}/AbortCBTransaction?timestamp=${timestamp}`;
                }
                console.log("handle ABORT CB transaction", { url });

                fetch(url)
                    .then((response) => response.json())
                    .then((response) => {
                        console.log(
                            "The response  canceling CB transaction is",
                            JSON.stringify(response)
                        );
                    })
                    .catch((error) => {
                        console.log(
                            `Error while cancelling the ${selectedRegulationModeValue} transaction, with the message: ${error}`
                        );
                    });
                setIsButtonClickedOnCBPaymentMode({
                    isAnotherPaymentModeButtonClicked: false,
                    isBackPaymentModeButtonClicked: true,
                });
                setIsAbortTransactionModalOpened(false);
            }
        }
    }

    if (isAcceptAbortCBTransactionButtonDisabled) {
        console.log(
            JSON.stringify({
                isAcceptAbortCBTransactionButtonDisabled:
                    isAcceptAbortCBTransactionButtonDisabled,
            }),
            "We have received a message indicating that the payment has been accepted, which means that we cannot accept the cancellation"
        );
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isAbortTransactionModalOpened}
            contentLabel="help-modal"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "340px" }}
            >
                <img
                    alt="abort"
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType
                    )}
                />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "117px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Cancellation of payment")}
                </span>
            </div>
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("The payment will be cancelled.")}
                </span>
            </div>

            <div style={{ height: "50px" }}></div>
            <div
                className="d-flex justify-content-center align-items-center text-center w-100"
                style={{ height: "117px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Would you like to continue ?")}
                </span>
            </div>
            <div style={{ height: "200px" }}></div>
            <div
                style={{ height: "450px" }}
                className="d-flex justify-content-center"
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <button
                        style={{
                            all: "unset",
                        }}
                    >
                        <img
                            alt="abort"
                            src={getNoActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            )}
                            style={{ height: "85%" }}
                            onClick={() => {
                                if (isTransactionCanceled === false) {
                                    console.log(
                                        "The transaction canceling is refused!!!"
                                    );
                                    setPaymentStepCounter(0);
                                    setIsAbortTransactionModalOpened(false);
                                }
                            }}
                        />
                    </button>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("No")}
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <button
                        onClick={handleAbortTransaction}
                        style={{
                            all: "unset",
                        }}
                    >
                        <img
                            alt="action btn"
                            src={getYesActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            )}
                            style={{
                                height: "85%",
                                pointerEvents:
                                    isAcceptAbortCBTransactionButtonDisabled
                                        ? "none"
                                        : "auto",
                                opacity:
                                    isAcceptAbortCBTransactionButtonDisabled
                                        ? "0.5"
                                        : 1,
                            }}
                        />
                    </button>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("Yes")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}
