import { LICENCE_PROBLEM } from "@constants";
import { snapshot } from "valtio";

import {
    setIsLicenseProblem,
    setIsTechProblemModalOpened,
    store,
} from "@store";

import { syncStore } from "@components/Synchronization/store";

export function checkforLicenseAvailability(): Promise<boolean> {
    const { middlewareApiUrl } = snapshot(store);
    const { machineIpAddress } = snapshot(syncStore);
    const timestamp = new Date().getTime();

    const uuidSession = localStorage.getItem("uuidSession");
    // eslint-disable-next-line
    const shopDetail: any = JSON.parse(
        localStorage.getItem("shopDetail") as string
    );
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 180000);
    const signal = controller.signal;
    return (
        fetch(
            `${middlewareApiUrl}/verifylicense?uuidSession=${uuidSession}&shopUid=${shopDetail?.shop_uid}&uuidConnectedEntity=${shopDetail?.franchise_uid}&IPaddress=${machineIpAddress}&timestamp=${timestamp}`,
            { signal, cache: "no-store" }
        )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((result): Promise<any> => result.json())
            .then((data): boolean => {
                if (data.error) {
                    setIsLicenseProblem(true);
                    setIsTechProblemModalOpened(true, LICENCE_PROBLEM);
                    localStorage.removeItem("uuidSession");
                    console.log(
                        `license problem detected on check licenses availability with uuidSession: ${uuidSession}`
                    );
                    return false;
                }
                if (data.uuidSession !== 0 || data.uuidSession !== undefined) {
                    localStorage.setItem("uuidSession", data.uuidSession);
                    setIsLicenseProblem(false);
                    setIsTechProblemModalOpened(false);
                    console.log(
                        `Successfully open license session on check licenses availability with uuidSession: ${uuidSession}`
                    );
                    return true;
                } else {
                    setIsLicenseProblem(true);
                    setIsTechProblemModalOpened(true, LICENCE_PROBLEM);
                    console.log(
                        `license problem detected on check licenses availability with uuidSession: ${uuidSession}`
                    );
                    localStorage.removeItem("uuidSession");
                    return false;
                }
            })

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                setIsLicenseProblem(true);
                setIsTechProblemModalOpened(true, LICENCE_PROBLEM);
                localStorage.removeItem("uuidSession");
                console.log(
                    `Error while verifying license availability with message: ${error}`
                );
                return false;
            })
            .finally(() => {
                clearTimeout(timeoutId);
            })
    );
}
