import { StyledButton } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore as store } from "@pages/Kiosk/store";

import { PromoCodeModalWrapper } from "./PromoCodeModalWrapper";

export function Promotion(): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const { promoValue } = useSnapshot(store);

    const { globalColor } = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.settingSlice.generalSetting,
        shallowEqual
    );

    return (
        <React.Fragment>
            <div
                className="m-3 d-flex justify-content-between align-items-center"
                style={{
                    boxShadow: "0px 3px 6px #00000080",
                    borderRadius: "19px",
                    height: "150px",
                    width: "91%",
                    background: globalColor,
                }}
            >
                <div
                    className="m-3 d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                >
                    <span
                        style={{
                            font: "normal normal bold 40px/54px Nunito",
                        }}
                    >
                        {t("Promotions")}
                    </span>
                    {promoValue !== 0 ? (
                        <span
                            style={{
                                font: "normal normal 27px/48px Nunito",
                            }}
                        >
                            {t("(A promo code is applied)")}
                        </span>
                    ) : null}
                </div>
                {promoValue === 0 ? (
                    <StyledButton
                        className="mr-4"
                        rounded
                        variant="primary"
                        style={{ width: "50px", height: "40px" }}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {t("Scanner")}
                    </StyledButton>
                ) : null}
            </div>

            {isOpen === true ? (
                <PromoCodeModalWrapper setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : null}
        </React.Fragment>
    );
}
