import { useSnapshot } from "valtio";

import React from "react";

import {
    kioskStore,
    setIsSubStepActionButtonsActive,
    setIsSubStepIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setSubStepActionButtons,
    setSubStepIconId,
    setSubStepInformationMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function InformationModesConsommationModalWrapper(): JSX.Element {
    const {
        isPrm,
        selectedInformationMode,
        project: {
            template: {
                pages: { subSteps },
            },
        },
        saleMode,
        isUserAuthenticated,
        localSelectedInformationMode,
    } = useSnapshot(kioskStore);

    const localInformationMode =
        saleMode.name === "Delivery" && isUserAuthenticated === false
            ? localSelectedInformationMode
            : selectedInformationMode;

    React.useEffect(() => {
        setIsSubStepIconActive(
            (
                (
                    (subSteps[localInformationMode] as PageType)
                        .header as NewHeaderType
                ).icon as IconType
            ).active as boolean
        );
        setIsSubStepInformationMessageActive(
            (subSteps[localInformationMode] as PageType).header
                .informationMessage.active
        );
        setSubStepInformationMessageItems(
            (subSteps[localInformationMode] as PageType).header
                .informationMessage.languages
        );
        setIsSubStepTopBannerActive(
            (subSteps[localInformationMode] as PageType).topBanner.active
        );
        setIsSubStepLogoActive(
            (subSteps[localInformationMode] as PageType).topBanner.logo.active
        );
        setIsSubStepActionButtonsActive(
            (subSteps[localInformationMode] as PageType).actionsButtons.active
        );
        setSubStepActionButtons(
            (subSteps[localInformationMode] as PageType).actionsButtons.items
        );

        setSubStepIconId(
            (
                (subSteps[localInformationMode] as PageType).header
                    .icon as IconType
            ).id
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
