import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    DELIVERY_MODE,
    TCPOS,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import {
    getAlertActionButtonImageSrc,
    getImageContentById,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import {
    checkVisibilityNewOrder,
    clearTCPOSOrder,
    deletedInvisibleNewOrder,
    editSalesMode,
} from "@components/OrderTaking/Helpers";
import { orderSalesModeModification } from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setSaleMode,
    setSelectedInformationMode,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
} from "@pages/Kiosk/store";

import SalesModesChangeConfirmationDialogBody from "./SalesModesChangeConfirmationDialogBody";

export function SalesModesChangeConfirmationDialog({
    open,
    onClose,
    salesMode,
    isPrm,
    filledBasketIconContent,
}) {
    const [isPatientModelOpen, setIsPatientModelOpen] = React.useState(false);
    const { orderItems, totalPrice } = useSelector(
        (state) => state.orderSlice.order
    );

    const dispatchStore = useDispatch();
    const {
        project: {
            template: {
                content: { actionsButtons, informationModes },
                pages: { ways },
            },
        },
        logo,
        selectedInformationMode,
        isUserAuthenticated,
        kioskWays,
        devise,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);

    const { t } = useTranslation();

    const backButton = actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);
    const filteredButtons = actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];

    const validateImageSrc = getImageContentById(filteredButtons.id);
    const logoImageSrc = getImageContentById(logo.id);

    let order = JSON.parse(JSON.stringify(orderItems));
    const refactorOrder = () =>
        !(TCPOS === posEditor)
            ? checkVisibilityNewOrder(order, salesMode.settingKey)
            : orderItems;
    const deletedInvisibleOrder = () =>
        !(TCPOS === posEditor)
            ? deletedInvisibleNewOrder(newOrder, salesMode.settingKey)
            : orderItems;
    const editSalesModeNewOrder = () =>
        !(TCPOS === posEditor)
            ? editSalesMode(newModifiedOrder, salesMode.id)
            : orderItems;

    const [newOrder] = React.useState(refactorOrder());
    const [newModifiedOrder] = React.useState(deletedInvisibleOrder());
    const [newOrderItems] = React.useState(editSalesModeNewOrder());

    const handleModificationSalesMode = () => {
        setSaleMode(salesMode);
        if (TCPOS === posEditor) {
            setIsPatientModelOpen(true);
            setSelectedSaleModeValue(salesMode.name);
            setSelectedSaleModeId(salesMode.id);
            setSelectedSaleModeOrderTaking(salesMode.settingKey);
            dispatchStore(clearTCPOSOrder(salesMode));
        }

        if (
            salesMode.name !== DELIVERY_MODE ||
            (salesMode.name === DELIVERY_MODE && isUserAuthenticated === true)
        ) {
            setSelectedSaleModeValue(salesMode.name);
            setSelectedSaleModeId(salesMode.id);
            setSelectedSaleModeOrderTaking(salesMode.settingKey);

            dispatchStore(orderSalesModeModification(newOrderItems));
        }

        const activesInformationModes = informationModes.items.filter(
            (item) => item.active[salesMode.name]
        );
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "orderTaking")[0]
        );
        const informationModesStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "informationModes")[0]
        );

        if (
            (activesInformationModes.filter(
                (infoMode) => infoMode.shortName === selectedInformationMode
            ).length === 0 ||
                activesInformationModes.length > 1) &&
            ways.informationModes !== undefined &&
            orderTakingStepIndex > informationModesStepIndex
        ) {
            if (activesInformationModes.length === 0) {
                setFinalMessageSelectedInformationMode("none");
                setSelectedInformationMode("");
                setChosenInformationMode({
                    type: "",
                    data: { infoModeUuId: "", value: "" },
                });
                if (!isUserAuthenticated) setIsUserAccountModalOpened(true);
            } else {
                setIsInformationModesModalOpened(true);
            }
        } else if (
            salesMode.name === DELIVERY_MODE &&
            isUserAuthenticated === false &&
            ((ways.informationModes !== undefined &&
                ((activesInformationModes.filter(
                    (infoMode) => infoMode.shortName === selectedInformationMode
                ).length !== 0 &&
                    orderTakingStepIndex > informationModesStepIndex) ||
                    orderTakingStepIndex < informationModesStepIndex)) ||
                ways.informationModes === undefined)
        ) {
            setIsUserAccountModalOpened(true);
        }

        onClose();
    };

    return orderItems.length === 0 || TCPOS === posEditor ? (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1335px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid var(--global-color)!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "15%" : "unset",
                    right: "unset!important",
                    left: "unset!important",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
            onClick={isPatientModelOpen && ((e) => e.stopPropagation())}
        >
            <DialogTitle style={{ height: isPrm ? "50%" : "" }}>
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: isPrm ? "60%" : "" }}
                >
                    <img
                        src={
                            isPatientModelOpen
                                ? logoImageSrc
                                : getAlertActionButtonImageSrc(actionsButtons)
                        }
                        style={{
                            height: "188px",
                            width: "188px",
                        }}
                    />
                </div>
            </DialogTitle>
            <div className="d-flex flex-column justify-content-between h-100">
                <SalesModesChangeConfirmationDialogBody
                    setIsPatientModelOpen={setIsPatientModelOpen}
                    isPatientModelOpen={isPatientModelOpen}
                    onClose={onClose}
                />
                <DialogActions>
                    <div
                        style={{ height: "550px", gap: "30px" }}
                        className="centered-items w-100"
                    >
                        {!isPatientModelOpen ? (
                            <>
                                <div
                                    className="column-centered-items h-100 w-50"
                                    style={{ gap: "20px" }}
                                >
                                    <img
                                        src={getNoActionButtonImageSrc(
                                            actionsButtons
                                        )}
                                        style={{ height: "50%", width: "70%" }}
                                        onClick={() => {
                                            onClose();
                                        }}
                                    />

                                    <div className="d-flex justify-content-center align-items-center h-25">
                                        <span
                                            style={{
                                                font: "normal normal 600 33px/49px Segoe UI",
                                            }}
                                        >
                                            {t("No")}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="column-centered-items h-100 w-50"
                                    style={{ gap: "20px" }}
                                >
                                    <img
                                        src={getYesActionButtonImageSrc(
                                            actionsButtons
                                        )}
                                        style={{ height: "50%", width: "70%" }}
                                        onClick={() => {
                                            handleModificationSalesMode();
                                        }}
                                    />

                                    <div className="d-flex justify-content-center align-items-center h-25">
                                        <span
                                            style={{
                                                font: "normal normal 600 33px/49px Segoe UI",
                                            }}
                                        >
                                            {t("Yes")}
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    ) : (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "5px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: isPrm ? "50%!important" : "90%!important",
                    width: "98%!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid var(--global-color)!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "20%" : "unset",
                    right: "unset!important",
                    left: "unset!important",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <DialogTitle style={{ height: isPrm ? "50%" : "" }}>
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: isPrm ? "60%" : "" }}
                >
                    <img
                        src={getAlertActionButtonImageSrc(actionsButtons)}
                        style={{
                            height: "188px",
                            width: "188px",
                        }}
                    />
                </div>
                <div className="text-confirmed-alert">
                    <span>
                        {t("Your order will be modified")}.
                        <br /> {t("Do you want to continue ?")}
                    </span>
                </div>
            </DialogTitle>
            <div
                className="d-flex flex-column justify-content-start align-items-center w-100"
                style={{
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    height: "80%",
                }}
            >
                {newOrder.map((product) => (
                    <ComposedProductCartPopup
                        key={product.iuudOrder}
                        product={product}
                        isSalesModeChange={true}
                    />
                ))}
            </div>

            <div
                className="d-flex align-items-center justify-content-between w-100 px-4"
                style={{ backgroundColor: "#e3e3e3" }}
            >
                <div className="d-flex align-items-center p-1">
                    {filledBasketIconContent !== "" ? (
                        <div className="d-flex justify-content-start align-items-center h-100">
                            <img
                                src={filledBasketIconContent}
                                alt={filledBasketIconContent}
                                style={{
                                    width: " 70px",
                                    height: "70px",
                                    borderRadius: "15px",
                                }}
                            />
                        </div>
                    ) : (
                        <CartIconFilled />
                    )}
                    <span
                        style={{
                            font: "normal normal 600 20px/48px Segoe UI",
                            paddingLeft: "0.5rem",
                        }}
                    >
                        NOUVEAU PRIX :{" "}
                    </span>
                </div>

                <span style={{ font: "normal normal 600 29px/35px Segoe UI" }}>
                    {TCPOS
                        ? totalPrice.toFixed(devise.decimalPrice)
                        : newOrderItems.totalPrice.toFixed(
                              devise.decimalPrice
                          ) + ` ${devise.deviseSymbole}`}
                </span>
            </div>
            <DialogActions>
                <div
                    className="d-flex justify-content-center align-items-center pb-2"
                    style={{ gap: "15px", height: "10%" }}
                >
                    {backImageSrc !== "" ? (
                        <div className="mx-2" onClick={onClose}>
                            {VALIDATED_EXTENSION_FILE.includes(
                                backImageSrc.substr(-4)
                            ) ? (
                                <img
                                    src={backImageSrc}
                                    alt="backButton"
                                    style={{ height: "87px", width: "350px" }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={backImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}{" "}
                        </div>
                    ) : null}
                    {validateImageSrc !== "" ? (
                        <div
                            className="mx-2"
                            onClick={() => {
                                handleModificationSalesMode();
                            }}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                validateImageSrc.substr(-4)
                            ) ? (
                                <div
                                    style={{
                                        backgroundSize: " 100% 100%",
                                        backgroundImage: `url(${validateImageSrc})`,
                                        height: "87px",
                                        width: "350px",
                                    }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={validateImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}{" "}
                        </div>
                    ) : (
                        <img
                            alt="addToBasketButton"
                            src="./images/validate.png"
                            style={{ height: "87px", width: "350px" }}
                            onClick={() => {
                                handleModificationSalesMode();
                            }}
                        />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
}

SalesModesChangeConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    salesMode: PropTypes.object,
    isPrm: PropTypes.bool,
    filledBasketIconContent: PropTypes.string,
};
