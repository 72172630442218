import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { VerifyIsPrinterStepWithEmail } from "@helpers/general";

import { LoyaltyConsommationCard } from "@pages/Kiosk/Steps/LoyaltyConsommationStep/LoyaltyConsommationCard";
import { kioskStore as store } from "@pages/Kiosk/store";

import { ImageContent } from "../UsualModeMainContentWrapper/ImageContent";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function MainContentWrapper(): JSX.Element {
    const {
        mainContentItems,
        navigationIndex,
        kioskWays,
        selectedSaleModeValue,
        customerLanguage,
    } = useSnapshot(store);

    const isPrinterStepWithEmail = VerifyIsPrinterStepWithEmail();

    const activeItems =
        kioskWays[navigationIndex]?.name === "informationModes"
            ? (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          (item.active as ItemContentType)[
                              selectedSaleModeValue
                          ] === true
                      );
                  }
              )
            : kioskWays[navigationIndex]?.name === "printerOptions"
            ? (mainContentItems as printerOptionsItemsType[])?.filter(
                  (item) => {
                      if (isPrinterStepWithEmail) {
                          return (
                              item.active === true &&
                              item.languages[customerLanguage.name].content !==
                                  "" &&
                              (item.shortName === "envoyer" ||
                                  item.shortName === "nepasenvoyer")
                          );
                      } else {
                          return (
                              item.active === true &&
                              item.languages[customerLanguage.name].content !==
                                  "" &&
                              (item.shortName === "oui" ||
                                  item.shortName === "non")
                          );
                      }
                  }
              )
            : (mainContentItems as ProjectMainContentItemType[]).filter(
                  (item) => {
                      return (
                          item.active === true &&
                          item.languages[customerLanguage.name].content !== ""
                      );
                  }
              );

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 3 ? (
                <div className="d-flex flex-column justify-content-center align-items-center p-2">
                    {kioskWays[navigationIndex]?.name ===
                    "loyaltyConsommation" ? (
                        <div style={{ marginBottom: "500px" }}>
                            <LoyaltyConsommationCard />
                        </div>
                    ) : null}

                    <div className="d-flex justify-content-center align-items-center w-100">
                        {activeItems.map(
                            // eslint-disable-next-line
                            (item: any, index: number) => (
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            )
                        )}
                    </div>
                </div>
            ) : (
                <Swiper
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={40}
                    modules={[Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {activeItems.map(
                        // eslint-disable-next-line
                        (item: any, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}
