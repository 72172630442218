import React from "react";

type SettingSyncroProps = {
    isDisabled: boolean;
};
export default function SettingSyncro({
    isDisabled,
}: SettingSyncroProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={36.946}
            height={36.048}
            viewBox="0 0 36.946 36.048"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="path4776"
                        d="M0-682.665H36.946v36.048H0Z"
                        transform="translate(0 682.665)"
                        fill={isDisabled ? "#fff" : "#7B7B7B"}
                    />
                </clipPath>
            </defs>
            <g id="g4770" transform="translate(0 682.665)">
                <g id="g4772" transform="translate(0 -682.665)">
                    <g id="g4774" clipPath="url(#clip-path)">
                        <g id="g4780" transform="translate(8.017 7.463)">
                            <path
                                id="path4782"
                                d="M-334.089-189.439a8.151,8.151,0,0,0-.349-2.242l2.146-1.209-2.165-3.658-2.123,1.2a8.7,8.7,0,0,0-4-2.235V-200h-4.33v2.412a8.7,8.7,0,0,0-4,2.235l-2.123-1.2-2.165,3.658,2.146,1.209a8.151,8.151,0,0,0-.349,2.242,8.151,8.151,0,0,0,.349,2.242l-2.146,1.209,2.165,3.658,2.123-1.2a8.7,8.7,0,0,0,4,2.235v2.412h4.33v-2.412a8.7,8.7,0,0,0,4-2.235l2.123,1.2,2.165-3.658-2.146-1.209A8.151,8.151,0,0,0-334.089-189.439Z"
                                transform="translate(353.204 200)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                        <g id="g4784" transform="translate(14.144 13.8)">
                            <path
                                id="path4786"
                                d="M-75.67-151.551A4.282,4.282,0,0,1-80-155.775,4.282,4.282,0,0,1-75.67-160a4.282,4.282,0,0,1,4.33,4.224A4.282,4.282,0,0,1-75.67-151.551Z"
                                transform="translate(80 160)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                        <g id="g4788" transform="translate(1.082 1.056)">
                            <path
                                id="path4790"
                                d="M-23.344-418.028a16.5,16.5,0,0,1-1.336-6.337A17.254,17.254,0,0,1-7.289-441.332a18.1,18.1,0,0,1,13.777,6.513"
                                transform="translate(24.68 441.332)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                        <g id="g4792" transform="translate(4.696 11.687)">
                            <path
                                id="path4794"
                                d="M-521.379,0a16.5,16.5,0,0,1,1.336,6.337A17.254,17.254,0,0,1-537.434,23.3a18.1,18.1,0,0,1-13.777-6.513"
                                transform="translate(551.211)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                        <g id="g4796" transform="translate(27.658 3.089)">
                            <path
                                id="path4798"
                                d="M-53.508,0l1.531,4.481-4.592-1.494"
                                transform="translate(56.569)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                        <g id="g4800" transform="translate(4.696 28.479)">
                            <path
                                id="path4802"
                                d="M-80.261-26.79l-4.592-1.494,1.531,4.481"
                                transform="translate(84.853 28.284)"
                                fill="none"
                                stroke={isDisabled ? "#fff" : "#7B7B7B"}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
