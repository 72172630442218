import { useSnapshot } from "valtio";

import React from "react";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import OrderTakingScreen from "@components/OrderTaking/Screens/OrderTakingScreen";
import PrmOrderTakingScreen from "@components/OrderTaking/Screens/PrmOrderTakingScreen";

import { kioskStore } from "@pages/Kiosk/store";

export function Screens() {
    const { isPrm } = useSnapshot(kioskStore);
    useSalesModeModification();

    return <>{isPrm ? <PrmOrderTakingScreen /> : <OrderTakingScreen />}</>;
}
