import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    handleClearClickAndCollectInput: () => void;
    setIsInvalidQrCodeModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isInvalidQrCodeModalOpened: boolean;
};
export function QrCodeIncorrectModalWrapper({
    handleClearClickAndCollectInput,
    setIsInvalidQrCodeModalOpened,
    isInvalidQrCodeModalOpened,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(store);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isInvalidQrCodeModalOpened}
            onRequestClose={() => {
                setIsInvalidQrCodeModalOpened(false);
                handleClearClickAndCollectInput();
            }}
            contentLabel="qr-code -incorrect"
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: "15px solid #040404",
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                onClick={() => {
                    setIsInvalidQrCodeModalOpened(false);
                    handleClearClickAndCollectInput();
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                >
                    <img src={alertImgSrc} />
                </div>

                <div
                    className="d-flex justify-content-center text-center w-100"
                    style={{ height: "270px", fontSize: "55px" }}
                >
                    {t("Click & Collect pickup")}
                </div>
                <div
                    className="d-flex flex-column align-items-center text-center w-100"
                    style={{ height: "415px", fontSize: "45px" }}
                >
                    <span>{t("Unknown QR code.")}</span>
                    <span>{t("Please contact a manager")}</span>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{ height: "250px", fontSize: "35px" }}
                >
                    {t("Touch the screen to continue")}
                </div>
            </div>
        </Modal>
    );
}
