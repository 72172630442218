import { useSnapshot } from "valtio";

import React from "react";

import { CommandInsertionProblemModalWrapper } from "@components/common/CommandInsertionProblemModalWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function ContainerContent(): JSX.Element {
    const { isCommandInsertionProblemModalOpened } = useSnapshot(kioskStore);

    return (
        <React.Fragment>
            {isCommandInsertionProblemModalOpened === true ? (
                <CommandInsertionProblemModalWrapper />
            ) : null}
        </React.Fragment>
    );
}
