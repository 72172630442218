/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    NO_THANKS_BUTTON_ROLE,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import {
    addResetUserRewards,
    addRewardsItems,
    cancelUserGifts,
    setUsedGifts,
} from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setIsCustomerBenefitOnClick,
    setIsCustomerBenefitOpen,
} from "@pages/Kiosk/store";

export default function Footer(): JSX.Element {
    const dispatch = useDispatch();
    const {
        project: {
            template: {
                content,
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: { nothanksButton },
                        },
                    },
                },
            },
        },
    } = useSnapshot(kioskStore);
    const { userRewards, userGifts } = useSelector(
        (state: any) => state.orderSlice
    );
    const [initialReward] = React.useState(userRewards);

    const { actionsButtons } = useSelector(
        (state: any) => state.settingSlice.generalSetting
    );

    const noThanksButton = actionsButtons.find(
        (item: any) => item.active && item.role === NO_THANKS_BUTTON_ROLE
    );
    const validateButton = (
        content.actionsButtons as ActionsButtonsDesignType
    ).items.filter((item: any) => item.role === VALIDATE_BUTTON_ROLE)[0];

    const validateImageSrc = getImageContentById(validateButton.id);
    const noThnxImageSrc = getImageContentById(noThanksButton.id);

    const handleValidateOnClickEvent = () => {
        dispatch(addRewardsItems());

        const selectedCode =
            userGifts.filter((item: any) => item.isSelected) || [];
        dispatch(setUsedGifts(selectedCode));

        setIsCustomerBenefitOpen(false);
        setIsCustomerBenefitOnClick(false);
    };

    const closeModal = () => {
        setIsCustomerBenefitOpen(false);
        setIsCustomerBenefitOnClick(false);
        dispatch(addResetUserRewards(initialReward));
        dispatch(cancelUserGifts());
    };

    return (
        <div
            className="centered-items w-100 position-absolute"
            style={{ height: "100px", bottom: "1rem" }}
        >
            {nothanksButton ? (
                noThnxImageSrc ? (
                    <div
                        className="additional-sale-button mx-3"
                        onClick={closeModal}
                    >
                        {VALIDATED_EXTENSION_FILE.includes(
                            noThnxImageSrc.substr(-4)
                        ) ? (
                            <img
                                alt="No thank you button"
                                src={noThnxImageSrc}
                                style={{ height: "87px", width: "350px" }}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={noThnxImageSrc}
                                width={"350px"}
                                height={"87px"}
                            />
                        )}
                    </div>
                ) : (
                    <button
                        className="additional-sale-button mx-2"
                        style={{ background: `var(--global-color)` }}
                        onClick={closeModal}
                    >
                        <div className="text-button">{t("No thanks")}</div>
                    </button>
                )
            ) : null}

            {validateImageSrc ? (
                <div
                    className="additional-sale-button mx-3"
                    onClick={handleValidateOnClickEvent}
                >
                    {VALIDATED_EXTENSION_FILE.includes(
                        validateImageSrc.substr(-4)
                    ) ? (
                        <img
                            alt="validate button"
                            src={validateImageSrc}
                            style={{ height: "87px", width: "350px" }}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={validateImageSrc}
                            width={"350px"}
                            height={"87px"}
                        />
                    )}
                </div>
            ) : (
                <button
                    onClick={handleValidateOnClickEvent}
                    className="additional-sale-button"
                    style={{ background: `var(--global-color)` }}
                >
                    <div className="text-button">{t("Validate")}</div>
                </button>
            )}
        </div>
    );
}
