import styled from "styled-components";

type PropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isActionButtonsActive: boolean;
};

export const PaymentContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isHeaderActive,
        isTopBannerActive,
        isActionButtonsActive,
    }: PropsType) =>
        isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "12fr 12fr 71fr 5fr"
            : !isTopBannerActive && isHeaderActive && isActionButtonsActive
            ? "12fr 83fr 5fr"
            : isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "12fr 83fr 5fr"
            : isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "12fr 12fr 76fr"
            : !isTopBannerActive && !isHeaderActive && isActionButtonsActive
            ? "12fr 83fr 5fr"
            : !isTopBannerActive && isHeaderActive && !isActionButtonsActive
            ? "20fr 80fr"
            : isTopBannerActive && !isHeaderActive && !isActionButtonsActive
            ? "20fr 80fr"
            : "100%"};
`;
