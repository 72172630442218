import { StyledButton } from "@aureskonnect/react-ui";
import { TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import {
    getSkippedStepNumber,
    VerifyIsPrinterStepWithPrintTicketButton,
} from "@helpers/general";

import {
    kioskStore,
    setIsPrintTicket,
    setIsSendMailWithPdfActive,
    setNavigationIndex,
} from "@pages/Kiosk/store";

import { ActionsButtonsImageContent } from "../ActionsButtonsWrapper/ActionsButtonsImageContent";

type ActionsButtonsPropsType = {
    actionsButtonsItems: ActionsButtonsDesignItemType[];
};

export function PrmActionsButtonsWrapper({
    actionsButtonsItems,
}: ActionsButtonsPropsType): JSX.Element {
    const {
        navigationIndex,
        kioskWays,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isUserAuthenticated,
        project: {
            template: { content },
        },
        orderTotalPrice,
        customerLanguage,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);

    const isPrinterStep = VerifyIsPrinterStepWithPrintTicketButton();

    async function handleOnClickEvent() {
        setIsPrintTicket(true);
        setIsSendMailWithPdfActive(false);
        setNavigationIndex(
            navigationIndex +
                Number(
                    await getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content,
                        Number(orderTotalPrice)
                    )
                )
        );
    }
    return (
        <div className="d-flex justify-content-center align-items-center">
            {actionsButtonsItems?.length === 1 ? (
                <div className="d-flex justify-content-center align-items-center">
                    <ActionsButtonsImageContent item={actionsButtonsItems[0]} />
                </div>
            ) : actionsButtonsItems?.length === 2 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={item.id}
                                    item={item}
                                />
                            );
                        }
                    )}
                </div>
            ) : actionsButtonsItems?.length === 3 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={index}
                                    item={item}
                                />
                            );
                        }
                    )}
                </div>
            ) : null}
            {isPrinterStep && !(TCPOS === posEditor) ? (
                <StyledButton
                    rounded={true}
                    className="m-2"
                    style={{
                        height: "80px",
                        width: "242px",
                        background: "#545454",
                        font: "normal normal 600 18px/24px Segoe UI",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #FFFFFF",
                        marginRight: "0px",
                    }}
                    onClick={handleOnClickEvent}
                >
                    {t("Print ticket", { lng: customerLanguage.name })}
                </StyledButton>
            ) : null}
        </div>
    );
}
