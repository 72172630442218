import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { shallowEqual, useSelector } from "react-redux";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { setPromoValue, kioskStore as store } from "@pages/Kiosk/store";

import "./index.css";

type PropsType = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export function PromoCodeModalWrapper({
    isOpen,
    setIsOpen,
}: PropsType): JSX.Element {
    const { isPrm } = useSnapshot(store);
    const [promoCodeValue, setPromoCodeValue] = React.useState("");

    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(store);

    const { globalColor } = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.settingSlice.generalSetting,
        shallowEqual
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const backButton = (actionsButtons as any).items.filter((button: any) => {
        return button.role === BACK_BUTTON_ROLE;
    })[0];
    const backButtonImageSrc = getImageContentById(backButton.id);
    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setPromoCodeValue(e.target.value);
    }

    if (promoCodeValue.length === 4) {
        getResponse();
    }

    async function getResponse() {
        try {
            const response = await fetch(`/promocode.json`);
            const data = await response.json();
            setPromoValue(data.value);
            setIsOpen(false);
        } catch (error) {
            console.error("promo error:", JSON.stringify(error));
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(!isOpen);
            }}
            contentLabel="qr-code -incorrect"
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "80vh",
                    width: "80vw",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid ${globalColor}`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <ModalHeader className="border-0" style={{ height: "25%" }}>
                <div className="mb-2 d-flex justify-content-center align-items-center w-100">
                    <img
                        src="./images/gift_icon.png"
                        style={{ height: "6vh" }}
                    />
                </div>

                <div
                    className="mx-3 d-flex justify-content-center align-items-center text-center"
                    style={{
                        fontSize: "4rem",
                        cursor: "default",
                    }}
                >
                    {t("Please scan your promo code")}
                </div>
            </ModalHeader>
            <ModalBody className="d-flex justify-content-center align-items-center">
                <input
                    className="form-input text-center hidden-promo-code__clz"
                    value={promoCodeValue}
                    type="text"
                    placeholder={t("Promo code")}
                    onChange={handleOnChangeEvent}
                    autoFocus={true}
                />
            </ModalBody>
            <ModalFooter
                className="border-0 d-flex justify-content-center align-items-center w-100"
                style={{ height: "15%" }}
            >
                <div
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    {VALIDATED_EXTENSION_FILE.includes(
                        backButtonImageSrc.substr(-4)
                    ) ? (
                        <img
                            src={backButtonImageSrc}
                            alt="action button"
                            style={{
                                backgroundSize: "100% 100%",
                            }}
                        />
                    ) : (
                        <DynamicSvgComponent
                            nameSvg={backButtonImageSrc}
                            width={"350px"}
                            height={"87px"}
                        />
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
}
