import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    setLoginKeyboardInputsValues,
    syncStore,
} from "@components/Synchronization/store";

import { kioskStore, setIsUsersListModalOpened } from "@pages/Kiosk/store";

export function UsersListModalWrapper(): JSX.Element {
    const { isUsersListModalOpened } = useSnapshot(kioskStore);
    const { loginKeyboardInputsValues } = useSnapshot(syncStore);
    const [selectedUser, setSelectedUser] = React.useState<string>("");

    const usersList = [
        { name: "patrick a", email: "kiosk.app@gmail.com" },
        { name: "patrick b", email: "patrick.breulb@gmail.com" },
        { name: "patrick c", email: "patrick.breulc@gmail.com" },
        { name: "patrick d", email: "patrick.breuld@gmail.com" },
        { name: "patrick e", email: "patrick.breule@gmail.com" },
        { name: "patrick f", email: "patrick.breulf@gmail.com" },
    ];
    return (
        <Modal
            isOpen={isUsersListModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "1368px",
                    width: "914px",
                    marginTop: "275px",
                    marginLeft: "82px",
                    backgroundColor: "rgba(81, 79, 79, 0.55)",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "800px",
                    width: "700px",
                    zIndex: 2,
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",
                    borderRadius: "12px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    boxShadow: "0px 3px 6px #00000029",
                    backdropFilter: "blur(30px)",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        borderBottom: "1px solid #939292",
                        width: "650px",
                        height: "140px",
                        marginLeft: "25px",
                        font: "normal normal 600 37px/50px Nunito Sans",
                    }}
                >
                    {t("Users list")}
                </div>
                <div
                    style={{
                        display: "grid",
                        height: "500px",
                        font: "normal normal 600 18px/24px Segoe UI",
                        marginLeft: "30px",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: `repeat(${usersList.length},70px) auto`,
                        overflowY: "auto",
                    }}
                >
                    {usersList.map((user, index) => {
                        return (
                            <div
                                className="d-flex align-items-center px-3"
                                key={index}
                                style={{
                                    height: "65px",
                                    width: "650px",
                                    gap: "30px",
                                    backgroundColor:
                                        index % 2 === 0 ? "#FFFFFF" : "#F4F4F4",
                                }}
                                onClick={() => {
                                    setSelectedUser(user.email);
                                }}
                            >
                                <input
                                    id={user.name}
                                    checked={selectedUser === user.email}
                                    type="checkbox"
                                    style={{
                                        height: "40px",
                                        width: "40px",
                                        accentColor: "white",
                                    }}
                                />
                                <label>{user.name}</label>
                            </div>
                        );
                    })}
                </div>
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                        height: "140px",
                        borderTop: "1px solid #9d9a9a",
                        marginLeft: "25px",
                        width: "650px",
                    }}
                >
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "250px",
                            height: "70px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                        }}
                        onClick={() => {
                            setIsUsersListModalOpened(false);
                        }}
                    >
                        {t("Back")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        disabled={selectedUser === ""}
                        style={{
                            width: "250px",
                            height: "70px",
                            background: "#070707",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #FFFFFF",
                            borderRadius: "12px",
                        }}
                        onClick={() => {
                            setLoginKeyboardInputsValues({
                                ...loginKeyboardInputsValues,
                                email: selectedUser,
                            });
                            setIsUsersListModalOpened(false);
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
}
