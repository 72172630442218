import { addWorkflowStepChoice } from "@components/OrderTaking/Store/feature";

function addNbrOfChoiceStep(
    currentStep,
    product,
    AddWorkFlowItemShopCartData,
    workflowData,
    dispatch,
    isAutoPermutation = false,
    menuOnModification
) {
    let NbrOfChoices = currentStep.NbrOfChoices + 1;
    let withSpecialPrice = false;
    let withStepPrice = false;
    let {
        nbrOfChoicesWithspecialPrice,
        selectedChoicesWithspecialPrice,
        nbrOfChoicesWithStepPrice,
        selectedChoicesWithStepPrice,
    } = currentStep;

    // ! in progress
    if (nbrOfChoicesWithspecialPrice > 0) {
        if (selectedChoicesWithspecialPrice < nbrOfChoicesWithspecialPrice) {
            selectedChoicesWithspecialPrice += 1;
            withSpecialPrice = true;
        } else if (nbrOfChoicesWithStepPrice > 0) {
            if (selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice) {
                selectedChoicesWithStepPrice += 1;
                withStepPrice = true;
            }
        }
    } else if (nbrOfChoicesWithStepPrice > 0) {
        if (selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice) {
            selectedChoicesWithStepPrice += 1;
            withStepPrice = true;
        }
    }

    const haveWorkflow =
        product.haveWorkflow ||
        product.haveBasicComposition ||
        product.haveAdditionalSale;

    //!  ici on modifie nombre de choix avec test si on passe a l'etape suivante si produit n'a pas workflow
    if (
        !isAutoPermutation ||
        (isAutoPermutation && currentStep.NbrOfChoices === 0)
    ) {
        dispatch(
            addWorkflowStepChoice({
                NbrOfChoices,
                selectedChoicesWithStepPrice,
                selectedChoicesWithspecialPrice,
                haveWorkflow,
                menuOnModification,
                isAutoPermutation,
            })
        );
    }
    // ! ajouter produit au mini panier de menu

    AddWorkFlowItemShopCartData(workflowData, withSpecialPrice, withStepPrice);
}

export default addNbrOfChoiceStep;
