export const transitions = {
    none: {
        prettyName: "None",
        timeout: 0,
        className: "none",
    },
    fade: {
        prettyName: "Fade",
        timeout: 1000,
        className: "fade",
    },
    slide: {
        prettyName: "Slide",
        timeout: 4500,
        className: "slide",
    },
    rotate: {
        prettyName: "Rotate",
        timeout: 300,
        className: "rotate",
    },
    leftToRight: {
        prettyName: "Left to right",
        timeout: 4500,
        className: "left-to-right",
    },
    rightToLeft: {
        prettyName: "Right to left",
        timeout: 4500,
        className: "right-to-left",
    },
    topToBottom: {
        prettyName: "Top to bottom",
        timeout: 4500,
        className: "top-to-bottom",
    },
};
