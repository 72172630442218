import React from "react";

export const CheckInCircle = (
    props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
            background: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        viewBox="0 0 512 512"
        width={512}
        height={512}
        {...props}
    >
        <path d="M256 512c-68.38 0-132.667-26.629-181.019-74.98C26.628 388.667 0 324.38 0 256S26.628 123.333 74.981 74.98C123.333 26.629 187.62 0 256 0s132.667 26.629 181.019 74.98C485.372 123.333 512 187.62 512 256s-26.628 132.667-74.981 181.02C388.667 485.371 324.38 512 256 512zm0-490C126.972 22 22 126.972 22 256s104.972 234 234 234 234-104.972 234-234S385.028 22 256 22z" />
        <path
            style={{
                fill: "#3cc",
            }}
            d="M219.253 331.194a10.965 10.965 0 0 1-7.749-3.192l-79.467-78.875c-4.312-4.28-4.338-11.245-.058-15.557 4.279-4.311 11.243-4.338 15.556-.059l71.689 71.155 135.74-135.74c4.296-4.295 11.261-4.295 15.557 0 4.295 4.296 4.295 11.261 0 15.557l-143.489 143.49a10.969 10.969 0 0 1-7.779 3.221z"
        />
    </svg>
);
