import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import {
    kioskStore,
    setCreditCardPaymentChoice,
    setIsBackWithoutCreditCardPaymentChoice,
} from "@pages/Kiosk/store";

import "./index.css";

type ImageContentPropsType = {
    item: TPAValinaItemType;
    activeItemsLength: number;
    setIsTpaValinaModalOpened: (variable: boolean) => void;
};

export function ImageContent({
    item,
    setIsTpaValinaModalOpened,
}: ImageContentPropsType): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);

    const imageSrc = getImageContentById(item.id);

    function handleOnClickEvent() {
        console.log(
            JSON.stringify({ creditCardPaymentChoice: item.shortName })
        );
        setCreditCardPaymentChoice(item.shortName);
        setIsTpaValinaModalOpened(false);
        setIsBackWithoutCreditCardPaymentChoice(false);
    }

    return (
        <div
            className="justify-content-center align-items-center"
            style={{ display: "grid", gridTemplateRows: "6fr 1fr" }}
            onClick={handleOnClickEvent}
        >
            <div className="d-flex justify-content-center align-items-end h-100">
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        height: isPrm ? "75%" : "",
                    }}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontSize: isPrm ? "20px" : "35px" }}>
                    {(item as TPAValinaItemType).isNameVisible
                        ? (item as TPAValinaItemType).name
                        : null}
                </span>
            </div>
        </div>
    );
}
