/* eslint-disable @typescript-eslint/no-explicit-any */
import { CATALOGUE_MODE } from "@constants";
import { Dialog } from "@mui/material";
import { SpinningCircles } from "svg-loaders-react";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    handleCreateVoucher,
    refundAmountCashMachine,
} from "@helpers/cashmachine";
import {
    acceptVoucherGetting,
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getSkippedStepNumber,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { syncStore } from "@components/Synchronization/store";

import { kioskStore, setNavigationIndex } from "@pages/Kiosk/store";
import {
    setCashMachineStatus,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsButtonClickedOnCBPaymentMode,
    setIsCommandInsertionProblemModalOpened,
    setIsOrderInsertionRefused,
} from "@pages/Kiosk/store/actions";

import MainContent from "./MainContent";
import WithdrawalCasMachine from "./WithdrawalCasMachine";

type CachMashineModalWrapperType = {
    handleCommandPayment: () => Promise<void>;
};

export default function CachMashineModalWrapper({
    handleCommandPayment,
}: CachMashineModalWrapperType): JSX.Element {
    const {
        isPrm,
        project: {
            template: { content },
        },
        cashMachineStatus,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        orderTotalPrice,
    } = useSnapshot(kioskStore);
    const { selectedShopApp, peripheralShopApp, machineIpAddress } =
        useSnapshot(syncStore);

    const middlwareOrder = useSelector(
        (state: any) => state.middlwareOrderSlice.middlwareOrder
    );

    const { t } = useTranslation();

    const onClose = () => {
        setCashMachineStatus({
            isOpened: false,
            isNotAvailable: false,
            isRefundProblem: false,
            isOccupied: false,
            isBillRefund: false,
            isNetworkLost: false,
            isNetworkProblem: false,
            isRefund: false,
        });
    };

    function handleOnCilckEvent(): void {
        if (cashMachineStatus.isNotAvailable || cashMachineStatus.isOccupied) {
            handleRetryCashMachine();
        } else {
            handleCommandCashmachine();
        }
        onClose();
    }

    function handleRetryCashMachine(): void {
        console.log("recall new payment transaction for cash machine");
        setIsButtonClickedOnCBPaymentMode({
            isAnotherPaymentModeButtonClicked: false,
            isBackPaymentModeButtonClicked: false,
        });
        setIsAnotherPaymentModeButtonDisabled(false);
        setIsBackButtonDisabled(false);
        onClose();
        handleCommandPayment();
    }

    async function handleCommandCashmachine(): Promise<void> {
        console.log("cash machine command inserting");
        const isVoucherCreated = await handleCreateVoucher(middlwareOrder);

        if (isVoucherCreated) {
            console.log("handle command inserting after voucher created");
            acceptVoucherGetting(orderTotalPrice);
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
        } else {
            console.log("problem on creating cash machine voucher");
            setIsCommandInsertionProblemModalOpened(true);
            setIsOrderInsertionRefused(true);
        }
    }

    async function handleAbortCashMachine(): Promise<void> {
        console.log("recall new payment transaction");
        if (cashMachineStatus.isNetworkProblem) {
            handleResetCashMachine();
        }

        onClose();
        const isRefundProblem = await refundAmountCashMachine();
        if (!isRefundProblem) {
            setNavigationIndex(0);
        }
    }

    async function handleResetCashMachine(): Promise<void> {
        const cashMachine: any = peripheralShopApp?.cash_managements;
        const cashMachineConfig: any = cashMachine
            ? Object.values(cashMachine)
            : [];
        const cashMachineIpAddress = cashMachineConfig[0]?.ip;
        const cashMachinePort = cashMachineConfig[0]?.cashMachinePort;
        const cashMachineApiUrl = cashMachineConfig[0]?.cashMachineApiUrl;

        fetch(`http://${cashMachineApiUrl}:5011/initConnection`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cashMachineIpAddress,
                cashMachinePort,
                clientIP: machineIpAddress,
                userId: selectedShopApp.name,
                userPwd: selectedShopApp.passcode,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Success on init cash machine connection:", data);
            })
            .catch((error) => {
                console.error("Error on init cash machine connection:", error);
            });
    }

    React.useEffect(() => {
        return () => onClose();
    }, []);

    return (
        <Dialog
            open={cashMachineStatus.isOpened}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: cashMachineStatus.isBillRefund ? "8rem!important" : "",
                    alignItems: "center!important",
                    justifyContent: "space-around",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1230px!important",
                    width: "860px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    borderTop: `15px solid var(--global-color)!important`,
                    top: "unset!important",
                    right: "unset!important",
                    left: "unset!important",
                    bottom: isPrm ? "-14%" : "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {cashMachineStatus.isBillRefund ? (
                <WithdrawalCasMachine />
            ) : (
                <>
                    <div className="d-flex justify-content-center align-items-center w-100 modal-container">
                        <img
                            alt="alert image"
                            className="alert-image"
                            src={getAlertActionButtonImageSrc(
                                content.actionsButtons as ActionsButtonsDesignType
                            )}
                        />
                    </div>
                    <MainContent />
                    <div className="d-flex justify-content-center align-items-center btn-container">
                        {cashMachineStatus.isNetworkLost ? (
                            <SpinningCircles width="150px" height="150px" />
                        ) : (
                            <>
                                {cashMachineStatus.isNotAvailable ||
                                cashMachineStatus.isOccupied ||
                                cashMachineStatus.isRefund ||
                                cashMachineStatus.isNetworkProblem ? (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img
                                            alt="no button"
                                            src={getNoActionButtonImageSrc(
                                                content.actionsButtons as ActionsButtonsDesignType
                                            )}
                                            className="btn_clz"
                                            onClick={handleAbortCashMachine}
                                        />
                                        <span className="btn-text">
                                            {t("Abort")}
                                        </span>
                                    </div>
                                ) : null}
                                {!cashMachineStatus.isNetworkProblem ? (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img
                                            alt="yes button"
                                            className="btn_clz"
                                            src={getYesActionButtonImageSrc(
                                                content.actionsButtons as ActionsButtonsDesignType
                                            )}
                                            onClick={handleOnCilckEvent}
                                        />
                                        <span className="btn-text">
                                            {cashMachineStatus.isNotAvailable ||
                                            cashMachineStatus.isOccupied
                                                ? t("Retry")
                                                : t("Yes")}
                                        </span>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                </>
            )}
        </Dialog>
    );
}
