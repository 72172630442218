/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

import { LoyaltyCard } from "./LoyaltyCard";
import "./promo.css";
import {
    AvailableBalance,
    EmptyCodePromoContainer,
    GiftsContainer,
    GitsTitle,
} from "./styledCss";

import "swiper/swiper-bundle.min.css";

export default function GiftSection(): JSX.Element | null {
    const {
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts,
                                rewards: { active: rewardsActive },
                            },
                        },
                    },
                },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const isGiftOnly = !rewardsActive;

    return gifts.active ? (
        <GiftsContainer className="w-100" isGiftOnly={isGiftOnly}>
            <GiftSectionTitle
                giftsTitleMsg={gifts.languages[customerLanguage.name]}
            />
            <GiftsCardSwiperContainer isGiftOnly={isGiftOnly} />
        </GiftsContainer>
    ) : null;
}

type giftSectionTitleType = {
    giftsTitleMsg: string;
};

function GiftSectionTitle({ giftsTitleMsg }: giftSectionTitleType) {
    const userGifts = useSelector(
        (state: any) => state.orderSlice.userGifts,
        shallowEqual
    );
    const availableGiftsTotal = userGifts.length;
    return (
        <GitsTitle>
            <strong>{giftsTitleMsg} </strong>
            <AvailableBalance>
                {t("({{loyaltyAccount}} available)", {
                    loyaltyAccount: availableGiftsTotal,
                })}
            </AvailableBalance>
        </GitsTitle>
    );
}

type GiftsCardSwiperContainerType = {
    isGiftOnly: boolean;
};

function GiftsCardSwiperContainer({
    isGiftOnly,
}: GiftsCardSwiperContainerType) {
    const userGifts = useSelector(
        (state: any) => state.orderSlice.userGifts,
        shallowEqual
    );
    const isEmptyCodePromo = userGifts.length === 0;
    return (
        <div
            className={`d-flex flex-row ${
                isEmptyCodePromo
                    ? "justify-content-center align-items-center"
                    : null
            }`}
        >
            {isEmptyCodePromo ? (
                <EmptyCodePromoContainer>
                    <DynamicSvgComponent
                        nameSvg="promoCode"
                        width={"100px"}
                        height={"100px"}
                    />
                    <span>{t("No gifts awarded")}</span>
                </EmptyCodePromoContainer>
            ) : (
                <Swiper
                    slidesPerView={isGiftOnly ? 5.5 : 4.15}
                    slidesPerGroup={4}
                    spaceBetween={5}
                    direction={isGiftOnly ? "vertical" : "horizontal"}
                    className="position-absolute w-100 h-100"
                    style={{
                        maxWidth: "61.5rem",
                        maxHeight: isGiftOnly ? "40rem" : "7rem",
                        boxShadow: isGiftOnly
                            ? "inset 0px -2px 9px -7px rgba(0, 0, 0, 0.4), inset 0px 2px 9px -7px rgba(0, 0, 0, 0.4)"
                            : "inset -2px 0px 9px -7px rgba(0, 0, 0, 0.4), inset 2px 0px 9px -7px rgba(0, 0, 0, 0.4)",
                        margin: "0 0.5rem",
                        padding: "0.5rem",
                    }}
                >
                    {userGifts.map((couponData: any, index: number) => {
                        return (
                            <SwiperSlide
                                key={index}
                                className="product-swiper-slide"
                            >
                                <LoyaltyCard
                                    key={index}
                                    couponData={couponData}
                                    isPromoCode={true}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </div>
    );
}
