import React from "react";
import { KeyboardInput } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import KioskKeyboard from "@components/common/KioskKeyboard";

import { setKeyboardInputsValues } from "@pages/Kiosk/store";

import "./index.css";

export function KeyboardWrapper(): JSX.Element {
    const [, setInputs] = React.useState<KeyboardInput>({});
    const [inputName] = React.useState<string>("");

    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);

    function handleOnChangeAllEvent(inputs: KeyboardInput) {
        setKeyboardInputsValues(inputs);
        setInputs({ ...inputs });
    }

    return (
        <FlexboxGrid
            className="w-100"
            alignItemsCentered={true}
            justifyContentCentered={true}
        >
            <KioskKeyboard
                keyboard={keyboard}
                onChangeAll={handleOnChangeAllEvent}
                inputName={inputName}
                theme={"keyboard__clz hg-theme-default"}
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
            />
        </FlexboxGrid>
    );
}
