import { t } from "i18next";
import React from "react";

type PaginationType = {
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageOptions: number[];
    pageCount: number;
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
    nextPage: () => void;
    previousPage: () => void;
    pageIndex: number;
};

export function Pagination({
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
}: PaginationType): JSX.Element {
    return (
        <div className="d-flex justify-content-end mr-3 pagination">
            <button
                className="btn"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
            >
                {"<<"}
            </button>{" "}
            <button
                className="btn"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            >
                {"<"}
            </button>{" "}
            <span className="d-flex align-items-center">
                {t("Page of", {
                    page_number: pageIndex + 1,
                    total: pageOptions.length,
                })}
            </span>
            <button
                className="btn"
                onClick={() => nextPage()}
                disabled={!canNextPage}
            >
                {">"}
            </button>{" "}
            <button
                className="btn"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            >
                {">>"}
            </button>{" "}
        </div>
    );
}
