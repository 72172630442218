import { StyledButton } from "@aureskonnect/react-ui";
import swal from "@sweetalert/with-react";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { ScrollContainer } from "@components/ScrollContainer";

import { kioskStore, setIsLoadingModalOpened } from "@pages/Kiosk/store";

export function Tcpos(): JSX.Element {
    const { activeTabTitle } = useSnapshot(kioskStore);
    const timestamp = new Date().getTime();
    // eslint-disable-next-line
    const [tcposSettings, setTcposSettings] = React.useState<any>();

    async function getTcposSettings() {
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl") as string
        );

        const url = `${
            middlewareApiUrl.split(":5")[0]
        }:5008/tcpos/v1/adminFunctions?timestamp=${timestamp}`;

        fetch(url)
            .then((res) => res.json())
            .then(({ adminFunctions, error }) => {
                if (!error) {
                    setTcposSettings(adminFunctions);
                }
            });
    }

    const handleClickButton = async (code: number) => {
        setIsLoadingModalOpened(true);
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl") as string
        );
        const url = `${
            middlewareApiUrl.split(":5")[0]
        }:5008/tcpos/v1/excuteAdminFunction?functionCode=${code}&timestamp=${timestamp}`;

        fetch(url, { method: "POST" })
            .then((res) => res.json())
            .then(({ error }) => {
                if (!error) {
                    setIsLoadingModalOpened(false);
                    return swal({
                        icon: "success",
                        content: (
                            <p> {t("Operation performed successfully")}</p>
                        ),
                        buttons: false,
                        timer: 3000,
                    });
                } else {
                    setIsLoadingModalOpened(false);
                    return swal({
                        icon: "error",
                        content: <p>{t("Technical incident")}</p>,
                        buttons: false,
                        timer: 3000,
                    });
                }
            })
            .finally(() => {
                setIsLoadingModalOpened(false);
            });
    };

    React.useEffect(() => {
        getTcposSettings();
    }, []);

    return (
        <>
            <div className="d-flex flex-column" style={{ marginLeft: "30px" }}>
                <div
                    style={{
                        font: "normal normal 600 37px/49px Segoe UI",
                        marginLeft: "30px",
                        marginTop: "30px",
                    }}
                    onClick={() => getTcposSettings()}
                >
                    {activeTabTitle}
                </div>
                <ScrollContainer
                    style={{
                        maxHeight: "880px",
                        overflowY: "auto",
                    }}
                >
                    <div className="row font-weight-bold px-2">
                        {tcposSettings !== undefined
                            ? // eslint-disable-next-line
                              tcposSettings.map((item: any, index: number) => {
                                  return (
                                      <StyledButton
                                          key={index}
                                          rounded={true}
                                          className="col-6 my-2 mx-2"
                                          style={{
                                              maxWidth: "300px",
                                              height: "75px",
                                              font: "normal normal 600 18px/24px Segoe UI",
                                              background:
                                                  "#e9e7e7 0% 0% no-repeat padding-box",
                                              boxShadow:
                                                  "0px 3px 6px #00000029",
                                              border: "1px solid #C9C9C9",
                                              borderRadius: "12px",
                                              color: "black",
                                          }}
                                          onClick={() => {
                                              handleClickButton(item?.code);
                                          }}
                                      >
                                          {item.description}
                                      </StyledButton>
                                  );
                              })
                            : null}
                    </div>
                </ScrollContainer>
            </div>
        </>
    );
}
