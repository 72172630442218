import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    getFilenameFromUrl,
} from "@helpers/general";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { kioskStore, setIsLogoutProblemModalOpened } from "@pages/Kiosk/store";

export function LogoutProblemModal(): JSX.Element {
    const {
        isLogoutProblemModalOpened,
        isPrm,
        project: {
            files: images,
            template: {
                content: { actionsButtons, generalDesign },
            },
        },

        customerLanguage,
    } = useSnapshot(kioskStore);

    const backButtonImage = Object.values(images).filter(
        (item: ImageItemType) => {
            return item.name?.toLowerCase() === "back";
        }
    )[0].content as ImageItemLanguagesType;

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    const imageContent = backButtonImage[customerLanguage.name].path.includes(
        "http"
    )
        ? getFilenameFromUrl(
              backButtonImage[customerLanguage.name].path,
              "template"
          )
        : `./images/${backButtonImage[customerLanguage.name].path}`;

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isLogoutProblemModalOpened}
            onRequestClose={() => {
                setIsLogoutProblemModalOpened(!isLogoutProblemModalOpened);
            }}
            contentLabel="help-modal"
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1100px",
                    width: "800px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <FlexboxGrid gap="200px" className="flex-column">
                <FlexboxGrid
                    justifyContentCentered={true}
                    alignItemsCentered={true}
                    gap="100px"
                    className="flex-column"
                >
                    <img
                        src={alertImgSrc}
                        style={{
                            height: "124px",
                            width: "144px",
                            marginTop: "30px",
                        }}
                    />
                    <h2
                        className="text-uppercase text-center"
                        style={{
                            font: "normal normal bold 50px/55px Bebas Neue",
                            letterSpacing: "2px",
                            color: "#171717",
                        }}
                    >
                        {t("Attention")}
                    </h2>
                </FlexboxGrid>
                <div
                    className="d-flex flex-column align-items-center px-5"
                    style={{
                        font: "normal normal 300 35px/42px Segoe UI",
                        letterSpacing: "0.17px",
                        textAlign: "center",
                    }}
                >
                    {t(
                        "To be able to disconnect, please change the mode of sale"
                    )}
                </div>
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ gap: "50px", height: "144px", width: "750px" }}
                >
                    <img
                        src={imageContent}
                        alt={backButtonImage[customerLanguage.name].path}
                        onClick={() => {
                            setIsLogoutProblemModalOpened(
                                !isLogoutProblemModalOpened
                            );
                        }}
                    />
                </div>
            </FlexboxGrid>
        </Modal>
    );
}
