import { useDispatch, useSelector } from "react-redux";

import { getCard } from "@components/OrderTaking/Helpers/card";
import { memoizedCategories } from "@components/OrderTaking/Store/feature";

export function useCard(): void {
    const dispatch = useDispatch();
    const globalcategorys = useSelector((state) => memoizedCategories(state));
    if (globalcategorys.length === 0) {
        dispatch(getCard());
    }
}
