/* eslint-disable @typescript-eslint/no-explicit-any */
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import KioskKeyboard from "@components/common/KioskKeyboard";
import { HidePasswordSvgIcon } from "@components/SvgIcons/HidePasswordSvgIcon";
import { ShowPasswordSvgIcon } from "@components/SvgIcons/ShowPasswordSvgIcon";
import { setLoginKeyboardInputsValues } from "@components/Synchronization/store";

import "./index.css";

type UsualKeyboardWrapperPropsType = {
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setLoginButton: React.Dispatch<React.SetStateAction<string>>;
    setIsUserListModelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    inputs: any;
    setInputs: React.Dispatch<React.SetStateAction<any>>;
    isAccessFromUserList: boolean;
    handleOnClickLoginEvent(): Promise<void>;
    keyboard: React.MutableRefObject<any>;
    displayedInputs: any;
    setDisplayedInputs: React.Dispatch<React.SetStateAction<any>>;
    setIsCleared: React.Dispatch<React.SetStateAction<boolean>>;
    isCleared: boolean;
    selectedUser: string | null;
};

export function UsualKeyboardWrapper({
    setIsUserListModelOpen,
    inputs,
    setInputs,
    displayedInputs,
    setDisplayedInputs,
    isAccessFromUserList,
    handleOnClickLoginEvent,
    keyboard,
    setIsCleared,
    isCleared,
    selectedUser,
}: UsualKeyboardWrapperPropsType): JSX.Element {
    const { isAppSynchronized } = useSnapshot(store);

    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);
    const [inputName, setInputName] = React.useState("");
    const usersToken = JSON.parse(localStorage.getItem("usersToken") as string);
    const EmailLocalUser = localStorage.getItem("EmailLocalUser");

    const emailInputRef = React.useRef<HTMLInputElement>(null);
    const passwordInputRef = React.useRef<HTMLInputElement>(null);

    const onChangeAll = (inputs: any) => {
        if (isCleared || EmailLocalUser === null) {
            handleSetInputs(inputs);
            return;
        } else if (selectedUser !== null) {
            handleSetInputs(
                { ...inputs, email: selectedUser },
                {
                    ...inputs,
                    email: displayedEmail(selectedUser as string),
                }
            );
        } else {
            handleSetInputs(
                { ...inputs, email: EmailLocalUser },
                {
                    ...inputs,
                    email: displayedEmail(EmailLocalUser as string),
                }
            );
        }
    };

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;

        if (isCleared || EmailLocalUser === null) {
            handleSetInputs({
                ...inputs,
                [inputName]: inputVal,
            });
            keyboard.current.setInput(inputVal);
            return;
        } else {
            handleSetInputs(
                {
                    email: inputs.email,
                    [inputName]: inputVal,
                },
                {
                    email: displayedEmail(inputs.email),
                    [inputName]: inputVal,
                }
            );
            keyboard.current.setInput("password", inputVal);
        }
    };

    const displayedEmail = (email: string | undefined): string => {
        if (email) {
            return `${email.substring(0, 3)}${email
                .substring(3)
                .replaceAll(/[a-zA-Z0-9._%+-]/g, "*")}`;
        }
        return "";
    };

    const handleSetInputs = (inputObj: any, displayObj?: any) => {
        setDisplayedInputs(displayObj ?? inputObj);
        setInputs(inputObj);
        setLoginKeyboardInputsValues(inputObj);
    };

    const getInputValue = (inputName: any) => {
        return displayedInputs[inputName] || "";
    };

    const handleClearInput = () => {
        if (emailInputRef.current !== null) {
            emailInputRef.current.focus();
        }
        setIsCleared(true);
        setInputs({});
        setLoginKeyboardInputsValues({});
        setDisplayedInputs({});
        if (keyboard.current) {
            keyboard.current.setInput("", "email");
            keyboard.current.setInput("", "password");
        }
    };

    React.useEffect(() => {
        EmailLocalUser !== null &&
            handleSetInputs(
                { ...inputs, email: EmailLocalUser },
                {
                    ...inputs,
                    email: displayedEmail(EmailLocalUser),
                }
            );
    }, []);

    return (
        <FlexboxGrid
            alignItemsCentered={true}
            justifyContentCentered={true}
            className="flex-column py-1"
            gap="10px"
        >
            <form
                className="p-4 d-flex flex-column justify-content-center align-items-center"
                style={{ gap: "15px" }}
            >
                {isAppSynchronized && usersToken !== null ? (
                    <div
                        className="user-actions-buttons"
                        style={{
                            left:
                                (isAccessFromUserList ||
                                    getInputValue("email")) &&
                                getInputValue("email")
                                    ? "43%"
                                    : "46%",
                        }}
                    >
                        <div onClick={handleClearInput}>
                            {getInputValue("email") ? <ClearIcon /> : null}
                        </div>

                        <div
                            className="user-list-button"
                            onClick={() => setIsUserListModelOpen?.(true)}
                        >
                            <PersonIcon />
                        </div>
                    </div>
                ) : null}
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("ID")}
                    <div className="d-flex">
                        <input
                            ref={emailInputRef}
                            id="email"
                            type={"email"}
                            value={getInputValue("email")}
                            onFocus={() => setInputName("email")}
                            placeholder={t("Enter your ID")}
                            onChange={onChangeInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                    </div>
                </label>

                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        opacity: 1,
                        gap: "10px",
                    }}
                >
                    {t("Password")}
                    <div className="password-input-container">
                        <input
                            ref={passwordInputRef}
                            id="password"
                            value={getInputValue("password")}
                            onFocus={() => setInputName("password")}
                            type={isPasswordDisplayed ? "text" : "password"}
                            placeholder={t("Enter your password")}
                            onChange={onChangeInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                        <div
                            className="password-eye-icon"
                            onClick={() =>
                                setIsPasswordDisplayed(
                                    (prevState) => !prevState
                                )
                            }
                        >
                            {isPasswordDisplayed ? (
                                <HidePasswordSvgIcon />
                            ) : (
                                <ShowPasswordSvgIcon />
                            )}
                        </div>
                    </div>
                </label>
            </form>

            <FlexboxGrid
                alignItemsCentered={true}
                justifyContentCentered={true}
                className="w-100"
            >
                <KioskKeyboard
                    enterClick={handleOnClickLoginEvent}
                    keyboard={keyboard}
                    onChangeAll={onChangeAll}
                    inputName={inputName}
                    theme={"keyboard__clz hg-theme-default"}
                    buttonTheme={[
                        {
                            class: "space-key__clz",
                            buttons: "{space}",
                        },
                    ]}
                />
            </FlexboxGrid>
        </FlexboxGrid>
    );
}
