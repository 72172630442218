import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import { ContainerWrapper } from "../ContainerWrapper";

export function PrmMode(): JSX.Element {
    const {
        actionsButtonsItems,
        customerLanguage,
        isHeaderActive,
        isTopBannerActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper>
            <ContainerWrapper
                isHeaderActive={isHeaderActive}
                isTopBannerActive={isTopBannerActive}
                isActionButtonsActive={isActionButtonsActive}
            >
                <LogoWrapper />

                {isHeaderActive ? (
                    <div>
                        {isInformationMessageActive ? (
                            <div className="d-flex text-center justify-content-center align-items-center">
                                <span style={{ fontSize: "55px" }}>
                                    {
                                        informationMessageItems[
                                            customerLanguage.name
                                        ].content
                                    }
                                </span>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <MainContentWrapper />

                <ActionsButtonsWrapper
                    actionsButtonsItems={mainActiveActionsButtons}
                />
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
