import { useSnapshot } from "valtio";

import React from "react";

import { ImageContent } from "@components/common/UsualModeMainContentWrapper/ImageContent";

import { LoyaltyConsommationCard } from "@pages/Kiosk/Steps/LoyaltyConsommationStep/LoyaltyConsommationCard";
import { kioskStore as store } from "@pages/Kiosk/store";

export function MainContentWrapper(): JSX.Element {
    const { mainContentItems, navigationIndex, kioskWays } = useSnapshot(store);

    const activeItemsLength = mainContentItems?.length;

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            {kioskWays[navigationIndex]?.name === "loyaltyConsommation" ? (
                <LoyaltyConsommationCard />
            ) : null}
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div
                    className="d-flex flex-wrap justify-content-center align-items-center main-content-scroll"
                    style={{
                        gap: activeItemsLength <= 3 ? "5px" : "40px",
                        maxHeight: "1000px",
                        overflowY: activeItemsLength <= 6 ? "hidden" : "scroll",
                    }}
                >
                    {mainContentItems.map(
                        // eslint-disable-next-line
                        (item: any, index: number) => (
                            <ImageContent
                                key={item.id + index}
                                item={item}
                                activeItemsLength={activeItemsLength}
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
