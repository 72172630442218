import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function CancelOrder(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437 65" {...props}>
            <g
                id="Groupe_56"
                data-name="Groupe 56"
                transform="translate(695 4055)"
            >
                <g
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    transform="translate(-695 -4055)"
                    fill="#fff"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#f8f8f6"
                    />
                </g>
                <text
                    id="Abandonner_ma_commande"
                    data-name="Abandonner ma commande"
                    transform="translate(-476 -4011)"
                    fill="#141414"
                    fontSize="29"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Cancel my order", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
CancelOrder.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
