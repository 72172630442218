import PropTypes from "prop-types";

import React from "react";

import { getProductTitle } from "@components/OrderTaking/Helpers";

export function BasicCompositionTitle({ isTitleVisible, product, isPrm }) {
    const title = getProductTitle(product.name);

    return (
        <div
            className={`centredItems__clz px-1 two-lines-preview-text ${
                isPrm ? "prm-product-title" : "product-title__clz"
            }`}
            style={{ height: "100px" }}
        >
            <div
                className="two-lines-preview-text"
                style={{
                    opacity: !product.basicCompositionActive ? "40%" : "100%",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Segoe UI",
                    lineHeight: "1.2em",
                    textAlign: "center",
                }}
            >
                {isTitleVisible ? title : ""}
            </div>
        </div>
    );
}
BasicCompositionTitle.propTypes = {
    isTitleVisible: PropTypes.bool,
    product: PropTypes.object,
    isPrm: PropTypes.bool,
};
