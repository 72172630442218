import Fade from "@mui/material/Fade";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
    memoizedActiveCategoryUid,
    memoizedOrderItems,
} from "@components/OrderTaking/Store/feature";
import { initialState } from "@components/OrderTaking/Store/feature/orderWorkflowItemSlice";

import { kioskStore } from "@pages/Kiosk/store";

import { AnimatesProducts } from "./AnimatesProducts";
// eslint-disable-next-line
import { ResponsiveItemsList } from "./component";
import { getVisibleItems } from "./hooks";
import "./index.css";
import { ShopCartHeight } from "./ShopCartHeight";

export function ListItems({
    listProducts,
    currentWorkflow,
    isAdditionalSale = false,
    listAdditionnalItems,
    isWorkFlow,
    workflowData = [],
    orderWorkflowItemSlice = initialState,
}) {
    const scrollableParentRef = React.useRef(null);

    const { isPrm, selectedSaleModeOrderTaking } = useSnapshot(kioskStore);

    const activeCategory = useSelector((state) =>
        memoizedActiveCategoryUid(state)
    );

    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const { design } = useSelector((state) => state.orderSlice, shallowEqual);

    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { visibleItems, gridSystem } = getVisibleItems(
        listProducts,
        listAdditionnalItems,
        activeCategory,
        selectedSaleModeOrderTaking,
        isWorkFlow,
        currentWorkflow,
        isAdditionalSale,
        additionalSale,
        design
    );

    const keySlide =
        listProducts !== undefined ? listProducts.length : activeCategory;
    const isSlideAnimationEnter =
        currentWorkflow || listAdditionnalItems ? 0 : 200;

    return (
        <div
            className="d-flex scrollbarY justify-content-center h-100 w-100"
            ref={scrollableParentRef}
        >
            {currentWorkflow ? (
                <AnimatesProducts>
                    <div
                        style={{
                            width: currentWorkflow && !isPrm && "860px",
                            height: "100%",
                        }}
                    >
                        <div
                            className={`row w-100 my-1 h-100 ${
                                visibleItems.length <= 3 && ""
                            } ${
                                visibleItems.length === 3
                                    ? "initialFlexDirection "
                                    : ""
                            }`}
                            style={{
                                marginLeft: "unset",
                                marginRight: "unset",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <ResponsiveItemsList
                                scrollableParentRef={scrollableParentRef}
                                visibleItems={visibleItems}
                                currentWorkflow={currentWorkflow}
                                orderItems={orderItems}
                                isWorkFlow={isWorkFlow}
                                gridSystem={gridSystem}
                                workflowData={workflowData}
                                orderWorkflowItemSlice={orderWorkflowItemSlice}
                            />
                        </div>
                        <ShopCartHeight />
                    </div>
                </AnimatesProducts>
            ) : (
                <Fade
                    in={true}
                    key={keySlide}
                    unmountOnExit
                    style={{
                        transformOrigin: "0 0 0 ",
                        width: currentWorkflow && !isPrm && "860px",
                    }}
                    timeout={{
                        appear: 0,
                        enter: isSlideAnimationEnter,
                        exit: 100,
                    }}
                    direction="up"
                >
                    <div
                        className={`row w-100 my-1  ${
                            visibleItems.length <= 3 && ""
                        } ${
                            visibleItems.length === 3
                                ? "initialFlexDirection"
                                : ""
                        }`}
                        style={{
                            marginLeft: "unset",
                            marginRight: "unset",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ResponsiveItemsList
                            scrollableParentRef={scrollableParentRef}
                            visibleItems={visibleItems}
                            currentWorkflow={currentWorkflow}
                            orderItems={orderItems}
                            isWorkFlow={isWorkFlow}
                            gridSystem={gridSystem}
                            workflowData={workflowData}
                            orderWorkflowItemSlice={orderWorkflowItemSlice}
                        />
                    </div>
                </Fade>
            )}

            {/* //!!! new animation */}

            {/* {currentWorkflow ? (
                <AnimatesProducts>
                    <div
                        style={{
                            width: currentWorkflow && !isPrm && "860px",
                            height: "100%",
                        }}
                    >
                        <div
                            className={`row w-100 my-1 h-100 ${
                                visibleItems.length <= 3 && ""
                            } ${
                                visibleItems.length === 3
                                    ? "initialFlexDirection "
                                    : ""
                            }`}
                            style={{
                                marginLeft: "unset",
                                marginRight: "unset",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <ResponsiveItemsList
                                scrollableParentRef={scrollableParentRef}
                                visibleItems={visibleItems}
                                currentWorkflow={currentWorkflow}
                                orderItems={orderItems}
                                isWorkFlow={isWorkFlow}
                                gridSystem={gridSystem}
                                workflowData={workflowData}
                                orderWorkflowItemSlice={orderWorkflowItemSlice}
                            />
                        </div>
                    </div>
                </AnimatesProducts>
            ) : (
                <motion.div
                    key={keySlide}
                    initial={{ x: "-2vw", y: 0, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: "-2vw", opacity: 0 }}
                    transition={{ type: "spring", stiffness: 40 }}
                    style={{
                        width: currentWorkflow && !isPrm && "860px",
                    }}
                >
                    <div
                        className={`row w-100 my-1  h-100 ${
                            visibleItems.length <= 3 && ""
                        } ${
                            visibleItems.length === 3
                                ? "initialFlexDirection"
                                : ""
                        }`}
                        style={{
                            marginLeft: "unset",
                            marginRight: "unset",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ResponsiveItemsList
                            scrollableParentRef={scrollableParentRef}
                            visibleItems={visibleItems}
                            currentWorkflow={currentWorkflow}
                            orderItems={orderItems}
                            isWorkFlow={isWorkFlow}
                            gridSystem={gridSystem}
                            workflowData={workflowData}
                            orderWorkflowItemSlice={orderWorkflowItemSlice}
                        />
                    </div>
                </motion.div>
            )} */}
        </div>
    );
}

ListItems.propTypes = {
    listProducts: PropTypes.array,
    currentWorkflow: PropTypes.object,
    isAdditionalSale: PropTypes.bool,
    isWorkFlow: PropTypes.bool,
    listAdditionnalItems: PropTypes.array,
    workflowData: PropTypes.array,
    orderWorkflowItemSlice: PropTypes.object,
};
