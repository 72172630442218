import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

import { PromoCodeModalWrapper } from "./PromoCodeModalWrapper";

export function PromotionPrm(): JSX.Element {
    const { promoValue } = useSnapshot(kioskStore);

    const [isOpen, setIsOpen] = React.useState(false);

    const { globalColor } = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.settingSlice.generalSetting,
        shallowEqual
    );

    return (
        <React.Fragment>
            <img
                className="promotion-prm-image__clz"
                src="./images/promotion-prm-icon.png"
                style={{
                    borderBottom:
                        promoValue !== 0 ? `${globalColor} 10px solid` : "",
                }}
                onClick={() => {
                    if (promoValue === 0) {
                        setIsOpen(!isOpen);
                    }
                }}
            />
            {isOpen === true ? (
                <PromoCodeModalWrapper setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : null}
        </React.Fragment>
    );
}
