import { useSnapshot } from "valtio";

import React from "react";

import {
    kioskStore,
    setActionsButtonsItems,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setIsUserAccountActive,
    setMainContentItems,
    setUserAccountMessageItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function InformationModesModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways },
            },
        },
        selectedSaleModeValue,
    } = useSnapshot(kioskStore);

    React.useEffect(() => {
        setMainContentItems(
            (content.informationModes as ProjectContentItemType).items.filter(
                (item) => {
                    return (
                        content.salesMethods as ProjectContentItemType
                    ).items
                        .filter((saleMethod) => {
                            return saleMethod.name === selectedSaleModeValue;
                        })[0]
                        .informationModes?.items?.includes(item.id);
                }
            )
        );

        setIsUserAccountActive(
            (
                (ways.informationModes as PageType).header
                    .userAccount as UserAccountType
            ).active
        );
        setUserAccountMessageItems(
            (
                (ways.informationModes as PageType).header
                    .userAccount as UserAccountType
            ).languages
        );

        setIsLogoActive(
            (ways.informationModes as PageType).topBanner.logo.active
        );

        setIsTopBannerActive(
            (ways.informationModes as PageType).topBanner.active
        );
        setIsHeaderActive((ways.informationModes as PageType).header.active);

        setIsInformationMessageActive(
            (ways.informationModes as PageType).header.informationMessage.active
        );

        setInformationMessageItems(
            (ways.informationModes as PageType).header.informationMessage
                .languages
        );

        setIsActionButtonsActive(
            (ways.informationModes as PageType).actionsButtons.active
        );

        setActionsButtonsItems(
            (
                (ways.informationModes as PageType)
                    .actionsButtons as ActionsButtonsDesignType
            ).items
        );
    }, []);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
