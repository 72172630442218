import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE, ELECTRON_MODE, WEBVIEW_MODE } from "@constants";
import { Card } from "@mui/material";
import swal from "@sweetalert/with-react";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { setIsAppPaused, store } from "@store";

import { handleLedControl } from "@helpers/general";

import { PlaySvgIcon } from "@components/SvgIcons/PlaySvgIcon";
import { KeyboardWrapper } from "@components/SyncModal/KeyboardWrapper";
import { MiddlewareApiUrlInputWrapper } from "@components/SyncModal/MiddlewareApiUrlInputWrapper";

import {
    kioskStore,
    setIsLocalConfigModalOpened,
    setIsLocalSettingModalOpened,
    setIsMenuModalOpened,
} from "@pages/Kiosk/store";

enum systemActionsType {
    NoAction = "NoAction",
    Reset = "reset",
    ChangeMiddleware = "Change Middleware",
    Active = "pause",
    Update = "update",
    Restart = "restart",
    Close = "close",
    SwitchOff = "switch off",
}
type ConfirmationSystemActionsType = {
    setSystemActions: React.Dispatch<React.SetStateAction<systemActionsType>>;
    systemActions: systemActionsType;
    setIsSystemConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSystemConfirmationOpen: boolean;
};

export default function ConfirmationSystemActionsModelWrapper({
    setSystemActions,
    systemActions,
    setIsSystemConfirmationOpen,
    isSystemConfirmationOpen,
}: ConfirmationSystemActionsType): JSX.Element {
    const { isLedActive, connectedLedPort, isKeyboardOpened } =
        useSnapshot(kioskStore);
    const { isAppPaused } = useSnapshot(store);

    const middlewareApiUrl = JSON.parse(
        localStorage.getItem("middlewareApiUrl") as string
    );
    const shopDetail = JSON.parse(localStorage.getItem("shopDetail") as string);
    const selectedShopApp = JSON.parse(
        localStorage.getItem("selectedShopApp") as string
    );

    const [isInputValueInvalid, setIsInputValueInvalid] = React.useState(false);
    const [isFieldRequired, setIsFieldRequired] = React.useState(false);
    const [isUpdateMiddlware, setIsUpdateMiddlware] = React.useState(false);

    function handleSystemActionOnclickEvent() {
        switch (systemActions) {
            case "reset":
                localStorage.clear();
                location.reload();
                break;
            case "Change Middleware":
                setIsUpdateMiddlware(true);
                break;
            case "pause":
                setIsSystemConfirmationOpen(false);
                handleChangeApplicationStatus();
                break;
            case "update":
                if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
                    (window as RendererWindow).electronAPI.sendUpdateEvent();
                }
                setSystemActions(systemActionsType.NoAction);
                setIsSystemConfirmationOpen(false);
                break;
            case "restart":
                setSystemActions(systemActionsType.NoAction);
                setIsSystemConfirmationOpen(false);
                if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
                    useSystemAction("restart_poste");
                } else if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
                    (window as RendererWindow).electronAPI.sendRestartEvent();
                }
                break;
            case "close":
                if (isLedActive) {
                    handleLedControl(connectedLedPort, "Disable");
                }
                setSystemActions(systemActionsType.NoAction);
                setIsSystemConfirmationOpen(false);
                if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
                    useSystemAction("close_app");
                } else if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
                    (window as RendererWindow).electronAPI.sendQuitEvent();
                }
                break;
            case "switch off":
                setSystemActions(systemActionsType.NoAction);
                setIsSystemConfirmationOpen(false);

                if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
                    useSystemAction("shutDown_poste");
                } else if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
                    (window as RendererWindow).electronAPI.sendShutdownEvent();
                }
                break;
            default:
                setSystemActions(systemActionsType.NoAction);
                setIsSystemConfirmationOpen(false);
        }
    }

    function useSystemAction(systemAction: string): void {
        const url = `http://localhost:6009/api/v1/kiosk?systemAction=${systemAction}`;
        fetch(url).catch((error) =>
            console.log(
                `Error while using system action with action: "${systemAction}" and Error: "${error}"`
            )
        );
    }

    function handleChangeApplicationStatus() {
        const previousAppPaused = !isAppPaused;
        setIsAppPaused(!isAppPaused);
        setIsMenuModalOpened(false);
        swal({
            icon: "success",
            content: <p>{t("Application Status changed successfully")}!</p>,
            buttons: false,
            timer: 2000,
        });
        setIsLocalConfigModalOpened(false);
        setIsLocalSettingModalOpened(false);
        setSystemActions(systemActionsType.NoAction);
        if (isLedActive && previousAppPaused) {
            handleLedControl(connectedLedPort, "Orange");
        }

        const url = `${middlewareApiUrl}/ModifyKioskState`;
        fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                franchiseId: shopDetail.franchise_uid,
                shopId: shopDetail.shop_uid,
                appId: selectedShopApp.appId,
                etat: isAppPaused ? "In line" : "Standby state",
            }),
        })
            // eslint-disable-next-line
            .then((response): Promise<any> => response.json())
            .then((data): void => {
                if (data.error) {
                    console.log(
                        `Error on change Kiosk application status in the cloud with data message: ${data.message}`
                    );
                    return;
                }
                console.log(
                    `Changing Kiosk application status with success in the cloud`
                );
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                console.log(
                    `Catch error on changing kiosk application with error: ${error}`
                );
            });
    }

    return (
        <Modal
            isOpen={isSystemConfirmationOpen}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "none",
                    zIndex: 3,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "0px",
                    color: "black",
                    height: systemActions !== "pause" ? "30rem" : "36rem",
                    width: "37rem",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #c2bebe",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            {!isUpdateMiddlware ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                        gap: "50px",
                    }}
                >
                    {systemActions === "pause" && (
                        <PlaySvgIcon
                            style={{
                                marginTop: "50px",
                            }}
                        />
                    )}

                    <div
                        style={{
                            textAlign: "left",
                            font: "normal normal bold 35px/55px Segoe UI",
                        }}
                    >
                        {t("Confirmation")}
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            font: "normal normal normal 25px/33px Segoe UI",
                            marginLeft: "100px",
                            marginRight: "100px",
                        }}
                    >
                        {t(
                            `Would you like to ${
                                systemActions === "pause"
                                    ? isAppPaused
                                        ? "restart"
                                        : systemActions
                                    : systemActions
                            } your ${
                                systemActions === "restart" ||
                                systemActions === "switch off"
                                    ? "post"
                                    : "application"
                            } ?`
                        )}
                    </div>
                    <div className="d-flex">
                        <StyledButton
                            rounded={true}
                            className="m-2"
                            style={{
                                width: "179px",
                                height: "61px",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                            }}
                            onClick={() => {
                                setIsSystemConfirmationOpen(false);
                            }}
                        >
                            {t("Back")}
                        </StyledButton>
                        <StyledButton
                            rounded={true}
                            className="m-2"
                            style={{
                                width: "233px",
                                height: "61px",
                                background:
                                    "#0a0a0a 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "white",
                            }}
                            onClick={() => {
                                handleSystemActionOnclickEvent();
                            }}
                        >
                            {t("Confirm")}
                        </StyledButton>
                    </div>
                </div>
            ) : (
                <>
                    <MiddlewareApiUrlInputWrapper
                        isInputValueInvalid={isInputValueInvalid}
                        setIsInputValueInvalid={setIsInputValueInvalid}
                        isFieldRequired={isFieldRequired}
                        setIsSystemConfirmationOpen={
                            setIsSystemConfirmationOpen
                        }
                        setIsUpdateMiddlware={setIsUpdateMiddlware}
                        isUpdateMiddlware={isUpdateMiddlware}
                    />
                    {isKeyboardOpened === true ? (
                        <Card
                            style={{
                                position: "fixed",
                                bottom: 0,
                                width: "98vw",
                                left: "0.5rem",
                            }}
                            data-testid="keyboard-wrapper"
                        >
                            <KeyboardWrapper
                                setIsFieldRequired={setIsFieldRequired}
                                isInputValueInvalid={isInputValueInvalid}
                                setIsInputValueInvalid={setIsInputValueInvalid}
                            />
                        </Card>
                    ) : null}
                </>
            )}
        </Modal>
    );
}
