import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import { kioskStore } from "@pages/Kiosk/store";

const Tr = styled.tr`
    background-color: white;
    display: ${({ isDragging }: { isDragging: boolean }) =>
        isDragging ? "table" : ""};
`;

// eslint-disable-next-line
export function Table({ columns }: any) {
    const { standbyStateScreenItems } = useSnapshot(kioskStore);

    const table = useTable({
        columns,
        data: standbyStateScreenItems,
    });

    const { getTableProps, headerGroups, prepareRow, rows, getTableBodyProps } =
        table;

    return (
        <table {...getTableProps()}>
            <thead>
                {
                    // eslint-disable-next-line
                    headerGroups.map((headerGroup: any, index: number) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(
                                // eslint-disable-next-line
                                (column: any, index: number) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </th>
                                )
                            )}
                        </tr>
                    ))
                }
            </thead>

            <tbody {...getTableBodyProps()}>
                {
                    // eslint-disable-next-line
                    rows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={row.original.id}>
                                {row.cells.map(
                                    (
                                        // eslint-disable-next-line
                                        cell: any,
                                        index: number
                                    ) => (
                                        <td
                                            key={index}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                )}
                            </Tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}
