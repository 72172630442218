const PAYMENT_REFUSED = 0;
const VALIDE_PAYMENT_WITH_CHANGEMACHINE = 1001;
const CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT = 1002;
const CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT = 1003;
const CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY = 999;
const VANILA_PAYMENT_WITHOUT_CONTACT = 3;
const VANILA_PAYMENT_WITH_CONTACT = 4;
const DEFAULT_VANILA_PAYMENT = 5;

const CHANGE_MACHINE_ID = "d38997d8-b520-49b9-a990-1735e39a6b90";
const CREDIT_CRAD_ID = "acfb6f10-c65f-4809-8b98-a67feda36bce";
const RESTOFLASH = "a93dcb45-9e0c-4cb2-8984-d9f05ded04fc";

export {
    PAYMENT_REFUSED,
    VALIDE_PAYMENT_WITH_CHANGEMACHINE,
    CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT,
    CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY,
    CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT,
    VANILA_PAYMENT_WITHOUT_CONTACT,
    VANILA_PAYMENT_WITH_CONTACT,
    DEFAULT_VANILA_PAYMENT,
    CHANGE_MACHINE_ID,
    CREDIT_CRAD_ID,
    RESTOFLASH,
};
