import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaper-root": {
            width: "1010px",
            height: "1823px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #707070",
            borderRadius: "111px 111px 0px 0px",
            borderTop: "15px solid #FF8205",
            maxWidth: "unset",
            padding: "25px",
            gap: "50px",
            display: "flex",
            justifyContent: "center",
        },
    },
    title: {
        textAlign: "center",
        font: "normal normal normal 58px/70px Segoe UI",
    },
    subTitle: {
        font: "normal normal 600 25px/30px Segoe UI",
        marginLeft: "15px",
        width: "663px",
        height: "82px",
        display: "flex",
        alignItems: "center",
    },
    customPaper: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    element: {
        width: "307px",
        height: "61px",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "15px",
        color: "#000000",
    },
    cardMedia: {
        width: "unset",
        height: "312px",
    },
    customCard: {
        height: "189px",
        width: "924px",
        padding: "0px 35px 0px 35px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #FFFFFF",
        borderRadius: "22px",
        display: "flex",
        gap: "15px",
        alignItems: "center",
    },
    mainCard: {
        width: "939px",
        borderRadius: "22px",
        boxShadow: "0px 3px 6px #00000059",
        border: "1px solid #FFFFFF",
        textAlign: "center",
        // color: "#FFFFFF",
    },
    cardTab: {
        height: "63px",
        background: "#34C38F 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "22px 22px 0px 0px",
    },
    text: {
        font: "normal 15px/18px Segoe UI",
        width: "896px",
        height: "148px",
    },
    groupTitle: {
        font: "normal normal bold 20px/24px Segoe UI",
        marginBottom: "30px",
    },
    allergenCard: {
        font: "normal normal normal 20px/47px Segoe UI",
        width: "260px",
        height: "49px",
        /* boxShadow: "0px 3px 6px #00000029",
             borderRadius: "15px", */
        color: "#000000",
    },
}));
