import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    title: {
        textAlign: "center",
        font: "normal normal normal 58px/70px Segoe UI",
        // marginTop: "25px",
    },
    subTitle: {
        font: "normal normal 600 37px/44px Segoe UI",
        marginLeft: "15px",
        textAlign: "left",
        width: "787px",
    },
    customPaper: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    element: {
        width: "307px",
        height: "61px",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "15px",
        color: "#000000",
        display: "flex",
        alignItems: "center",
        paddingLeft: "5px",
        gap: "50px",
        font: "normal normal 600 30px/35px Segoe UI",
    },

    customCard: {
        height: "100px",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "924px",
    },

    mainCard: {
        height: "790px",
        width: "924px",
        borderRadius: "22px 22px 22px 22px",
        boxShadow: "0px 3px 6px #00000059",
        textAlign: "center",
        font: "normal normal 600 25px/30px Segoe UI",
        color: "#FFFFFF",
    },

    cardTab: {
        height: "63px",
        background: "#ffffff 0% 0% no-repeat padding-box",
        color: "#000000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "470px",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "22px 22px 0px 0px",
    },
    activeCardTab: {
        height: "63px",
        background: "#000000 0% 0% no-repeat padding-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "470px",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "22px 22px 0px 0px",
    },
    text: {
        font: "normal normal normal 25px/30px Segoe UI",
    },

    allergenCard: {
        font: "normal normal normal 20px/47px Segoe UI",
        width: "260px",
        height: "49px",
        /* boxShadow: "0px 3px 6px #00000029",
             borderRadius: "15px", */
        color: "#000000",
    },
}));
