import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Yes = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_68"
                data-name="Groupe 68"
                transform="translate(-3549 4082)"
            >
                <path
                    id="Tracé_13"
                    data-name="Tracé 13"
                    d="M9,0H341a9,9,0,0,1,9,9V78a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V9A9,9,0,0,1,9,0Z"
                    transform="translate(3549 -4082)"
                    fill="#141414"
                />
                <text
                    id="Oui"
                    transform="translate(3724 -4024)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Yes", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};
