import styled from "styled-components";

type PropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isHeaderActive,
        isTopBannerActive,
        isActionButtonsActive,
    }: PropsType) =>
        isHeaderActive && isTopBannerActive && isActionButtonsActive
            ? "12fr 12fr 68fr 8fr"
            : isHeaderActive && !isTopBannerActive && isActionButtonsActive
            ? "12fr 80fr 8fr "
            : !isHeaderActive && isTopBannerActive && isActionButtonsActive
            ? "12fr 80fr 8fr"
            : isHeaderActive && isTopBannerActive && !isActionButtonsActive
            ? "12fr 12fr 76fr"
            : !isHeaderActive && !isTopBannerActive && isActionButtonsActive
            ? "92fr 8fr"
            : isHeaderActive && !isTopBannerActive && !isActionButtonsActive
            ? "12fr 88fr"
            : !isHeaderActive && isTopBannerActive && !isActionButtonsActive
            ? "12fr 88fr"
            : "100fr"};
`;
