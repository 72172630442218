import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore as store } from "@pages/Kiosk/store";

export function ManHeadIconWrapper(): JSX.Element {
    const { isPrm } = useSnapshot(store);

    const styles = isPrm
        ? { height: "185px", width: "185px" }
        : { height: "230px", width: "230px" };

    return (
        <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "397px", width: "865px" }}
        >
            <img src="./images/man-head.png" style={styles} />
        </div>
    );
}
