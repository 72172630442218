import { TCPOS } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { PuffLoader } from "react-spinners";

import { store } from "@store";

import { kioskStore, setIsTcposOrderRefreshDone } from "@pages/Kiosk/store";

type ChangeSalesModesConfirmationTcposType = {
    setIsPatientModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isPatientModelOpen: boolean;
    onClose: () => void;
};

export default function SalesModesChangeConfirmationDialogBody({
    setIsPatientModelOpen,
    isPatientModelOpen,
    onClose,
}: ChangeSalesModesConfirmationTcposType): JSX.Element {
    const { t } = useTranslation();
    const { posEditor } = useSnapshot(store);

    const { isTcposOrderRefreshDone } = useSnapshot(kioskStore);

    React.useEffect(() => {
        if (isPatientModelOpen) {
            if (isTcposOrderRefreshDone) {
                onClose();
                setIsPatientModelOpen(false);
                setIsTcposOrderRefreshDone(false);
            }
            setTimeout(() => {
                onClose();
                setIsPatientModelOpen(false);
                setIsTcposOrderRefreshDone(false);
            }, 720000);
        }
    }, [isPatientModelOpen, isTcposOrderRefreshDone]);

    return (
        <>
            {!isPatientModelOpen ? (
                <div className="text-confirmed-alert">
                    {posEditor === TCPOS ? (
                        <span>
                            {t(
                                "Your order will be lost as a result of the change in sale method"
                            )}
                        </span>
                    ) : (
                        <span>
                            {t("Your order will be modified")}.
                            <br /> {t("Do you want to continue ?")}
                        </span>
                    )}
                </div>
            ) : (
                <div
                    className="d-flex flex-column justify-content-end align-items-center h-100"
                    style={{ gap: "40%", marginTop: "50%" }}
                >
                    <h1>{t("Thank you for waiting")}</h1>
                    <PuffLoader color="#0c1b28" size={160} />
                </div>
            )}
        </>
    );
}
