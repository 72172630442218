/* eslint-disable */
import {
    DEFAULT_COUNTRY_CODE_FR,
    GODIA_LOYALTY,
    INITIAL_VALUES,
    KIOSK_ACCOUNT_CREATOR,
    RESPONSE_CODE_FAILED,
    RESPONSE_CODE_SUCCESS,
} from "@constants";
import { useFormik } from "formik";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { store } from "@store";

import {
    getSkippedStepNumber,
    phoneNumberValidation,
    validationSchema,
} from "@helpers/general";

import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { InformationMessage } from "@components/common/KioskStructureV2/InformationMessage";
import { LogoWrapper } from "@components/common/KioskStructureV2/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/KioskStructureV2/PreviewBackgroundWrapper";
import {
    addResetUserRewards,
    setUserGifts,
} from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setAuthModalStepIndex,
    setIsAuthenticationErrorModalOpened,
    setIsCustomerBenefitOpen,
    setIsUserAuthenticated,
    setNavigationIndex,
    setSelectedAuthMode,
    setUser,
    setUserAuthInfo,
} from "@pages/Kiosk/store";

import { ActionsButtons } from "./ActionsButtons";
import { FullFormInput } from "./FullFormInput";
import "./index.css";
import PhoneFormInput from "./PhoneFormInput";

export function CreateAnAccount(): JSX.Element {
    const {
        isConfigOptionsActive,
        project: {
            template: {
                content,
                pages: { subSteps },
            },
        },
        isSubStepInformationMessageActive,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isSubStepActionButtonsActive,
        orderTotalPrice,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                            },
                        },
                    },
                },
            },
        },
        isAuthenticationErrorModalOpened,
    } = useSnapshot(kioskStore);
    const dispatch = useDispatch();
    const { middlewareApiUrl } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const activeInputFormItems = (subSteps["accountFidelite"] as any)
        .activeInputForm;

    const isPhoneNumberOnlyActive =
        Object.values(activeInputFormItems).flat().length === 1;

    const formik = useFormik({
        validateOnChange: false,
        validationSchema: validationSchema,
        initialValues: INITIAL_VALUES,
        onSubmit: async (values, { resetForm }) => {
            if (Object.keys(values).length > 0) {
                const body = {
                    tel: values["phoneNumber"],
                    nom: values["name"],
                    prenom: values["firstName"],
                    email: values["email"],
                    pays: values["country"],
                    code_postal: values["postalCode"],
                    origin: KIOSK_ACCOUNT_CREATOR,
                };

                const url = `${middlewareApiUrl}/CompteClientGodia`;
                setIsAuthenticationErrorModalOpened(false);
                await fetch(url, {
                    method: "post",
                    body: JSON.stringify(body),
                    headers: { "Content-Type": "application/json" },
                })
                    .then((response) => response.json())
                    .then(async (response) => {
                        if (response.code === RESPONSE_CODE_SUCCESS) {
                            setUserAuthInfo(values);
                            setNavigationIndex(
                                navigationIndex +
                                    Number(
                                        await getSkippedStepNumber(
                                            kioskWays,
                                            navigationIndex,
                                            isAuthenticationAccepted,
                                            true,
                                            selectedSaleModeValue,
                                            selectedInformationMode,
                                            isOrderPaidWithLoyaltyAccountBalance,
                                            selectedRegulationModeValue,
                                            content,
                                            Number(orderTotalPrice)
                                        )
                                    )
                            );

                            setAuthModalStepIndex(0);
                            setSelectedAuthMode("");
                            setIsUserAuthenticated(true);
                            setUser(response.response);
                            if (
                                selectedShopApp.fidelityType ===
                                    GODIA_LOYALTY &&
                                (giftsActive || rewardsActive)
                            ) {
                                setIsCustomerBenefitOpen(true);
                                dispatch(
                                    addResetUserRewards({
                                        rewards: response.response.rewards,
                                        loyaltyAccount:
                                            response.response.loyaltyAccount,
                                    })
                                );
                                dispatch(setUserGifts(response.response.promo));
                            }
                            resetForm();
                        } else if (response.code === RESPONSE_CODE_FAILED) {
                            setIsAuthenticationErrorModalOpened(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    });

    return (
        <PreviewBackgroundWrapper selectedMode={"accountFidelite"}>
            {isAuthenticationErrorModalOpened === true ? (
                <AuthenticationErrorModal />
            ) : null}
            <div
                style={{
                    display: "grid",
                    gridTemplateRows:
                        "min-content min-content 1fr min-content ",
                    height: "100%",
                    width: "100%",
                }}
            >
                <LogoWrapper />

                {isSubStepInformationMessageActive ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "120px" }}
                    >
                        <InformationMessage />
                    </div>
                ) : (
                    <div></div>
                )}

                <div className="d-flex justify-content-center align-items-center">
                    <form
                        style={{
                            display: "grid",
                            gridTemplateRows: "1fr min-content min-content ",
                            height: "100%",
                            gap: "40px",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {isPhoneNumberOnlyActive ? (
                            <PhoneFormInput formik={formik} />
                        ) : (
                            <FullFormInput
                                activeInputFormItems={activeInputFormItems}
                                formik={formik}
                            />
                        )}

                        {isSubStepActionButtonsActive ? (
                            <ActionsButtons onClick={formik.handleSubmit} />
                        ) : null}
                    </form>
                </div>

                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </div>
        </PreviewBackgroundWrapper>
    );
}
