import React from "react";

export function useCountdown(
    initialTime: number,
    callBack: () => void,
    interval = 1000
): number {
    const [time, setTime] = React.useState<number>(initialTime);
    React.useEffect(() => {
        const customTimer = setInterval(() => {
            if (time > 0) setTime((prev) => prev - 1);
        }, interval);
        if (time === 0) {
            callBack();
        }
        return () => clearInterval(customTimer);
    }, [time]);

    return time;
}
