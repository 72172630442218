import { CHANGE_MACHINE_ID, CREDIT_CRAD_ID } from "@constants";
import classNames from "classnames";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { Provider } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { ChangeMachineCard } from "@components/common/Payment/ChangeMachineCard";
import { CreditCard } from "@components/common/Payment/CreditCard";
import { RestoflashCard } from "@components/common/Payment/RestoflashCard";
import store from "@components/OrderTaking/Store";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function PaymentMainContentWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        selectedRegulationModeValue,
        customerLanguage,
    } = useSnapshot(kioskStore);
    let payment;
    if (selectedRegulationModeValue === "changeMachine") {
        payment = (content.payment as PaymentContentItemType).items.filter(
            (item: PaymentMainContentItemType) => item.id === CHANGE_MACHINE_ID
        )[0].languages[customerLanguage.name].paymentInstructions;
    } else if (selectedRegulationModeValue === "creditCard") {
        payment = (content.payment as PaymentContentItemType).items.filter(
            (item: PaymentMainContentItemType) => item.id === CREDIT_CRAD_ID
        )[0].languages[customerLanguage.name].paymentInstructions;
    }

    const paymentInstructionsImageContent = getImageContentById(
        (payment as PaymentInstructionsType).id as string
    );

    return (
        <Provider store={store}>
            <div
                className={classNames("d-flex ", {
                    "payment-with-instruction_clz":
                        (payment as PaymentInstructionsType)?.isTextVisible ===
                            true &&
                        (payment as PaymentInstructionsType)?.isImageVisible ===
                            true,
                    "payment-without-instruction_clz":
                        (payment as PaymentInstructionsType)?.isTextVisible ===
                            false &&
                        (payment as PaymentInstructionsType)?.isImageVisible ===
                            false,
                    "payment-with-partial-instruction_clz":
                        (payment as PaymentInstructionsType)?.isTextVisible ===
                            true ||
                        (payment as PaymentInstructionsType)?.isImageVisible ===
                            true,
                })}
                style={{ gap: "100px" }}
            >
                {selectedRegulationModeValue === "changeMachine" ? (
                    <React.Fragment>
                        <ChangeMachineCard />
                        {(payment as PaymentInstructionsType)?.isTextVisible ===
                        true ? (
                            <div
                                className="text-center"
                                style={{
                                    font: "normal normal 600 50px/67px Segoe UI",
                                }}
                            >
                                {(payment as PaymentInstructionsType).message}
                            </div>
                        ) : null}
                    </React.Fragment>
                ) : selectedRegulationModeValue === "creditCard" ? (
                    <React.Fragment>
                        <CreditCard />
                        {(payment as PaymentInstructionsType)?.isTextVisible ===
                        true ? (
                            <div
                                className="text-center"
                                style={{ fontSize: "50px" }}
                            >
                                {(payment as PaymentInstructionsType).message}
                            </div>
                        ) : null}
                    </React.Fragment>
                ) : selectedRegulationModeValue === "restoflash" ? (
                    <React.Fragment>
                        <RestoflashCard />
                        <div
                            className="text-center"
                            style={{ fontSize: "50px" }}
                        >
                            {t(
                                "Please scan your QR code or enter the transaction code"
                            )}
                        </div>
                    </React.Fragment>
                ) : null}
                {selectedRegulationModeValue === "restoflash" ? (
                    <UsualNumericKeyboardWrapper />
                ) : selectedRegulationModeValue === "creditCard" ? (
                    (payment as PaymentInstructionsType)?.isImageVisible ===
                    true ? (
                        <img src={paymentInstructionsImageContent} />
                    ) : null
                ) : (payment as PaymentInstructionsType)?.isImageVisible ===
                  true ? (
                    <img src={paymentInstructionsImageContent} />
                ) : null}
            </div>
        </Provider>
    );
}
