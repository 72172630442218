import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "./ImageContent";

export function MainContentWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        saleMode,
    } = useSnapshot(kioskStore);

    const informationModesItems = (
        content.informationModes as ProjectContentItemType
    ).items;

    const activeInformationModesItems = informationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return (item.active as ItemContentType)[saleMode.name] === true;
        }
    );

    const activeInformationModesItemsLength =
        activeInformationModesItems.length;

    return (
        <React.Fragment>
            {activeInformationModesItemsLength !== undefined &&
            activeInformationModesItemsLength <= 2 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeInformationModesItems?.map(
                        (item: ProjectMainContentItemType) => (
                            <ImageContent
                                item={item}
                                key={item.id}
                                activeInformationModesItemsLength={
                                    activeInformationModesItemsLength
                                }
                            />
                        )
                    )}
                </div>
            ) : activeInformationModesItemsLength !== undefined &&
              activeInformationModesItemsLength > 2 &&
              activeInformationModesItemsLength <= 4 ? (
                <div
                    className="justify-content-center align-items-center"
                    style={{ display: "grid", gap: "25px" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeInformationModesItems
                            ?.slice(0, 2)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    key={item.id}
                                    activeInformationModesItemsLength={
                                        activeInformationModesItemsLength
                                    }
                                />
                            ))}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeInformationModesItems
                            ?.slice(2)
                            .map((item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    key={item.id}
                                    activeInformationModesItemsLength={
                                        activeInformationModesItemsLength
                                    }
                                />
                            ))}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
