import { GODIA_LOYALTY } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import {
    getColorFromButton,
    getGlobalColorValue,
} from "@components/OrderTaking/Helpers";
import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsCustomerBenefitOnClick,
    setIsCustomerBenefitOpen,
} from "@pages/Kiosk/store";

export function SummaryOption({
    isSummaryOptionsItemsActive,
    newOptionConfig,
    handleConfigOptionOnClickEvent,
}) {
    const {
        isUserAuthenticated,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                            },
                        },
                    },
                },
            },
        },
    } = useSnapshot(kioskStore);
    const { selectedShopApp } = useSnapshot(syncStore);
    const colorValue = getGlobalColorValue("--global-color");
    const color = getColorFromButton(colorValue);

    return isSummaryOptionsItemsActive ? (
        <>
            {newOptionConfig.length !== 0 ? (
                <hr className="vertical-separation-line" />
            ) : null}

            <div
                className="d-flex justify-content-end align-items-center pr-2"
                style={{ gap: "10px" }}
            >
                {selectedShopApp.fidelityType === GODIA_LOYALTY &&
                isUserAuthenticated === true &&
                (giftsActive || rewardsActive) ? (
                    <div
                        className="gifts-icon-container"
                        onClick={() => {
                            setIsCustomerBenefitOpen(true);
                            setIsCustomerBenefitOnClick(true);
                        }}
                    >
                        <DynamicSvgComponent
                            nameSvg="gifts"
                            height={"50px"}
                            width={"50px"}
                            color={color}
                        />
                    </div>
                ) : null}
                {newOptionConfig.map((option, index) => {
                    const imageSrc = getImageContentById(option.id);

                    return (
                        <img
                            className="sales-method-image"
                            key={index}
                            src={imageSrc}
                            alt={option.title}
                            onClick={() => {
                                handleConfigOptionOnClickEvent(option);
                            }}
                        />
                    );
                })}
            </div>
        </>
    ) : null;
}

SummaryOption.propTypes = {
    isSummaryOptionsItemsActive: PropTypes.bool,
    newOptionConfig: PropTypes.array,
    handleConfigOptionOnClickEvent: PropTypes.func,
};
