import {
    ASK_FOR_HELP_BUTTON,
    CONNECT_USER_OPTION,
    FULL__BASKET_ICON_ROLE,
    PRM_BUTTON,
    TCPOS,
    USUAL_MODE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById, getSkippedStepNumber } from "@helpers/general";

import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { LogoWrapper } from "@components/OrderTaking/common/LogoWrapper";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import { Promotion } from "@components/OrderTaking/Components/Promotion";
import { PromotionPrm } from "@components/OrderTaking/Components/Promotion/PromotionPrm";
import { getOrdertakingOptions } from "@components/OrderTaking/Helpers";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setAuthModalStepIndex,
    setIsHelpModalOpened,
    setIsOrderTakingPreviousStep,
    setIsPrm,
    setIsUserAccountModalOpened,
    setLeftToPay,
    setNavigationIndex,
    setOrderTotalPrice,
} from "@pages/Kiosk/store";

import "./index.css";
import SummaryHeader from "./SummaryHeader";
import { SummaryOption } from "./SummaryOption";

export default function CCOrderSummary() {
    const { posEditor } = useSnapshot(store);
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const { totalPrice, itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.basketSettings
    );

    const {
        kioskWays,
        selectedSaleModeValue,
        project,
        isPrm,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        customerLanguage,
        project: {
            template: { content },
            files: images,
        },
        activeConfigOptions,
        paymentModes,
        orderTotalPrice,
        devise,
    } = useSnapshot(kioskStore);

    const {
        isSummaryLogoActive,
        IsAccumulatedLoyaltyPointsActive,
        summaryHeaderMessage,
        isSummaryOptionsItemsActive,
        summaryOptionsItems,
        isSummaryBackgroundActive,
    } = useSelector((state) => state.settingSlice.summary, shallowEqual);

    const { designItems } = useSelector((state) => state.settingSlice);

    const isPromoCodeActive =
        project.template.pages.ways["orderTaking"].summary.promoCodeActive ??
        false;
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });
    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(filledBasketIcon[0].id)
            : "";

    const loyaltyCardItem =
        project.template.content.authenticationModes.items.filter((item) => {
            return item.name === "Loyalty card" && item.active === true;
        })[0];

    const newActiveOptions = isPrm
        ? activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== PRM_BUTTON
          )
        : activeConfigOptions.filter(
              (config) => config.title.toLowerCase() !== USUAL_MODE
          );

    const newOptionConfig = getOrdertakingOptions(
        newActiveOptions,
        summaryOptionsItems
    );

    const backgroundImage = Object.values(images).find((image) => {
        return (
            image.key === "background" && image.name === "orderTakingSummary"
        );
    });
    const imageSrc = getImageContentById(backgroundImage.id);

    async function handleCartChangedCLickEvent() {
        const localPaymentAmount = paymentModes?.reduce(
            (acc, curr) => acc + Number(curr.paymentAmount),
            0
        );
        console.log({ localPaymentAmount });
        setLeftToPay(orderTotalPrice - localPaymentAmount);
        setOrderTotalPrice(orderTotalPrice - localPaymentAmount);

        if (posEditor === TCPOS) {
            const finalMessageIndex = kioskWays.indexOf(
                kioskWays.filter((page) => page?.name === "finalMessage")[0]
            );
            setNavigationIndex(finalMessageIndex);
        } else {
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
        }
        setIsOrderTakingPreviousStep(true);
    }

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;

            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);

                break;
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;

            default:
                null;
        }
    }

    return (
        <React.Fragment>
            <div>
                <OrderTakingBackgroundWrapper
                    filename={imageSrc}
                    isBackgroundActive={isSummaryBackgroundActive}
                >
                    <div
                        className="d-flex flex-column  align-items-center"
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 2,
                        }}
                    >
                        {isPrm ? (
                            <PrmBackgroundWrapper isSummary={true} />
                        ) : null}
                        <div className="headerSummary my-3">
                            {isSummaryLogoActive ? (
                                <div
                                    className="centered-items"
                                    style={{ height: "250px" }}
                                >
                                    <LogoWrapper width={140} height={140} />
                                </div>
                            ) : null}

                            <div
                                className="d-flex flex-column justify-content-center"
                                style={{ height: "100%" }}
                            >
                                {loyaltyCardItem !== undefined ? (
                                    <>
                                        <hr className="separation-line" />
                                        {IsAccumulatedLoyaltyPointsActive ? (
                                            <SummaryHeader />
                                        ) : null}
                                        <hr className="separation-line" />
                                    </>
                                ) : null}
                                <div className="header-text">
                                    <span
                                        className="centered-items"
                                        style={{
                                            height: "100px",
                                            font: "normal normal 600 55px/60px Nunito Sans",
                                        }}
                                    >
                                        {
                                            summaryHeaderMessage.languages[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        {!isPrm ? <hr className="separation-line" /> : null}

                        {isPromoCodeActive && !isPrm ? <Promotion /> : null}

                        <div
                            className="summary-cart-content py-2"
                            style={{
                                width: "100%",
                                height: isPrm ? "" : "100%",
                            }}
                        >
                            {orderItems.map((product) => (
                                <ComposedProductCartPopup
                                    key={product.iuudOrder}
                                    product={product}
                                />
                            ))}
                        </div>

                        {!isPrm ? <hr className="separation-line" /> : null}

                        <div className="footer-summary-cart">
                            <div className="first-block-footer-summary-cart">
                                <div
                                    className="d-flex justify-content-start align-items-center h-100"
                                    style={{
                                        gap: "10px",
                                        width: "20%",
                                    }}
                                >
                                    {filledBasketIconContent !== "" ? (
                                        <div
                                            className="d-flex justify-content-start align-items-center h-100"
                                            style={{
                                                width: "40%",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "95%",
                                                    height: "95%",
                                                }}
                                                src={filledBasketIconContent}
                                                alt={"filledBasket"}
                                            />
                                        </div>
                                    ) : (
                                        <CartIconFilled />
                                    )}
                                    <span>
                                        {itemsCount} {t("Article(s)")}
                                    </span>
                                </div>

                                <div className="config-option">
                                    {isPromoCodeActive && isPrm ? (
                                        <PromotionPrm />
                                    ) : null}

                                    <SummaryOption
                                        isSummaryOptionsItemsActive={
                                            isSummaryOptionsItemsActive
                                        }
                                        newOptionConfig={newOptionConfig}
                                        handleConfigOptionOnClickEvent={
                                            handleConfigOptionOnClickEvent
                                        }
                                    />
                                </div>
                            </div>

                            <div className="summary-actions">
                                {actionsButtons
                                    .filter((item) => {
                                        return item.active === true;
                                    })
                                    .sort((a, b) => {
                                        return a.role > b.role ? -1 : 1;
                                    })
                                    .map((button, index) => {
                                        const imageSrc = getImageContentById(
                                            button.id
                                        );

                                        return button.name.toLowerCase() ===
                                            "pay" ? (
                                            <div
                                                className="total-order-footer"
                                                key={index}
                                                onClick={() => {
                                                    handleCartChangedCLickEvent(
                                                        button
                                                    );
                                                }}
                                            >
                                                {VALIDATED_EXTENSION_FILE.includes(
                                                    imageSrc.substr(-4)
                                                ) ? (
                                                    <img
                                                        src={imageSrc}
                                                        style={{
                                                            opacity:
                                                                button.name.toLowerCase() ===
                                                                    "pay" &&
                                                                orderItems.length ===
                                                                    0 &&
                                                                "0.5",
                                                            width: "591px",
                                                            height: "65px",
                                                        }}
                                                    />
                                                ) : (
                                                    <DynamicSvgComponent
                                                        nameSvg={imageSrc}
                                                        opacity={
                                                            button.name.toLowerCase() ===
                                                                "pay" &&
                                                            orderItems.length ===
                                                                0
                                                                ? "0.5"
                                                                : "1"
                                                        }
                                                        width={"591px"}
                                                        height={"65px"}
                                                    />
                                                )}

                                                <div
                                                    className="total-order px-4"
                                                    style={{
                                                        color: globalColor,
                                                        height: "3%",
                                                    }}
                                                >
                                                    {totalPrice.toFixed(
                                                        devise.decimalPrice
                                                    ) +
                                                        ` ${devise.deviseSymbole}`}
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="basket-cart-buttons"
                                                key={index}
                                            >
                                                {VALIDATED_EXTENSION_FILE.includes(
                                                    imageSrc.substr(-4)
                                                ) ? (
                                                    <img
                                                        src={imageSrc}
                                                        style={{
                                                            width: "437px",
                                                            height: "65px",
                                                        }}
                                                        onClick={() => {
                                                            setNavigationIndex(
                                                                0
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <DynamicSvgComponent
                                                        nameSvg={imageSrc}
                                                        height={"65x"}
                                                        width={"437px"}
                                                        onClick={() => {
                                                            setNavigationIndex(
                                                                0
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </OrderTakingBackgroundWrapper>
            </div>
        </React.Fragment>
    );
}

CCOrderSummary.propTypes = {
    order: PropTypes.object,
    // openDialogCart: PropTypes.bool,
};
