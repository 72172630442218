import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import {
    kioskStore,
    setIsUserAccountModalOpened,
    setNavigationIndex,
    setSelectedAuthMode,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeAuthenticationModesItemsLength: number;
};

export function ImageContent({ item }: ImageContentPropsType): JSX.Element {
    const { kioskWays, customerLanguage } = useSnapshot(kioskStore);

    const imageSrc = getImageContentById(item.id);

    function handleOnClickEvent() {
        setSelectedAuthMode(item.shortName as string);
        const authModeConsammationStepIndex = kioskWays.indexOf(
            kioskWays.filter(
                (step) => step.name === "authenticationModeConsommation"
            )[0]
        );
        setNavigationIndex(authModeConsammationStepIndex);
        setIsUserAccountModalOpened(false);
    }

    return (
        <div
            className="justify-content-center align-items-center"
            onClick={handleOnClickEvent}
            style={{ display: "grid", gridTemplateRows: "6fr 1fr" }}
        >
            <div className="d-flex justify-content-center align-items-end h-100">
                <img
                    src={imageSrc}
                    alt={item.name}
                    style={{
                        width: "300px",
                        height: "300px",
                    }}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontSize: "35px", whiteSpace: "nowrap" }}>
                    {item.languages[customerLanguage.name].name}
                </span>
            </div>
        </div>
    );
}
