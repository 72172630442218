import PropTypes from "prop-types";

import React from "react";

import { AllergenIcon } from "@components/OrderTaking/Components/Icons/AllergenIcon";

export function ProductAllergen({ isAllergenIconActive, isPrm }) {
    return isAllergenIconActive === true ? (
        <div
            className="d-flex justify-content-end align-items-center pl-1"
            style={{ height: "95%" }}
        >
            <AllergenIcon
                width={isPrm ? "30" : "32"}
                height={isPrm ? "30" : "100%"}
            />
        </div>
    ) : null;
}

ProductAllergen.propTypes = {
    isPrm: PropTypes.bool,
    isAllergenIconActive: PropTypes.bool,
};
