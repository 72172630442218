import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function NoTHANKS(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            viewBox="0 0 384 439"
        >
            <defs>
                <filter
                    id="Rectangle_14"
                    x="0"
                    y="0"
                    width={props.width}
                    height={props.height}
                    filterUnits="userSpaceOnUse"
                >
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_20"
                data-name="Groupe 20"
                transform="translate(-3156 4091)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 3156, -4091)"
                    filter="url(#Rectangle_14)"
                >
                    <g
                        id="Rectangle_14-2"
                        data-name="Rectangle 14"
                        transform="translate(9 6)"
                        fill="#fff"
                        stroke="#cbcbcb"
                        strokeWidth="1"
                    >
                        <rect
                            width="366"
                            height="421"
                            rx="13"
                            stroke="none"
                            fill="#fff"
                        />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="365"
                            height="420"
                            rx="12.5"
                            fill="none"
                        />
                    </g>
                </g>
                <text
                    id="NO_THANKS"
                    data-name="NO THANKS"
                    transform="translate(3348 -3868)"
                    fontSize="38"
                    fontFamily="HelveticaNeue-Medium, Helvetica Neue"
                    fontWeight="500"
                    textAnchor="middle"
                >
                    {t("NO THANKS", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
NoTHANKS.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
