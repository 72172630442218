import { BASIC_COMPOSITION, COMPOSITIONS } from "@constants";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { kioskStore } from "@pages/Kiosk/store";

const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
        opacity: 1,
        transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
    }),
};

const child = {
    visible: {
        opacity: 1,
        x: 0,

        transition: {
            type: "spring",
            damping: 12,
            stiffness: 100,
        },
    },
    hidden: {
        opacity: 0,
        x: 0,

        transition: {
            type: "spring",
            damping: 12,
            stiffness: 100,
        },
    },
};

function WorkflowTree() {
    const {
        isBreadcrumbTrailActive,
        isInformationMessageActive,
        isMinMaxShown,
    } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );

    const { secondaryColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { isPrm } = useSnapshot(kioskStore);
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    let workflowTree = [orderWorkflowItemSlice.item.name];
    if (workflowData.length > 0) {
        let listworkflow = workflowData[workflowData.length - 1];

        let path = [];
        listworkflow?.workflow?.[listworkflow.index]?.path.forEach((elm) => {
            if (elm.name !== COMPOSITIONS) {
                if (elm.name.indexOf("|") > 1) {
                    elm.name.substr(0, elm.name.indexOf("|"));
                } else {
                    path.push(elm.displayTitle);
                }
            }
        });

        workflowTree = [...workflowTree, ...path];
    }
    const showMinMax =
        isMinMaxShown &&
        workflowData[workflowData.length - 1]?.workflow[
            workflowData[workflowData.length - 1]?.index
        ]?.type !== BASIC_COMPOSITION;

    const minMax =
        workflowData[workflowData.length - 1]?.workflow[
            workflowData[workflowData.length - 1]?.index
        ]?.minNbrOfChoices > 0
            ? `( ` +
              t("Min") +
              ` ${
                  workflowData[workflowData.length - 1]?.workflow[
                      workflowData[workflowData.length - 1]?.index
                  ]?.minNbrOfChoices
              }  :` +
              t("Max") +
              ` ${
                  workflowData[workflowData.length - 1]?.workflow[
                      workflowData[workflowData.length - 1]?.index
                  ]?.maxNbrOfChoices
              } ) `
            : ` ( ` +
              t("Max") +
              ` ${
                  workflowData[workflowData.length - 1]?.workflow[
                      workflowData[workflowData.length - 1]?.index
                  ]?.maxNbrOfChoices
              }  )`;

    return (
        <>
            <AnimatePresence>
                {isBreadcrumbTrailActive ? (
                    <div className="workflow-tree d-flex align-items-center px-1 w-100">
                        {workflowTree?.map((item, index) => {
                            const name = item?.name ? item.name : item;
                            return index !== workflowTree.length - 1 ? (
                                <React.Fragment key={index}>
                                    <div className="workflow-step d-flex justify-content-center d-flex align-items-center mr-1">
                                        <span className="mx-3">
                                            {name.toUpperCase()}
                                        </span>
                                    </div>
                                    <div className="mx-1">{" / "}</div>
                                </React.Fragment>
                            ) : (
                                <div
                                    key={name}
                                    className="workflow-step d-flex justify-content-center d-flex align-items-center mr-1"
                                    style={{
                                        backgroundColor: `var(--global-color)`,
                                        color: "white",
                                    }}
                                >
                                    <span
                                        className="mx-3"
                                        style={{
                                            color: secondaryColor,
                                        }}
                                    >
                                        {name?.toUpperCase()}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                <motion.div
                    key="titlechoice"
                    variants={container}
                    initial="hidden"
                    animate="visible"
                    className="d-flex justify-content-center workflow-tree-step-title pt-2 "
                    style={{ flexDirection: "column" }}
                >
                    {isInformationMessageActive ? (
                        <motion.span
                            variants={child}
                            className={
                                isPrm
                                    ? "preview-text"
                                    : "two-lines-preview-text px-2"
                            }
                        >
                            {
                                workflowData[workflowData.length - 1]?.workflow[
                                    workflowData[workflowData.length - 1]?.index
                                ].messageStep
                            }
                        </motion.span>
                    ) : null}

                    {showMinMax && (
                        <motion.span
                            key="nbrchoice"
                            variants={child}
                            className="my-2 tada-animation"
                        >
                            {minMax}
                        </motion.span>
                    )}
                </motion.div>
            </AnimatePresence>
        </>
    );
}

export default WorkflowTree;
