import styled from "styled-components";

export type PropsType = {
    isSubStepTopBannerActive: boolean;
    isSubStepIconActive: boolean;
    isSubStepInformationMessageActive: boolean;
    isSubStepActionButtonsActive: boolean;
    isLanguagesActive: boolean;
    isConfigOptionsActive: boolean;
};

export const UsualModeSubStepContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isSubStepActionButtonsActive,
        isLanguagesActive,
        isConfigOptionsActive,
    }: PropsType) =>
        isSubStepTopBannerActive &&
        isSubStepIconActive &&
        isSubStepInformationMessageActive &&
        isSubStepActionButtonsActive &&
        isLanguagesActive &&
        isConfigOptionsActive
            ? "11fr 17fr 6fr 40fr 11fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 6fr 45fr 11fr 10fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 6fr 50fr 11fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 6fr 55fr 11fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 6fr 51fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 6fr 56fr 10fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 6fr 61fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 6fr 66fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 46fr 11fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 51fr 11fr 10fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 56fr 11fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 61fr 11fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 57fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 62fr 10fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 67fr 5fr"
            : isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 73fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 46fr 11fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 51fr 11fr 10fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 56fr 11fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 61fr 11fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 57fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 62fr 10fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 17fr 67fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 17fr 72fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 63fr 11fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 68fr 11fr 10fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 73fr 11fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 78fr 11fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 74fr 10fr 5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 79fr 10fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "11fr 84fr  5fr"
            : isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "11fr 89fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 6fr 51fr 11fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 6fr 56fr 11fr 10fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 6fr 61fr 11fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 6fr 66fr 11fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 6fr 62fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 6fr 67fr 10fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 6fr 72fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 6fr 77fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 57fr 11fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 62fr 11fr 10fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 67fr 11fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 72fr 11fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 68fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 73fr 10fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 78fr 5fr"
            : !isSubStepTopBannerActive &&
              isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 83fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 57fr 11fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 62fr 11fr 10fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 67fr 11fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 72fr 11fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 68fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 73fr 10fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "17fr 78fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "17fr 83fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "74fr 11fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "79fr 11fr 10fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "84fr 11fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "89fr 11fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "85fr 10fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "90fr 10fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "95fr 5fr"
            : !isSubStepTopBannerActive &&
              !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "100fr"
            : "auto"};
`;
