import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function RegulationModes(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeRegulationModes = (
        localProject.template.content.meansOfPayment as ProjectContentItemType
    ).items;

    return (
        <MainContentWrapper
            items={activeRegulationModes}
            contentName="meansOfPayment"
        />
    );
}
