import { CREDIT_CRAD_ID } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { formatCurrency } from "@helpers/general";

import { kioskStore, setLeftToPay } from "@pages/Kiosk/store";

export function CreditCard(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        leftToPayAfterCashMachineValidation,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        leftToPay,
        orderTotalPrice,
        customerLanguage,
    } = useSnapshot(kioskStore);
    // eslint-disable-next-line
    const { totalPrice } = useSelector((state: any) => state.orderSlice.order);
    const payment = (content.payment as PaymentContentItemType).items.filter(
        (item: PaymentMainContentItemType) => item.id === CREDIT_CRAD_ID
    )[0].languages[customerLanguage.name].amount;
    React.useEffect(() => {
        setLeftToPay(
            isOrderPartialPaidWithLoyaltyAccountBalance
                ? totalOrderAfterPaidWithLoyaltyAccountBalance
                : orderTotalPrice
        );
    }, []);

    return (
        <Card
            style={{
                width: "910px",
                height: "265px",
                borderRadius: "31px",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
                borderBottom: `12px solid ${
                    (content.generalDesign as GeneralDesignType).colors[0]
                        .content
                }`,
            }}
        >
            <div
                className="d-grid__clz"
                style={{
                    gridTemplateRows: payment.informationMessage?.isTextVisible
                        ? "1fr 0.1fr 0.8fr 0.8fr"
                        : "1fr 1fr",
                    height: "100%",
                }}
            >
                {payment.informationMessage?.isTextVisible === true ? (
                    <>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "280px",
                                fontSize: "31px",
                            }}
                        >
                            <div>{payment.informationMessage.name}</div>
                        </div>

                        <hr
                            style={{
                                height: "2px",
                                backgroundColor: "#AFAFAF",
                                border: "0px",
                                margin: "20px",
                            }}
                        />
                    </>
                ) : null}
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-2"
                    style={{
                        fontSize: "45px",
                    }}
                >
                    <div>{payment.amountToBePaid.name}</div>
                    <div>
                        {formatCurrency(
                            leftToPayAfterCashMachineValidation !== 0
                                ? leftToPayAfterCashMachineValidation
                                : leftToPay
                        )}
                    </div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center mx-5 px-4"
                    style={{
                        fontSize: "30px",
                        color: "#7C7C7C",
                    }}
                >
                    <div>{payment.totalAmount.name}</div>
                    <div>{formatCurrency(totalPrice)}</div>
                </div>
            </div>
        </Card>
    );
}
