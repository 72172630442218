/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog } from "@mui/material";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import Footer from "./Footer";
import GiftSection from "./GiftSection";
import Header from "./Header";
import RewardSection from "./RewardSection";

function LoyaltyStep(): JSX.Element {
    const { isCustomerBenefitOpen } = useSnapshot(kioskStore);

    return (
        <Dialog
            open={isCustomerBenefitOpen}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "93%!important",
                    height: "max-content!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "4rem",
                    maxWidth: "unset!important",
                    paddingTop: "25px!important",
                    paddingBottom: "25px!important",
                    display: "flex",
                    justifyContent: "start",
                    maxHeight: "unset",
                    alignItems: "center!important",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <Header />
            <GiftSection />
            <RewardSection />
            <Footer />
        </Dialog>
    );
}

export default LoyaltyStep;
