import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function CustomerAccount(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeAuthenticationModes = (
        localProject.template.content
            .authenticationModes as ProjectContentItemType
    ).items.filter((item) => item.shortName !== "emailAndPassword");

    return (
        <MainContentWrapper
            items={activeAuthenticationModes}
            contentName="authenticationModes"
        />
    );
}
