import { VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentByIdV2 } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    kioskStore,
    setIsCreateAnAccountSelected,
    setKeyboardInputsValues,
    setNavigationIndex,
} from "@pages/Kiosk/store";

type ActionsButtonsPropsType = {
    // eslint-disable-next-line
    onClick: (values: any) => void;
};
export function ActionsButtons({
    onClick,
}: ActionsButtonsPropsType): JSX.Element {
    const { subStepActionButtons, authModalStepIndex, customerLanguage } =
        useSnapshot(kioskStore);

    const actionButtons = (
        subStepActionButtons as ActionsButtonsItemV2Type[]
    ).filter((item: ActionsButtonsItemV2Type) => {
        const IGNORE_BUTTON_ROLE = 2;
        const VALIDATE_BUTTON_ROLE = 1;
        const BACK_BUTTON_ROLE = 3;
        const ROLES = [
            IGNORE_BUTTON_ROLE,
            VALIDATE_BUTTON_ROLE,
            BACK_BUTTON_ROLE,
        ];

        return ROLES.includes(item.role);
    });
    // eslint-disable-next-line
    const mainActiveActionsButtons = actionButtons.sort((a: any, b: any) => {
        return a.role > b.role ? -1 : 1;
    });

    async function handleOnClickEvent(
        // eslint-disable-next-line
        item: any,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        e.preventDefault();
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                onClick({});
                break;
            }
            case "Back".toLowerCase(): {
                setIsCreateAnAccountSelected(false);
                setKeyboardInputsValues({});
                if (authModalStepIndex !== 0) {
                    setNavigationIndex(authModalStepIndex);
                }
                break;
            }
        }
    }

    return (
        <div
            className="mx-3 d-flex justify-content-center align-items-center"
            style={{ gap: "40px", flexWrap: "wrap", marginBottom: "1vh" }}
        >
            {mainActiveActionsButtons.map((item: ActionsButtonsItemV2Type) => {
                const imageSrc = getImageContentByIdV2(item.language);
                return (
                    <button
                        key={item.name}
                        type="submit"
                        className="d-flex flex-row"
                        style={{
                            background: "transparent",
                            border: "none",
                            placeSelf: "center",
                            placeItems: "center",
                            gap: "10px",
                        }}
                        onClick={(e) => {
                            handleOnClickEvent(item, e);
                        }}
                    >
                        {VALIDATED_EXTENSION_FILE.includes(
                            imageSrc.substr(-4)
                        ) ? (
                            <img
                                src={imageSrc}
                                alt="action button"
                                style={{
                                    height: "87px",
                                    width: "350px",
                                    opacity: 1,
                                    pointerEvents: "auto",
                                }}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={item.language[customerLanguage.name]}
                                width={"350px"}
                                height={"87px"}
                                pointerEvents={"auto"}
                            />
                        )}
                    </button>
                );
            })}
        </div>
    );
}
