/* eslint-disable react/prop-types */
import React from "react";

export function PromoCodeIcon(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.969"
            height="42.133"
            viewBox="0 0 45.969 42.133"
            {...props}
        >
            <path
                d="M12.045 16.482A1.078 1.078 0 0011 17.591V20a1.047 1.047 0 102.09 0v-2.409a1.078 1.078 0 00-1.045-1.109z"
                transform="translate(-1.597 -3.758)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M12.045 21.914A1.078 1.078 0 0011 23.023v2.408a1.047 1.047 0 102.09 0v-2.408a1.078 1.078 0 00-1.045-1.109z"
                transform="translate(-1.597 -3.167)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M12.045 27.346A1.078 1.078 0 0011 28.455v2.408a1.047 1.047 0 102.09 0v-2.408a1.078 1.078 0 00-1.045-1.109z"
                transform="translate(-1.597 -2.575)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M9.175 5.008a3.194 3.194 0 00-2.73 2.268l-1.392 4.459A3.458 3.458 0 002 15.235v4.412a1.1 1.1 0 00.724 1.053c1.3.446 2.409 2.184 2.41 4.312s-1.106 3.868-2.41 4.314A1.1 1.1 0 002 30.383v6.627a3.447 3.447 0 003.324 3.528h16.494l17.738 6.428a3.1 3.1 0 003.969-2.11l1.355-4.345a3.454 3.454 0 003.089-3.5V30.38a1.1 1.1 0 00-.724-1.055c-1.3-.446-2.409-2.184-2.41-4.312s1.106-3.868 2.41-4.314a1.1 1.1 0 00.724-1.055v-4.41a3.447 3.447 0 00-3.324-3.528H28.156l-17.737-6.54a4.26 4.26 0 00-1.245-.158zm.153 2.2a1.04 1.04 0 01.418.058l12.039 4.44H7.263l1.165-3.735a1.031 1.031 0 01.9-.762zm-4 6.716H11.4v1.055a1.047 1.047 0 102.09 0v-1.054h31.155a1.248 1.248 0 011.235 1.31v3.775a6.772 6.772 0 00-3.134 6 6.771 6.771 0 003.134 6v6a1.248 1.248 0 01-1.235 1.31H13.49c.005-.383 0-.776 0-1.163a1.047 1.047 0 10-2.09 0v1.163H5.324a1.248 1.248 0 01-1.234-1.31v-5.993a6.772 6.772 0 003.134-6 6.771 6.771 0 00-3.134-6v-3.782a1.248 1.248 0 011.235-1.31zm22.96 26.614H42.67l-1.128 3.621a1 1 0 01-1.31.708z"
                transform="translate(-2 -5.008)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M32.907 25.565a3.6 3.6 0 11-3.391 3.6 3.514 3.514 0 013.391-3.6zm0 2.218a1.383 1.383 0 101.3 1.38 1.327 1.327 0 00-1.3-1.38z"
                transform="translate(-.768 -2.769)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M23.385 16.043a3.6 3.6 0 010 7.195 3.6 3.6 0 010-7.195zm0 2.218a1.382 1.382 0 101.3 1.38 1.327 1.327 0 00-1.3-1.38z"
                transform="translate(-1.195 -3.806)"
                fill={props.color || "black"}
            ></path>
            <path
                d="M32.494 18.611l-9.95 10.561a1.158 1.158 0 000 1.568 1 1 0 001.477 0l9.95-10.561a1.158 1.158 0 000-1.568 1.017 1.017 0 00-1.477 0z"
                transform="translate(-1.094 -3.561)"
                fill={props.color || "black"}
            ></path>
        </svg>
    );
}
