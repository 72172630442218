/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const eventTypes = [
    "load",
    "click",
    "mousemove",
    "keydown",
    "wheel",
    "touchmove",
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addEventListeners = (listener: any): void => {
    eventTypes.forEach((type) => {
        window.addEventListener(type, listener, false);
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEventListeners = (listener: any): void => {
    if (listener) {
        eventTypes.forEach((type) => {
            window.removeEventListener(type, listener, false);
        });
    }
};
