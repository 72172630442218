import { t } from "i18next";

import React from "react";

type MenuCompositionsLabelsType = {
    // eslint-disable-next-line
    compositions: any;
    shopCart: boolean;
};

export function MenuCompositionsLabels({
    compositions,
    shopCart,
}: MenuCompositionsLabelsType): JSX.Element {
    let result: string[] = [];
    if (shopCart) {
        Object.keys(compositions).map((key) => {
            if (
                compositions[key].compositions &&
                Object.keys(compositions[key].compositions).length > 0
            ) {
                Object.values(compositions[key].compositions).forEach(
                    // eslint-disable-next-line
                    (product: any) =>
                        result.push(product.displayTitle.toLowerCase())
                );
            }
        });
    } else {
        result = compositions;
    }
    // eslint-disable-next-line
    const groups: any = {};

    result.forEach((item) => {
        if (!groups[item]) {
            groups[item] = [item];
        } else {
            groups[item].push(item);
        }
    });
    const groupedArray = Object.values(groups);
    // eslint-disable-next-line
    const finalResult: any = [];
    // eslint-disable-next-line
    groupedArray.forEach((arr: any) => {
        const length = arr.length > 1 ? arr.length + "x " : "";
        const firstElement = arr[0];
        finalResult.push(length + firstElement);
    });
    return (
        <div className="d-flex flex-column justify-content-start align-items-start">
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#747272",
                    textAlign: "left",
                }}
            >
                {t("You have selected:")}
            </span>
            <span className="summary-product-compositions three-lines-preview-text">
                {finalResult.join(" ,")}.
            </span>
        </div>
    );
}
