import * as React from "react";

export function MenuIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={83} height={83}>
            <defs>
                <filter
                    id="a"
                    x={0}
                    y={0}
                    width={83}
                    height={83}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.302} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Groupe 30870">
                <g data-name="Groupe 30865">
                    <g data-name="Groupe 27267">
                        <g data-name="Groupe 3178">
                            <g
                                transform="translate(.001)"
                                filter="url(#a)"
                                data-name="Groupe 7"
                            >
                                <g
                                    data-name="Rectangle 21"
                                    transform="translate(9 6)"
                                    fill="#f8f8f6"
                                    stroke="#f8f8f6"
                                >
                                    <rect
                                        width={65}
                                        height={65}
                                        rx={15}
                                        stroke="none"
                                        fill="white"
                                    />
                                    <rect
                                        x={0.5}
                                        y={0.5}
                                        width={64}
                                        height={64}
                                        rx={14.5}
                                        fill="none"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <path
                    data-name="Trac\xE9 22041"
                    d="M61.682 19.607H19.701a3.704 3.704 0 0 0 0 7.408h41.981a3.704 3.704 0 0 0 0-7.408Z"
                    fill="black"
                />
                <path
                    data-name="Trac\xE9 22042"
                    d="M54.685 34.422H19.701a3.704 3.704 0 0 0 0 7.408h34.98a3.704 3.704 0 0 0 0-7.408Z"
                    fill="black"
                />
                <path
                    data-name="Trac\xE9 22043"
                    d="M40.693 49.238H19.701a3.704 3.704 0 0 0 0 7.408h20.992a3.704 3.704 0 0 0 0-7.408Z"
                    fill="black"
                />
            </g>
        </svg>
    );
}
