import React from "react";

function ClosecircleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={61.554} height={61.554}>
            <defs>
                <filter
                    id="a"
                    x={13.378}
                    y={13.37}
                    width={34.777}
                    height={34.785}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.161} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="b"
                    x={0}
                    y={0}
                    width={61.554}
                    height={61.554}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur-2" />
                    <feFlood floodOpacity={0.161} />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Icon ionic-ios-close-circle-outline">
                <g filter="url(#a)" transform="translate(.005 -.005)">
                    <path
                        data-name="Trac\xE9 21170"
                        d="m38.662 33.298-5.518-5.518 5.518-5.518a1.673 1.673 0 0 0-2.366-2.366l-5.518 5.518-5.518-5.518a1.673 1.673 0 0 0-2.366 2.366l5.517 5.517-5.518 5.518a1.618 1.618 0 0 0 0 2.366 1.662 1.662 0 0 0 2.366 0l5.518-5.518 5.517 5.518a1.681 1.681 0 0 0 2.366 0 1.662 1.662 0 0 0 .002-2.365Z"
                    />
                </g>
                <g filter="url(#b)" transform="translate(.005 -.005)">
                    <path
                        data-name="Trac\xE9 21171"
                        d="M30.782 8.936a18.838 18.838 0 1 1-13.328 5.518 18.721 18.721 0 0 1 13.328-5.518m0-2.931a21.777 21.777 0 1 0 21.777 21.777A21.774 21.774 0 0 0 30.782 6.005Z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default ClosecircleIcon;
