import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Allergen = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="214"
            height="50"
            viewBox="0 0 214 50"
            {...props}
        >
            <g
                id="Groupe_58"
                data-name="Groupe 58"
                transform="translate(2505 246)"
            >
                <path
                    id="Tracé_17"
                    data-name="Tracé 17"
                    d="M9,0H205a9,9,0,0,1,9,9V41a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V9A9,9,0,0,1,9,0Z"
                    transform="translate(-2505 -246)"
                    fill="#141414"
                />
                <text
                    id="Allergènes"
                    transform="translate(-2379 -212)"
                    fill="#fff"
                    fontSize="24"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Allergens", { lng: customerLanguage.name })}
                </text>
                <g id="gluten-free" transform="translate(-2488 -236.961)">
                    <g
                        id="Groupe_4"
                        data-name="Groupe 4"
                        transform="translate(0)"
                    >
                        <path
                            id="Tracé_5"
                            data-name="Tracé 5"
                            d="M21.4,22.724a7.206,7.206,0,0,1-4.549,1.427v3.028a.933.933,0,1,1-1.866,0V24.151a6.947,6.947,0,0,1-4.813-1.669c-2.062-2.062-1.641-5.652-1.622-5.8a.933.933,0,0,1,.81-.81c.019,0,.1-.011.217-.021a8.406,8.406,0,0,1-1.027-5.073.93.93,0,0,1,.312-.587L4.023,5.342a15.922,15.922,0,0,0,11.9,26.5A15.8,15.8,0,0,0,26.5,27.821ZM15.922,0A15.8,15.8,0,0,0,5.342,4.023l5.941,5.941a8.715,8.715,0,0,1,1.216.205,4.716,4.716,0,0,1-.107-.99c0-2.916,2.836-5.157,2.957-5.25a.934.934,0,0,1,1.146,0c.121.094,2.957,2.335,2.957,5.25a4.716,4.716,0,0,1-.107.99,9.543,9.543,0,0,1,3.135-.206.933.933,0,0,1,.81.81,8.408,8.408,0,0,1-1.027,5.074c.121.009.2.018.217.021a.933.933,0,0,1,.81.81,8.811,8.811,0,0,1-.729,4.563l5.26,5.26A15.922,15.922,0,0,0,15.922,0Z"
                            transform="translate(0)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_6"
                            data-name="Tracé 6"
                            d="M275.879,284.37c-.038,3.126-1.44,4.578-4.6,4.6a4.009,4.009,0,0,1,4.6-4.6Z"
                            transform="translate(-254.408 -266.684)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_7"
                            data-name="Tracé 7"
                            d="M171.379,288.969a4.009,4.009,0,0,1-4.6-4.6,4.009,4.009,0,0,1,4.6,4.6Z"
                            transform="translate(-156.407 -266.684)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_8"
                            data-name="Tracé 8"
                            d="M171.379,194.026a4.006,4.006,0,0,1-4.6-4.6C170.058,189.465,171.358,191,171.379,194.026Z"
                            transform="translate(-156.407 -177.649)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_9"
                            data-name="Tracé 9"
                            d="M271.28,194.026a4.006,4.006,0,0,0,4.6-4.6C272.6,189.465,271.3,191,271.28,194.026Z"
                            transform="translate(-254.408 -177.649)"
                            fill="#fff"
                        />
                        <path
                            id="Tracé_10"
                            data-name="Tracé 10"
                            d="M232.577,98.552a5.033,5.033,0,0,1-1.664,3.252,5.035,5.035,0,0,1-1.664-3.252,5.114,5.114,0,0,1,1.664-3.252A5.117,5.117,0,0,1,232.577,98.552Z"
                            transform="translate(-214.992 -89.373)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
