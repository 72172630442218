import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Next = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_76"
                data-name="Groupe 76"
                transform="translate(-1842 4082)"
            >
                <rect
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    width={props.width}
                    height={props.height}
                    rx="9"
                    transform="translate(1842 -4082)"
                    fill="#141414"
                />
                <text
                    id="Suivant"
                    transform="translate(2017 -4024)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Next", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};
Next.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
