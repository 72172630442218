import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { kioskStore } from "@pages/Kiosk/store";

type ConfirmationSystemActionsType = {
    setIsConfirmationRemoveProductModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isConfirmationRemoveProductModalOpen: boolean;
    callback: () => void;
};

export function ConfirmationRemoveProductModal({
    setIsConfirmationRemoveProductModalOpen,
    isConfirmationRemoveProductModalOpen,
    callback,
}: ConfirmationSystemActionsType): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);
    return (
        <Modal
            isOpen={isConfirmationRemoveProductModalOpen}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "none",
                    zIndex: 1,
                    backdropFilter: "contrast(50%)",
                },
                content: {
                    display: "flex!important",
                    alignItems: "center!important",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "20rem",
                    width: "32rem",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: "15px solid var(--global-color)",
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "50px",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    bottom: "unset",
                    top: isPrm ? "1500px" : "unset",
                    right: "unset",
                    left: "unset",
                    background: `transparent linear-gradient(180deg, #FFFFFF 0%, var(--global-color) 300%) 0% 0% no-repeat padding-box`,
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center mt-2"
                style={{
                    gap: "40px",
                }}
            >
                {/* <span
                    style={{
                        font: "normal normal 600 40px/60px Nunito Sans",
                    }}
                >
                    {t("Confirmation")}
                </span> */}
                <div
                    style={{
                        textAlign: "center",
                        font: "normal normal 600 30px/60px Nunito Sans",
                        marginLeft: "50px",
                        marginRight: "50px",
                    }}
                >
                    {t("Would you like to delete this article?")}
                </div>
                <div className="d-flex justify-content-end">
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "150px",
                            height: "60px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "black",
                            fontSize: "25px",
                        }}
                        onClick={() => {
                            setIsConfirmationRemoveProductModalOpen(false);
                        }}
                    >
                        {t("Back")}
                    </StyledButton>
                    <StyledButton
                        rounded={true}
                        className="m-2"
                        style={{
                            width: "200px",
                            height: "60px",
                            background: "#0a0a0a 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            border: "1px solid #C9C9C9",
                            borderRadius: "12px",
                            color: "white",
                            fontSize: "25px",
                        }}
                        onClick={() => {
                            callback();
                            setIsConfirmationRemoveProductModalOpen(false);
                        }}
                    >
                        {t("Confirm")}
                    </StyledButton>
                </div>
            </div>
        </Modal>
    );
}
