import React from "react";

export default function FranceFlag(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="iconify iconify--twemoji"
            viewBox="0 0 36 36"
            {...props}
        >
            <path
                fill="#ED2939"
                d="M36 27a4 4 0 01-4 4h-8V5h8a4 4 0 014 4v18z"
            />
            <path fill="#002495" d="M4 5a4 4 0 00-4 4v18a4 4 0 004 4h8V5H4z" />
            <path fill="#EEE" d="M12 5h12v26H12z" />
        </svg>
    );
}
