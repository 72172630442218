import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { getProductTitle } from "@components/OrderTaking/Helpers";

export function Tag({ tag }) {
    const { secondaryColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const tagImg = getFilenameFromUrl(tag.imageUrl, "catalog");

    const tagTitle = getProductTitle(tag.name);

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                gap: "8px",
                height: "50px",
            }}
        >
            {tagImg.length > 48 && (
                <div style={{ width: "45px" }}>
                    <img
                        className="w-100 h-100 p-1"
                        style={{ borderRadius: "100%" }}
                        src={tagImg}
                    />
                </div>
            )}
            <div
                style={{
                    color: tag.active === true ? secondaryColor : "black",
                    font: "normal normal 600 20px/27px Segoe UI",
                }}
            >
                {tagTitle}
            </div>
        </div>
    );
}

Tag.propTypes = {
    tag: PropTypes.object,
};
