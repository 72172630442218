import { CATALOGUE_MODE } from "@constants";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { FinalMessageFooterWrapper } from "./FinalMessageFooterWrapper";
import { FinalMessageWrapper } from "./FinalMessageWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { OrderNumber } from "./OrderNumber";
import { TicketNumberMessageFooter } from "./TicketNumberMessageFooter";

export function OrderNumberWrapper(): JSX.Element | null {
    const { isOrderInsertedSuccessfully, isTicketNumberActive } =
        useSnapshot(kioskStore);

    console.log({ isTicketNumberActive });
    return isOrderInsertedSuccessfully ||
        process.env.REACT_APP_MODE === CATALOGUE_MODE ? (
        <React.Fragment>
            <HeaderWrapper />
            {/* always shown */}
            <FinalMessageWrapper />
            <div
                className={classNames({
                    "d-flex flex-column justify-content-center align-items-center":
                        isTicketNumberActive === true,
                    "mt-2 d-flex justify-content-center align-items-end":
                        isTicketNumberActive === false,
                })}
            >
                {isTicketNumberActive === true ? (
                    <div
                        className="d-flex flex-column"
                        style={{ height: "898px" }}
                    >
                        <TicketNumberMessageFooter />
                        <OrderNumber />
                    </div>
                ) : null}
            </div>
            <FinalMessageFooterWrapper />
        </React.Fragment>
    ) : null;
}
