import styled from "styled-components";

// HEADER CSS Style
export const HeaderContainer = styled.div`
    height: 15rem;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    color: black;
    padding: 1.5rem 0rem;
`;

export const Separator = styled.div`
    background-color: #00ff07;
    height: 1rem;
`;

export const GreetingSectionContainer = styled.span`
    font-size: 40px;
    font-weight: 700;
    grid-column: 1 / 4;
    grid-row: 1;
    margin-top: 1rem;
`;
export const AuthenticateButtonContainer = styled.button<{
    inputColor: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 1.5rem;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 1rem;
    border-radius: 12px;
    color: ${(props) => props.inputColor || "white"};
    width: 18rem;
    text-align: center;
    height: 4rem;
    grid-column: 4 / 4;
    grid-row: 1;
    justify-self: end;
`;
export const LoyaltyBalanceContainer = styled.div`
    font-weight: 400;
    letter-spacing: 2px;
    grid-column: 1 / 4;
    grid-row: 2;
    font-size: 40px;
`;
export const PromoCodeScanContainer = styled.button<{
    inputColor: string;
}>`
    grid-column: 4 / 4;
    grid-row: 2;
    color: ${(props) => props.inputColor || "black"};
    background-color: var(--global-color);
    border-color: var(--global-color);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    border-radius: 12px;
    width: 26rem;
    height: 4rem;
    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
`;

// Promo Section CSS Style
export const PromoSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 20% 20%;
    gap: 30px;
    background-color: #00ff07;
    color: black;
    width: 100%;
    height: 5rem;
    padding: 0.5rem 1rem;
`;

export const PromoButtonContainer = styled.div`
    width: 20rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const PromoButton = styled.button`
    width: 60%;
    height: 3.5rem;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 10px;
    color: black;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const PromoSctionQrCodeContainer = styled.div`
    padding: 4rem 2rem 3rem 2rem;
    height: 68rem;
    width: 100%;
    gap: 2rem;
`;

// Gifts Section CSS

export const GiftsContainer = styled.div<{ isGiftOnly: boolean }>`
    min-height: ${(props) => (props.isGiftOnly ? "51rem" : "10rem")};
    padding: 0.8 1rem 0 1rem;
`;
export const GitsTitle = styled.span`
    font-size: 40px;
    font-weight: 500;
    margin: 0 1rem;
`;
export const AvailableBalance = styled.span`
    all: unset;
    font-size: 1.5rem;
    color: #4c5155;
    font-weight: normal;
`;

export const CardButton = {
    width: "90%",
    height: "3rem",
    border: "none",
    fontSize: "1rem",
    borderRadius: "0px",
};

export const selectedCardButton = {
    width: "90%",
    height: "3.5rem",
    border: "none",
    fontSize: "1rem",
    borderRadius: "0px",
};

export const EmptyCodePromoContainer = styled.div`
    margin-top: 1rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

export const PromoCodeCardContainer = styled.div`
    box-sizing: border-box;
    background-color: var(--global-color);
    border-radius: 10px;
    padding: 0 5px 0 15px;
    width: 98%;
    height: 100px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px;
`;

export const PromoCodeOnlyCardContainer = styled.div`
    box-sizing: border-box;
    background-color: var(--global-color);
    border-radius: 10px;
    padding: 0 5px 0 15px;
    width: 60.5rem;
    height: 100px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
`;

// Rewards Section
export const RewardsContainer = styled.div`
    height: 75rem;
    display: grid;
    grid-template-rows: 5rem auto;
    width: 100%;
`;
export const RewardTitle = styled.span`
    font-size: 40px;
    font-weight: 500;
    margin: 1rem;
`;
