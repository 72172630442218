import {
    CATALOG_SYNCRO_STEP,
    COMPLETED_SYNCRO_STEP,
    FINAL_SYNCHRO_STEP,
    SETTING_SYNCRO_STEP,
} from "@constants";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { t } from "i18next";

import React from "react";

import AccountSyncro from "@components/SvgIcons/AccountSyncro";
import CatalogSyncro from "@components/SvgIcons/CatalogSyncro";
import CompletedSyncro from "@components/SvgIcons/CompletedSyncro";
import SettingSyncro from "@components/SvgIcons/SettingSyncro";

type CustomizedSteppersPropsType = {
    activeStep: number;
    errorOnSyncro: boolean;
};

interface ColorStepIconProps extends StepIconProps {
    activeStep: number;
    errorOnSyncro: boolean;
}

const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        maxWidth: "60%",
        marginInline: "auto",
        borderRaduis: "2px",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#7B7B7B",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#35CCCD",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: "#7B7B7B",
        borderRadius: 1,
    },
}));

function ColorlibStepIcon(props: ColorStepIconProps) {
    const { activeStep, icon, errorOnSyncro } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: icon === activeStep ? <CircularProgress /> : <AccountSyncro />,
        2:
            errorOnSyncro && activeStep === SETTING_SYNCRO_STEP ? (
                <CancelIcon htmlColor="red" />
            ) : icon === activeStep ? (
                <CircularProgress color="primary" />
            ) : (
                <SettingSyncro isDisabled={activeStep > SETTING_SYNCRO_STEP} />
            ),
        3:
            errorOnSyncro && activeStep === CATALOG_SYNCRO_STEP ? (
                <CancelIcon htmlColor="red" />
            ) : icon === activeStep ? (
                <CircularProgress color="primary" />
            ) : (
                <CatalogSyncro isDisabled={activeStep > CATALOG_SYNCRO_STEP} />
            ),
        4:
            icon === activeStep ? (
                <CircularProgress color="primary" />
            ) : (
                <CompletedSyncro
                    isDisabled={activeStep > COMPLETED_SYNCRO_STEP}
                />
            ),

        5: <CompletedSyncro isDisabled={activeStep === FINAL_SYNCHRO_STEP} />,
    };

    return <>{icons[String(props.icon)]}</>;
}

const steps = [
    "Connection",
    "Synchronization Template KIOSK",
    "Synchronization Catalogue",
    "Finished",
];

export default function SyncProgressSteppers({
    activeStep,
    errorOnSyncro,
}: CustomizedSteppersPropsType): JSX.Element {
    const IconComponent = (props: StepIconProps) => {
        const { active, completed, className, icon } = props;
        return (
            <ColorlibStepIcon
                activeStep={activeStep}
                active={active}
                completed={completed}
                className={className}
                icon={icon}
                errorOnSyncro={errorOnSyncro}
            />
        );
    };
    return (
        <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {steps.map((label, stepIndex) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={IconComponent}
                            optional={
                                <span
                                    className="lapel-step-regular"
                                    style={{
                                        color:
                                            activeStep > stepIndex
                                                ? "#fff"
                                                : "#7B7B7B",
                                    }}
                                >
                                    {t(label)}
                                </span>
                            }
                        />
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}
