import {
    BASIC_COMPOSITION_TYPE,
    ETK_CESAR,
    FREE_AMOUNT_ON_ORDER,
    FREE_AMOUNT_ON_PRODUCT,
    FREE_DELIVRY,
    FREE_PRODUCT,
    PERCENT_ON_ORDER,
    PERCENT_ON_PRODUCT,
} from "@constants";
import { t } from "i18next";
import { snapshot } from "valtio";

import { ErrorLoyaltyToast, LoyaltyToast } from "@components/common/Toast";

import { kioskStore } from "@pages/Kiosk/store";

export function getLoyaltyTotal(orderItems) {
    let sum = 0;

    let order = JSON.parse(JSON.stringify(orderItems));

    order.forEach((item) => {
        let localShopCartSum = item?.fidelity ? item?.fidelity : 0;

        if (Object.keys(item?.shopCart).length > 0) {
            Object.values(item.shopCart).forEach((el) => {
                if (el.type !== BASIC_COMPOSITION_TYPE) {
                    localShopCartSum += getSum(el.compositions);
                }
            });
        }
        sum += localShopCartSum * item.quantity;
    });

    return sum;
}

function getSum(compositions) {
    let localSum = 0;

    Object.values(compositions).forEach((product) => {
        let localProductSum = product.fidelity ? product?.fidelity : 0;
        if (
            product.compositions !== undefined &&
            Object.keys(product.compositions).length > 0
        ) {
            localProductSum = localProductSum + getSum(product.compositions);
        }
        localSum += localProductSum;
    });

    return localSum;
}

export function getAvailablePromoCode(promos) {
    const result = promos.reduce(
        (acc, promo) => {
            acc.minOrder.push(promo.minOrder);
            acc.maxOrder = Math.max(acc.maxOrder, promo.maxOrder);
            return acc;
        },
        { minOrder: [], maxOrder: 0 }
    );

    result.minOrder.sort((a, b) => a - b);
    return result;
}
export function reCalculateTotalOrderWithGift(
    initialPrice,
    discountType,
    discountValue,
    cardType
) {
    let totalPriceWithGift = initialPrice;
    switch (parseFloat(discountType)) {
        case PERCENT_ON_ORDER:
            totalPriceWithGift =
                totalPriceWithGift - (totalPriceWithGift * discountValue) / 100;
            break;
        case FREE_AMOUNT_ON_ORDER:
            if (cardType === ETK_CESAR) {
                const discountPercent =
                    (discountValue / totalPriceWithGift) * 100;
                totalPriceWithGift =
                    totalPriceWithGift * (1 - discountPercent / 100);
            } else {
                totalPriceWithGift = totalPriceWithGift - discountValue;
            }

            break;
        case FREE_AMOUNT_ON_PRODUCT:
            break;
        case PERCENT_ON_PRODUCT:
            break;
        case FREE_DELIVRY:
            break;
        case FREE_PRODUCT:
            break;

        default:
            break;
    }
    return totalPriceWithGift >= 0 ? Number(totalPriceWithGift) : 0;
}
export function handleRewardSelection(rewards, iuud, menuItem = {}) {
    return rewards.map((reward) => {
        const newRewardList = reward.rewardList.map((rewardItem) => {
            if (rewardItem.iuud === iuud) {
                return {
                    ...rewardItem,
                    ...menuItem,
                    isSelected: !rewardItem.isSelected,
                };
            }
            return rewardItem;
        });
        return {
            ...reward,
            rewardList: newRewardList,
        };
    });
}

export function handleClearRewardSelection(rewards) {
    return rewards.map((reward) => {
        const newRewardList = reward.rewardList.map((rewardItem) => {
            return {
                ...rewardItem,
                isSelected: false,
            };
        });
        return {
            ...reward,
            rewardList: newRewardList,
        };
    });
}
export function getdiscountRate(totalOrder, discountType, discountValue) {
    let discountRate = 0;
    switch (parseFloat(discountType)) {
        case PERCENT_ON_ORDER:
            discountRate = discountValue;
            break;
        case FREE_AMOUNT_ON_ORDER:
            if (discountValue >= totalOrder) {
                discountRate = 100;
            } else {
                discountRate = (discountValue / totalOrder) * 100;
            }
            break;
        case FREE_AMOUNT_ON_PRODUCT:
            break;
        case PERCENT_ON_PRODUCT:
            break;
        case FREE_DELIVRY:
            break;
        case FREE_PRODUCT:
            break;

        default:
            break;
    }
    return discountRate;
}
export function checkPromoCodeValidity(usedGifts, userGifts, totalOrder) {
    const { customerLanguage } = snapshot(kioskStore);
    if (usedGifts.length > 0) {
        let promoRemoved = false;

        usedGifts.forEach((gift) => {
            const giftName =
                gift.name[customerLanguage.name.toUpperCase()] !== ""
                    ? gift.name[customerLanguage.name.toUpperCase()]
                    : gift.name.default;
            if (gift.maxOrder > 0 && totalOrder > gift.maxOrder) {
                ErrorLoyaltyToast(
                    `${t(
                        `The reward {{giftName}} has been removed due to not meeting the maximum requirement`,
                        { giftName }
                    )}`
                );
                promoRemoved = true;
            }
            if (totalOrder < gift.minOrder) {
                ErrorLoyaltyToast(
                    `${t(
                        `The reward {{giftName}} has been removed due to not meeting the minimum requirement`,
                        {
                            giftName,
                        }
                    )}`
                );
                promoRemoved = true;
            }
        });
        if (promoRemoved) {
            usedGifts = usedGifts.filter(
                (gift) =>
                    totalOrder <= gift.maxOrder && totalOrder >= gift.minOrder
            );

            userGifts = userGifts.map((item) => {
                return {
                    ...item,
                    isSelected: false,
                };
            });
        }
        return { usedGifts, userGifts };
    }
}

export function unlockedPromoCode(state, draftState) {
    for (
        let i = state.promoCodesMinMaxOrder.minOrder.length - 1;
        state.promoCodesMinMaxOrder.minOrder[i] > state.promoCode;
        i--
    ) {
        if (
            draftState.totalPriceWithOutFidelity >=
                state.promoCodesMinMaxOrder.minOrder[i] &&
            draftState.totalPriceWithOutFidelity <
                state.promoCodesMinMaxOrder.maxOrder
        ) {
            if (state.promoCodesMinMaxOrder.minOrder[i] !== null) {
                state.promoCode = state.promoCodesMinMaxOrder.minOrder[i];
            }
            LoyaltyToast(
                `${t(`You have unlocked one or more promotions:`)} ${t(
                    `Click here to use it`
                )}`
            );
            break;
        }
    }
}
