import { CATALOGUE_MODE, VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getFilenameFromUrl } from "@helpers/general";

import { HeaderStep } from "@components/common/HeaderStep";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setOrderModificationMode,
    setSelectedInformationMode,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
    kioskStore as store,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function UsualMode(): JSX.Element {
    const {
        isInformationModesModalOpened,
        customerLanguage,
        project: {
            template: {
                content: { informationModes, generalDesign },
            },
            files: images,
        },
        isUserAuthenticated,
        saleMode,
    } = useSnapshot(store);

    const informationModesItems = (informationModes as ProjectContentItemType)
        .items;

    const activeInformationModesItems = informationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return (item.active as ItemContentType)[saleMode.name] === true;
        }
    );

    const activeInformationModesItemsLength =
        activeInformationModesItems.length;

    const backButtonImage = Object.values(images).filter(
        (item: ImageItemType) => {
            return item.name?.toLowerCase() === "ignore";
        }
    )[0].content as ImageItemLanguagesType;

    const backButtonImageSrc = backButtonImage[
        customerLanguage.name
    ].path?.includes("http")
        ? getFilenameFromUrl(
              backButtonImage[customerLanguage.name].path,
              "template"
          )
        : `./images/${backButtonImage[customerLanguage.name].path}`;

    return (
        <Modal
            isOpen={
                activeInformationModesItemsLength !== 0 &&
                isInformationModesModalOpened
            }
            ariaHideApp={false}
            onRequestClose={() => {
                setIsInformationModesModalOpened(
                    !isInformationModesModalOpened
                );
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <LogoWrapper />

            <HeaderStep />

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "928px", width: "865px" }}
            >
                <MainContentWrapper />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "144px", width: "865px" }}
            >
                {VALIDATED_EXTENSION_FILE.includes(
                    backButtonImageSrc.substr(-4)
                ) ? (
                    <img
                        src={backButtonImageSrc}
                        alt="back"
                        onClick={() => {
                            if (saleMode.name === "Delivery") {
                                if (isUserAuthenticated === false) {
                                    setIsUserAccountModalOpened(true);
                                } else {
                                    setSelectedSaleModeValue(saleMode.name);
                                    setSelectedSaleModeId(saleMode.id);
                                    setSelectedSaleModeOrderTaking(
                                        saleMode.settingKey
                                    );
                                    setOrderModificationMode();
                                    setFinalMessageSelectedInformationMode(
                                        "none"
                                    );
                                    setSelectedInformationMode("");
                                }
                            } else {
                                setFinalMessageSelectedInformationMode("none");
                                setSelectedInformationMode("");
                                setChosenInformationMode({
                                    type: "",
                                    data: { infoModeUuId: "", value: "" },
                                });
                            }
                            setIsInformationModesModalOpened(
                                !isInformationModesModalOpened
                            );
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={
                            backButtonImage[customerLanguage.name]
                                .path as string
                        }
                        width={"350px"}
                        height={"87px"}
                    />
                )}
            </div>
        </Modal>
    );
}
