const SCAN_BUTTON_ID = "049c04b4-9b01-42a6-8497-8b2a2aedd2d3";
const ENTER_BUTTON_ID = "fb60d068-b372-4537-afd1-bb18d42da854";
const USE_BUTTON_ID = "bbf6b88b-b5f4-438f-b8b0-0c7827083b53";
const SELECT_BUTTON_ID = "c3f52255-7cd6-4024-b784-52a79172254a";
const LOGOUT_BUTTON_ID = "4bb339a4-ec7b-4d66-9fb0-9e71c1d6d983";

const CESAR_LOYALTY = 1;
const GODIA_LOYALTY = 4;

export {
    SCAN_BUTTON_ID,
    ENTER_BUTTON_ID,
    USE_BUTTON_ID,
    SELECT_BUTTON_ID,
    LOGOUT_BUTTON_ID,
    GODIA_LOYALTY,
    CESAR_LOYALTY,
};
