import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";

import { kioskStore, setSelectedSaleModeValue } from "@pages/Kiosk/store";

export function SalesMethodsSelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const { localProject } = useSnapshot(kioskStore);

    const [salesMethodsOptions, setSalesMethodsOptions] = React.useState<
        LocalOptionsType[]
    >([]);

    const [selectedSalesMethodsOption, setSelectedSalesMethodsOption] =
        React.useState<LocalOptionsType>(salesMethodsOptions[0]);

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedSaleModeValue(selectedValue.value as string);
        setSelectedSalesMethodsOption(selectedValue);
    }

    React.useEffect(() => {
        const options = (
            localProject.template.content.salesMethods as ProjectContentItemType
        ).items
            .filter((saleMethod) => {
                return saleMethod.name !== "Retrait C&C" && saleMethod.active;
            })
            .map((option) => ({
                label: t(option.name) as string,
                value: option.name,
            }));
        const selectedsaleMode = (
            localProject.template.content.salesMethods as ProjectContentItemType
        ).items.filter((saleMethod) => {
            return saleMethod.name !== "Retrait C&C" && saleMethod.active;
        })[0].name;
        setSelectedSalesMethodsOption({
            label: t(selectedsaleMode) as string,
            value: selectedsaleMode,
        });

        setSelectedSaleModeValue(selectedsaleMode);
        setSalesMethodsOptions(options);
    }, [localProject.template.content.salesMethods]);

    return (
        <StyledSelectInput
            id="sales-methods-select"
            name="sales-methods-select"
            options={salesMethodsOptions}
            value={
                selectedSalesMethodsOption !== undefined
                    ? selectedSalesMethodsOption
                    : salesMethodsOptions[0]
            }
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("There's no more choice")}
            className="w-25 ml-4"
        />
    );
}
