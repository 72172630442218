import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ListItems } from "../../Products/ProductWrapper/ListItems";
import PmrProducts from "../../Products/ProductWrapper/PmrProducts";

function WorkflowProduct({
    listProducts,
    currentWorkflow,
    workflowData,
    orderWorkflowItemSlice,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    return !isPrm ? (
        <ListItems
            listProducts={listProducts}
            currentWorkflow={currentWorkflow}
            isWorkFlow={true}
            workflowData={workflowData}
            orderWorkflowItemSlice={orderWorkflowItemSlice}
        />
    ) : (
        <div className="d-flex justify-content-center h-100">
            <PmrProducts
                listProducts={listProducts}
                currentWorkflow={currentWorkflow}
                isWorkFlow={true}
                workflowData={workflowData}
                orderWorkflowItemSlice={orderWorkflowItemSlice}
            />
        </div>
    );
}

export default WorkflowProduct;
WorkflowProduct.propTypes = {
    listProducts: PropTypes.array,
    currentWorkflow: PropTypes.object,
    workflowData: PropTypes.array,
    orderWorkflowItemSlice: PropTypes.object,
};
