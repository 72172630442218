import {
    CATALOGUE_MODE,
    CESAR,
    DEV_MODE,
    ELECTRON_MODE,
    FENCE_PROBLEM,
    GETING_CARD_CATALOGUE_PROBLEM,
    NETWORK_PROBLEM,
    TCPOS,
    WEBVIEW_MODE,
} from "@constants";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { setIsTechProblemModalOpened, store } from "@store";

import {
    getFilenameFromUrl,
    getSkippedStepNumber,
    handleLedControl,
} from "@helpers/general";

import { useLocalStorage } from "@hooks/useLocalStorage";

import {
    checkforLicenseAvailability,
    checkTcposAvailability,
} from "@components/OrderTaking/Helpers";
import { setMachineIpAddress } from "@components/Synchronization/store";

import { kioskStore, setNavigationIndex } from "@pages/Kiosk/store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function ProdAppSwiperWrapper(): JSX.Element {
    const [isCallOnProgress, setIsCallOnProgress] = React.useState(false);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const [isVideoActive, setIsVideoActive] = useState<boolean>(false);

    const { middlewareApiUrl, posEditor, isLicenseProblem } =
        useSnapshot(store);

    const isBrokenCard = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.orderSlice.isBrokenCard,
        shallowEqual
    );
    const {
        project: {
            template: { content },
        },
        activeStandbyScreenItems,
        navigationIndex,
        kioskWays,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isTcposCancelOrderOngoing,
        orderTotalPrice,
        connectedLedPort,
        isLedActive,
    } = useSnapshot(kioskStore);
    const [machineIpAddress] = useLocalStorage<string>(
        "machineIpAddress",
        "0.0.0.0"
    );

    function orangeLedActivation(): void {
        if (isLedActive) {
            handleLedControl(connectedLedPort, "Orange");
        }
    }

    function redLedActivation(): void {
        if (isLedActive) {
            handleLedControl(connectedLedPort, "Red");
        }
    }

    async function handleNavigationClick() {
        //! here no need to check anything direct pass to next step
        if (
            process.env.REACT_APP_MODE === DEV_MODE ||
            process.env.REACT_APP_MODE === CATALOGUE_MODE
        ) {
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
            return;
        }
        if (isLicenseProblem && process.env.REACT_APP_MODE !== CATALOGUE_MODE) {
            const isLicenseValide = await checkforLicenseAvailability();
            if (!isLicenseValide) {
                return;
            }
        }

        if (isBrokenCard) {
            console.log(`Error card catlogue is brocken`);
            setIsTechProblemModalOpened(true, GETING_CARD_CATALOGUE_PROBLEM);
        }

        if (posEditor !== CESAR) {
            if (TCPOS === posEditor) {
                if (isTcposCancelOrderOngoing) {
                    return;
                }
                const isTcposAvailable = await checkTcposAvailability();

                if (isTcposAvailable) {
                    // change led color to orange
                    orangeLedActivation();
                } else {
                    // change led color to red
                    redLedActivation();
                }

                isTcposAvailable &&
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                return;
            }
            // change led color to orange
            orangeLedActivation();
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
            return;
        }
        if (isCallOnProgress) {
            return;
        }
        setIsCallOnProgress(true);

        const timestamp = new Date().getTime();

        await fetch(
            `${middlewareApiUrl}/checkKioskFence?timestamp=${timestamp}`,
            {
                cache: "no-store",
            }
        )
            .then((response) => response.json())
            .then(async ({ code, fence_problem }) => {
                if (code !== 2) {
                    console.log(
                        "The middleware return an error while checking kiosk fence!"
                    );
                    setIsTechProblemModalOpened(true, FENCE_PROBLEM);
                    return;
                }
                // this is a hidden bug coming from the middleware
                // @todo find a better solution
                if (fence_problem === "false") {
                    // change led color to orange
                    orangeLedActivation();

                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else {
                    setIsTechProblemModalOpened(true, FENCE_PROBLEM);
                    console.log(
                        "The middleware return an error while checking kiosk fence!"
                    );
                }
            })
            .catch((error) => {
                console.log(
                    `Error while checking kiosk fence network error, with message: ${JSON.stringify(
                        error
                    )}`
                );
                setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
            })
            .finally(() => {
                setIsCallOnProgress(false);
            });
    }
    const localActiveStandbyScreenItems = activeStandbyScreenItems.map(
        (item: StandbyScreenItemType) => {
            let contentFile = "./images/activeStateScreen.webm";

            if (item.content?.includes("http")) {
                contentFile = getFilenameFromUrl(item.content, "template");
            }

            return {
                ...item,
                content: contentFile,
            };
        }
    );

    React.useEffect(() => {
        if (
            process.env.REACT_APP_MODE === CATALOGUE_MODE ||
            process.env.REACT_APP_MODE === DEV_MODE
        ) {
            return;
        }
        if (
            machineIpAddress === "0.0.0.0" &&
            process.env.REACT_APP_MODE === ELECTRON_MODE
        ) {
            // eslint-disable-next-line
            (window as any)
                .electronAPI!.getAppIpAddressEvent()
                .then((response: string) => {
                    setMachineIpAddress(response);
                });
            return;
        }
        if (process.env.REACT_APP_MODE === WEBVIEW_MODE) {
            fetch(`${middlewareApiUrl}/get-ip`)
                .then((response) => response.json())
                .then((response) => {
                    setMachineIpAddress(response);
                    console.log(middlewareApiUrl);
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    console.log(`Error while getting IP`, error);
                });
        }
    }, []);

    React.useEffect(() => {
        if (isVideoActive && videoRef.current) {
            videoRef.current.currentTime = 0;
        }
    }, [isVideoActive]);

    return (
        <div
            onClick={handleNavigationClick}
            style={{
                position:
                    process.env.REACT_APP_MODE === CATALOGUE_MODE
                        ? "unset"
                        : "fixed",
                zIndex: -1,
                height: "100%",
                width: "100%",
            }}
        >
            {localActiveStandbyScreenItems.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                    }}
                    noSwipingClass="swiper-slide"
                    className="standby-screen-swiper-wrapper__clz"
                    noSwiping={true}
                >
                    {localActiveStandbyScreenItems.map(
                        (item: StandbyScreenItemType) => {
                            const isvideo = item.type.includes("video");
                            return isvideo ? (
                                <SwiperSlide
                                    key={item.id}
                                    data-swiper-autoplay={item.timeout * 1000}
                                >
                                    {({ isActive }) => {
                                        setIsVideoActive(!isActive);
                                        return (
                                            <video
                                                ref={videoRef}
                                                autoPlay={true}
                                                muted={true}
                                                loop={true}
                                                className="h-100 w-100"
                                                style={{
                                                    objectFit: `${
                                                        process.env
                                                            .REACT_APP_MODE ===
                                                        CATALOGUE_MODE
                                                            ? "contain"
                                                            : "fill"
                                                    }`,
                                                }}
                                            >
                                                <source
                                                    src={item.content}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        );
                                    }}
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide>
                                    <img
                                        src={item.content}
                                        alt={item.id}
                                        className="h-100 w-100"
                                        style={{
                                            objectFit: `${
                                                process.env.REACT_APP_MODE ===
                                                CATALOGUE_MODE
                                                    ? "contain"
                                                    : "fill"
                                            }`,
                                        }}
                                    />
                                </SwiperSlide>
                            );
                        }
                    )}
                </Swiper>
            ) : (
                localActiveStandbyScreenItems.map(
                    (item: StandbyScreenItemType) => {
                        return item.type.includes("video") ? (
                            <video
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                key={item.id}
                                className="h-100 w-100"
                                style={{
                                    objectFit: `${
                                        process.env.REACT_APP_MODE ===
                                        CATALOGUE_MODE
                                            ? "contain"
                                            : "fill"
                                    }`,
                                }}
                            >
                                <source src={item.content} type="video/mp4" />
                            </video>
                        ) : (
                            <img
                                key={item.id}
                                src={item.content}
                                className="h-100 w-100"
                                style={{
                                    objectFit: `${
                                        process.env.REACT_APP_MODE ===
                                        CATALOGUE_MODE
                                            ? "contain"
                                            : "fill"
                                    }`,
                                }}
                            />
                        );
                    }
                )
            )}
        </div>
    );
}
