import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getFilenameFromUrl } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import { AllergenCard } from "./AllergenCard";
import "./index.css";

export function AllergenGroup({
    globalAllergens,
    handleActiveAllergenOnClickEvent,
    group,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    return (
        <div
            className="d-flex flex-column align-items-center "
            style={{
                marginTop: "15px",
            }}
        >
            <span className="title-group">{group}</span>

            {!isPrm ? (
                globalAllergens[group].length > 10 ? (
                    <hr className="separation-line" />
                ) : null
            ) : globalAllergens[group].length > 3 ? (
                <hr className="separation-line" />
            ) : null}
            <div
                className="group-allergens"
                style={{
                    height: isPrm ? "230px" : "800px",
                }}
            >
                <div
                    className="d-flex flex-column"
                    style={{
                        gap: "30px",
                        maxHeight: "1200px",
                    }}
                >
                    {globalAllergens[group].map((allergen) => {
                        const allergenIcon = getFilenameFromUrl(
                            allergen.icon,
                            "catalog"
                        );
                        return (
                            <AllergenCard
                                key={allergen.id}
                                allergen={allergen}
                                handleActiveAllergenOnClickEvent={
                                    handleActiveAllergenOnClickEvent
                                }
                                group={group}
                                allergenIcon={allergenIcon}
                                isProduct={false}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
AllergenGroup.propTypes = {
    globalAllergens: PropTypes.object,
    handleActiveAllergenOnClickEvent: PropTypes.func,
    group: PropTypes.string,
};
