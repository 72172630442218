import React from "react";

export default function AccountSyncro(): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36.742"
            height="36.744"
            viewBox="0 0 36.742 36.744"
        >
            <path
                id="account"
                d="M65.232,52.591A18.121,18.121,0,1,0,46.661,70.707v0h.093c.12,0,.237.005.357.005s.237,0,.357-.005h.093v0A18.144,18.144,0,0,0,65.232,52.591ZM46.754,69.279A16.636,16.636,0,0,1,35.46,64.532a11.8,11.8,0,0,1,23.306,0,16.628,16.628,0,0,1-11.3,4.748Zm.357-16.113A5.863,5.863,0,1,1,52.974,47.3,5.869,5.869,0,0,1,47.111,53.166Zm0-17.268A16.685,16.685,0,0,1,59.958,63.239a13.3,13.3,0,0,0-3.493-6.2,13.148,13.148,0,0,0-5.783-3.386,7.29,7.29,0,1,0-7.139,0,13.265,13.265,0,0,0-9.279,9.581A16.687,16.687,0,0,1,47.111,35.9Z"
                transform="translate(-28.74 -34.22)"
                fill="#fff"
                stroke="#fff"
                strokeWidth="0.5"
            />
        </svg>
    );
}
