import { setIsOrderLoading, setIsWorkflowLoading } from "@pages/Kiosk/store";

export const isLoadingWorkflow = () => {
    setIsWorkflowLoading(true);
    setTimeout(() => {
        setIsWorkflowLoading(false);
    }, 300);
};
export const isLoadingProduct = () => {
    setIsOrderLoading(true);
    setTimeout(() => {
        setIsOrderLoading(false);
    }, 100);
};
