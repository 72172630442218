import React from "react";

type SettingSyncroProps = {
    isDisabled: boolean;
};

export default function CatalogSyncro({
    isDisabled,
}: SettingSyncroProps): JSX.Element {
    return (
        <svg
            id="big-brochure"
            xmlns="http://www.w3.org/2000/svg"
            width="36.521"
            height="33.867"
            viewBox="0 0 36.521 33.867"
        >
            <path
                id="Tracé_9764"
                data-name="Tracé 9764"
                d="M71.216,3.094,88.264.029l.02,0c.04-.007.08-.012.121-.016l.04,0c.049,0,.1-.006.148-.007h0c.052,0,.1,0,.152.007l.036,0q.065.006.127.017l.016,0,.027,0h0l17.019,3.059c.521.094.88.395.88.737V33.1a.728.728,0,0,1-.482.611,1.8,1.8,0,0,1-1.062.126L88.6,30.833,71.878,33.838a1.877,1.877,0,0,1-.331.029,1.719,1.719,0,0,1-.73-.155.728.728,0,0,1-.482-.611V3.831C70.335,3.489,70.695,3.188,71.216,3.094ZM104.432,4.41,89.808,1.781V29.457l14.625,2.629ZM72.759,32.087l14.625-2.629V1.781L72.759,4.41V32.087Z"
                transform="translate(-70.335 0)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9765"
                data-name="Tracé 9765"
                d="M259.9,62.877,252.544,64.2a1.88,1.88,0,0,1-.332.029,1.267,1.267,0,0,1-1.165-.557c-.183-.407.19-.831.834-.947l7.352-1.322a1.405,1.405,0,0,1,1.5.527C260.913,62.337,260.54,62.761,259.9,62.877Z"
                transform="translate(-246.152 -56.671)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9766"
                data-name="Tracé 9766"
                d="M259.9,112.876l-7.352,1.322a1.881,1.881,0,0,1-.332.029,1.266,1.266,0,0,1-1.165-.557c-.183-.407.19-.831.834-.947l7.352-1.322a1.4,1.4,0,0,1,1.5.527C260.913,112.336,260.54,112.76,259.9,112.876Z"
                transform="translate(-246.152 -102.839)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9767"
                data-name="Tracé 9767"
                d="M259.9,162.876l-7.352,1.322a1.88,1.88,0,0,1-.332.029,1.266,1.266,0,0,1-1.165-.557c-.183-.407.19-.831.834-.947l7.352-1.322a1.4,1.4,0,0,1,1.5.527C260.913,162.337,260.54,162.76,259.9,162.876Z"
                transform="translate(-246.152 -149.008)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9768"
                data-name="Tracé 9768"
                d="M259.9,222.992l-7.352,1.322a1.876,1.876,0,0,1-.332.029,1.718,1.718,0,0,1-.73-.155.728.728,0,0,1-.482-.611V213.463c0-.342.359-.643.881-.737l7.352-1.322a1.8,1.8,0,0,1,1.062.126.728.728,0,0,1,.482.611v10.114C260.778,222.6,260.419,222.9,259.9,222.992Zm-1.544-9.837-4.928.886v8.521l4.928-.886Z"
                transform="translate(-246.154 -195.179)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9769"
                data-name="Tracé 9769"
                d="M111.875,343.4l7.353,1.322c.644.116,1.017.54.834.947a1.266,1.266,0,0,1-1.165.557,1.873,1.873,0,0,1-.332-.029l-7.353-1.322c-.644-.116-1.017-.54-.834-.947A1.405,1.405,0,0,1,111.875,343.4Z"
                transform="translate(-88.435 -317.061)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9770"
                data-name="Tracé 9770"
                d="M118.9,64.227a1.879,1.879,0,0,1-.332-.029l-7.353-1.322c-.644-.116-1.017-.54-.834-.947a1.4,1.4,0,0,1,1.5-.527l7.353,1.322c.644.116,1.017.54.834.947A1.266,1.266,0,0,1,118.9,64.227Z"
                transform="translate(-88.434 -56.669)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
            <path
                id="Tracé_9771"
                data-name="Tracé 9771"
                d="M111.875,111.4l7.353,1.322c.644.116,1.017.54.834.947a1.266,1.266,0,0,1-1.165.557,1.88,1.88,0,0,1-.332-.029l-7.353-1.322c-.644-.116-1.017-.54-.834-.947A1.405,1.405,0,0,1,111.875,111.4Z"
                transform="translate(-88.435 -102.838)"
                fill={isDisabled ? "#fff" : "#7B7B7B"}
            />
        </svg>
    );
}
