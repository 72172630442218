import React from "react";
import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";

import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import store from "@components/OrderTaking/Store";

import { Kiosk } from "@pages/Kiosk";

// import { Steps } from "@pages/Kiosk/Steps";

const Layout = React.lazy(() => import("@components/Layout"));

export const routes = [
    {
        element: (
            <React.Suspense fallback={<SpinnerWrapper />}>
                <Layout />
            </React.Suspense>
        ),
        children: [
            {
                path: "/",
                element: (
                    <Provider store={store}>
                        <Kiosk />
                    </Provider>
                ),
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
];
