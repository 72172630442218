import { t } from "i18next";
import PropTypes from "prop-types";

import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { shallowEqual, useSelector } from "react-redux";
import "react-simple-keyboard/build/css/index.css";

import KioskKeyboard from "../../../../components/common/KioskKeyboard";
import "./index.css";

export default function KeyboardWrapper({
    productToComment,
    setSelectedValue,
    handleAddCommentOnClickEvent,
    input,
    setInput,

    handleOnSelectEvent,
    CommentList,

    isPrm,
}) {
    const { isFreeCommentProductActive, isCommentProductRecoveryActive } =
        useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const [layoutName, setLayoutName] = useState("default");

    const [isShiftClicked, setIsShiftClicked] = React.useState(false);

    const [isCrossIconClicked, setICrossIconClicked] = React.useState(false);
    const keyboard = useRef();

    function handleShift() {
        setLayoutName(layoutName === "default" ? "shift" : "default");
        setIsShiftClicked(!isShiftClicked);
    }
    const onChange = (input) => {
        setSelectedValue("");

        if (isShiftClicked === true) {
            handleShift();
        }

        setInput(input.default);
    };

    const onChangeInput = (event) => {
        const input = event.target.value;

        setInput(input);
    };

    useEffect(() => {
        keyboard?.current?.setInput(input);
    }, [input]);
    return (
        <>
            <div
                className="d-flex flex-column my-2"
                style={{ gap: isPrm ? "" : "10px", width: "90%" }}
            >
                {isCommentProductRecoveryActive && (
                    <select
                        className="form-select form-select-lg"
                        aria-label=".form-select-lg example"
                        style={{
                            height: isPrm ? "5vw" : "7vw",

                            font: isPrm
                                ? "normal normal 300 24px/46px Segoe UI"
                                : "300 34px / 46px Segoe UI",
                        }}
                        onChange={handleOnSelectEvent}
                    >
                        <option value="">{t("Select a comment")}</option>
                        {CommentList.map((item, index) => {
                            return (
                                <option
                                    key={index}
                                    value={item.content}
                                    selected={
                                        productToComment.comment ===
                                        item.content
                                            ? "selected"
                                            : ""
                                    }
                                >
                                    {item.content}
                                </option>
                            );
                        })}
                    </select>
                )}
                {isFreeCommentProductActive && (
                    <div className="d-flex justify-content-center align-items-center my-2">
                        <input
                            value={input}
                            type="text"
                            placeholder={
                                productToComment?.comment !== "" &&
                                !isCrossIconClicked
                                    ? productToComment?.comment
                                    : t("Comment...")
                            }
                            style={{
                                font: isPrm
                                    ? "normal normal 300 24px/46px Segoe UI"
                                    : "normal normal 300 34px/46px Segoe UI",
                                border: "none",
                                borderBottom: "1px solid rgb(221, 214, 214)",
                                height: "60px",
                                width: "100%",
                                paddingLeft: "1rem",
                            }}
                            onChange={onChangeInput}
                        />

                        {input !== "" && (
                            <FaTimes
                                style={{
                                    position: "relative",
                                    right: "40px",
                                    zIndex: "100",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setInput("");
                                    setICrossIconClicked(!isCrossIconClicked);
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            {isFreeCommentProductActive && (
                <KioskKeyboard
                    enterClick={() => {
                        handleAddCommentOnClickEvent();
                    }}
                    keyboard={keyboard}
                    onChangeAll={onChange}
                    theme={`hg-theme-default ${
                        !isPrm ? "keyboard__clz" : "prm-keyboard-comment__clz"
                    }`}
                    className={
                        "react-simple-keyboard simple-keyboard hg-theme-default hg-layout-default"
                    }
                    style={{ maxWidth: "90% !important" }}
                    preventMouseDownDefault={true}
                    buttonTheme={[
                        {
                            class: "enter-key__clz",
                            buttons: "{enter}",
                        },
                        {
                            class: "space-key__clz",
                            buttons: "{space}",
                        },
                    ]}
                />
            )}
        </>
    );
}
KeyboardWrapper.propTypes = {
    productToComment: PropTypes.object,
    setSelectedValue: PropTypes.func,
    handleAddCommentOnClickEvent: PropTypes.func,
    input: PropTypes.string,
    setInput: PropTypes.func,
    handleOnSelectEvent: PropTypes.func,
    CommentList: PropTypes.array,
    isPrm: PropTypes.bool,
};
