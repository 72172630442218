import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getYesActionButtonImageSrc } from "@helpers/general";

import {
    setIsSendedEmailWithSuccessModalOpened,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

export function EmailSendedWithSuccessModalWrapper(): JSX.Element {
    const {
        isSendedEmailWithSuccessModalOpened,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
    } = useSnapshot(store);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsSendedEmailWithSuccessModalOpened(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    });
    return (
        <Modal
            ariaHideApp={false}
            isOpen={isSendedEmailWithSuccessModalOpened}
            onRequestClose={() => {
                setIsSendedEmailWithSuccessModalOpened(
                    !isSendedEmailWithSuccessModalOpened
                );
            }}
            shouldCloseOnOverlayClick={true}
            className="ReactModal__Content__clz"
            closeTimeoutMS={500}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "380px",
                    width: "580px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex flex-column align-items-center justify-content-center mt-5"
                style={{
                    gap: "50px",
                }}
            >
                <div
                    style={{
                        height: "100px",
                        width: "100px",
                    }}
                >
                    <img
                        src={getYesActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        className="w-100 h-100"
                        style={{ backgroundSize: "cover" }}
                    />
                </div>

                <span
                    style={{
                        marginLeft: "130px",
                        marginRight: "130px",
                        font: "normal normal 600 30px/60px Segoe UI",
                    }}
                >
                    {t("Your receipt is sent by email")}
                </span>
            </div>
        </Modal>
    );
}
