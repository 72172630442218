import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function DeleteButton(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 224 65">
            <g
                id="Groupe_50"
                data-name="Groupe 50"
                transform="translate(-5719 4106)"
            >
                <g
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    transform="translate(5719 -4106)"
                    fill="red"
                    stroke="red"
                    strokeWidth="2"
                >
                    <rect width="224" height="65" rx="9" stroke="red" />
                    <rect
                        x="1"
                        y="1"
                        width="222"
                        height="63"
                        rx="8"
                        fill="white"
                    />
                </g>
                <text
                    id="Supprimer"
                    transform="translate(5831 -4062)"
                    fill="red"
                    fontSize="30"
                    fontFamily="HelveticaNeue-Medium, Helvetica Neue"
                    fontWeight="500"
                >
                    <tspan x="-72.225" y="0">
                        {t("Remove", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
