import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import useDebounce from "@hooks/useDebounce";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import {
    kioskStore,
    setIsConfirmationModalOpened,
    setNumericKeyboardInputValue,
    setOrderItemsLength,
    setQrCodeValue,
    setUserAuthInfo,
} from "@pages/Kiosk/store";

import "./index.css";

export function PrmNumericKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
        selectedAuthMode,
        numericKeyboardInputValue,
        isConfirmationModalOpened,
        customerLanguage,
        qrCodeValue,
    } = useSnapshot(kioskStore);
    const debouncedQrcode = useDebounce(qrCodeValue, 50);
    const dispatch = useDispatch();
    const [loyaltyCode, setLoyaltyCode] = React.useState<string>("");
    const { orderItems, totalPrice } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice.order
    );
    const [enterKeyDisabled, setEnterKeyDisabled] =
        React.useState<boolean>(false);

    const isInformationModeBridgeNumberOrPagerNumber =
        kioskWays[navigationIndex].name === "informationModesConsommation" &&
        (selectedInformationMode === "pagerNumber" ||
            selectedInformationMode === "bridgeNumber");

    const disabledButtons =
        numericKeyboardInputValue.length >= 20 ||
        (isInformationModeBridgeNumberOrPagerNumber &&
            numericKeyboardInputValue.length >= 4);

    const enterButtonDisabledAttribute = enterKeyDisabled
        ? [
              {
                  attribute: "disabled",
                  value: `${enterKeyDisabled}`,
                  buttons: "{enter}",
              },
          ]
        : [];

    function handleKeyboardOnChangeEvent(input: string) {
        const slicedInputValue = input.slice(0, 20);
        setLoyaltyCode(slicedInputValue);
        setNumericKeyboardInputValue(slicedInputValue);
    }

    function handleOnClickEnterButtonEvent() {
        if (selectedAuthMode !== "") {
            const authModeInfo: AuthModeInfoType =
                selectedAuthMode === "phoneNumber"
                    ? {
                          phoneNumber: numericKeyboardInputValue,
                      }
                    : {
                          loyaltyCardNumber:
                              qrCodeValue === ""
                                  ? numericKeyboardInputValue
                                  : qrCodeValue,
                      };

            setOrderItemsLength(orderItems.length);
            setUserAuthInfo(authModeInfo);
            handleUserAuthentication(
                authModeInfo,
                kioskWays,
                navigationIndex,
                isAuthenticationAccepted,
                selectedSaleModeValue,
                selectedInformationMode,
                isOrderPaidWithLoyaltyAccountBalance,
                selectedRegulationModeValue,
                authModalStepIndex,
                isAuthenticationErrorModalOpened,
                content,
                Number(totalPrice),
                dispatch
            );
        } else {
            setIsConfirmationModalOpened(!isConfirmationModalOpened);
        }
    }

    function handleKeyboardOnKeyPressEvent(button: string) {
        if (button === "{enter}") {
            handleOnClickEnterButtonEvent();
        }
    }

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setQrCodeValue(e.target.value);
    }

    React.useEffect(() => {
        setEnterKeyDisabled(numericKeyboardInputValue.length === 0);
        if (selectedAuthMode !== "phoneNumber" && debouncedQrcode !== "") {
            handleOnClickEnterButtonEvent();
        }
    }, [numericKeyboardInputValue, debouncedQrcode]);

    return (
        <FlexboxGrid
            className="prm-numeric-keyboard-wrapper__clz flex-column w-100"
            gap="15px"
            alignItemsCentered={true}
            justifyContentCentered={true}
        >
            <input
                className="text-center"
                type="text"
                value={qrCodeValue === "" ? loyaltyCode : qrCodeValue}
                placeholder={t("Your number", { lng: customerLanguage.name })}
                onChange={handleOnChangeEvent}
                autoFocus
                style={{
                    width: "300px",
                    fontSize: "25px",
                    border: "unset",
                    borderBottom: "1px solid #00000029",
                    outline: "none",
                }}
            />
            <Keyboard
                useButtonTag={true}
                theme="prm-numeric-keyboard__clz hg-theme-default hg-layout-numeric numeric-theme"
                display={{ "{bksp}": "&#10006", "{enter}": "&#10003" }}
                style={{ color: "#0a0909" }}
                layoutName="default"
                onChange={handleKeyboardOnChangeEvent}
                disableCaretPositioning={true}
                disableRowButtonContainers={true}
                onKeyPress={handleKeyboardOnKeyPressEvent}
                layout={{
                    default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
                }}
                buttonAttributes={
                    disabledButtons
                        ? [
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "1 2 3",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "4 5 6",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "7 8 9",
                              },
                              {
                                  attribute: "disabled",
                                  value: "false",
                                  buttons: "0",
                              },
                          ]
                        : enterButtonDisabledAttribute
                }
            />
        </FlexboxGrid>
    );
}
