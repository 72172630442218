import React from "react";

export function PadLockIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={35}
            height={35}
            {...props}
        >
            <defs>
                <filter
                    id="a"
                    x={0}
                    y={0}
                    width={35}
                    height={35}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur stdDeviation={2} result="blur" />
                    <feFlood floodColor="red" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Groupe 30001">
                <g data-name="Groupe 26911">
                    <g transform="translate(.004 -.001)" filter="url(#a)">
                        <circle
                            data-name="Ellipse 1221"
                            cx={11.5}
                            cy={11.5}
                            r={11.5}
                            transform="translate(6 6)"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Groupe 28816">
                        <path
                            data-name="Trac\xE9 20536"
                            d="M17.268 10.001a4.266 4.266 0 0 0-4.263 4.261v2.434a.114.114 0 0 1-.061.083c-.23.077-.418.143-.589.209a.589.589 0 0 0-.358.527v5.592a.594.594 0 0 0 .356.527 13.337 13.337 0 0 0 9.826 0 .593.593 0 0 0 .356-.527v-5.592a.59.59 0 0 0-.358-.527 12.175 12.175 0 0 0-.589-.209.115.115 0 0 1-.061-.083v-2.434a4.266 4.266 0 0 0-4.259-4.261Zm-1.163 9.217a1.16 1.16 0 1 1 2.321 0 1.143 1.143 0 0 1-.58.983v1.919a.58.58 0 1 1-1.16 0v-1.919a1.143 1.143 0 0 1-.581-.983Zm3.956-4.956v2.1a13.3 13.3 0 0 0-5.592 0v-2.1a2.8 2.8 0 0 1 5.592 0Z"
                            fill="#f30000"
                            opacity={0.798}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
