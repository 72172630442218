import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";

import {
    // GlobalWorkflowSlice,
    middlwareOrderSlice,
    orderSlice,
    orderWorkflowItemSlice,
    ProductSlice,
    settingSlice,
    workFlowDataSlice,
} from "./feature";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        orderSlice,
        settingSlice,
        workFlowDataSlice,
        ProductSlice,
        orderWorkflowItemSlice,
        middlwareOrderSlice,
        // GlobalWorkflowSlice,
    });

export default createRootReducer;
