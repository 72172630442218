import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { AddIconWrapper } from "@components/OrderTaking/Components/Icons/AddIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";

export function AddRemoveActionsButton({
    product,
    opacity,
    handleRemoveProductFromOrder,
    quantity,
}) {
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const isAddIconShown =
        product.outOfStock === false && product.isSuspended === false;

    const isRemoveIconShown =
        quantity > 0 &&
        ((product.outOfStock === false && product.isSuspended === false) ||
            (product.outOfStock && orderWorkflowItemSlice.isModification));

    return (
        <div className="action-buttons">
            {isAddIconShown ? (
                <div
                    style={{
                        marginTop: -7,
                    }}
                >
                    <AddIconWrapper
                        opacity={opacity}
                        width={product.showProductIcons ? 54 : 68}
                        height={product.showProductIcons ? 54 : 68}
                    />
                </div>
            ) : null}
            {isRemoveIconShown ? (
                <div
                    style={{
                        marginTop:
                            product.outOfStock &&
                            orderWorkflowItemSlice.isModification
                                ? -8
                                : -20,
                        position: "relative",
                        zIndex: "999",
                    }}
                >
                    <RemoveIcon
                        width={product.showProductIcons ? 54 : 68}
                        height={product.showProductIcons ? 54 : 68}
                        onClick={handleRemoveProductFromOrder}
                    />
                </div>
            ) : null}
        </div>
    );
}

AddRemoveActionsButton.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    handleRemoveProductFromOrder: PropTypes.func,
};
