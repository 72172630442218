import * as React from "react";

export function FireIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="calories"
            width="80%"
            height="80%"
            viewBox="0 0 9.175 11.035"
        >
            <path
                id="Tracé_22409"
                data-name="Tracé 22409"
                d="M8.731,2.6a.274.274,0,0,0-.271-.137.3.3,0,0,0-.232.209,3.447,3.447,0,0,1-.394.838.088.088,0,0,1-.149.005A5.97,5.97,0,0,1,6.62.3a.3.3,0,0,0-.3-.3A3.24,3.24,0,0,0,4,1.419a6.553,6.553,0,0,0-1.159,3.15.1.1,0,0,1-.125.089,1.938,1.938,0,0,1-1.1-1.36.3.3,0,0,0-.271-.242.292.292,0,0,0-.279.23A8.951,8.951,0,0,0,.776,6.5a5.773,5.773,0,0,0,.853,2.56A4.35,4.35,0,0,0,5.3,11.035,4.607,4.607,0,0,0,9.452,8.446C10.254,6.759,10,4.682,8.731,2.6Zm-4.947,5.9a.263.263,0,0,1-.4,0l-.348-.4v.165a.306.306,0,0,1-.286.323.306.306,0,0,1-.286-.323V6.044a.306.306,0,0,1,.286-.323.306.306,0,0,1,.286.323v.741l.3-.275a.263.263,0,0,1,.4.046.353.353,0,0,1-.04.455l-.443.41.538.617a.354.354,0,0,1,0,.457Zm1.723-.246a.89.89,0,0,1-.083.1.766.766,0,0,1-.555.231A1.021,1.021,0,0,1,3.919,7.5,1.021,1.021,0,0,1,4.87,6.424a.871.871,0,0,1,.532.184.351.351,0,0,1,.077.449.266.266,0,0,1-.4.087.347.347,0,0,0-.212-.073.433.433,0,0,0,0,.86.234.234,0,0,0,.175-.068.274.274,0,0,0,.025-.029.263.263,0,0,1,.4-.04A.353.353,0,0,1,5.508,8.249Zm1.915.007a.306.306,0,0,1-.286.323.271.271,0,0,1-.211-.106.793.793,0,0,1-.384.1.946.946,0,0,1-.881-1,.946.946,0,0,1,.881-1,.793.793,0,0,1,.384.1.271.271,0,0,1,.211-.106.306.306,0,0,1,.286.323v1.36Zm.76,0a.288.288,0,1,1-.571,0V6.013a.288.288,0,1,1,.571,0Z"
                transform="translate(-0.735 0)"
                fill={`var(--global-color)`}
            />
            <path
                id="Tracé_22410"
                data-name="Tracé 22410"
                d="M15.14,16.369a.354.354,0,1,0,.31.351A.333.333,0,0,0,15.14,16.369Z"
                transform="translate(-9.334 -9.144)"
                fill={`var(--global-color)`}
            />
        </svg>
    );
}
