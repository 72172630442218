import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { store } from "@store";

import {
    convertSelectedSalesModeValue,
    getSkippedStepNumber,
    handleUserAuthentication,
} from "@helpers/general";

import useDebounce from "@hooks/useDebounce";

import { addClickANdCollectOrder } from "@components/OrderTaking/Store/feature";

import {
    kioskStore,
    setClickAndCollectOrderId,
    setClickAndCollectOrderSalesMode,
    setIsClickAndCollect,
    setIsClickAndCollectOrderPaid,
    setLeftToPay,
    setNavigationIndex,
    setOrderTotalPrice,
    setUserAuthInfo,
} from "@pages/Kiosk/store";

import { OrderProcessedModalWrapper } from "./OrderProcessedModalWrapper";
import { PrmMode } from "./PrmMode";
import { QrCodeCorrectOrderNotProcessedModalWrapper } from "./QrCodeCorrectOrderNotProcessedModalWrapper";
import { QrCodeIncorrectModalWrapper } from "./QrCodeIncorrectModalWrapper";
import { ReScanQrCodeModal } from "./ReScanQrCodeModal";
import { TechnicalProblemModal } from "./TechnicalProblemModal";
import { UsualMode } from "./UsualMode";

export function ClickAndCollectStep(): JSX.Element {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isScanningQrCode, setIsScanningQrCode] = React.useState(false);
    const [qrCode, setQrCode] = React.useState("");
    const debouncedQrcode = useDebounce(qrCode, 50);

    const {
        kioskWays,
        navigationIndex,
        selectedRegulationModeValue,
        isAuthenticationAccepted,
        isUserAuthenticated,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedSaleModeValue,
        selectedInformationMode,
        isAuthenticationErrorModalOpened,
        authModalStepIndex,
        customerLanguage,
        isPrm,
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);

    const dispatch = useDispatch();
    const controllerRef = React.useRef<AbortController>();
    const [qrCodeScanTryingNumber, setQrCodeScanTryingNumber] =
        React.useState(1);
    const [isQrCodeShown, setIsQrCodeShown] = React.useState(false);
    const { middlewareApiUrl } = useSnapshot(store);
    const timestamp = new Date().getTime();

    const shopDetails = JSON.parse(
        localStorage.getItem("shopDetail") as string
    );

    const [
        isClickAndCollectSalesModeDeactivated,
        setIsClickAndCollectSalesModeDeactivated,
    ] = React.useState(false);

    const [
        isOrderProcessedModalWrapperOpened,
        setIsOrderProcessedModalWrapperOpened,
    ] = React.useState(false);
    const [isInvalidQrCodeModalOpened, setIsInvalidQrCodeModalOpened] =
        React.useState(false);
    const [
        isQrCodeCorrectOrderNotProcessedModalOpened,
        setIsQrCodeCorrectOrderNotProcessedModalOpened,
    ] = React.useState(false);

    const [isReScanQrCodeModalOpened, setIsReScanQrCodeModalOpened] =
        React.useState(false);

    const [
        isClickAndCollectTechnicalProblemModalOpened,
        setIsClickAndCollectTechnicalProblemModalOpened,
    ] = React.useState(false);

    const activeSalesMode = (
        content.salesMethods as ProjectContentItemType
    ).items
        .filter((item) => item.active === true)
        .map((item) => item.name);

    function handleOnChangeEvent(e: React.ChangeEvent<HTMLInputElement>) {
        setIsScanningQrCode(true);
        setQrCode(e.target.value);
    }

    async function getClickCollectOrderBasket(
        idCommand: string
    ): Promise<void> {
        fetch(
            `${middlewareApiUrl}/kioskBasket?idCommand=${idCommand}&customerLanguage=${customerLanguage.language}&timestamp=${timestamp}`
        )
            .then((response) => response.json())
            .then(async (result) => {
                //console.log({ result });
                if (result.error === true) {
                    if (qrCodeScanTryingNumber === 3) {
                        setIsClickAndCollectTechnicalProblemModalOpened(true);
                    } else {
                        setIsReScanQrCodeModalOpened(true);
                    }
                    return;
                }

                const clickAndCollectOrder = result.data;
                dispatch(
                    addClickANdCollectOrder({
                        ...clickAndCollectOrder.order,
                    })
                );
                setIsClickAndCollectOrderPaid(
                    Number(clickAndCollectOrder.totalttc) === 0
                );

                setIsClickAndCollect(true);
                if (
                    !activeSalesMode.includes(
                        convertSelectedSalesModeValue(
                            clickAndCollectOrder.saleMode
                        )
                    )
                ) {
                    setIsClickAndCollectSalesModeDeactivated(true);
                } else {
                    setClickAndCollectOrderSalesMode(
                        convertSelectedSalesModeValue(
                            clickAndCollectOrder.saleMode
                        )
                    );
                    setOrderTotalPrice(Number(clickAndCollectOrder.totalttc));
                    setLeftToPay(Number(clickAndCollectOrder.totalttc));

                    const userPhoneNumber =
                        clickAndCollectOrder.client.phoneNumber;
                    if (userPhoneNumber !== "") {
                        const authModeInfo = {
                            phoneNumber: userPhoneNumber,
                        };

                        setUserAuthInfo(authModeInfo);

                        handleUserAuthentication(
                            authModeInfo,
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            authModalStepIndex,
                            isAuthenticationErrorModalOpened,
                            content,
                            Number(clickAndCollectOrder.totalttc),
                            dispatch,
                            clickAndCollectOrder.client
                        );
                    } else {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(clickAndCollectOrder.totalttc)
                                    )
                                )
                        );
                    }
                }
            })
            .catch((error) => {
                console.error(
                    `Error while getting click and collect order basket, with the message:${JSON.stringify(
                        error
                    )}`
                );
                if (qrCodeScanTryingNumber === 3) {
                    setIsClickAndCollectTechnicalProblemModalOpened(true);
                } else {
                    setIsReScanQrCodeModalOpened(true);
                }
            });
    }

    async function getClickAndCollectOrderStatus(qrCode: string) {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        console.log("QR CODE for Click and Collect:", qrCode);
        const signal = controllerRef.current.signal;

        const localQrCode = qrCode.split("-");

        if (
            localQrCode[2] !==
                (
                    shopDetails.uid_franchise_database_info.slice(0, 5) +
                    shopDetails.uid_franchise_database_info.slice(-5)
                ).toUpperCase() ||
            Number(localQrCode[3]) !== shopDetails.id
        ) {
            //code qr inconnue
            setIsInvalidQrCodeModalOpened(true);
        } else if (localQrCode[0] === "CCF") {
            setIsOrderProcessedModalWrapperOpened(true);
        } else {
            setIsQrCodeCorrectOrderNotProcessedModalOpened(true);
            const finalOrderNumber = await fetch(
                `${middlewareApiUrl}/finalOrderNumber?id_command=${localQrCode[1]}&timestamp=${timestamp}`,
                { signal }
            ).then((response) => response.json());
            // eslint-disable-next-line
            if ((finalOrderNumber as any).data !== "") {
                setIsQrCodeCorrectOrderNotProcessedModalOpened(false);
                setIsOrderProcessedModalWrapperOpened(true);
            } else {
                setClickAndCollectOrderId(localQrCode[1]);
                getClickCollectOrderBasket(localQrCode[1]);
            }
        }
    }

    function handleClearClickAndCollectInput() {
        setIsScanningQrCode(false);
        if (inputRef.current !== null) {
            inputRef.current.value = "";
        }
    }

    React.useEffect(() => {
        if (debouncedQrcode !== "") {
            getClickAndCollectOrderStatus(debouncedQrcode);
            setQrCode("");
        }
    }, [debouncedQrcode]);

    return (
        <>
            {isInvalidQrCodeModalOpened === true ? (
                <QrCodeIncorrectModalWrapper
                    handleClearClickAndCollectInput={
                        handleClearClickAndCollectInput
                    }
                    setIsInvalidQrCodeModalOpened={
                        setIsInvalidQrCodeModalOpened
                    }
                    isInvalidQrCodeModalOpened={isInvalidQrCodeModalOpened}
                />
            ) : null}
            {isOrderProcessedModalWrapperOpened === true ? (
                <OrderProcessedModalWrapper
                    handleClearClickAndCollectInput={
                        handleClearClickAndCollectInput
                    }
                    setIsOrderProcessedModalWrapperOpened={
                        setIsOrderProcessedModalWrapperOpened
                    }
                    isOrderProcessedModalWrapperOpened={
                        isOrderProcessedModalWrapperOpened
                    }
                />
            ) : null}
            {isQrCodeCorrectOrderNotProcessedModalOpened === true ? (
                <QrCodeCorrectOrderNotProcessedModalWrapper
                    handleClearClickAndCollectInput={
                        handleClearClickAndCollectInput
                    }
                    setIsQrCodeCorrectOrderNotProcessedModalOpened={
                        setIsQrCodeCorrectOrderNotProcessedModalOpened
                    }
                    isQrCodeCorrectOrderNotProcessedModalOpened={
                        isQrCodeCorrectOrderNotProcessedModalOpened
                    }
                    setIsClickAndCollectSalesModeDeactivated={
                        setIsClickAndCollectSalesModeDeactivated
                    }
                    isClickAndCollectSalesModeDeactivated={
                        isClickAndCollectSalesModeDeactivated
                    }
                />
            ) : null}

            {isReScanQrCodeModalOpened === true ? (
                <ReScanQrCodeModal
                    handleClearClickAndCollectInput={
                        handleClearClickAndCollectInput
                    }
                    setIsReScanQrCodeModalOpened={setIsReScanQrCodeModalOpened}
                    isReScanQrCodeModalOpened={isReScanQrCodeModalOpened}
                    setIsQrCodeCorrectOrderNotProcessedModalOpened={
                        setIsQrCodeCorrectOrderNotProcessedModalOpened
                    }
                    setQrCodeScanTryingNumber={setQrCodeScanTryingNumber}
                    qrCodeScanTryingNumber={qrCodeScanTryingNumber}
                />
            ) : null}

            {isClickAndCollectTechnicalProblemModalOpened === true ? (
                <TechnicalProblemModal
                    handleClearClickAndCollectInput={
                        handleClearClickAndCollectInput
                    }
                    setQrCodeScanTryingNumber={setQrCodeScanTryingNumber}
                    setIsQrCodeCorrectOrderNotProcessedModalOpened={
                        setIsQrCodeCorrectOrderNotProcessedModalOpened
                    }
                    setIsClickAndCollectTechnicalProblemModalOpened={
                        setIsClickAndCollectTechnicalProblemModalOpened
                    }
                    isClickAndCollectTechnicalProblemModalOpened={
                        isClickAndCollectTechnicalProblemModalOpened
                    }
                />
            ) : null}

            {isPrm ? (
                <PrmMode
                    isQrCodeShown={isQrCodeShown}
                    setIsQrCodeShown={setIsQrCodeShown}
                    handleOnChangeEvent={handleOnChangeEvent}
                    inputRef={inputRef}
                    isScanningQrCode={isScanningQrCode}
                />
            ) : (
                <UsualMode
                    isQrCodeShown={isQrCodeShown}
                    setIsQrCodeShown={setIsQrCodeShown}
                    handleOnChangeEvent={handleOnChangeEvent}
                    inputRef={inputRef}
                    isScanningQrCode={isScanningQrCode}
                />
            )}
        </>
    );
}
