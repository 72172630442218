import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch } from "react-redux";

import { confirmSalesModeModification } from "@components/OrderTaking/Store/feature";

import { kioskStore, setOrderModificationMode } from "@pages/Kiosk/store";

export function useSalesModeModification(): void {
    const dispatchStore = useDispatch();
    const { saleMode, orderModificationMode } = useSnapshot(kioskStore);

    React.useEffect(() => {
        if (orderModificationMode === false) return;

        dispatchStore(confirmSalesModeModification({ ...saleMode }));

        setOrderModificationMode();
    }, [orderModificationMode]);
}
