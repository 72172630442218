import { CATALOGUE_MODE, VALIDATED_EXTENSION_FILE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    kioskStore,
    setIsRegulationModesModalOpened,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";
import { PrmMainContentWrapper } from "./PrmMainContentWrapper";

type PropsType = {
    isTpaValinaModalOpened: boolean;
    setIsTpaValinaModalOpened: (item: boolean) => void;
};

export function TpaValinaModalWrapper({
    isTpaValinaModalOpened,
    setIsTpaValinaModalOpened,
}: PropsType): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign },
            },
        },
        isTpaValinaIconActive,
        tpaValinaIconId,
        customerLanguage,
        isTpaValinaInformationMessageActive,
        tpaValinaInformationMessageItems,
        isTpaValinaActionButtonsActive,
        tpaValinaActionButtons,
    } = useSnapshot(kioskStore);

    const imageSrc = getImageContentById(tpaValinaIconId);

    const activeActionsButtons = tpaValinaActionButtons.filter((item) => {
        return item.active === true;
    });
    const activeActionsButtonsSorted = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isTpaValinaModalOpened}
            // eslint-disable-next-line

            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: isPrm ? "end" : "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: isPrm ? "1334px" : "1742px",
                    width: isPrm ? "860px" : "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            {isTpaValinaIconActive ? (
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                >
                    <img src={imageSrc} style={{ height: "330px" }} />
                </div>
            ) : null}

            {isTpaValinaInformationMessageActive ? (
                <div
                    className="d-flex justify-content-center align-items-center px-5 text-center"
                    style={{ height: "170px" }}
                >
                    <span
                        style={{ font: "normal normal 600 55px/80px Segoe UI" }}
                    >
                        {
                            tpaValinaInformationMessageItems[
                                customerLanguage.name
                            ].content
                        }
                    </span>
                </div>
            ) : null}

            <div
                className="d-flex justify-content-center w-100"
                style={{
                    height: isPrm ? "500px" : "1000px",
                    alignItems: isPrm ? "end" : "center",
                }}
            >
                {isPrm ? (
                    <PrmMainContentWrapper
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                ) : (
                    <MainContentWrapper
                        setIsTpaValinaModalOpened={setIsTpaValinaModalOpened}
                    />
                )}
            </div>

            {isTpaValinaActionButtonsActive
                ? activeActionsButtonsSorted.map(
                      (item: ActionsButtonsDesignItemType) => {
                          const backImage = getImageContentById(item.id);
                          return (
                              <div
                                  key={item.id}
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ height: "145px" }}
                              >
                                  {VALIDATED_EXTENSION_FILE.includes(
                                      backImage.substr(-4)
                                  ) ? (
                                      <img
                                          src={backImage}
                                          alt="action button"
                                          style={{
                                              height: isPrm ? "70px" : "87px",
                                              width: isPrm ? "281px" : "350px",
                                          }}
                                          onClick={() => {
                                              setIsRegulationModesModalOpened(
                                                  true
                                              );
                                          }}
                                      />
                                  ) : (
                                      <DynamicSvgComponent
                                          nameSvg={backImage as string}
                                          height={isPrm ? "70px" : "87px"}
                                          width={isPrm ? "281px" : "350px"}
                                          onClick={() => {
                                              setIsRegulationModesModalOpened(
                                                  true
                                              );
                                          }}
                                      />
                                  )}{" "}
                              </div>
                          );
                      }
                  )
                : null}
        </Modal>
    );
}
