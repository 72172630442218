import { useLocation } from "react-router-dom";

type QueryStringType = { [key: string]: string };

export function useQueryString(): QueryStringType {
    const location = useLocation();

    const search = location.search;

    if (!search) {
        return {};
    }

    const queryString = search.substring(1);
    const queryParams = queryString.split("&");
    const result: QueryStringType = {};

    queryParams.forEach((queryParam) => {
        const keyValue = queryParam.split("=");

        if (keyValue.length === 2) {
            result[keyValue[0]] = keyValue[1];
        }
    });

    return result;
}
