import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Previous = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_71"
                data-name="Groupe 71"
                transform="translate(-5359 4106)"
            >
                <g
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    transform="translate(5359 -4106)"
                    fill="#fff"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="348"
                        height="85"
                        rx="8"
                        fill="none"
                    />
                </g>
                <text
                    id="Précédent"
                    transform="translate(5534 -4048)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Previous", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};
Previous.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
