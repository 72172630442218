import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import LoyaltyStep from "./LoyaltyStep";

// eslint-disable-next-line
export function LoyaltyWrapper(): any {
    const {
        isGlobalSummaryOrderOpened,
        isCustomerBenefitOnClick,
        isCustomerBenefitOpen,
        isUserAuthenticated,
        kioskWays,
        navigationIndex,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                autoLaunchOnPayButton,
                                autoLaunchOnOrderTaking,
                            },
                        },
                    },
                },
            },
        },
    } = useSnapshot(kioskStore);
    const isOrderTakingStep =
        kioskWays[navigationIndex]?.name === "orderTaking";

    return isUserAuthenticated &&
        isOrderTakingStep &&
        isCustomerBenefitOpen &&
        ((autoLaunchOnPayButton && isGlobalSummaryOrderOpened) ||
            (autoLaunchOnOrderTaking && !isGlobalSummaryOrderOpened) ||
            isCustomerBenefitOnClick) ? (
        <LoyaltyStep />
    ) : null;
}
