import PropTypes from "prop-types";

import React from "react";

export function AllergenIcon({ width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width !== undefined ? width : "32"}
            height={height !== undefined ? height : "30"}
            viewBox="0 0 34 34"
        >
            <g
                id="Groupe_35054"
                data-name="Groupe 35054"
                transform="translate(8848 20112)"
            >
                <circle
                    id="Ellipse_1300"
                    data-name="Ellipse 1300"
                    cx="17"
                    cy="17"
                    r="17"
                    transform="translate(-8848 -20112)"
                    fill="red"
                />
                <path
                    id="Ellipse_1300_-_Contour"
                    data-name="Ellipse 1300 - Contour"
                    d="M17,1A16,16,0,0,0,5.686,28.314,16,16,0,0,0,28.314,5.686,15.9,15.9,0,0,0,17,1m0-1A17,17,0,1,1,0,17,17,17,0,0,1,17,0Z"
                    transform="translate(-8848 -20112)"
                    fill="none"
                    stroke="none"
                />
                <g id="g3047" transform="translate(-8836.563 -20106.498)">
                    <g
                        id="Groupe_35013"
                        data-name="Groupe 35013"
                        transform="translate(0 0)"
                    >
                        <g id="g3055" transform="translate(2.985)">
                            <path
                                id="path3057"
                                d="M1.829-97.53a.583.583,0,0,1,.359.123c.091.071,2.22,1.753,2.22,3.928s-2.129,3.858-2.22,3.928a.584.584,0,0,1-.717,0C1.379-89.621-.75-91.3-.75-93.478s2.129-3.858,2.22-3.928A.583.583,0,0,1,1.829-97.53Zm0,6.738a4.181,4.181,0,0,0,1.413-2.687,4.177,4.177,0,0,0-1.411-2.685A4.177,4.177,0,0,0,.418-93.478,4.22,4.22,0,0,0,1.826-90.791Z"
                                transform="translate(0.75 97.53)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3059" transform="translate(4.992 6.091)">
                            <path
                                id="path3061"
                                d="M-25.806-30.688h0a6.267,6.267,0,0,1,.708.036.584.584,0,0,1,.507.507c.014.114.33,2.809-1.208,4.347a5.249,5.249,0,0,1-3.64,1.244,6.262,6.262,0,0,1-.708-.036.584.584,0,0,1-.507-.507c-.014-.114-.33-2.809,1.208-4.347A5.249,5.249,0,0,1-25.806-30.688Zm.086,1.168h-.085a4.124,4.124,0,0,0-2.814.9,4.22,4.22,0,0,0-.9,2.9h.085a4.124,4.124,0,0,0,2.814-.9A4.22,4.22,0,0,0-25.72-29.52Z"
                                transform="translate(30.69 30.688)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3063" transform="translate(0 6.091)">
                            <path
                                id="path3065"
                                d="M-108.17-30.688a5.249,5.249,0,0,1,3.64,1.244c1.538,1.538,1.222,4.234,1.208,4.347a.584.584,0,0,1-.507.507,6.264,6.264,0,0,1-.708.036,5.249,5.249,0,0,1-3.64-1.244c-1.538-1.538-1.222-4.233-1.208-4.347a.584.584,0,0,1,.507-.507A6.263,6.263,0,0,1-108.17-30.688Zm3.715,4.966a4.178,4.178,0,0,0-.9-2.9,4.124,4.124,0,0,0-2.814-.9h-.082a4.178,4.178,0,0,0,.9,2.9,4.124,4.124,0,0,0,2.814.9Z"
                                transform="translate(109.421 30.688)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3067" transform="translate(4.992 11.907)">
                            <path
                                id="path3069"
                                d="M-25.806-30.689h0a6.266,6.266,0,0,1,.708.036.584.584,0,0,1,.507.507c.014.114.33,2.809-1.208,4.347a5.249,5.249,0,0,1-3.64,1.244,6.264,6.264,0,0,1-.708-.036.584.584,0,0,1-.507-.507c-.014-.114-.33-2.809,1.208-4.347A5.249,5.249,0,0,1-25.806-30.689Zm.086,1.168h-.085a4.124,4.124,0,0,0-2.814.9,4.178,4.178,0,0,0-.9,2.9h.082a4.124,4.124,0,0,0,2.814-.9A4.22,4.22,0,0,0-25.72-29.521Z"
                                transform="translate(30.69 30.689)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3071" transform="translate(0 11.907)">
                            <path
                                id="path3073"
                                d="M-108.17-30.689a5.249,5.249,0,0,1,3.64,1.244c1.538,1.538,1.222,4.233,1.208,4.347a.584.584,0,0,1-.507.507,6.264,6.264,0,0,1-.708.036,5.249,5.249,0,0,1-3.64-1.244c-1.538-1.538-1.222-4.233-1.208-4.347a.584.584,0,0,1,.507-.507A6.264,6.264,0,0,1-108.17-30.689Zm3.715,4.966a4.178,4.178,0,0,0-.9-2.9,4.124,4.124,0,0,0-2.814-.9h-.082a4.178,4.178,0,0,0,.9,2.9,4.124,4.124,0,0,0,2.814.9Z"
                                transform="translate(109.421 30.689)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3075" transform="translate(4.98 10.416)">
                            <path
                                id="path3077"
                                d="M-.166,10.776a.584.584,0,0,1-.584-.584V-.166A.584.584,0,0,1-.166-.75a.584.584,0,0,1,.584.584V10.192A.584.584,0,0,1-.166,10.776Z"
                                transform="translate(0.75 0.75)"
                                fill="#fff"
                            />
                        </g>
                        <g id="g3091" transform="translate(4.954 10.361)">
                            <path
                                id="path3093"
                                d="M-1.479-.895a.582.582,0,0,1-.413-.171l-.049-.049a.584.584,0,0,1,0-.826.584.584,0,0,1,.826,0l.049.049a.584.584,0,0,1,0,.826A.582.582,0,0,1-1.479-.895Z"
                                transform="translate(2.111 2.111)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
AllergenIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};
