import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import {
    kioskStore,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesConsommationModalOpened,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setLocalFinalMessageSelectedInformationMode,
    setLocalSelectedInformationMode,
    setSelectedInformationMode,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ProjectMainContentItemType;
    activeInformationModesItemsLength: number;
};

export function ImageContent({ item }: ImageContentPropsType): JSX.Element {
    const { customerLanguage, isUserAuthenticated, saleMode, kioskWays } =
        useSnapshot(kioskStore);

    const imageSrc = getImageContentById(item.id);

    function handleOnClickEvent() {
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "orderTaking")[0]
        );
        const informationModesConsommationStepIndex = kioskWays.indexOf(
            kioskWays.filter(
                (page) => page?.name === "informationModesConsommation"
            )[0]
        );
        if (orderTakingStepIndex > informationModesConsommationStepIndex) {
            if (saleMode.name === "Delivery" && isUserAuthenticated === false) {
                setLocalSelectedInformationMode(item.shortName as string);
                setLocalFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );
            } else {
                setSelectedInformationMode(item.shortName as string);
                setFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );
            }
            setIsInformationModesConsommationModalOpened(true);
        } else {
            if (saleMode.name === "Delivery" && isUserAuthenticated === false) {
                setLocalSelectedInformationMode(item.shortName as string);
                setLocalFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );
                setIsUserAccountModalOpened(true);
            } else {
                setSelectedInformationMode(item.shortName as string);
                setFinalMessageSelectedInformationMode(
                    item.prettyName as string
                );
            }
            setIsInformationModesModalOpened(false);
        }
    }

    return (
        <div
            className="justify-content-center align-items-center"
            onClick={handleOnClickEvent}
            style={{ display: "grid", gridTemplateRows: "6fr 1fr" }}
        >
            <div className="d-flex justify-content-center align-items-end h-100">
                <img src={imageSrc} alt={item.name} />
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <span style={{ fontSize: "35px", whiteSpace: "nowrap" }}>
                    {item.languages[customerLanguage.name].name}
                </span>
            </div>
        </div>
    );
}
