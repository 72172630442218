import { BASIC_COMPOSITION, ITEM_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { InfoIcon } from "@components/OrderTaking/Components/Icons/InfoIcon";

export function InformationProductIcon({
    product,
    isInformationProductIconActive,
    handleNutritionalInformationDialogOnCloseEvent,
    informationProductIconContent,
}) {
    const imageSrc = getImageContentById(informationProductIconContent);

    return isInformationProductIconActive &&
        (product.type === ITEM_TYPE || BASIC_COMPOSITION) ? (
        <div
            className="d-flex justify-content-center pl-1 pt-1"
            onClick={(e) => {
                e.stopPropagation();
                !product.outOfStock &&
                    handleNutritionalInformationDialogOnCloseEvent();
            }}
        >
            {imageSrc !== "" ? (
                <img
                    src={imageSrc}
                    style={{
                        width: "45%",
                    }}
                />
            ) : (
                <InfoIcon width={30} height={30} />
            )}
        </div>
    ) : null;
}

InformationProductIcon.propTypes = {
    product: PropTypes.object.isRequired,
    isInformationProductIconActive: PropTypes.bool,
    informationProductIconContent: PropTypes.string,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
};
