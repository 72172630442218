import React from "react";

export const useVideoReplay = (
    videoRef: React.RefObject<HTMLVideoElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies: any[]
): void => {
    const handleEnded = () => {
        const video = videoRef.current;
        if (video) {
            video.currentTime = 0;
            video.play();
        }
    };

    React.useEffect(() => {
        const video = videoRef.current;

        if (video) {
            video.addEventListener("ended", handleEnded);
        }

        // Clean up event listeners on unmount
        return () => {
            if (video) {
                video.removeEventListener("ended", handleEnded);
            }
        };
    }, dependencies);
};
