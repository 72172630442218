import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

export function InformationMessage(): JSX.Element {
    const { subStepInformationMessageItems, customerLanguage } =
        useSnapshot(store);

    return (
        <span
            className="two-lines-preview-text w-100"
            style={{
                fontSize: "45px",
                textAlign: "center",
                lineHeight: "3rem",
            }}
        >
            {subStepInformationMessageItems[customerLanguage.name]}
        </span>
    );
}
