import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getAlertActionButtonImageSrc } from "@helpers/general";
import { timerIsReached } from "@helpers/timer";

import { useCountdown } from "@hooks/useCountDown";

import { setIsCommentKeyboardActive } from "@components/OrderTaking/Store/feature";

import { kioskStore, setPaymentStepCounter } from "@pages/Kiosk/store";

type StandbyModalWrapperType = {
    isOpen: true;
    onRequestClose: () => void;
};

export function StandbyModalWrapper({
    isOpen,
    onRequestClose,
}: StandbyModalWrapperType): JSX.Element {
    const dispatchStore = useDispatch();
    const { isCommentKeyboardActive } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice,
        shallowEqual
    );

    const {
        initialStepTimeout,
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);
    const onCloseEvents = () => {
        timerIsReached();
        onRequestClose();
    };

    const counter = useCountdown(initialStepTimeout, onCloseEvents);

    isCommentKeyboardActive &&
        dispatchStore(setIsCommentKeyboardActive({ active: false }));

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                setPaymentStepCounter(0);
                onRequestClose();
            }}
            contentLabel="standby-modal"
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    color: "black",
                    height: "1230px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "111px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                onClick={() => {
                    setPaymentStepCounter(0);
                    onRequestClose();
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ height: "400px" }}
                    onClick={() => {
                        setPaymentStepCounter(0);
                        onRequestClose();
                    }}
                >
                    <img
                        src={getAlertActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                    />
                </div>
                <div
                    className="d-flex flex-column text-center"
                    style={{ height: "202px", fontSize: "55px", gap: "10px" }}
                >
                    <span>{t("Your order")}</span>
                    <span>{t("will be abandoned in")}</span>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{
                        height: "400px",
                        font: "normal 70px Segoe UI",
                    }}
                >
                    {counter} {t("seconds")}
                </div>
                <div
                    className="d-flex justify-content-center align-items-center text-center w-100"
                    style={{ height: "180px", fontSize: "35px" }}
                >
                    {t("Touch the screen to continue")}
                </div>
            </div>
        </Modal>
    );
}
