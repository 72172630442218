import {
    ADDING_PROLEM_TCPOS,
    CANCELLATION_PROBLEM_TCPOS,
    DELETED_PROBLEM_TCPOS,
    REFRESH_PROBLEM_TCPOS,
    REQUEST_TIMEOUT_TCPOS,
} from "@constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { snapshot } from "valtio";

import { setIsTechProblemModalOpened } from "@store";

import {
    ErrorToast,
    RequirementToast,
    successAddToast,
} from "@components/common/Toast";

import {
    kioskStore,
    setIsTcposOrderRefreshDone,
    setNavigationIndex,
    setTcposCancelOrderStatus,
} from "@pages/Kiosk/store";

export const addItemCommandTcPos = createAsyncThunk(
    "carte/addItemCommandTcPos",
    async (itemToAdd) => {
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl")
        );
        const timestamp = new Date().getTime();
        const { selectedSaleModeValue, selectedSaleModeId, customerLanguage } =
            snapshot(kioskStore);
        const controller = new AbortController();
        const timeoutId = setTimeout(
            () => controller.abort(),
            REQUEST_TIMEOUT_TCPOS
        );
        const signal = controller.signal;
        let TcPosSaleMode = "EAT_IN";

        if (selectedSaleModeValue === "To take") {
            TcPosSaleMode = "TAKE_OUT";
        }

        const url = `${
            middlewareApiUrl.split(":5")[0]
        }:5008/tcpos/v1/putTcposOrderLine?saleMode=${TcPosSaleMode}&saleModeUuid=${selectedSaleModeId}&isModification=${
            itemToAdd.isModification
        }&selectedLanguage=${customerLanguage.language}&timestamp=${timestamp}`;
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(itemToAdd),
            signal,
        })
            .then((response) => response.json())
            .then((result) => {
                const data = {
                    order: result.response,
                    lastAddedItem: itemToAdd,
                    outOfStock: result.outOfStock,
                };
                successAddToast(t("Product added"));

                if (result.error) {
                    console.log(
                        "Error true while adding TCPOS product with message: ",
                        result.errorMessage
                    );
                    ErrorToast(result.errorMessage, "TCPOS add item");
                    return;
                }

                return data;
            })

            .catch((error) => {
                setIsTechProblemModalOpened(true, ADDING_PROLEM_TCPOS);
                setNavigationIndex(0);
                console.log(
                    "TCPOS Client Error While adding item Order: ",
                    error
                );
            })
            .finally(() => {
                setIsTcposOrderRefreshDone(false);
                clearTimeout(timeoutId);
            });
    }
);

export const removeItemCommandTcPos = createAsyncThunk(
    "carte/removeItemCommandTcPos",
    async (orederItemToRemove) => {
        const { selectedSaleModeId, customerLanguage } = snapshot(kioskStore);
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl")
        );
        const partnerOrderLineId = orederItemToRemove.partnerOrderLineId;
        const timestamp = new Date().getTime();
        const controller = new AbortController();
        const timeoutId = setTimeout(
            () => controller.abort(),
            REQUEST_TIMEOUT_TCPOS
        );
        const signal = controller.signal;

        return fetch(
            `${
                middlewareApiUrl.split(":5")[0]
            }:5008/tcpos/v1/putTcposOrderLine?partnerOrderLineId=${partnerOrderLineId}&saleModeUuid=${selectedSaleModeId}&selectedLanguage=${
                customerLanguage.language
            }&timestamp=${timestamp}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(orederItemToRemove),
                signal,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.error) {
                    console.log(
                        "Error true while removing TCPOS product with message: ",
                        result.errorMessage
                    );
                    return;
                }
                RequirementToast(t("Deleted product"));

                return {
                    order: result.response,
                    outOfStock: result.outOfStock,
                };
            })

            .catch((error) => {
                setIsTechProblemModalOpened(true, DELETED_PROBLEM_TCPOS);
                setNavigationIndex(0);
                console.log(
                    "TCPOS Client Error While Removing item from Order: ",
                    error
                );
            })
            .finally(() => clearTimeout(timeoutId));
    }
);

export const refreshTcPosOrder = createAsyncThunk(
    "carte/refreshTcPosOrder",
    async (selectedSalesMode) => {
        setTcposCancelOrderStatus(true);
        const { customerLanguage } = snapshot(kioskStore);
        const controller = new AbortController();
        const timeoutId = setTimeout(
            () => controller.abort(),
            REQUEST_TIMEOUT_TCPOS
        );
        const signal = controller.signal;
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl")
        );
        const timestamp = new Date().getTime();
        let TcPosSaleMode = "EAT_IN";

        if (selectedSalesMode.name === "To take") {
            TcPosSaleMode = "TAKE_OUT";
        }

        return fetch(
            `${
                middlewareApiUrl.split(":5")[0]
            }:5008/tcpos/v1/bascketRefresh?saleMode=${TcPosSaleMode}&saleModeUuid=${
                selectedSalesMode.id
            }&selectedLanguage=${
                customerLanguage.language
            }&timestamp=${timestamp}`,
            {
                method: "GET",
                mode: "cors",
            },
            signal
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.error) {
                    console.log(
                        "TCPOS Client Error While refreshing Order with message: ",
                        result.errorMessage
                    );
                    ErrorToast(result.errorMessage, "refresh order");
                    return;
                }
                return result.response;
            })
            .catch((error) => {
                setIsTechProblemModalOpened(true, REFRESH_PROBLEM_TCPOS);
                setNavigationIndex(0);
                console.log(
                    "TCPOS Client Error While refreshing Order: ",
                    error
                );
            })
            .finally(() => {
                setTcposCancelOrderStatus(false);
                clearTimeout(timeoutId);
            });
    }
);

export const clearTCPOSOrder = createAsyncThunk(
    "carte/clearTCPOSOrder",
    async () => {
        setTcposCancelOrderStatus(true);
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 300000);
        const signal = controller.signal;
        const middlewareApiUrl = JSON.parse(
            localStorage.getItem("middlewareApiUrl")
        );
        const timestamp = new Date().getTime();
        return fetch(
            `${
                middlewareApiUrl.split(":5")[0]
            }:5008/tcpos/v1/cancelCurrentTransaction?timestamp=${timestamp}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                signal,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.error) {
                    setIsTechProblemModalOpened(
                        true,
                        CANCELLATION_PROBLEM_TCPOS
                    );
                    setNavigationIndex(0);
                    return;
                }
                setTcposCancelOrderStatus(false);
                return {
                    outOfStock: result.outOfStock,
                };
            })
            .catch((error) => {
                console.log(
                    "TCPOS Client Error While Canceling Order: ",
                    error
                );
                setIsTechProblemModalOpened(true, CANCELLATION_PROBLEM_TCPOS);
                setNavigationIndex(0);
            })
            .finally(() => {
                setTcposCancelOrderStatus(false);
                setIsTcposOrderRefreshDone(true);
                clearTimeout(timeoutId);
            });
    }
);
