import {
    BASIC_COMPOSITION_TYPE,
    CATALOGUE_MODE,
    COMPOSITIONS,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ClosecircleIcon from "@components/OrderTaking/Components/Icons/ClosecircleIcon";
import MessageIcon from "@components/OrderTaking/Components/Icons/MessageIcon";
import { groupWorkflowShopCard } from "@components/OrderTaking/Helpers";
import { setIsCommentKeyboardActive } from "@components/OrderTaking/Store/feature";
import { EditButton } from "@components/SvgIcons";

import "./index.css";
import { ShowDetailsCartProduct } from "./ShowDetailsCartProduct";

import "swiper/swiper.min.css";

export default function ShowDetailsDialog({
    open,
    onClose,
    product,
    path,
    setPath,
    setIsShowDetailsOpened,
    setIsLocalKeyBoardOpened,
}) {
    const [title, setTitle] = React.useState(
        product.name !== BASIC_COMPOSITION_TYPE
            ? product.displayTitle
            : t("BASIC COMPOSITION").toUpperCase()
    );

    const dispatchStore = useDispatch();
    const { isCommentProductActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    let workflowCards = {};

    if (path.length > 0) {
        let Datapath = [];

        path.forEach((elm) => {
            Datapath.push(elm.name);
        });

        Datapath.push(COMPOSITIONS);

        let objectTostep = Datapath.reduce((prev, key) => {
            return prev[key] || {};
        }, product.shopCart);

        workflowCards = objectTostep;
    } else {
        workflowCards = {};

        Object.keys(product.shopCart).forEach((key) => {
            Object.keys(product.shopCart[key]?.compositions).forEach((key2) => {
                if (key === BASIC_COMPOSITION_TYPE) {
                    /**  composition de base   in item  main  */
                    workflowCards = {
                        ...workflowCards,
                        [BASIC_COMPOSITION_TYPE]: product.shopCart[key],
                    };
                } else {
                    const item = {
                        ...product.shopCart[key]?.compositions[key2],
                    };

                    if (item.name) {
                        let newKey = key2;
                        if (workflowCards[key2] !== undefined) {
                            newKey = key2 + "|" + item.name;
                        }

                        /** step */
                        workflowCards = {
                            ...workflowCards,
                            [newKey]: item,
                        };
                    } else if (product.name === key) {
                        /**composition de base in item */
                        let Datapath = [product.name];
                        Datapath.push(COMPOSITIONS);
                        Datapath.push(BASIC_COMPOSITION_TYPE);

                        let objectTostep = Datapath.reduce((prev, key) => {
                            return prev[key] || {};
                        }, product.shopCart);

                        workflowCards = {
                            ...workflowCards,
                            [BASIC_COMPOSITION_TYPE]: objectTostep,
                        };
                    } else {
                        /**categories  */
                        Object.keys(item?.compositions).forEach((key3) => {
                            let newKey = key3;
                            const item2 = {
                                ...item.compositions[key3],
                            };
                            //! en case have same key

                            if (workflowCards[key3] !== undefined) {
                                newKey = key3 + "|" + item2.name;
                            }

                            workflowCards = {
                                ...workflowCards,
                                [newKey]: item2,
                            };
                        });
                    }
                }
            });
        });
    }

    const groupedItems = groupWorkflowShopCard(workflowCards);

    if (groupedItems.length === 0 || open === false) {
        onClose();
    }
    function handleAddCommentOnClickEvent() {
        setIsLocalKeyBoardOpened(true);
        dispatchStore(
            setIsCommentKeyboardActive({
                active: true,
                product: product,
            })
        );
        onClose();
    }
    return (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "20px!important",
                    alignItems: "start!important",
                    justifyContent: "start!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    width: "98% !important",
                    height: "min-content !important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderRadius: "50px !important",
                    backgroundColor: "#FFFFFF!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: "21% !important",
                    right: "unset!important",
                    left: "unset!important",
                    margin: "15px !important",
                    paddingLeft: "10px !important",
                    paddingRight: "10px !important",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%", height: "30%" }}
            >
                <span
                    style={{
                        font: "normal normal 600 35px/47px Segoe UI",
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {t("Do you want to modify your")} {title} ?
                </span>
                <div
                    className="d-flex justify-content-end align-items-end px-3"
                    onClick={onClose}
                >
                    <ClosecircleIcon />
                </div>
            </div>

            <div className="d-flex " style={{ width: "100%", height: "100%" }}>
                {path.length > 0 && (
                    <div className="d-flex mx-3" style={{ width: "10%" }}>
                        {groupedItems.length > 0 && (
                            <div
                                className="d-flex flex-column  justify-content-center align-items-center"
                                style={{
                                    width: "126px",
                                    height: "217px",
                                    background:
                                        " #FFFFFF 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 3px 6px #0000001A",
                                    border: "1px solid #7B7B7B",
                                    borderRadius: "10px",
                                }}
                                onClick={() => {
                                    let Data = [...path];
                                    Data.pop();
                                    Data.pop();
                                    Data.length <= 2
                                        ? setPath([])
                                        : setPath(Data);
                                    setTitle(
                                        Data.length > 2
                                            ? Data[Data.length - 1].displayTitle
                                            : product.displayTitle
                                    );
                                }}
                            >
                                <div className="arrow-left__clz"></div>
                                <span
                                    style={{
                                        font: " normal normal 600 20px/27px Segoe UI",
                                    }}
                                >
                                    {t("Back")}
                                </span>
                            </div>
                        )}
                    </div>
                )}

                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="swiper-container__clz"
                    style={{ width: "100%" }}
                >
                    <>
                        {groupedItems.map((item, index) => {
                            return (
                                <>
                                    <SwiperSlide
                                        className="slide-popup"
                                        key={groupedItems.length - index}
                                    >
                                        <ShowDetailsCartProduct
                                            item={item}
                                            setPath={setPath}
                                            setTitle={setTitle}
                                            product={product}
                                            onClose={onClose}
                                            setIsShowDetailsOpened={
                                                setIsShowDetailsOpened
                                            }
                                            isShowDetailsOpened={open}
                                        />
                                    </SwiperSlide>
                                </>
                            );
                        })}
                    </>
                </Swiper>
            </div>
            {isCommentProductActive === true && product.haveComment === true ? (
                <div
                    className="d-flex justify-content-between align-items-center p-2"
                    style={{
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid var(--global-color)",
                        bottom: "30px",
                        position: "relative",
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                    >
                        <div
                            className="d-flex justify-content-center align-items-center p-1"
                            style={{
                                width: "55px",
                                height: "55px",

                                borderRadius: " 10px",
                            }}
                        >
                            <MessageIcon
                                fill="#000000"
                                width="30"
                                height="30"
                            />
                        </div>

                        <div>{product.comment}</div>
                    </div>
                    <div
                        onClick={() => {
                            handleAddCommentOnClickEvent();
                        }}
                    >
                        <EditButton />
                    </div>
                </div>
            ) : null}
        </Dialog>
    );
}
ShowDetailsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isPrm: PropTypes.bool,
    product: PropTypes.object,
    path: PropTypes.array,
    setPath: PropTypes.func,
    setIsShowDetailsOpened: PropTypes.func,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
