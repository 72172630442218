import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const Payer = (
    props:
        | React.SVGProps<SVGSVGElement>
        | React.MouseEventHandler<HTMLDivElement>
): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 591 65" {...props}>
            <g
                id="Groupe_50"
                data-name="Groupe 50"
                transform="translate(-4262 4106)"
            >
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    // eslint-disable-next-line
                    width={(props as any).width}
                    // eslint-disable-next-line
                    height={(props as any).height}
                    rx="7"
                    transform="translate(4262 -4106)"
                    fill="#141414"
                />
                <text
                    id="Payer"
                    transform="translate(4348 -4059)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                >
                    <tspan x="-49.634" y="0">
                        {t("Pay", { lng: customerLanguage.name })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
Payer.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
