import { useSnapshot } from "valtio";

import React from "react";

import CCOrderSummary from "@components/OrderTaking/Components/Dialogs/OrderSummary/CCOrderSummary";
import { Screens } from "@components/OrderTaking/Screens";

import { kioskStore } from "@pages/Kiosk/store";

export function OrderTakingStep(): JSX.Element {
    const { isClickAndCollect } = useSnapshot(kioskStore);
    if (localStorage.getItem("virtalization") === null) {
        localStorage.setItem("virtalization", "2");
    }

    return isClickAndCollect ? <CCOrderSummary /> : <Screens />;
}
