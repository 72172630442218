import { t } from "i18next";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import React from "react";
import ReactSwitch from "react-switch";

import { kioskStore, setActiveStateScreenItems } from "@pages/Kiosk/store";

import { Table } from "./Table";

const Styles = styled.div`
    padding: 1rem;
    min-height: 550px;
    border: 1px solid #c6c6c6;
    table {
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            text-align: left;
                        }
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            padding: 0.5rem;
            height: 30px;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 15%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 35%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;

export function ActiveStateScreenTable(): JSX.Element {
    const { activeStateScreenItems } = useSnapshot(kioskStore);

    const activeScreens = activeStateScreenItems.filter(
        (item: StandbyScreenItemType) => item.active
    );

    // eslint-disable-next-line
    function OrderCell({ row, dragHandleProps, className }: any) {
        return (
            <div
                className="d-flex align-items-center position-relative"
                style={{ gap: "20px" }}
            >
                <span style={{ font: "normal normal 600 20px Segoe UI" }}>
                    {row.index + 1}
                </span>
            </div>
        );
    }

    // eslint-disable-next-line
    function ActionsCell({ row }: any) {
        return (
            <div className="d-flex align-items-center justify-content-end position-relative">
                <ReactSwitch
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={row.original.active}
                    onChange={() => {
                        const localData = [...activeStateScreenItems];
                        localData[row.id] = {
                            ...localData[row.id],
                            active: !row.original.active,
                        };

                        setActiveStateScreenItems(localData);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={55}
                    height={20}
                />
            </div>
        );
    }

    // eslint-disable-next-line
    function UploaderCell({ row }: any) {
        return (
            <span
                className="two-lines-preview-text"
                style={{
                    textAlign: "left",
                    font: "normal normal normal 15px/20px Segoe UI",
                    letterSpacing: "0px",
                    color: "#6A6A6A",
                }}
            >
                {
                    row.original.content.split("/")[
                        row.original.content.split("/").length - 1
                    ]
                }
            </span>
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("Order"),
                accessor: t("Order"),
                Cell: OrderCell,
            },
            {
                Header: t("Image or video"),
                accessor: "content",
                Cell: UploaderCell,
            },

            {
                Header: t("Actions"),
                accessor: t("Actions"),
                Cell: ActionsCell,
            },
        ],
        [activeScreens, setActiveStateScreenItems, activeStateScreenItems]
    );

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Segoe UI",
                        color: " #B2B2B2",
                    }}
                >
                    {t("Reading list")}
                </span>
            </div>

            <Styles>
                <Table columns={columns} />
            </Styles>
        </div>
    );
}
