import { IS_TOAST_ENABLE } from "@constants";
import confetti from "canvas-confetti";

import { toast, Zoom } from "react-toastify";

import {
    setIsCustomerBenefitOnClick,
    setIsCustomerBenefitOpen,
} from "@pages/Kiosk/store";

import "./Style.css";

export function ErrorToast(message: string, toastId: string): void {
    toast.error(message, {
        className: "responsive-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
        theme: "colored",
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        toastId,
    });
}

export function informationToast(message: string): void {
    toast.info(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        theme: "dark",
        closeOnClick: true,
        hideProgressBar: false,
    });
}

export function successToast(message: string): void {
    toast.success(message, {
        className: "responsive-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        theme: "dark",
        closeOnClick: true,
        hideProgressBar: false,
    });
}

export function successAddToast(message: string): void {
    if (process.env.REACT_APP_IS_TOAST_ACTIF === IS_TOAST_ENABLE) {
        toast.success(message, {
            className: "responsive-toast",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
            theme: "colored",
            closeOnClick: false,
            draggable: true,
            hideProgressBar: true,
        });
    }
}
export function LoyaltyToast(message: string): void {
    const count = 200;
    const defaults = {
        origin: { x: 0.8, y: 0.9 },
    };
    const customToastStyle = {
        backgroundImage: "linear-gradient(to bottom,#ff00ff,  #ff6a00 )",
        color: "black",
    };

    toast(message, {
        className: "responsive-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        theme: "colored",
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true,
        style: customToastStyle,
        transition: Zoom,
        onClick() {
            setIsCustomerBenefitOpen(true);
            setIsCustomerBenefitOnClick(true);
        },
    });

    // eslint-disable-next-line
    function fire(particleRatio: any, opts: any) {
        confetti({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio),
        });
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    });
}
export function RequirementToast(message: string): void {
    if (process.env.REACT_APP_IS_TOAST_ACTIF === IS_TOAST_ENABLE) {
        toast.warn(message, {
            className: "responsive-toast",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
            theme: "colored",
            closeOnClick: true,
            draggable: true,
            hideProgressBar: true,
        });
    }
}
export function ErrorLoyaltyToast(message: string, toastId: string): void {
    toast.error(message, {
        className: "responsive-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        theme: "colored",
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        toastId,
        onClick() {
            setIsCustomerBenefitOpen(true);
            setIsCustomerBenefitOnClick(true);
        },
    });
}
