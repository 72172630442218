import { OUT_OF_STOCK_ROLE, SUSPENDED_ROLE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

export function OutOfStock({
    isOutOfStock,
    // eslint-disable-next-line react/prop-types
    isSuspended,
    widthOfImage,
    heightOfImage,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    const { designItems } = useSelector((state) => state.settingSlice);
    const outOfStockImage = designItems.filter(
        (item) => item.role === OUT_OF_STOCK_ROLE
    )[0];

    const suspendedImage = designItems.filter(
        (item) => item.role === SUSPENDED_ROLE
    )[0];

    const outOfStockImageSrc = getImageContentById(outOfStockImage?.id);
    const suspendedImageSrc = getImageContentById(suspendedImage?.id);

    const imgSrc = isOutOfStock
        ? outOfStockImageSrc !== undefined
            ? outOfStockImageSrc
            : ""
        : isSuspended
        ? suspendedImageSrc !== undefined
            ? suspendedImageSrc
            : ""
        : "";
    const withStyle = !isPrm ? widthOfImage + "px" : "auto";
    const heightStyle = !isPrm ? heightOfImage + 100 + "px" : "100%";

    return outOfStockImageSrc !== "" || suspendedImageSrc !== "" ? (
        <div
            style={{
                position: "absolute",
                borderRadius: "15%",
                maxWidth: withStyle,
                minWidth: withStyle,
                maxHeight: heightStyle,
                minHeight: heightStyle,
            }}
            className={`${!isPrm && "card_Height"} centered-block`}
        >
            {["webp", ".png", ".jpg"].includes(imgSrc.substr(-4)) ? (
                <img
                    className="d-flex align-items-center"
                    src={isOutOfStock ? outOfStockImageSrc : suspendedImageSrc}
                    alt="ouf_of_stock"
                    loading="lazy"
                    style={{
                        width: isPrm ? "220px" : "100%",
                        borderRadius: "35px",
                        height: "100%",
                    }}
                />
            ) : (
                <div
                    className="d-flex align-items-center"
                    alt="ouf_of_stock"
                    loading="lazy"
                    style={{
                        width: isPrm ? "220px" : "100%",
                        height: "100%",
                        borderRadius: "35px",
                        position: "relative",
                    }}
                >
                    <DynamicSvgComponent
                        nameSvg={
                            isOutOfStock
                                ? outOfStockImageSrc
                                : suspendedImageSrc
                        }
                    />
                </div>
            )}
        </div>
    ) : (
        <img
            className="img-Out-Of-Stock"
            src="./images/out-of-stock.png"
            alt="ouf_of_stock"
            loading="lazy"
            style={{ width: isPrm ? "220px" : "363px" }}
        />
    );
}
OutOfStock.propTypes = {
    isOutOfStock: PropTypes.bool,
    suspendSale: PropTypes.array,
    widthOfImage: PropTypes.number,
    heightOfImage: PropTypes.number,
};
