import React from "react";

export function AlertIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={56.896}
            height={49.578}
            {...props}
        >
            <g
                data-name="Icon feather-alert-triangle"
                fill="white"
                stroke="#c90e17"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
            >
                <path
                    data-name="Trac\xE9 19753"
                    d="M24.047 3.977 2.254 40.354a5.146 5.146 0 0 0 4.4 7.719h43.582a5.146 5.146 0 0 0 4.4-7.719L32.846 3.977a5.146 5.146 0 0 0-8.8 0Z"
                    fill="white"
                    stroke="red"
                    strokeWidth={2.5}
                />
                <path
                    data-name="Trac\xE9 19754"
                    d="M28.446 17.202v10.292"
                    fill="red"
                    stroke="red"
                    strokeWidth={2.5}
                />
                <path
                    data-name="Trac\xE9 19755"
                    d="M28.446 37.786h0"
                    fill="red"
                    stroke="red"
                    strokeWidth={2.5}
                />
            </g>
        </svg>
    );
}
