/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSnapshot } from "valtio";

import React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { kioskStore } from "@pages/Kiosk/store";

import {
    ALT_KEY,
    ALT_LAYAOUT,
    AZERTY_KEYBOARD,
    BACK_SLASH_KEY,
    DEFAULT_KEY,
    DISPLAY_LAYAOUT,
    EN_LAYAOUT,
    EN_SHIFT_LAYAOUT,
    FR_LAYAOUT,
    FR_SHIFT_LAYAOUT,
    LOCK_KEY,
    SHIFT_ACTIVATED_KEY,
    SHIFT_KEY,
} from "../../../constants/keyboardLayout";
import "./index.css";

type KioskKeyboardType = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    enterClick?: () => void;
    keyboard: React.MutableRefObject<any>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChangeAll: Function;
    inputName?: string;
    theme: string;
    buttonAttributes?: any;
    className?: string;
    style?: any;
    preventMouseDownDefault?: boolean;
    buttonTheme?: { class: string; buttons: string }[] | undefined;
    setIsFieldRequired?: (value: React.SetStateAction<boolean>) => void;
    inputRefs?: any;
};

export default function KioskKeyboard({
    enterClick,
    keyboard,
    onChangeAll,
    inputName,
    theme,
    buttonAttributes,
    className,
    style,
    preventMouseDownDefault,
    buttonTheme,
    setIsFieldRequired,
}: KioskKeyboardType): JSX.Element {
    const [layout, setLayout] = React.useState("default");
    const {
        project: {
            template: { keyboard: keyboardLayout },
        },
    } = useSnapshot(kioskStore);

    const onKeyPress = (button: string) => {
        const currentLayout = layout;
        let layoutName = "";

        switch (button) {
            case SHIFT_KEY:
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case LOCK_KEY:
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case SHIFT_ACTIVATED_KEY:
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case DEFAULT_KEY:
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case "{enter}":
                if (enterClick !== undefined) {
                    enterClick();
                }
                break;

            case ALT_KEY:
                layoutName = currentLayout === "alt" ? "default" : "alt";
                break;

            case BACK_SLASH_KEY:
                if (
                    layoutName === "default" &&
                    setIsFieldRequired !== undefined
                ) {
                    setIsFieldRequired(false);
                }
                break;

            default:
                break;
        }

        if (layoutName) {
            setLayout(layoutName);
        }
    };

    return (
        <Keyboard
            layout={{
                default:
                    keyboardLayout === AZERTY_KEYBOARD
                        ? FR_LAYAOUT
                        : EN_LAYAOUT,
                shift:
                    keyboardLayout === AZERTY_KEYBOARD
                        ? FR_SHIFT_LAYAOUT
                        : EN_SHIFT_LAYAOUT,
                alt: ALT_LAYAOUT,
            }}
            className={className}
            theme={theme}
            keyboardRef={(r) => (keyboard.current = r)}
            inputName={inputName}
            layoutName={layout}
            onChangeAll={(e) => onChangeAll(e)}
            onKeyPress={onKeyPress}
            display={DISPLAY_LAYAOUT}
            buttonTheme={buttonTheme}
            style={style}
            preventMouseDownDefault={preventMouseDownDefault}
            buttonAttributes={buttonAttributes}
        />
    );
}
