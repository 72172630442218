import React from "react";

function MessageIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 88.478 88.383"
            // eslint-disable-next-line
            fill={props.color}
            {...props}
        >
            <path
                d="M47.182 3A44.132 44.132 0 007.077 65.643a9.491 9.491 0 01.295 6.972L3.247 84.891a4.944 4.944 0 006.236 6.236L21.758 87a9.491 9.491 0 016.972.295A44.191 44.191 0 1047.182 3zm-19.64 49.1a4.91 4.91 0 01-.044-9.82h.044a4.91 4.91 0 110 9.82zm19.64 0a4.91 4.91 0 01-.044-9.82h.044a4.91 4.91 0 110 9.82zm19.64 0a4.91 4.91 0 01-.044-9.82h.044a4.91 4.91 0 110 9.82z"
                transform="translate(-3.002 -3)"
            ></path>
        </svg>
    );
}

export default MessageIcon;
