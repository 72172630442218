import { AuthenticationModeConsommation } from "@pages/Kiosk/Steps/AuthenticationModeConsommation";
import { AuthenticationModesStep } from "@pages/Kiosk/Steps/AuthenticationModesStep";
import { ClickAndCollectStep } from "@pages/Kiosk/Steps/ClickAndCollectStep";
import { ConnectionStep } from "@pages/Kiosk/Steps/ConnectionStep";
import { FinalMessageStep } from "@pages/Kiosk/Steps/FinalMessageStep";
import { InformationModesConsommationStep } from "@pages/Kiosk/Steps/InformationModesConsommationStep";
import { InformationModesStep } from "@pages/Kiosk/Steps/InformationModesStep";
import { LoyaltyConsommationStep } from "@pages/Kiosk/Steps/LoyaltyConsommationStep";
import { OrderTakingStep } from "@pages/Kiosk/Steps/OrderTakingStep";
import { PaymentStep } from "@pages/Kiosk/Steps/PaymentStep";
import { PrinterOptionsStep } from "@pages/Kiosk/Steps/PrinterOptionsStep";
import { RegulationModesStep } from "@pages/Kiosk/Steps/RegulationModesStep";
import { SalesMethodStep } from "@pages/Kiosk/Steps/SalesMethodStep";
import { StandbyStep } from "@pages/Kiosk/Steps/StandbyStep";

export function useLocalWays(project: ProjectType): StepType[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const localWaysComponents: any[] = [];

    (
        Object.values((project.template.pages as ItemsPagesType)["ways"]) as (
            | PageType
            | ConsommationPageType
            | StandbyScreenPageType
            | PaymentPageType
            | OrderTakingPageType
        )[]
    )
        .sort((a, b) => (a.index > b.index ? 1 : -1))
        .forEach(
            (
                item:
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
                    | OrderTakingPageType
            ) => {
                switch (item.name) {
                    case "salesMethods":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: SalesMethodStep,
                            skipped: (item as PageType).skipped,
                            animation: item.animation,
                        });
                        localWaysComponents.push({
                            name: "clickAndCollect",
                            stepContent: ClickAndCollectStep,
                            animation: item.animation,
                        });
                        break;

                    case "meansOfPayment":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: RegulationModesStep,
                            skipped: (item as PageType).skipped,
                            animation: item.animation,
                        });
                        break;
                    case "informationModes":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: InformationModesStep,
                            skipped: (item as PageType).skipped,
                            animation: item.animation,
                        });
                        break;
                    case "informationModesConsommation":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: InformationModesConsommationStep,
                            animation: item.animation,
                        });
                        break;
                    case "standbyScreen":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: StandbyStep,
                            animation: item.animation,
                        });
                        break;
                    case "connection":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: ConnectionStep,
                            animation: item.animation,
                        });
                        break;
                    case "authenticationModes":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: AuthenticationModesStep,
                            skipped: (item as PageType).skipped,
                            animation: item.animation,
                        });
                        localWaysComponents.push({
                            name: "authenticationModeConsommation",
                            stepContent: AuthenticationModeConsommation,
                            animation: item.animation,
                        });
                        break;
                    case "orderTaking":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: OrderTakingStep,
                            animation: item.animation,
                        });
                        break;
                    case "payment":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: PaymentStep,
                            animation: item.animation,
                        });
                        break;
                    case "finalMessage":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: FinalMessageStep,
                            animation: item.animation,
                        });
                        break;
                    case "loyaltyConsommation":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: LoyaltyConsommationStep,
                            animation: item.animation,
                        });
                        break;

                    case "printerOptions":
                        localWaysComponents.push({
                            name: item.name,
                            stepContent: PrinterOptionsStep,
                            animation: item.animation,
                        });
                }
            }
        );

    return localWaysComponents;
}
