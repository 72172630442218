/* eslint-disable @typescript-eslint/no-explicit-any */
import { LockIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { MdMailOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import "react-simple-keyboard/build/css/index.css";

import { handleUserAuthentication } from "@helpers/general";

import KioskKeyboard from "@components/common/KioskKeyboard";

import { kioskStore, setKeyboardInputsValues } from "@pages/Kiosk/store";

import "./index.css";

export function PrmKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        isAuthenticationErrorModalOpened,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        authModalStepIndex,
        orderTotalPrice,
        customerLanguage,
    } = useSnapshot(kioskStore);
    const dispatch = useDispatch();

    const [isKeyboardOpened, setIsKeyboardOpened] =
        React.useState<boolean>(false);

    const [inputs, setInputs] = React.useState<any>({
        email: "",
        password: "",
    });
    const [inputName, setInputName] = React.useState("");
    const keyboard = React.useRef<any>();

    const onChangeAll = (inputs: any) => {
        setInputs({ ...inputs });
        setKeyboardInputsValues({ ...inputs });
    };

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;
        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });
        keyboard.current.setInput(inputVal);
    };

    const getInputValue = (inputName: any) => {
        return inputs[inputName] || "";
    };
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
                width: "1080px",
                height: isKeyboardOpened ? "510px" : "398px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                zIndex: 1,
            }}
        >
            <form className="d-flex flex-column justify-content-center align-items-center">
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        whiteSpace: "nowrap",
                        gap: "10px",
                    }}
                >
                    {t("E-mail address")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <MdMailOutline
                            className="m-2"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            style={{
                                border: "none",
                                width: "930px",
                                height: "50px",
                            }}
                            id="email"
                            type="text"
                            placeholder={t("Enter your email", {
                                lng: customerLanguage.name,
                            })}
                            value={getInputValue("email")}
                            onClick={() => {
                                setIsKeyboardOpened(true);
                            }}
                            onFocus={() => setInputName("email")}
                            onChange={onChangeInput}
                            className="px-3"
                        />
                    </div>
                </label>
                <label
                    className="d-flex flex-column"
                    style={{
                        font: "normal 27px/32px Segoe UI",
                        letterSpacing: "0px",
                        color: "#000000",
                        gap: "10px",
                    }}
                >
                    {t("Password")}
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            border: "1px solid #70707033",
                            borderRadius: "6px",
                        }}
                    >
                        <LockIcon
                            className="m-2"
                            style={{
                                width: "40px",
                                height: "40px",
                                color: "#565555",
                            }}
                        />
                        <input
                            id="password"
                            value={getInputValue("password")}
                            onFocus={() => setInputName("password")}
                            type={"password"}
                            placeholder={t("Enter your password", {
                                lng: customerLanguage.name,
                            })}
                            onClick={() => {
                                setIsKeyboardOpened(true);
                            }}
                            onChange={onChangeInput}
                            className="usual-keyboard-password__clz px-3"
                        />
                    </div>
                </label>
            </form>

            {isKeyboardOpened === true ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%" }}
                >
                    <div
                        onClick={() => {
                            setIsKeyboardOpened(false);
                        }}
                        style={{ width: "70px", height: "300px" }}
                    ></div>
                    <KioskKeyboard
                        enterClick={() => {
                            const authModeInfo: AuthModeInfoType = {
                                email: inputs[t("Enter your email")],
                                password: inputs[t("Enter your password")],
                            };

                            handleUserAuthentication(
                                authModeInfo,
                                kioskWays,
                                navigationIndex,
                                isAuthenticationAccepted,
                                selectedSaleModeValue,
                                selectedInformationMode,
                                isOrderPaidWithLoyaltyAccountBalance,
                                selectedRegulationModeValue,
                                authModalStepIndex,
                                isAuthenticationErrorModalOpened,
                                content,
                                Number(orderTotalPrice),
                                dispatch
                            );
                        }}
                        keyboard={keyboard}
                        onChangeAll={onChangeAll}
                        inputName={inputName}
                        theme={
                            "prm-keyboard__clz hg-theme-default hg-layout-default"
                        }
                        preventMouseDownDefault={true}
                        buttonTheme={[
                            {
                                class: "enter-key__clz",
                                buttons: "{enter}",
                            },
                            {
                                class: "space-key__clz",
                                buttons: "{space}",
                            },
                        ]}
                    />
                    <div
                        onClick={() => {
                            setIsKeyboardOpened(false);
                        }}
                        style={{ width: "70px", height: "300px" }}
                    />
                </div>
            ) : null}
        </div>
    );
}
