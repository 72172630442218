import { PERCENT_ON_ORDER } from "@constants";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import {
    getColorFromButton,
    getGlobalColorValue,
} from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

import "./promo.css";

type LoyaltyInfoModalType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    couponData: any;
    setIsInfoShown: React.Dispatch<React.SetStateAction<boolean>>;
    isInfoShown: boolean;
    isPromoCodeDisabled: boolean;
    setIsPromoCodeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LoyaltyInfoModal({
    couponData,
    setIsInfoShown,
    isInfoShown,
    isPromoCodeDisabled,
    setIsPromoCodeDisabled,
}: LoyaltyInfoModalType): JSX.Element {
    const {
        devise,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: { actionsButtons },
                        },
                    },
                },
            },
        },
    } = useSnapshot(kioskStore);

    const colorValue = getGlobalColorValue("--global-color");
    const inputColor = getColorFromButton(colorValue);
    function handleClose() {
        setIsInfoShown(false);
        setIsPromoCodeDisabled(false);
    }
    return (
        <Dialog
            open={isInfoShown || isPromoCodeDisabled}
            onClose={handleClose}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "40%!important",
                    height: "25%!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                },
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1.5rem",
                }}
            >
                <CloseIcon
                    style={{
                        alignSelf: "end",
                        margin: "1rem 1rem 0 0",
                        backgroundColor: "var(--global-color)",
                        borderRadius: "10px",
                        color: "var(--secondary-color)",
                    }}
                    className="MuiIcon-fontSizeSmall"
                    onClick={handleClose}
                    fontSize="small"
                />
                {isPromoCodeDisabled ? (
                    <div
                        className="d-flex align-items-center flex-column"
                        style={{ gap: "2rem" }}
                    >
                        <img
                            alt="alert"
                            className="code-promo-alert-image"
                            src={getAlertActionButtonImageSrc(
                                actionsButtons as ActionsButtonsDesignType
                            )}
                        />
                        {couponData.maxOrder === 0 && couponData.minOrder > 0 && (
                            <span
                                className="mx-2"
                                style={{
                                    font: "normal normal 600 24px/40px Segoe UI",
                                    textAlign: "center",
                                }}
                            >
                                {`${t(
                                    `Please ensure your total order amount meets or exceeds`
                                )}  `}
                                <strong className="code-promo-range-total">{`${couponData.minOrder}${devise.deviseSymbole}`}</strong>
                            </span>
                        )}
                        {couponData.minOrder === 0 && couponData.maxOrder > 0 && (
                            <span
                                className="mx-2"
                                style={{
                                    font: "normal normal 600 24px/40px Segoe UI",
                                    textAlign: "center",
                                }}
                            >
                                {`${t(
                                    `Please ensure your total order does not exceed`
                                )}  `}
                                <strong className="code-promo-range-total">{`${couponData.maxOrder}${devise.deviseSymbole}`}</strong>
                            </span>
                        )}
                        {couponData.maxOrder > 0 && couponData.minOrder > 0 && (
                            <span
                                className="mx-2"
                                style={{
                                    font: "normal normal 600 24px/40px Segoe UI",
                                    textAlign: "center",
                                }}
                            >
                                {`${t(
                                    `please have a total order amount between`
                                )}  `}
                                <strong className="code-promo-range-total">{`${couponData.minOrder}${devise.deviseSymbole} - ${couponData.maxOrder}${devise.deviseSymbole}`}</strong>
                            </span>
                        )}
                    </div>
                ) : (
                    <>
                        <div
                            className="code-promo-info-title"
                            style={{ color: inputColor }}
                        >
                            {couponData.promoCode}
                        </div>
                        <span className="code-promo-info-discount">
                            {t("Discount {{discountValue}}", {
                                discountValue: couponData.discountValue,
                            })}
                            {`${
                                Number(couponData.discountType) ===
                                PERCENT_ON_ORDER
                                    ? "%"
                                    : "€"
                            }`}
                        </span>
                        <div className="code-promo-info-details">
                            {couponData.minOrder > 0 && (
                                <span className="code-promo-info-value">
                                    {t(
                                        "Starting from {{minOrder}}€ of purchase",
                                        {
                                            minOrder: couponData.minOrder,
                                        }
                                    )}
                                </span>
                            )}
                            {couponData.maxOrder > 0 && (
                                <span className="code-promo-info-value">
                                    {t("up to {{maxOrder}}€", {
                                        maxOrder: couponData.maxOrder,
                                    })}
                                </span>
                            )}
                            <span>
                                {t("Valid until {{expirationDate}}", {
                                    expirationDate: couponData.expirationDate,
                                })}
                            </span>
                            <span>
                                {t("Promo start date on {{startDate}}", {
                                    startDate: couponData.startDate,
                                })}
                            </span>
                        </div>
                    </>
                )}
            </div>
            {!isPromoCodeDisabled ? (
                <div
                    className="code-promo-info-footer"
                    style={{ color: inputColor }}
                >
                    <h5>
                        {t("Discount on your cart in {{discountType}}", {
                            discountType:
                                Number(couponData.discountType) ===
                                PERCENT_ON_ORDER
                                    ? "%"
                                    : "€",
                        })}
                    </h5>
                </div>
            ) : null}
        </Dialog>
    );
}
