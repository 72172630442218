import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getSkippedStepNumber } from "@helpers/general";

import KioskKeyboard from "@components/common/KioskKeyboard";

import {
    kioskStore,
    setChosenInformationMode,
    setEmailTicket,
    setIsSendEmailConfirmed,
    setIsSendMailWithPdfActive,
    setIsValidEmail,
    setNavigationIndex,
    setPseudoNameValue,
} from "@pages/Kiosk/store";

import "../UsualKeyboardWrapper/index.css";

export function InputKeyboardWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        isSendEmailConfirmed,
        user,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        selectedRegulationModeValue,
        isValidEmail,
        pseudoNameValue,
        orderTotalPrice,
        customerLanguage,
    } = useSnapshot(kioskStore);

    const [input, setInput] = React.useState<string>(
        !isSendEmailConfirmed
            ? pseudoNameValue
            : isSendEmailConfirmed && isUserAuthenticated
            ? user.email
            : ""
    );
    const [enterKeyDisabled, setEnterKeyDisabled] =
        React.useState<boolean>(false);
    // eslint-disable-next-line
    const keyboard = React.useRef<any>(null);

    const enterButtonDisabledAttribute = enterKeyDisabled
        ? [
              {
                  attribute: "disabled",
                  value: `${enterKeyDisabled}`,
                  buttons: "{enter}",
              },
          ]
        : [];
    function validateEmail(input: string) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(input);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleKeyboardOnChangeEvent(e: any) {
        setInput(e.default);
        if (isSendEmailConfirmed) {
            setEmailTicket(e.default);
            setIsValidEmail(validateEmail(e.default));
        } else {
            setPseudoNameValue(e.default);
        }
    }

    async function handleLogin() {
        if (isSendEmailConfirmed) {
            setIsValidEmail(false);
            setIsSendEmailConfirmed(false);
            setIsSendMailWithPdfActive(true);
            setEmailTicket(input);
        } else {
            setPseudoNameValue(input);
            const selectedInformationModeUuId = (
                content.informationModes as ProjectContentItemType
            ).items.find(
                (mode) => (mode.shortName as string) === selectedInformationMode
            )?.id;
            setChosenInformationMode({
                type: selectedInformationMode,
                data: {
                    infoModeUuId: selectedInformationModeUuId as string,
                    value: input,
                },
            });
        }
        setNavigationIndex(
            navigationIndex +
                Number(
                    await getSkippedStepNumber(
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        isUserAuthenticated,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        content,
                        Number(orderTotalPrice)
                    )
                )
        );
    }

    React.useEffect(() => {
        keyboard.current.setInput(input);
        setEnterKeyDisabled(
            isSendEmailConfirmed ? !isValidEmail : input.length === 0
        );
    }, [isValidEmail, input]);

    React.useEffect(() => {
        if (isSendEmailConfirmed) {
            setEmailTicket(input);
            setIsValidEmail(validateEmail(input));
        } else {
            setPseudoNameValue(input);
        }
    });

    return (
        <div
            className="p-1"
            style={{
                display: "grid",
                border: "1px solid rgb(221, 214, 214)",
                borderRadius: "10px",
                boxShadow: "rgba(5, 4, 4, 0.15) 0px 2px 4px",
                gap: "15px",
                width: "95%",
            }}
        >
            <div className="d-flex justify-content-center">
                <input
                    id="email"
                    type="text"
                    value={input}
                    placeholder={
                        isSendEmailConfirmed
                            ? t("Enter your email", {
                                  lng: customerLanguage.name,
                              })
                            : t("Name or pseudonym", {
                                  lng: customerLanguage.name,
                              })
                    }
                    style={{
                        font: "normal normal 300 34px/46px Segoe UI",
                        border: "none",
                        borderBottom: "1px solid rgb(221, 214, 214)",
                        height: "60px",
                    }}
                    onChange={(e) => setInput(e.target.value)}
                    className="w-50 text-center"
                />
            </div>
            <KioskKeyboard
                enterClick={async () => {
                    handleLogin();
                }}
                keyboard={keyboard}
                buttonAttributes={enterButtonDisabledAttribute}
                preventMouseDownDefault={true}
                onChangeAll={handleKeyboardOnChangeEvent}
                theme={"keyboard__clz hg-theme-default ml-5"}
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
            />
        </div>
    );
}
