import { CrossIcon } from "@aureskonnect/react-ui";
import {
    CATALOG_SYNCRO_STEP,
    CATALOGUE_MODE,
    COMPLETED_SYNCRO_STEP,
    FINAL_SYNCHRO_STEP,
    SETTING_SYNCRO_STEP,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setIsAppSynchronized, store } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { setCarte } from "@components/OrderTaking/Store/feature";
import { KioskAppLogoSvgIcon } from "@components/SvgIcons";
import {
    setIsEntityChildrenSelectionModalOpened,
    setIsEntitySelectionModalOpened,
    setIsSyncProgressModalWrapperOpened,
    syncStore,
} from "@components/Synchronization/store";

import {
    kioskStore,
    setIsLocalConfigModalOpened,
    setIsMenuModalOpened,
} from "@pages/Kiosk/store";

import "./index.css";
import SyncProgressSteppers from "./SyncProgressSteppers";

export function SyncProgressModalWrapper(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        isSyncProgressModalWrapperOpened,
        selectedShopApp,
        selectedEntityChild,
    } = useSnapshot(syncStore);
    const { isLocalConfigModalOpened } = useSnapshot(kioskStore);
    const [activeStep, setActiveStep] =
        React.useState<number>(SETTING_SYNCRO_STEP);
    const [errorOnSyncro, setErrorOnSyncro] = React.useState<boolean>(false);
    const controller = new AbortController();
    const signal = controller.signal;
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = useSnapshot(store);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shopDetail: any = JSON.parse(
        localStorage.getItem("shopDetail") as string
    );
    const machineIpAddress = JSON.parse(
        localStorage.getItem("machineIpAddress") as string
    );

    function handleLaunchAppButtonOnClickEvent() {
        setIsSyncProgressModalWrapperOpened(false);
        setIsEntityChildrenSelectionModalOpened(false);
        setIsLocalConfigModalOpened(false);
        setIsEntitySelectionModalOpened(false);
        setIsMenuModalOpened(false);
        setIsAppSynchronized(true);
        if (!isLocalConfigModalOpened) {
            navigate("/");
        }
    }

    let launchKioskTimer: NodeJS.Timeout;
    if (activeStep === COMPLETED_SYNCRO_STEP) {
        launchKioskTimer = setTimeout(() => {
            setActiveStep(FINAL_SYNCHRO_STEP);
        }, 3000);
    }
    if (activeStep === FINAL_SYNCHRO_STEP) {
        launchKioskTimer = setTimeout(() => {
            handleLaunchAppButtonOnClickEvent();
        }, 2000);
    }

    React.useEffect(() => {
        async function handleSynchroSettingAndCatalog() {
            fetch(
                `${middlewareApiUrl}/settingsynchro?franchise_uuid=${
                    selectedEntityChild?.franchiseUuid
                }&shop_uuid=${
                    selectedEntityChild?.uid
                }&isDataNeeded=${true}&timestamp=${timestamp}`,
                { headers: { "Cache-Control": "no-cache" }, signal }
            )
                .then((response) => response.json())
                .then(async (data) => {
                    if (data.kiosk.length === 0) {
                        setErrorOnSyncro(true);
                        return;
                    }

                    const uuidModification =
                        localStorage.getItem("uidModification") === null
                            ? {
                                  catalog: "",
                                  setting: "",
                                  console: "",
                                  standByKiosk: "",
                                  peripheral: "",
                                  passCode: "",
                              }
                            : localStorage.getItem("uidModification");

                    localStorage.setItem(
                        "uidModification",
                        JSON.stringify(uuidModification)
                    );
                    setActiveStep(CATALOG_SYNCRO_STEP);
                    await handleSyncroCatalog();
                })
                .catch((error) => {
                    setErrorOnSyncro(true);
                    console.log(
                        `Error while getting shop apps, with message: ${error}`
                    );
                });
        }

        async function handleSyncroCatalog() {
            fetch(
                `${middlewareApiUrl}/kiosk/${shopDetail?.schema}/${shopDetail?.id}/catalogsynchro?timestamp=${timestamp}`,
                { headers: { "Cache-Control": "no-cache" }, signal }
            )
                .then((result) => result.json())
                .then((data) => {
                    if (data.error === true) {
                        throw Error(
                            "There's an issue while getting catalog from handle syncro catalog"
                        );
                    }
                    dispatch(setCarte(data));
                    console.log("Set up catalog card after synchronization");
                    setActiveStep(COMPLETED_SYNCRO_STEP);
                })
                .catch((error) => {
                    setErrorOnSyncro(true);
                    console.log(
                        `Error while getting catalog card, with message: ${error}`
                    );
                });

            fetch(`${middlewareApiUrl}/modifyIpApplication`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    franchiseId: selectedEntityChild?.franchiseUuid,
                    shopId: selectedEntityChild?.uid,
                    appId: selectedShopApp.appId,
                    ip: machineIpAddress,
                }),
                method: "POST",
            });
        }
        handleSynchroSettingAndCatalog();

        return () => {
            clearTimeout(launchKioskTimer);
        };
    }, []);

    return (
        <Modal
            isOpen={isSyncProgressModalWrapperOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "500px",
                    width: "980px",
                    zIndex: 2,
                    background: "#000000 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",
                    gap: "50px",
                    borderRadius: "18px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <FlexboxGrid className="justify-content-between">
                <KioskAppLogoSvgIcon height="88px" width="220px" />

                {errorOnSyncro && (
                    <CrossIcon
                        onClick={() => {
                            setIsSyncProgressModalWrapperOpened(false);
                        }}
                        height={20}
                        width={20}
                        fill="white"
                        style={{ cursor: "pointer" }}
                    />
                )}
            </FlexboxGrid>
            <FlexboxGrid className="flex-column" gap="80px">
                <FlexboxGrid
                    className="flex-column"
                    gap="20px"
                    justifyContentCentered={true}
                >
                    <span
                        style={{
                            font: "normal normal bold 27px/33px Segoe UI",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        {t("Synchronization in progress")}
                    </span>
                    <span
                        style={{
                            font: "normal normal normal 15px/17px Segoe UI",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                        }}
                    >
                        {t(
                            "Please wait a few minutes to be able to use your application"
                        )}
                    </span>
                </FlexboxGrid>
                <SyncProgressSteppers
                    activeStep={activeStep}
                    errorOnSyncro={errorOnSyncro}
                />
            </FlexboxGrid>
        </Modal>
    );
}
