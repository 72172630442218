import { ITEM_TYPE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { FireIcon } from "@components/OrderTaking/Components/Icons/FireIcon";

import { kioskStore } from "@pages/Kiosk/store";

export function ProductCalorie({ product }) {
    const { isPrm } = useSnapshot(kioskStore);
    const {
        calorieId,
        isCalorieActive,
        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const { type, calorie } = product;

    const calorieImageSrc = React.useMemo(() => {
        return getImageContentById(calorieId);
    }, []);

    return isCalorieActive && type === ITEM_TYPE && calorie?.isVisible ? (
        <div
            className={`centredItems__clz calorie ${
                isPrm ? "prm-calorie" : "calorie"
            }`}
            style={{
                height: "90%",
                background: "white",
                border: "1px #EEEEEE",
                borderRadius: "0.5vh",
                width: "32%",
                maxWidth: "65px",
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center h-100"
                style={{
                    bottom: 0,
                }}
            >
                {calorieImageSrc !== "" ? (
                    <img src={calorieImageSrc} style={{ height: "100%" }} />
                ) : (
                    <FireIcon />
                )}

                <span style={{ marginRight: "5px" }}>{calorie.content}</span>
            </div>
        </div>
    ) : null;
}

ProductCalorie.propTypes = {
    product: PropTypes.object.isRequired,
};
