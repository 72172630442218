import PropTypes from "prop-types";

import React from "react";

import "./index.css";

export function RemoveIcon({ width, height, onClick }) {
    return (
        <button className="icons-button__clz">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 68 68"
            >
                <defs>
                    <filter
                        id="Ellipse_1120"
                        width={width}
                        height={height}
                        x="0"
                        y="0"
                        filterUnits="userSpaceOnUse"
                    >
                        <feOffset dy="3"></feOffset>
                        <feGaussianBlur
                            result="blur"
                            stdDeviation="3"
                        ></feGaussianBlur>
                        <feFlood floodOpacity="0.243"></feFlood>
                        <feComposite in2="blur" operator="in"></feComposite>
                        <feComposite in="SourceGraphic"></feComposite>
                    </filter>
                </defs>
                <g
                    data-name="Groupe 30413"
                    transform="translate(8.69 5.755)"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    <g data-name="Groupe 26897">
                        <g
                            filter="url(#Ellipse_1120)"
                            transform="translate(-8.69 -5.76)"
                        >
                            <circle
                                cx="26"
                                cy="26"
                                r="26"
                                fill={`var(--global-color)`}
                                data-name="Ellipse 1120"
                                transform="translate(9 6)"
                                stroke={`var(--global-color)`}
                            ></circle>
                        </g>
                        <g
                            data-name="Groupe 26895"
                            transform="translate(1.31 1.004)"
                        >
                            <path
                                fill="#fff"
                                d="M25 0A25 25 0 110 25 25 25 0 0125 0z"
                                data-name="Tracé 21196"
                                transform="translate(0 .241)"
                                stroke={`var(--global-color)`}
                                strokeWidth="2"
                            ></path>
                            <g
                                data-name="Icon feather-plus"
                                transform="translate(19.09 18.291)"
                                fill={`var(--global-color)`}
                                stroke={`var(--global-color)`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            >
                                <path
                                    fill={`var(--global-color)`}
                                    stroke={`var(--global-color)`}
                                    strokeWidth="2"
                                    d="M0 6.844h12.9"
                                    data-name="Tracé 2455"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    );
}

RemoveIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    onClick: PropTypes.func.isRequired,
};
