import { BACK_BUTTON_ROLE, VALIDATED_EXTENSION_FILE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore } from "@pages/Kiosk/store";

export function RemoveDialogActionButton({ onClose }) {
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);

    const backButton = actionsButtons.items.filter((button) => {
        return button.role === BACK_BUTTON_ROLE;
    })[0];
    const backButtonImageSrc = getImageContentById(backButton.id);
    return (
        <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "250px" }}
        >
            <div
                onClick={() => {
                    onClose();
                }}
            >
                {VALIDATED_EXTENSION_FILE.includes(
                    backButtonImageSrc.substr(-4)
                ) ? (
                    <img
                        src={backButtonImageSrc}
                        alt="action button"
                        style={{
                            backgroundSize: "100% 100%",
                        }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={backButtonImageSrc}
                        width={"350px"}
                        height={"87px"}
                    />
                )}
            </div>
        </div>
    );
}

RemoveDialogActionButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};
