import {
    ABANDON_BUTTON_ROLE,
    CANCEL_BUTTON_ROLE,
    CONTINUE_ORDER_BUTTON_ROLE,
    NEXT_IN_STEP_BUTTON_ROLE,
    PAY_BUTTON_ROLE,
    PREVIOUS_IN_STEP_BUTTON_ROLE,
    PRM_CANCEL_BUTTON_ROLE,
    PRM_PAY_BUTTON_ROLE,
    VALIDATE_IN_STEP_BUTTON_ROLE,
} from "@constants";
import { groupBy } from "lodash";

import { getStepDetails } from "./getStepDetails";

export function convertSalesModeValue(saleMode) {
    switch (saleMode) {
        case "To take":
            return "TAKE_OUT";
        case "On the spot":
            return "EAT_IN";
        case "Delivery":
            return "TO_TAKE";
        default:
            return "TAKE_OUT";
    }
}

export function getTags(card) {
    return Object.keys(card.tags)?.reduce((tagsData, id) => {
        tagsData.push({ id, ...card.tags[id], active: false, isVisible: true });
        return tagsData;
    }, []);
}

export function setOrderTakingSetting(
    orderTakingSettingsData,
    primaryColor,
    secondaryColor
) {
    document.documentElement.style.setProperty("--global-color", primaryColor);
    document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor
    );
    return {
        generalSetting: {
            isOrderTakingTopBannerActive:
                orderTakingSettingsData.topBanner.active,
            isOrderTakingTagsActive:
                orderTakingSettingsData.orderTakingProduct.tags,
            isHeaderActive: orderTakingSettingsData.header.active,
            isOrderTakingUserAccountActive:
                orderTakingSettingsData.header.userAccount.active,
            userAccountMessage: orderTakingSettingsData.header.userAccount,

            globalColor: primaryColor, //"#f84c00",
            isBackgroundActive: orderTakingSettingsData.background.active,
            backgroundContent: orderTakingSettingsData.background.content,
            secondaryColor: secondaryColor,
            actionsButtons: orderTakingSettingsData.actionsButtons.items,
            generalShopCartMessage:
                orderTakingSettingsData.options.shopCardMessage,
            isShopCardActive: true,
            isOrderTakingSalesMethodsChangeActive: false,
            // orderTakingSettingsData.orderTakingProduct
            //     .salesMethodsChangeActive,
        },
        productSetting: {
            isCalorieActive:
                orderTakingSettingsData.orderTakingProduct.calorieIcon.active,
            calorieId:
                orderTakingSettingsData.orderTakingProduct.calorieIcon.id,
            isProductDescriptionActive:
                orderTakingSettingsData.orderTakingProduct
                    .isProductDescriptionActive,
            isProductTitleActive:
                orderTakingSettingsData.orderTakingProduct.isProductTitleActive,
            isCategorySubCategoryTitleActive:
                orderTakingSettingsData.orderTakingProduct
                    .categorySubCategoryTitle,
            isInformationProductIconActive:
                orderTakingSettingsData.orderTakingProduct.productAccessIcon
                    .active,
            informationProductIconContent:
                orderTakingSettingsData.orderTakingProduct.productAccessIcon.id,
            isCommentProductActive:
                orderTakingSettingsData.orderTakingProduct
                    .isCommentArticleActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isCommentArticleActive
                    : false,
            isFreeCommentProductActive:
                orderTakingSettingsData.orderTakingProduct
                    .isFreeCommentActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isFreeCommentActive
                    : false,
            isCommentProductRecoveryActive:
                orderTakingSettingsData.orderTakingProduct
                    .isCommentRecoveryActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isCommentRecoveryActive
                    : false,
            isShopCartDetailsActive:
                orderTakingSettingsData.orderTakingProduct
                    .isShopCartDetailsActive,
            designCategories:
                orderTakingSettingsData.orderTakingProduct.designCategories,
            isNutriscoreOnTheProductActive:
                orderTakingSettingsData.orderTakingProduct
                    .isNutriscoreOnTheProductActive,
            isNutriscoreInTheInformationPopUpActive:
                orderTakingSettingsData.orderTakingProduct
                    .isNutriscoreInTheInformationPopUpActive,
            isNutriscoreActive:
                orderTakingSettingsData.orderTakingProduct.isNutriscoreActive,
            isBorderCardProductActive:
                orderTakingSettingsData.orderTakingProduct
                    .isBorderCardProductActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isBorderCardProductActive
                    : false,
            isCoverImageActive:
                orderTakingSettingsData.orderTakingProduct
                    .isCoverImageActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isCoverImageActive
                    : false,
            isProductBreadcrumbTrailActive:
                orderTakingSettingsData.orderTakingProduct.breadcrumbTrail ??
                orderTakingSettingsData.orderTakingProduct.categoryTitle,

            isProductModificationActive:
                orderTakingSettingsData.orderTakingProduct
                    .isProductModificationActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isProductModificationActive
                    : true,
            isArticleDetailsAsTextActive:
                orderTakingSettingsData.orderTakingProduct
                    .isArticleDetailsAsTextActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isArticleDetailsAsTextActive
                    : false,
            isConfirmationPopupForDeletingAnArticleActive:
                orderTakingSettingsData.orderTakingProduct
                    .isConfirmationPopupForDeletingAnArticleActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isConfirmationPopupForDeletingAnArticleActive
                    : false,
            isShownProductCompositionDescription:
                orderTakingSettingsData.orderTakingProduct
                    .isShownProductCompositionDescription !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isShownProductCompositionDescription
                    : false,
            isCategoriesScrollActive:
                orderTakingSettingsData.orderTakingProduct
                    .isCategoriesScrollActive !== undefined
                    ? orderTakingSettingsData.orderTakingProduct
                          .isCategoriesScrollActive
                    : true,
        },

        categorySetting: {
            isOrderTakingCategoryTitleActive:
                orderTakingSettingsData.orderTakingProduct.categoryTitle,
            isSelectedCategoryActive:
                orderTakingSettingsData.orderTakingProduct
                    .selectedCategoryTitle,
        },
        orderTakingOptions: orderTakingSettingsData.options.items,
        basketSettings: {
            emptyBasketMessage: orderTakingSettingsData.options.shopCardMessage,
            actionsButtons: orderTakingSettingsData.actionsButtons.items.filter(
                (button) => {
                    return (
                        button.role === PAY_BUTTON_ROLE ||
                        button.role === CANCEL_BUTTON_ROLE
                    );
                }
            ),
        },
        designItems: orderTakingSettingsData.design.items,
        summary: {
            isSummaryBackgroundActive:
                orderTakingSettingsData.summary.background.active,
            summaryBackgroundContent: orderTakingSettingsData.background.id,

            isSummaryLogoActive:
                orderTakingSettingsData.summary.summaryHeader.logo.active,
            IsAccumulatedLoyaltyPointsActive:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.active,
            summaryHeaderMessage: orderTakingSettingsData.summary.summaryHeader,
            isSalesMethodsChangeActive: false,
            // orderTakingSettingsData.summary.salesMethodsChangeActive,
            summaryActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === PAY_BUTTON_ROLE ||
                            button.role === CONTINUE_ORDER_BUTTON_ROLE
                        );
                    }
                ),
            summaryHeaderBravoText:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.items[0],
            summaryHeaderLoyaltyPointText:
                orderTakingSettingsData.summary.summaryHeader
                    .accumulatedLoyaltyPoints.items[1],
            isSummaryOptionsItemsActive:
                orderTakingSettingsData.summary.options.active,
            summaryOptionsItems: orderTakingSettingsData.summary.options.items,
        },
        compositeProductSettings: {
            isBasketActive: orderTakingSettingsData.compositeProduct.basket,
            isHeaderColorActive:
                orderTakingSettingsData.compositeProduct.headerColor,
            isClosingButtonActive:
                orderTakingSettingsData.compositeProduct.closingButton.active,
            closingButtonContent:
                orderTakingSettingsData.compositeProduct.closingButton.id,
            isNextOrPreviousButtonsActive:
                orderTakingSettingsData.compositeProduct.nextOrPreviousButtons,
            courseProgressColor:
                orderTakingSettingsData.compositeProduct.courseProgressColor
                    .content,
            isBreadcrumbTrailActive:
                orderTakingSettingsData.compositeProduct.breadcrumbTrail,
            isProductInfoIconActive:
                orderTakingSettingsData.compositeProduct.productAccessIcon
                    .active,
            productInfoIconId:
                orderTakingSettingsData.compositeProduct.productAccessIcon.id,
            isInformationMessageActive:
                orderTakingSettingsData.compositeProduct.informationMessage,
            isMinMaxShown:
                orderTakingSettingsData.compositeProduct.isMinMaxShown !==
                undefined
                    ? orderTakingSettingsData.compositeProduct.isMinMaxShown
                    : true,
            compositeProductActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === NEXT_IN_STEP_BUTTON_ROLE ||
                            button.role === PREVIOUS_IN_STEP_BUTTON_ROLE ||
                            button.role === ABANDON_BUTTON_ROLE ||
                            button.role === VALIDATE_IN_STEP_BUTTON_ROLE
                        );
                    }
                ),
            shopCardMessage:
                orderTakingSettingsData.compositeProduct.shopCardMessage,
            isNutriscoreInCompositeProductActive:
                orderTakingSettingsData.compositeProduct
                    .isNutriscoreInCompositeProductActive,
        },
        prmSettings: {
            prmActionsButtons:
                orderTakingSettingsData.actionsButtons.items.filter(
                    (button) => {
                        return (
                            button.role === PRM_PAY_BUTTON_ROLE ||
                            button.role === PRM_CANCEL_BUTTON_ROLE
                        );
                    }
                ),
        },
    };
}
export const getNumberOfChoiceV0 = (path, items, shopCart) => {
    const newPath = path?.reduce(
        (prev, curr) => (curr?.name ? [...prev, curr.name] : [...prev, curr]),
        []
    );
    const objectToModify = newPath.reduce((prev, key) => {
        return prev[key] || {};
    }, shopCart);

    return Object.keys(objectToModify).reduce((prev, curr) => {
        const found = items.find(
            (elm) => elm.iuud === objectToModify[curr].iuud
        );
        return found ? prev + objectToModify[curr].quantity : prev + 0;
    }, 0);
};

export const refactorWorkflowData = (workflows, shopCart, items) => {
    return workflows?.map((work) => {
        const editwork = work.workflow.reduce((prev, curr) => {
            const NbrOfChoices = getNumberOfChoiceV0(
                curr?.path,
                curr?.Items,
                shopCart
            );
            const stepPath = curr?.path.map((elm) => elm.name);
            let stepComposition = stepPath.reduce((prev, key) => {
                return prev[key] || {};
            }, shopCart);

            const {
                selectedChoicesWithspecialPrice,
                selectedChoicesWithStepPrice,
            } = getStepDetails(stepComposition);

            const newCurr = { ...curr };
            newCurr.Items = newCurr.Items.map((item) => {
                if (
                    item.type === "items" ||
                    item.type === "basic_composition"
                ) {
                    return {
                        ...item,
                        outOfStock: items[item.iuud].outOfStock,
                    };
                }
                return item;
            });
            return [
                ...prev,
                {
                    ...newCurr,
                    NbrOfChoices,
                    selectedChoicesWithspecialPrice,
                    selectedChoicesWithStepPrice,
                },
            ];
        }, []);
        return { ...work, workflow: editwork };
    });
};
const reOrderItems = (data) => {
    return data.sort((a, b) => a.stepRank - b.stepRank);
};
export const reOrderItemsByStepOrder = (listItems) => {
    let goupedLineByparentUuid = groupBy(listItems, "parentLineUuid");

    return Object.values(goupedLineByparentUuid)[0].reduce((prev, curr) => {
        let newList = [curr];
        if (goupedLineByparentUuid[curr.lineUuid]) {
            const newOrder = reOrderItems(
                goupedLineByparentUuid[curr.lineUuid]
            );
            newList = [curr, ...newOrder];
        }
        return [...prev, ...newList];
    }, []);
};
export const reOrderItemsByStepOrderAndParentUuid = (listItems) => {
    let result = [];
    let goupedLineByparentUuid = groupBy(listItems, "parentLineUuid");
    const fillNestedElement = (goupedLineByparentUuid, product) => {
        const newOrder = reOrderItems(goupedLineByparentUuid[product.lineUuid]);
        newOrder.forEach((element) => {
            if (goupedLineByparentUuid[element.lineUuid]) {
                result.push(element);
                fillNestedElement(goupedLineByparentUuid, element);
            } else {
                result.push(element);
            }
        });
    };

    Object.values(goupedLineByparentUuid).length > 0 &&
        Object.values(goupedLineByparentUuid)[0].forEach((product) => {
            if (goupedLineByparentUuid[product.lineUuid]) {
                result.push(product);
                fillNestedElement(goupedLineByparentUuid, product);
            } else {
                result.push(product);
            }
        });

    return result;
};

export function getRowsColumnsDesign(nbrItems) {
    let rows = 0;
    let columns = 0;
    if (nbrItems === 0) {
        rows = 0;
        columns = 0;
        return {
            nbrRows: rows,
            nbrColumn: columns,
            isAutoDesign: true,
            nbrTotal: nbrItems,
        };
    }
    if (nbrItems >= 21) {
        rows = 5;
        columns = 5;
        return {
            nbrRows: rows,
            nbrColumn: columns,
            isAutoDesign: true,
            nbrTotal: nbrItems,
        };
    }
    rows = Math.ceil(Math.sqrt(nbrItems));
    columns = Math.ceil(nbrItems / rows);

    return {
        nbrRows: rows,
        nbrColumn: columns,
        isAutoDesign: true,
        nbrTotal: nbrItems,
    };
}

export function imageWithFallback(e) {
    e.currentTarget.src = "./images/nopicture.png";
}
