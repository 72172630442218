/* eslint-disable @typescript-eslint/no-explicit-any */
import { PERCENT_ON_ORDER } from "@constants";
import InfoIcon from "@mui/icons-material/Info";
import classNames from "classnames";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { WorkflowDialog } from "@components/OrderTaking/Components/Dialogs/WorkflowDialog";
import {
    AddIcon,
    AddIconWrapper,
} from "@components/OrderTaking/Components/Icons/AddIcon";
import { RemoveIcon } from "@components/OrderTaking/Components/Icons/RemoveIcon";
import {
    getColorFromButton,
    getGlobalColorValue,
    getProductTitle,
} from "@components/OrderTaking/Helpers";
import {
    addToOrderworkflow,
    ClearWorkflowItem,
    SelectProduct,
    selectUserRewards,
    setIsModification,
    setUserGifts,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { LoyaltyInfoModal } from "./loyaltyInfoModal";
import "./promo.css";
import { RemoveSelectedRewardModal } from "./RemoveSelectedRewardModal";
import {
    PromoCodeCardContainer,
    PromoCodeOnlyCardContainer,
} from "./styledCss";

type LoyaltyCardType = {
    couponData?: any;
    isPromoCode?: boolean;
};

export function LoyaltyCard({
    couponData,
    isPromoCode,
}: LoyaltyCardType): JSX.Element {
    const dispatch = useDispatch();
    const { selectedSaleModeId } = useSnapshot(kioskStore);
    const {
        globalcard,
        nestedCategories,
        userRewards,
        userGifts,
        order: { totalPriceWithOutFidelity },
    } = useSelector((state: any) => state.orderSlice);

    const isRewardsDisabled =
        !isPromoCode &&
        (couponData.rewardLoyaltyPoints as number) > userRewards.loyaltyAccount;

    // grey and disable click on promo code and rewards card
    const isBtnDisabled =
        isRewardsDisabled ||
        (isPromoCode && couponData.minOrder > totalPriceWithOutFidelity) ||
        (couponData.maxOrder !== 0 &&
            couponData.maxOrder < totalPriceWithOutFidelity);

    const [isWorkflowDialogOpened, setIsWorkflowDialogOpened] =
        React.useState(false);

    const [isInfoShown, setIsInfoShown] = React.useState(false);
    const [isRewardUnSelected, setIsRewardUnSelected] = React.useState(false);
    const [isPromoCodeDisabled, setIsPromoCodeDisabled] = React.useState(false);

    const handleWorkDialogCloseEvent = () => {
        dispatch(ClearWorkflowItem());
        dispatch(SelectProduct({}));
        dispatch(setWorkflowData([]));
        setIsWorkflowDialogOpened(!isWorkflowDialogOpened);
    };

    const handlePromoCodeOnClick = () => {
        if (!couponData?.isSelected) {
            if (isBtnDisabled) {
                setIsPromoCodeDisabled(true);
                return;
            }
            const promo: any = userGifts.map((item: any) => {
                return item.id === couponData.id
                    ? { ...item, isSelected: true }
                    : { ...item, isSelected: false };
            });

            dispatch(setUserGifts(promo));
        } else {
            const promo: any = userGifts.map((item: any) => {
                return { ...item, isSelected: false };
            });

            dispatch(setUserGifts(promo));
        }
    };

    const handleRewardsOnClick = () => {
        if (!couponData?.isSelected) {
            if (isBtnDisabled) {
                toast.info(t("Loyalty balance is not enough."), {
                    className: "responsive-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                    theme: "colored",
                    closeOnClick: true,
                    hideProgressBar: false,
                });
                return;
            }
            const isHaveOneOption =
                couponData?.optionsKiosk?.Options.length === 1 &&
                couponData?.optionsKiosk?.Options[0].listOptions.length > 0
                    ? couponData?.optionsKiosk?.Options[0].listOptions
                          .length === 1
                        ? true
                        : false
                    : false;

            const initialRewardOrder = {
                ...couponData,
                shopCart: {},
                price: 0,
                isReward: true,
                displayTitle: couponData?.name,
                tva: couponData.advancedItemPrice?.saleModeVatRates[
                    selectedSaleModeId
                ]
                    ? couponData.advancedItemPrice?.saleModeVatRates[
                          selectedSaleModeId
                      ]
                    : 0,
                isInformationModeActivated:
                    globalcard.categories[
                        nestedCategories[nestedCategories.length - 1]
                    ].isInformationModeActivated ?? false,
                selectedOptions: isHaveOneOption
                    ? [
                          getProductTitle(
                              couponData.optionsKiosk.Options[0].listOptions[0]
                                  .name
                          ),
                      ]
                    : [],
                selectedOptionsUuid: isHaveOneOption
                    ? [
                          couponData.optionsKiosk.Options[0].iuud,
                          couponData.optionsKiosk.Options[0].listOptions[0]
                              .iuud,
                      ]
                    : [],
                selectedIndexOptions: isHaveOneOption
                    ? [
                          couponData.optionsKiosk.Options[0].listOptions[0]
                              .optionIndex,
                      ]
                    : [],
                date: Date.now(),
            };
            if (
                couponData.modifier != "" &&
                (couponData.haveWorkflow || couponData.haveBasicComposition)
            ) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct(initialRewardOrder));
                setIsWorkflowDialogOpened(true);
                dispatch(ClearWorkflowItem());
                dispatch(setIsModification(false));
                dispatch(addToOrderworkflow(initialRewardOrder));
            } else {
                // add reward selection and subtract used loyalty point
                dispatch(selectUserRewards(initialRewardOrder));
            }
        } else {
            dispatch(selectUserRewards(couponData));
        }
    };

    return (
        <React.Fragment>
            {isWorkflowDialogOpened && (
                <WorkflowDialog
                    open={isWorkflowDialogOpened}
                    onClose={handleWorkDialogCloseEvent}
                />
            )}
            {(isInfoShown || isPromoCodeDisabled) && (
                <LoyaltyInfoModal
                    couponData={couponData}
                    setIsInfoShown={setIsInfoShown}
                    isInfoShown={isInfoShown}
                    isPromoCodeDisabled={isPromoCodeDisabled}
                    setIsPromoCodeDisabled={setIsPromoCodeDisabled}
                />
            )}
            {isRewardUnSelected && (
                <RemoveSelectedRewardModal
                    setIsRewardUnSelected={setIsRewardUnSelected}
                    isRewardUnSelected={isRewardUnSelected}
                    handleOnclick={handleRewardsOnClick}
                />
            )}
            {isPromoCode ? (
                <CodePromoCard
                    couponData={couponData}
                    isBtnDisabled={isBtnDisabled}
                    handleOnclick={handlePromoCodeOnClick}
                    isInfoShown={isInfoShown}
                    setIsInfoShown={setIsInfoShown}
                />
            ) : (
                <RewardsCard
                    couponData={couponData}
                    isBtnDisabled={isBtnDisabled}
                    handleOnclick={handleRewardsOnClick}
                    setIsRewardUnSelected={setIsRewardUnSelected}
                />
            )}
        </React.Fragment>
    );
}

type RewardsCardType = {
    couponData: any;
    isBtnDisabled?: boolean;
    handleOnclick: () => void;
    setIsRewardUnSelected: React.Dispatch<React.SetStateAction<boolean>>;
};
export function RewardsCard({
    couponData,
    isBtnDisabled,
    handleOnclick,
    setIsRewardUnSelected,
}: RewardsCardType): JSX.Element {
    return (
        <div
            style={{
                opacity: isBtnDisabled && !couponData?.isSelected ? 0.4 : 1,
            }}
        >
            <div
                className={classNames("cardStyle swiper-slide", {
                    selectedCard: couponData?.isSelected,
                })}
                style={{
                    border: couponData?.isSelected ? "4px solid #000000" : "",
                }}
                onClick={() => {
                    if (couponData?.isSelected) {
                        setIsRewardUnSelected(true);
                    } else {
                        handleOnclick();
                    }
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        zIndex: 2,
                    }}
                >
                    {couponData?.isSelected ? (
                        <RemoveIcon
                            width={54}
                            height={54}
                            onClick={() => setIsRewardUnSelected(true)}
                        />
                    ) : (
                        <button className="icons-button__clz " title="add icon">
                            <AddIcon
                                opacity={isBtnDisabled ? "0.3" : "1"}
                                width={54}
                                height={54}
                            />
                        </button>
                    )}
                </div>
                <div className="image-content">
                    <span className="overlay"></span>
                    <div className="card-image">
                        <img
                            src={couponData.urlImage}
                            alt={couponData.urlImage}
                            className="card-img"
                        />
                    </div>
                </div>
                <div className="card-content">
                    <h2
                        className="reward-name preview-text"
                        style={{ marginTop: "1rem" }}
                    >
                        {couponData.name}
                    </h2>
                </div>
            </div>
        </div>
    );
}

type CodePromoCardType = {
    couponData: any;
    isBtnDisabled?: boolean;
    handleOnclick: () => void;
    setIsInfoShown: React.Dispatch<React.SetStateAction<boolean>>;
    isInfoShown: boolean;
};
export function CodePromoCard({
    couponData,
    isBtnDisabled,
    handleOnclick,
    setIsInfoShown,
    isInfoShown,
}: CodePromoCardType): JSX.Element {
    const {
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: { rewards },
                        },
                    },
                },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    const colorValue = getGlobalColorValue("--global-color");
    const inputColor = getColorFromButton(colorValue);

    const codePromoName =
        couponData.name[customerLanguage.name.toUpperCase()] !== undefined &&
        couponData.name[customerLanguage.name.toUpperCase()] != ""
            ? couponData.name[customerLanguage.name.toUpperCase()]
            : couponData.name["default"];

    function handleInfoIconClick() {
        setIsInfoShown(!isInfoShown);
    }
    return (
        <>
            {rewards.active ? (
                <PromoCodeCardContainer
                    style={{
                        border: couponData?.isSelected
                            ? "4px solid #000000"
                            : "",
                    }}
                >
                    <div
                        className="code-promo-title"
                        onClick={handleOnclick}
                        style={{
                            opacity: isBtnDisabled ? 0.2 : 1,
                            color: inputColor ?? "white",
                        }}
                    >
                        <h2 className="discount-name">
                            {couponData.discountValue}
                            {`${
                                Number(couponData.discountType) ===
                                PERCENT_ON_ORDER
                                    ? "%"
                                    : "€"
                            }`}
                        </h2>
                        <span className="preview-text">{codePromoName}</span>
                    </div>
                    <div className="code-promo-icons">
                        <InfoIcon
                            style={{
                                cursor: "pointer",
                                width: "30px",
                                height: "30px",
                                color: inputColor ?? "white",
                            }}
                            onClick={handleInfoIconClick}
                        />
                        {couponData?.isSelected ? (
                            <RemoveIcon
                                width={54}
                                height={54}
                                onClick={handleOnclick}
                            />
                        ) : (
                            <AddIconWrapper
                                opacity={isBtnDisabled ? 0.3 : 1}
                                width={54}
                                height={54}
                                onClick={handleOnclick}
                            />
                        )}
                    </div>
                </PromoCodeCardContainer>
            ) : (
                <PromoCodeOnlyCardContainer
                    style={{
                        border: couponData?.isSelected
                            ? "4px solid #000000"
                            : "",
                    }}
                >
                    <div
                        className="code-promo-only-title"
                        onClick={handleOnclick}
                        style={{
                            opacity: isBtnDisabled ? 0.2 : 1,
                            color: inputColor ?? "white",
                        }}
                    >
                        <h2 className="discount-name">
                            {couponData.discountValue}
                            {`${
                                Number(couponData.discountType) ===
                                PERCENT_ON_ORDER
                                    ? "%"
                                    : "€"
                            }`}
                        </h2>
                        <span className="preview-text">{codePromoName}</span>
                    </div>
                    <div className="code-promo-only-icons">
                        <InfoIcon
                            style={{
                                cursor: "pointer",
                                width: "45px",
                                height: "45px",
                                color: inputColor ?? "white",
                            }}
                            onClick={handleInfoIconClick}
                        />
                        {couponData?.isSelected ? (
                            <RemoveIcon
                                width={54}
                                height={54}
                                onClick={handleOnclick}
                            />
                        ) : (
                            <AddIconWrapper
                                opacity={isBtnDisabled ? 0.3 : 1}
                                width={54}
                                height={54}
                                onClick={handleOnclick}
                            />
                        )}
                    </div>
                </PromoCodeOnlyCardContainer>
            )}
        </>
    );
}

export function CategoryCard({ categoryData }: categoryCardType): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    const colorValue = getGlobalColorValue("--global-color");
    const inputColor = getColorFromButton(colorValue);

    return (
        <div className="cardStyle swiper-slide">
            <div className="image-content">
                <span className="category-card-overly"></span>
                <div className="card-image">
                    <img
                        src={categoryData.image_url}
                        alt={categoryData.image_url}
                        className="category-card-img"
                    />
                </div>
            </div>
            <div className="category-card-content">
                <h2
                    className="name preview-text"
                    style={{ color: inputColor ?? "white" }}
                >
                    {
                        (categoryData.reward_name as { [key: string]: string })[
                            customerLanguage.name.toUpperCase()
                        ]
                    }
                </h2>
                <h6
                    style={{
                        textAlign: "center",
                        color: inputColor ?? "white",
                        fontSize: "1.2rem",
                    }}
                >{`${categoryData.loyalty_points} points`}</h6>
            </div>
        </div>
    );
}
