import { useSnapshot } from "valtio";

import React from "react";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/PrmModeWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import { CreateAnAccount } from "../CreateAnAccount";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isHeaderActive,
        actionsButtonsItems,
        isTopBannerActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isCreateAnAccountSelected,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return isCreateAnAccountSelected ? (
        <CreateAnAccount />
    ) : (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper>
                    <PrmModeWrapper
                        isTopBannerActive={isTopBannerActive}
                        isHeaderActive={isHeaderActive}
                        isLanguagesActive={isLanguagesActive}
                        isConfigOptionsActive={isConfigOptionsActive}
                        isActionButtonsActive={isActionButtonsActive}
                    >
                        <LogoWrapper />

                        {isHeaderActive ? (
                            <div
                                style={{ display: "grid" }}
                                className="justify-content-center align-items-center"
                            >
                                {isInformationMessageActive ? (
                                    <div className="d-flex justify-content-center align-items-center text-center">
                                        <span style={{ fontSize: "55px" }}>
                                            {
                                                informationMessageItems[
                                                    customerLanguage.name
                                                ].content
                                            }
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <MainContentWrapper />

                        {isActionButtonsActive ? (
                            <PrmActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}

                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper />

                            {isConfigOptionsActive ? (
                                <ConfigOptionsWrapper />
                            ) : null}
                        </div>
                    </PrmModeWrapper>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
