import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function SalesModes(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeSalesModes = (
        localProject.template.content.salesMethods as ProjectContentItemType
    ).items;

    return (
        <MainContentWrapper
            items={activeSalesModes}
            contentName="salesMethods"
        />
    );
}
