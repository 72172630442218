import quantize from "@lokesh.dhakar/quantize";

const CanvasImage = function (image) {
    this.canvas = document.createElement("canvas");
    this.context = this.canvas.getContext("2d");
    this.width = this.canvas.width = image.naturalWidth;
    this.height = this.canvas.height = image.naturalHeight;
    this.context.drawImage(image, 0, 0, this.width, this.height);
};

CanvasImage.prototype.getImageData = function () {
    return this.context.getImageData(0, 0, this.width, this.height);
};

export function getColor(sourceImage, imgHeigth, imgWidth) {
    const pixelCount = imgHeigth * imgWidth;
    const image = new CanvasImage(sourceImage);
    const imageData = image.getImageData();

    const pixelArray = createPixelArray(imageData.data, pixelCount, 10);
    const cmap = quantize(pixelArray, 10);
    const palette = cmap ? cmap.palette() : null;

    if (palette === null) return [0, 0, 0];
    return getColorPlatte(palette);
}

function createPixelArray(imgData, pixelCount, quality) {
    const pixels = imgData;
    const pixelArray = [];

    for (let i = 0, offset, r, g, b, a; i < pixelCount; i = i + quality) {
        offset = i * 4;
        r = pixels[offset + 0];
        g = pixels[offset + 1];
        b = pixels[offset + 2];
        a = pixels[offset + 3];

        // If pixel is mostly opaque and not white
        if (typeof a === "undefined" || a >= 125) {
            if (!(r > 250 && g > 250 && b > 250)) {
                pixelArray.push([r, g, b]);
            }
        }
    }
    return pixelArray;
}

function getColorPlatte(palette) {
    let red = 0;
    let green = 0;
    let blue = 0;

    for (let i = 0; i < palette.length; i++) {
        red += palette[i][0];
        green += palette[i][1];
        blue += palette[i][2];
    }

    return [
        Math.trunc(red / 10),
        Math.trunc(green / 10),
        Math.trunc(blue / 10),
    ];
}

export function getColorFromButton(hexColor) {
    const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const result = hexRegex.exec(hexColor);

    if (!result) {
        return "#141414";
    }

    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16);

    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
    return luminance > 0.5 ? "#141414" : "#ffffff";
}

export function getGlobalColorValue(color) {
    const element = document.querySelector("head");
    const styles = window.getComputedStyle(element);
    return styles.getPropertyValue(color);
}
