import { BASIC_COMPOSITION_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";

export default function ProductCardPopup({ Item }) {
    let productComposition = [];
    let productBasicComposition = [];

    if (Item?.compositions?.[BASIC_COMPOSITION_TYPE]) {
        Object.keys(
            Item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions
        ).forEach((key) => {
            const itemBasicComposition = `${Item?.compositions?.[BASIC_COMPOSITION_TYPE].compositions[key].displayTitle} `;

            productBasicComposition.push(itemBasicComposition);
        });
    }

    if (Item?.compositions) {
        Object.keys(Item?.compositions).forEach((key) => {
            if (key !== BASIC_COMPOSITION_TYPE) {
                const itemComposition = ` ${Item?.compositions[key].displayTitle} `;

                productComposition.push(itemComposition);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="composed-product-element my-2 px-3">
                <div className="composed-product-info h-100">
                    <div className="product-info__clz">
                        <div
                            className="d-flex justify-content-center align-items-center h-100"
                            style={{
                                gap: "20px",
                            }}
                        >
                            {Item.quantity > 1 ? (
                                <span
                                    className="number-composed-product"
                                    style={{
                                        color: "var(--global-color)",
                                    }}
                                >
                                    {Item.quantity + "X"}
                                </span>
                            ) : null}

                            <img
                                className="image-composed-product"
                                src={Item.urlImage}
                                alt={Item.urlImage}
                            />
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "10px",
                                height: "100%",
                            }}
                        >
                            <div className="d-flex flex-column ">
                                <span className="product-title two-lines-preview-text">
                                    {Item.displayTitle !== undefined
                                        ? Item.displayTitle
                                        : ""}
                                </span>
                                <span className="summary-options-product">
                                    {` ${
                                        Item.selectedOptions
                                            ? Item.selectedOptions?.join(",")
                                            : ""
                                    }`}
                                </span>

                                <div className="d-flex flex-column">
                                    {productBasicComposition.length > 0 && (
                                        <span className="basic-composition my-2">
                                            {productBasicComposition.join(" ,")}
                                        </span>
                                    )}
                                    {productComposition.length > 0 && (
                                        <span className="composed-product-characteristic">
                                            {productComposition.join(" ,")}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

ProductCardPopup.propTypes = {
    Item: PropTypes.object,
    Editproduct: PropTypes.func,
    isSalesModeChange: PropTypes.bool,
    isPrmDialogCart: PropTypes.bool,
    isCommentProductActive: PropTypes.bool,
    isRemovedProductDialog: PropTypes.bool,
};
