import { motion } from "framer-motion/dist/framer-motion";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

// eslint-disable-next-line
export function AnimatesProducts({ children }) {
    const nextPreviousStep = useSelector(
        (state) => state.workFlowDataSlice.nextPreviousStep,
        shallowEqual
    );

    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    const animationDirection = nextPreviousStep === "next" ? "100vw" : "-100vw";

    const animationKey =
        nextPreviousStep + workflowData[workflowData.length - 1].index;

    return (
        <motion.div
            key={animationKey}
            initial={{ x: animationDirection, y: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: animationDirection, opacity: 0 }}
            transition={{ type: "spring", stiffness: 40 }}
        >
            {children}
        </motion.div>
    );
}
