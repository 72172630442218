import React from "react";

export default function MessageErrorComponent({
    messageError,
}: {
    messageError: string;
}): JSX.Element {
    return (
        <div
            style={{
                width: "100%",
                marginTop: "0.25rem",
                fontSize: "80%",
                color: "#f46a6a",
            }}
        >
            {messageError}
        </div>
    );
}
