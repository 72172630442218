import { BASIC_COMPOSITION } from "@constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    setIsInternalModification,
    setProductBeforeUpdate,
    setShowToast,
    steWorkflowStepIndex,
} from "@components/OrderTaking/Store/feature";

import { AddOrderBasicCompositionWorkflow } from "./hooks";
import { StepImage } from "./StepImage";

import "swiper/swiper-bundle.min.css";

export function WorkflowStepper({
    currentWorkflow,
    activestep,
    NextOnClickEvent,
    PreviousOnClickEvent,
}) {
    const swiperRef = React.useRef(null);
    const dispatch = useDispatch();
    const { isNextOrPreviousButtonsActive, courseProgressColor } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );
    const { globalcard } = useSelector((state) => state.orderSlice);
    const { workflowData } = useSelector((state) => state.workFlowDataSlice);
    document.documentElement.style.setProperty(
        "--progress-color",
        courseProgressColor
    );
    function handleStepperItemOnClickEvent(index) {
        dispatch(setProductBeforeUpdate({ ...orderWorkflowItemSlice.item }));
        if (index === activestep) return;

        let listworkflow = workflowData[workflowData.length - 1];
        if (
            index < activestep &&
            ((listworkflow?.workflow[activestep]?.NbrOfChoices >=
                listworkflow?.workflow[activestep]?.minNbrOfChoices &&
                orderWorkflowItemSlice.isModification === true) ||
                orderWorkflowItemSlice.isModification === false)
        ) {
            dispatch(setIsInternalModification(true));
            dispatch(steWorkflowStepIndex(index));
            return;
        }

        if (
            listworkflow?.workflow[activestep]?.NbrOfChoices <
            listworkflow?.workflow[activestep]?.minNbrOfChoices
        ) {
            const minNbrOfChoices =
                listworkflow?.workflow[listworkflow.index].minNbrOfChoices;
            const minProductToast = { minProduct: minNbrOfChoices };
            dispatch(setShowToast(minProductToast));
        }

        // ! check step required
        else if (
            listworkflow.workflow[activestep]?.NbrOfChoices >=
            listworkflow.workflow[activestep]?.minNbrOfChoices
        ) {
            if (index === activestep + 1) {
                dispatch(steWorkflowStepIndex(index));
            } else if (index > activestep + 1) {
                let data = [...listworkflow.workflow];
                data.length = index;

                const result = data.some(
                    (element) =>
                        element.minNbrOfChoices > 0 &&
                        element.NbrOfChoices < element.minNbrOfChoices
                );
                if (result === false) {
                    dispatch(steWorkflowStepIndex(index));
                }
            }
            // ! check step basic composition

            if (
                listworkflow.workflow[listworkflow.index].type ===
                BASIC_COMPOSITION
            ) {
                AddOrderBasicCompositionWorkflow(
                    workflowData,
                    dispatch,
                    globalcard
                );
            }
        }
    }

    const width =
        currentWorkflow?.length === 4
            ? "1025px"
            : currentWorkflow?.length > 4
            ? "920px"
            : "auto";

    document.documentElement.style.setProperty("--swiper-width", width);

    const [swiper, setSwiper] = React.useState(null);

    if (swiper) swiper.slideTo(activestep);

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "99%" }}
        >
            {isNextOrPreviousButtonsActive
                ? currentWorkflow?.length > 3 && (
                      <div
                          id="previousButton"
                          onClick={() => {
                              PreviousOnClickEvent();
                          }}
                          className="arrow-left top-button-position"
                      />
                  )
                : null}
            <Swiper
                onSwiper={setSwiper}
                slidesPerView={"auto"}
                slidesPerGroup={1}
                className={classNames("workflow-swiper-container", {
                    "workflow-swiper-container-items-fewer-than-four":
                        currentWorkflow?.length <= 4,
                })}
                ref={swiperRef}
                modules={[Navigation]}
                activestep={activestep}
            >
                {currentWorkflow?.map((items, index) => {
                    const item = {
                        ...items,
                        name: items.title,
                        isActive: index === activestep,
                        isCompleted:
                            (index === activestep &&
                                items.NbrOfChoices >= items.maxNbrOfChoices) ||
                            index < activestep
                                ? true
                                : false,
                    };

                    return (
                        <SwiperSlide
                            key={item.uuidStep}
                            className="workflow-swiper-slide"
                            onClick={() => {
                                handleStepperItemOnClickEvent(index);
                            }}
                        >
                            <div className="d-flex flex-column justify-content-start align-items-center step-image">
                                <StepImage
                                    stepImageUrl={item.image}
                                    isBasicComposition={
                                        item.type === BASIC_COMPOSITION
                                    }
                                />
                                <div
                                    className="d-flex align-items-center justify-content-center w-100 h-50"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <span
                                        className={
                                            workflowData.length > 1
                                                ? "workflow-step-title two-lines-preview-text"
                                                : "workflow-main-step-title two-lines-preview-text"
                                        }
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            </div>
                            {item.isActive === true &&
                            item.isCompleted === false ? (
                                <div className="d-flex align-items-center justify-content-center h-110">
                                    <div
                                        className={classNames({
                                            "active-line":
                                                index + 1 !==
                                                currentWorkflow?.length,
                                            none:
                                                index + 1 ===
                                                currentWorkflow?.length,
                                        })}
                                    />
                                    <div className="more-outer circle">
                                        <div className="d-flex flex-column justify-content-center align-items-center outer circle">
                                            <div className="d-flex flex-column justify-content-center  align-items-center step-item">
                                                <span>{index + 1}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : item.isActive === true &&
                              item.isCompleted === true ? (
                                <div className="d-flex align-items-center justify-content-center h-110">
                                    <div
                                        className={classNames({
                                            "completed-line":
                                                index + 1 !==
                                                currentWorkflow?.length,
                                            none:
                                                index + 1 ===
                                                currentWorkflow?.length,
                                        })}
                                    />
                                    <div className="more-outer circle">
                                        <div className="d-flex flex-column justify-content-center  align-items-center outer circle">
                                            <div className="d-flex flex-column justify-content-center  align-items-center step-item">
                                                <div className="inner-circle">
                                                    <span className="check" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : item.isActive === false &&
                              item.isCompleted === false ? (
                                <div className="d-flex align-items-center justify-content-center h-110">
                                    <div
                                        className={`step-item left-position non-active-step ${
                                            index === activestep + 1
                                                ? "tada-animation"
                                                : ""
                                        } step${index + 1}
                                    `}
                                    >
                                        <span>{index + 1}</span>
                                    </div>
                                </div>
                            ) : item.isActive === false &&
                              item.isCompleted === true ? (
                                <div className="d-flex align-items-center  justify-content-center h-110">
                                    <div
                                        className={classNames({
                                            "completed-line":
                                                index + 1 !==
                                                currentWorkflow?.length,
                                            none:
                                                index + 1 ===
                                                currentWorkflow?.length,
                                        })}
                                    />
                                    <div className="step-item left-position">
                                        <div className="inner-circle">
                                            <span className="check" />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            {isNextOrPreviousButtonsActive
                ? currentWorkflow?.length > 3 && (
                      <div
                          id="nextButton"
                          onClick={() => {
                              NextOnClickEvent();
                          }}
                          className="arrow-right top-button-position"
                      />
                  )
                : null}
        </div>
    );
}

WorkflowStepper.propTypes = {
    currentWorkflow: PropTypes.array,
    activestep: PropTypes.number,
    NextOnClickEvent: PropTypes.func,
    PreviousOnClickEvent: PropTypes.func,
};
