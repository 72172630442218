import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function OtherPaymentMethods(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="628"
            height="87"
            viewBox="0 0 628 87"
            {...props}
        >
            <g
                id="Groupe_66"
                data-name="Groupe 66"
                transform="translate(-484 4082)"
            >
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="628"
                    height="87"
                    rx="9"
                    transform="translate(484 -4082)"
                    fill="#141414"
                />
                <text
                    id="Autres_moyens_de_paiement"
                    data-name="Autres moyens de paiement"
                    transform="translate(799 -4024)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Other means of payment", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
