import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const No = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_77"
                data-name="Groupe 77"
                transform="translate(-2436 4082)"
            >
                <g
                    id="Tracé_11"
                    data-name="Tracé 11"
                    transform="translate(2436 -4082)"
                    fill="#fff"
                >
                    <path
                        d="M 341 86 L 9 86 C 4.588772773742676 86 1 82.41122436523438 1 78 L 1 9 C 1 4.588772773742676 4.588772773742676 1 9 1 L 341 1 C 345.4112243652344 1 349 4.588772773742676 349 9 L 349 78 C 349 82.41122436523438 345.4112243652344 86 341 86 Z"
                        stroke="none"
                    />
                    <path
                        d="M 9 2 C 5.140167236328125 2 2 5.140182495117188 2 9 L 2 78 C 2 81.85981750488281 5.140167236328125 85 9 85 L 341 85 C 344.8598327636719 85 348 81.85981750488281 348 78 L 348 9 C 348 5.140182495117188 344.8598327636719 2 341 2 L 9 2 M 9 0 L 341 0 C 345.9705505371094 0 350 4.029457092285156 350 9 L 350 78 C 350 82.97054290771484 345.9705505371094 87 341 87 L 9 87 C 4.029449462890625 87 0 82.97054290771484 0 78 L 0 9 C 0 4.029457092285156 4.029449462890625 0 9 0 Z"
                        stroke="none"
                        fill="#141414"
                    />
                </g>
                <text
                    id="Non"
                    transform="translate(2611 -4024)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("No", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};
