import styled from "styled-components";

import { PropsType } from "../UsualModeMainContentWrapper/ContainerWrapper";

export const PrmModeWrapper = styled.div`
    display: grid;
    height: 100%;

    grid-template-rows: ${({
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isTopBannerActive,
        isActionButtonsActive,
    }: PropsType) =>
        isLanguagesActive &&
        isHeaderActive &&
        isConfigOptionsActive &&
        isTopBannerActive &&
        isActionButtonsActive
            ? "220px 230px 306px 87px 75px"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 10fr 24.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "47.5fr 5.5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "10fr 32fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 40fr"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "10fr 37.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 34.5fr 5.5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 10fr 19fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "47.5fr 5.5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 10fr 24.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "42fr 5.5fr 5.5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "10fr 32fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 34.5fr 5.5fr"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "10fr 37.5fr 5.5fr"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 29fr 5.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 29fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "10fr 43fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 34.5fr  5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "10fr 37.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "42fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 10fr 30fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "47.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 10fr 24.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 29fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "10fr 37.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 34.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "10fr 32fr 5.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "42fr 5.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "13fr 10fr 24.5fr 5.5fr"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "47.5fr 5.5fr"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "13fr 10fr 19fr 5.5fr 5.5fr"
            : "auto"};
`;
