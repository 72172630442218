import classNames from "classnames";
import { changeLanguage } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore, setCustomerLanguage } from "@pages/Kiosk/store";

type propsType = {
    language: LanguagesItemType;
    index: number;
};
export function LanguagesWrapper({ language }: propsType): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);

    function syncCustomerLanguageWithAppLanguage(language: LanguagesItemType) {
        setCustomerLanguage(language);
        changeLanguage(language.name);
    }

    const imageContent = getImageContentById(language.id);

    const urlFlag = imageContent;

    return (
        <div
            className="d-flex flex-row"
            style={{
                placeSelf: "center",
                placeItems: "center",
                gap: "10px",
            }}
            onClick={() => {
                syncCustomerLanguageWithAppLanguage(language);
            }}
        >
            <img
                src={urlFlag}
                alt={language.title}
                style={{
                    height: "60px",
                    width: "90px",
                }}
            />
            <span
                className={classNames({
                    "customer-language-title__clz":
                        language.name === customerLanguage.name,
                })}
                style={{
                    width: "135px",
                    fontSize: "28px",
                }}
            >
                {language.title}
            </span>
        </div>
    );
}
