import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function AbandonArticle(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309 65" {...props}>
            <g
                id="Groupe_51"
                data-name="Groupe 51"
                transform="translate(2332 4006)"
            >
                <g
                    id="Groupe_2"
                    data-name="Groupe 2"
                    transform="translate(500 -3791)"
                >
                    <g
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        transform="translate(-2832 -215)"
                        fill="#fff"
                        stroke="#141414"
                        strokeWidth="2"
                    >
                        <rect
                            width={props.width}
                            height={props.height}
                            rx="9"
                            stroke="none"
                        />
                        <rect
                            x="1"
                            y="1"
                            width={parseInt(props.width as string) - 2}
                            height={parseInt(props.height as string) - 2}
                            rx="8"
                            fill="#fff"
                        />
                    </g>
                    <text
                        id="Abondonner_l_article"
                        data-name="Abondonner l’article"
                        transform="translate(-2677 -173)"
                        fill="#141414"
                        fontSize="29"
                        fontFamily="ArialMT, Arial"
                        textAnchor="middle"
                    >
                        {t("Abandon article", {
                            lng: customerLanguage.name,
                        })}
                    </text>
                </g>
            </g>
        </svg>
    );
}
AbandonArticle.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
