import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function FinalMessageFooterWrapper(): JSX.Element {
    const {
        project: {
            template: {
                pages: {
                    ways: { finalMessage },
                },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
                font: "normal 55px/20px Segoe UI",
                height: "300px",
            }}
        >
            {((finalMessage as PageType).footer as FooterType).finalMessage
                .active === true
                ? ((finalMessage as PageType).footer as FooterType).finalMessage
                      .languages[customerLanguage.name].content !== undefined
                    ? ((finalMessage as PageType).footer as FooterType)
                          .finalMessage.languages[customerLanguage.name].content
                    : null
                : null}
        </div>
    );
}
