import { FULL__BASKET_ICON_ROLE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { SalesMethodsItems } from "@components/OrderTaking/Components/Dialogs/OrderSummary/SalesMethodsItems";
import { SalesModesChangeConfirmationDialog } from "@components/OrderTaking/Components/Dialogs/OrderSummary/SalesModesChangeConfirmationDialog";

import { kioskStore, setIsOrderTakingPreviousStep } from "@pages/Kiosk/store";

import { useOutsideClick } from "./useOutsideClick";

export default function OrderTakingChangeSalesMode() {
    const { project, isPrm, selectedSaleModeValue, selectedSaleModeId } =
        useSnapshot(kioskStore);

    const [salesMethod, setSalesMethod] = React.useState();

    const [isOpen, setIsOpen] = React.useState(false);
    const [
        isConfirmedSalesMethodAlertOpened,
        setIsConfirmedSalesMethodAlertOpened,
    ] = React.useState(false);

    const { designItems } = useSelector((state) => state.settingSlice);

    const salesMethodItem = project.template.content["salesMethods"].items;
    const defaultSalesMode = salesMethodItem.filter((item) => {
        return item.name === selectedSaleModeValue;
    })[0];

    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });
    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(filledBasketIcon[0].id)
            : "";

    const activeItems = salesMethodItem.filter((item) => item.active === true);

    function handleOnCLickEvent(selectedSalesMode) {
        if (selectedSaleModeId !== selectedSalesMode.id) {
            setIsConfirmedSalesMethodAlertOpened(
                !isConfirmedSalesMethodAlertOpened
            );
        }
        setIsOrderTakingPreviousStep(true);
    }

    function handleClickOutside() {
        setIsOpen(false);
    }

    const ref = useOutsideClick(handleClickOutside);

    return activeItems.length > 1 ? (
        <>
            {isConfirmedSalesMethodAlertOpened && (
                <SalesModesChangeConfirmationDialog
                    open={isConfirmedSalesMethodAlertOpened}
                    onClose={() => {
                        setIsConfirmedSalesMethodAlertOpened(
                            !isConfirmedSalesMethodAlertOpened
                        );
                    }}
                    salesMode={salesMethod}
                    isPrm={isPrm}
                    filledBasketIconContent={filledBasketIconContent}
                />
            )}

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    borderRadius: "15px",
                    maxHeight: "84px",
                    maxWidth: "115px",
                    position: "relative",
                }}
                ref={ref}
            >
                <div
                    className="d-flex  flex-column"
                    style={{
                        width: "70px",
                    }}
                >
                    <div
                        className="sale-methods-dropdown__clz d-flex flex-column align-items-center"
                        style={{
                            gap: "5px",
                            border: isOpen ? "1px solid #eaeae9" : "",
                            backgroundColor: isOpen ? "#eaeae9" : "",
                            position: isOpen ? "absolute " : "",
                            bottom: !isOpen ? "0" : "-2.2rem",
                            width: "70px",
                            borderRadius: isOpen ? "10px" : "",
                            zIndex: 1000,
                            top: !isOpen ? "4px" : "unset",
                            maxHeight: "fit-content",
                        }}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        {isOpen &&
                            activeItems.map((item) => {
                                return item.name !== selectedSaleModeValue ? (
                                    <SalesMethodsItems
                                        salesMode={item}
                                        handleOnCLickEvent={handleOnCLickEvent}
                                        setSalesMethod={setSalesMethod}
                                        setIsOpen={setIsOpen}
                                        isOpen={isOpen}
                                    />
                                ) : null;
                            })}
                        <SalesMethodsItems
                            salesMode={defaultSalesMode}
                            handleOnCLickEvent={handleOnCLickEvent}
                            setSalesMethod={setSalesMethod}
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : null;
}
