import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import { PreviewTopBannerBackgroundWrapper } from "../PreviewTopBannerBackgroundWrapper";
import "./index.css";

export function LogoWrapper(): JSX.Element {
    const {
        isTopBannerActive,
        isLogoActive,
        isPrm,
        project: { template },
        navigationIndex,
        kioskWays,
    } = useSnapshot(kioskStore);

    const logoSrc = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string
    );

    return (
        <React.Fragment>
            {isTopBannerActive ? (
                <div className="d-flex justify-content-center align-items-center">
                    <PreviewTopBannerBackgroundWrapper>
                        {isLogoActive ? (
                            <img
                                src={logoSrc}
                                alt="logo"
                                className={classNames({
                                    "usual-mode-logo__clz": !isPrm,
                                    "prm-mode-logo__clz":
                                        isPrm &&
                                        kioskWays[navigationIndex].name !==
                                            "printerOptions",
                                    "email-prm-mode-logo__clz":
                                        isPrm &&
                                        kioskWays[navigationIndex].name ===
                                            "printerOptions",
                                })}
                            />
                        ) : (
                            <div></div>
                        )}
                    </PreviewTopBannerBackgroundWrapper>
                </div>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}
