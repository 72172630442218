import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "./ImageContent";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function MainContentWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
        saleMode,
    } = useSnapshot(kioskStore);

    const informationModesItems = (
        content.informationModes as ProjectContentItemType
    ).items;

    const activeInformationModesItems = informationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return (item.active as ItemContentType)[saleMode.name] === true;
        }
    );

    return (
        <React.Fragment>
            {activeInformationModesItems.length <= 3 ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        padding: "50px 0px 50px 0px",
                        gap: "30px",
                        height: "294px",
                    }}
                >
                    {activeInformationModesItems.map(
                        (item: ProjectMainContentItemType) => (
                            <ImageContent
                                item={item}
                                key={item.id}
                                activeInformationModesItemsLength={
                                    activeInformationModesItems.length
                                }
                            />
                        )
                    )}
                </div>
            ) : (
                <Swiper
                    className="swiper-pagination__clz"
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={20}
                    modules={[Pagination]}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {activeInformationModesItems.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeInformationModesItemsLength={
                                        activeInformationModesItems.length
                                    }
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}
