import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";

import { getCategoryTitleHeader } from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

export function CategoryTitle(): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);
    const title = getCategoryTitleHeader();
    return (
        <div
            className={classNames({
                "d-flex justify-content-center align-items-center mx-2 py-2":
                    !isPrm,
                "d-flex flex-column justify-content-center align-items-center w-100":
                    isPrm,
            })}
            style={{
                font: isPrm
                    ? " normal normal 600 30px/38px Segoe UI"
                    : "normal 600 40px/48px Segoe UI",
            }}
        >
            {title}
        </div>
    );
}
