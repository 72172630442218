import React from "react";

export function CartIconFilled() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68.436"
            height="68.434"
            viewBox="0 0 68.436 68.434"
        >
            <g
                id="basket-cart"
                transform="translate(0 -0.008)"
                style={{ position: "fixed" }}
            >
                <path
                    id="Tracé_20443"
                    data-name="Tracé 20443"
                    d="M73.089,209.992H12.673A2.673,2.673,0,0,0,10,212.665v3.475a2.673,2.673,0,0,0,2.673,2.673H73.089a2.673,2.673,0,0,0,2.673-2.673v-3.475A2.672,2.672,0,0,0,73.089,209.992Z"
                    transform="translate(-8.663 -181.916)"
                    fill="#f0e6de"
                />
                <path
                    id="Tracé_20444"
                    data-name="Tracé 20444"
                    d="M30,275.992l4.947,28.013a2.674,2.674,0,0,0,2.631,2.195H82.839a2.674,2.674,0,0,0,2.631-2.195l4.947-28.013Z"
                    transform="translate(-25.99 -239.095)"
                    fill={`var(--global-color)`}
                />
                <path
                    id="Tracé_20445"
                    data-name="Tracé 20445"
                    d="M304.56,132.665v8.02H284.28l10.693-10.693h6.913A2.673,2.673,0,0,1,304.56,132.665Z"
                    transform="translate(-246.282 -112.61)"
                    fill="#fed42d"
                />
                <path
                    id="Tracé_20446"
                    data-name="Tracé 20446"
                    d="M264.623,10.776a2.673,2.673,0,0,0-3.78,0l-24.059,24.06a2.673,2.673,0,1,0,3.78,3.78l24.06-24.06A2.673,2.673,0,0,0,264.623,10.776Z"
                    transform="translate(-204.456 -8.65)"
                    fill="#f0e6de"
                />
                <path
                    id="Tracé_20447"
                    data-name="Tracé 20447"
                    d="M189.467,82.153,184.8,86.815a2.666,2.666,0,0,0-.783,1.89h-.074A12.035,12.035,0,0,0,176,78.7h0V72.665a2.673,2.673,0,0,1,2.673-2.673h8.02a2.673,2.673,0,0,1,2.673,2.673v9.387Z"
                    transform="translate(-152.475 -60.629)"
                    fill="#4dbbeb"
                />
                <path
                    id="Tracé_20448"
                    data-name="Tracé 20448"
                    d="M80.462,140.685H56.55a12.031,12.031,0,0,1,23.912,0Z"
                    transform="translate(-48.991 -112.61)"
                    fill="#fd6244"
                />
                <path
                    id="Tracé_20449"
                    data-name="Tracé 20449"
                    d="M64.426,26.739H59.614V20.056a4.014,4.014,0,0,0-4.01-4.01H51.918l9.195-9.195a4.01,4.01,0,0,0-5.67-5.67L38.228,18.4V12.036a4.014,4.014,0,0,0-4.01-4.01H26.2a4.014,4.014,0,0,0-4.01,4.01v4.28A13.37,13.37,0,0,0,6.417,26.739H4.01A4.014,4.014,0,0,0,0,30.749v3.475a4.017,4.017,0,0,0,2.859,3.841L7.642,65.15a4.008,4.008,0,0,0,3.946,3.292H56.849a4.01,4.01,0,0,0,3.947-3.3l4.782-27.078a4.017,4.017,0,0,0,2.859-3.841V30.749A4.014,4.014,0,0,0,64.426,26.739ZM34.218,35.561A1.337,1.337,0,1,0,35.555,36.9,1.337,1.337,0,0,0,34.218,35.561ZM55.6,18.719a1.338,1.338,0,0,1,1.337,1.337v6.683H41.225l8.02-8.02ZM57.333,3.071a1.336,1.336,0,0,1,1.89,1.89L35.163,29.02a1.336,1.336,0,0,1-1.89-1.89ZM26.2,10.7h8.02a1.338,1.338,0,0,1,1.337,1.337V21.07l-3.562,3.56a13.459,13.459,0,0,0-7.132-7.471V12.036A1.338,1.338,0,0,1,26.2,10.7Zm-6.683,8.02a10.707,10.707,0,0,1,10.355,8.02H9.157A10.675,10.675,0,0,1,19.515,18.719ZM65.763,34.224a1.338,1.338,0,0,1-1.337,1.337H39.565a1.337,1.337,0,1,0,0,2.673H62.833L58.164,64.671a1.336,1.336,0,0,1-1.316,1.1H11.587a1.335,1.335,0,0,1-1.314-1.091L5.6,38.234H28.871a1.337,1.337,0,0,0,0-2.673H4.01a1.338,1.338,0,0,1-1.337-1.337V30.749A1.338,1.338,0,0,1,4.01,29.412H30.436a4.013,4.013,0,0,0,6.617,1.5l1.5-1.5H64.426a1.338,1.338,0,0,1,1.337,1.337Z"
                    transform="translate(0 0)"
                />
                <path
                    id="Tracé_20455"
                    data-name="Tracé 20455"
                    d="M264.623,10.776a2.673,2.673,0,0,0-3.78,0l-24.059,24.06a2.673,2.673,0,1,0,3.78,3.78l24.06-24.06A2.673,2.673,0,0,0,264.623,10.776Z"
                    transform="translate(-204.455 -8.65)"
                    fill="#f0e6de"
                />
                <g
                    id="Groupe_27288"
                    data-name="Groupe 27288"
                    transform="translate(-23.207 -1496.539)"
                >
                    <g
                        id="Groupe_27288-2"
                        data-name="Groupe 27288"
                        transform="translate(-12 -4)"
                    >
                        <path
                            id="Tracé_20457"
                            data-name="Tracé 20457"
                            d="M252.772,14.7a1.952,1.952,0,0,0-2.761,0l-9.944,9.944a1.953,1.953,0,0,0,2.761,2.761l9.944-9.944A1.952,1.952,0,0,0,252.772,14.7Z"
                            transform="translate(-135.944 1711.495) rotate(-45)"
                        />
                        <path
                            id="Tracé_20458"
                            data-name="Tracé 20458"
                            d="M248.021,10.322a1.122,1.122,0,0,0-1.587,0l-10.1,10.1a1.123,1.123,0,0,0,1.587,1.587l10.1-10.1A1.122,1.122,0,0,0,248.021,10.322Z"
                            transform="translate(-129.486 1711.924) rotate(-45)"
                            fill="#f0e6de"
                        />
                    </g>
                    <g
                        id="Groupe_27289"
                        data-name="Groupe 27289"
                        transform="translate(-4 -4)"
                    >
                        <path
                            id="Tracé_20457-2"
                            data-name="Tracé 20457"
                            d="M252.772,14.7a1.952,1.952,0,0,0-2.761,0l-9.944,9.944a1.953,1.953,0,0,0,2.761,2.761l9.944-9.944A1.952,1.952,0,0,0,252.772,14.7Z"
                            transform="translate(-135.944 1711.495) rotate(-45)"
                        />
                        <path
                            id="Tracé_20458-2"
                            data-name="Tracé 20458"
                            d="M248.021,10.322a1.122,1.122,0,0,0-1.587,0l-10.1,10.1a1.123,1.123,0,0,0,1.587,1.587l10.1-10.1A1.122,1.122,0,0,0,248.021,10.322Z"
                            transform="translate(-129.486 1711.924) rotate(-45)"
                            fill="#f0e6de"
                        />
                    </g>
                    <g
                        id="Groupe_27290"
                        data-name="Groupe 27290"
                        transform="translate(4 -4)"
                    >
                        <path
                            id="Tracé_20457-3"
                            data-name="Tracé 20457"
                            d="M252.772,14.7a1.952,1.952,0,0,0-2.761,0l-9.944,9.944a1.953,1.953,0,0,0,2.761,2.761l9.944-9.944A1.952,1.952,0,0,0,252.772,14.7Z"
                            transform="translate(-135.944 1711.495) rotate(-45)"
                        />
                        <path
                            id="Tracé_20458-3"
                            data-name="Tracé 20458"
                            d="M248.021,10.322a1.122,1.122,0,0,0-1.587,0l-10.1,10.1a1.123,1.123,0,0,0,1.587,1.587l10.1-10.1A1.122,1.122,0,0,0,248.021,10.322Z"
                            transform="translate(-129.486 1711.924) rotate(-45)"
                            fill="#f0e6de"
                        />
                    </g>
                    <g
                        id="Groupe_27291"
                        data-name="Groupe 27291"
                        transform="translate(12 -4)"
                    >
                        <path
                            id="Tracé_20457-4"
                            data-name="Tracé 20457"
                            d="M252.772,14.7a1.952,1.952,0,0,0-2.761,0l-9.944,9.944a1.953,1.953,0,0,0,2.761,2.761l9.944-9.944A1.952,1.952,0,0,0,252.772,14.7Z"
                            transform="translate(-135.944 1711.495) rotate(-45)"
                        />
                        <path
                            id="Tracé_20458-4"
                            data-name="Tracé 20458"
                            d="M248.021,10.322a1.122,1.122,0,0,0-1.587,0l-10.1,10.1a1.123,1.123,0,0,0,1.587,1.587l10.1-10.1A1.122,1.122,0,0,0,248.021,10.322Z"
                            transform="translate(-129.486 1711.924) rotate(-45)"
                            fill="#f0e6de"
                        />
                    </g>
                    <g
                        id="Groupe_27292"
                        data-name="Groupe 27292"
                        transform="translate(20 -4)"
                    >
                        <path
                            id="Tracé_20457-5"
                            data-name="Tracé 20457"
                            d="M252.772,14.7a1.952,1.952,0,0,0-2.761,0l-9.944,9.944a1.953,1.953,0,0,0,2.761,2.761l9.944-9.944A1.952,1.952,0,0,0,252.772,14.7Z"
                            transform="translate(-135.944 1711.495) rotate(-45)"
                        />
                        <path
                            id="Tracé_20458-5"
                            data-name="Tracé 20458"
                            d="M248.021,10.322a1.122,1.122,0,0,0-1.587,0l-10.1,10.1a1.123,1.123,0,0,0,1.587,1.587l10.1-10.1A1.122,1.122,0,0,0,248.021,10.322Z"
                            transform="translate(-129.486 1711.924) rotate(-45)"
                            fill="#f0e6de"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
