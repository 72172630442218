/* eslint-disable react/prop-types */
import React from "react";

export function Gifts(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            width="32.013"
            height="32.012"
            viewBox="0 0 32.013 32.012"
            {...props}
        >
            <g
                id="Groupe_32660"
                data-name="Groupe 32660"
                transform="translate(0 0)"
                fill={props.color || "black"}
            >
                <path
                    id="Tracé_22332"
                    data-name="Tracé 22332"
                    d="M14.766,10.928H10.3A4.78,4.78,0,0,1,5.638,7.1a4.839,4.839,0,0,1,.968-4.11,4.765,4.765,0,0,1,8.353,2.085l.908,4.467a1.191,1.191,0,0,1-.238.938,1.117,1.117,0,0,1-.864.447ZM10.3,3.483A2.546,2.546,0,0,0,7.812,6.461a2.591,2.591,0,0,0,2.5,2.233h3.1l-.64-3.172a2.546,2.546,0,0,0-2.487-2.04Z"
                    transform="translate(0.839 -1.251)"
                    fill={props.color || "black"}
                />
                <path
                    id="Tracé_22333"
                    data-name="Tracé 22333"
                    d="M17.094,10.915H12.627a1.117,1.117,0,0,1-.864-.4,1.191,1.191,0,0,1-.238-.938l.908-4.467a4.769,4.769,0,1,1,9.351,1.876A4.824,4.824,0,0,1,17.094,10.915ZM14,8.682h3.1a2.546,2.546,0,0,0,2.487-2.04,2.591,2.591,0,0,0-.506-2.248A2.531,2.531,0,0,0,14.608,5.51Z"
                    transform="translate(3.766 -1.238)"
                    fill={props.color || "black"}
                />
                <path
                    id="Tracé_22334"
                    data-name="Tracé 22334"
                    d="M16.323,30.818H10.367A1.132,1.132,0,0,1,9.25,29.7V7.367A1.132,1.132,0,0,1,10.367,6.25h5.956a1.132,1.132,0,0,1,1.117,1.117V29.7A1.132,1.132,0,0,1,16.323,30.818Zm-4.839-2.233h3.722V8.483H11.483Z"
                    transform="translate(2.662 1.194)"
                    fill={props.color || "black"}
                />
                <path
                    id="Tracé_22335"
                    data-name="Tracé 22335"
                    d="M27.69,28.862H5.356A2.621,2.621,0,0,1,2.75,26.257V11.367A1.132,1.132,0,0,1,3.867,10.25h9.678a1.117,1.117,0,1,1,0,2.233H4.983V26.257a.372.372,0,0,0,.372.372H27.69a.372.372,0,0,0,.372-.372V11.367a1.117,1.117,0,1,1,2.233,0v14.89A2.621,2.621,0,0,1,27.69,28.862Z"
                    transform="translate(-0.517 3.149)"
                    fill={props.color || "black"}
                />
                <path
                    id="Tracé_22336"
                    data-name="Tracé 22336"
                    d="M30.657,14.439H24.7a1.117,1.117,0,0,1,0-2.233h5.956a.372.372,0,0,0,.372-.372V8.856a.372.372,0,0,0-.372-.372H3.856a.372.372,0,0,0-.372.372v2.978a.372.372,0,0,0,.372.372H14.279a1.117,1.117,0,0,1,0,2.233H3.856A2.621,2.621,0,0,1,1.25,11.834V8.856A2.621,2.621,0,0,1,3.856,6.25h26.8a2.621,2.621,0,0,1,2.606,2.606v2.978a2.621,2.621,0,0,1-2.606,2.606Z"
                    transform="translate(-1.25 1.194)"
                    fill={props.color || "black"}
                />
            </g>
        </svg>
    );
}
