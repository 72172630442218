import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

type PropsType = {
    children: React.ReactNode;
    selectedSubStep?: ProjectMainContentItemType;
};

export function PreviewTopBannerBackgroundWrapper({
    children,
}: PropsType): JSX.Element {
    const {
        project: {
            template: {
                pages: { subSteps },
            },
        },
    } = useSnapshot(kioskStore);
    // eslint-disable-next-line
    const topBannerBackgroundImageSrc = (subSteps["accountFidelite"] as any)
        .topBanner.background;

    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];

    const extension =
        topBannerBackgroundImageSrc.split(".")[
            topBannerBackgroundImageSrc.split(".").length - 1
        ];

    return (
        <React.Fragment>
            {possibleExtensions.includes(extension) === true ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            height: "250px",
                            width: "100%",
                            objectFit: "cover",
                            position: "fixed",
                            zIndex: -1,
                        }}
                        src={topBannerBackgroundImageSrc}
                    />
                    {children}
                </React.Fragment>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: `url(${topBannerBackgroundImageSrc})`,
                        backgroundSize: "cover",
                        height: "250px",
                        width: "100%",
                        borderRadius: "2px",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
