import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function LoyaltyCardStep(): JSX.Element {
    const { isPrm } = useSnapshot(kioskStore);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
