import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const NextPDC = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="383"
            // height="438"
            viewBox="0 0 383 438"
            {...props}
        >
            <defs>
                <filter
                    id="Tracé_21541"
                    width="383"
                    height="438"
                    x="0"
                    y="0"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur
                        result="blur"
                        stdDeviation="3"
                    ></feGaussianBlur>
                    <feFlood floodOpacity="0.161"></feFlood>
                    <feComposite in2="blur" operator="in"></feComposite>
                    <feComposite in="SourceGraphic"></feComposite>
                </filter>
                <filter
                    id="Tracé_21138"
                    width="374"
                    height="429"
                    x="4.5"
                    y="2.5"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur
                        result="blur-2"
                        stdDeviation="1.5"
                    ></feGaussianBlur>
                    <feFlood floodOpacity="0.149"></feFlood>
                    <feComposite in2="blur-2" operator="in"></feComposite>
                    <feComposite in="SourceGraphic"></feComposite>
                </filter>
            </defs>
            <g data-name="Groupe 31496" transform="translate(13250 11361)">
                <g
                    filter="url(#Tracé_21541)"
                    transform="translate(-13250 -11361)"
                >
                    <g fill="#fff" data-name="Tracé 21541">
                        <path
                            d="M354 419.5H11c-5.79 0-10.5-4.54-10.5-10.12V10.62C.5 5.04 5.21.5 11 .5h343c5.79 0 10.5 4.54 10.5 10.12v398.76c0 5.58-4.71 10.12-10.5 10.12z"
                            transform="translate(9 6)"
                        ></path>
                        <path
                            fill="#d2d2d2"
                            d="M11 1C5.486 1 1 5.316 1 10.62v398.76c0 5.304 4.486 9.62 10 9.62h343c5.514 0 10-4.316 10-9.62V10.62C364 5.317 359.514 1 354 1H11m0-1h343c6.075 0 11 4.755 11 10.62v398.76c0 5.865-4.925 10.62-11 10.62H11c-6.075 0-11-4.755-11-10.62V10.62C0 4.756 4.925 0 11 0z"
                            transform="translate(9 6)"
                        ></path>
                    </g>
                </g>
                <path
                    fill="none"
                    d="M0 0h365v190.321a14.339 14.339 0 01-14.339 14.339H14.339A14.339 14.339 0 010 190.321z"
                    data-name="Tracé 21542"
                    opacity="0.1"
                    transform="translate(-13241 -11140.061)"
                ></path>
                <g
                    data-name="Groupe 30099"
                    transform="translate(-13820 -12490)"
                >
                    <g
                        data-name="Groupe 29361"
                        filter="url(#Tracé_21138)"
                        transform="translate(579 1135) translate(-9 -6)"
                    >
                        <path
                            fill="#fff"
                            d="M10 0h345c5.523 0 10 4.216 10 9.417v401.166c0 5.2-4.477 9.417-10 9.417H10c-5.523 0-10-4.216-10-9.417V9.417C0 4.216 4.477 0 10 0z"
                            data-name="Tracé 21138"
                            transform="translate(9 6)"
                        ></path>
                    </g>
                    <g
                        data-name="Groupe 29363"
                        transform="translate(638 1307.539)"
                    >
                        <text
                            fill="#100909"
                            fontFamily="SegoePrint, Segoe Print"
                            fontSize="45"
                            transform="translate(127 49)"
                            textAnchor="middle"
                        >
                            {t("N", {
                                lng: customerLanguage.name,
                            }).toUpperCase()}
                        </text>
                    </g>
                </g>
            </g>
        </svg>
    );
};
