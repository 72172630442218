import { t } from "i18next";

import React from "react";

import { StyleBadge } from "@components/common/StyledBadge";

export function StatusBadge({ status }: { status: string }): JSX.Element {
    return (
        <StyleBadge color={status === "online" ? "success" : "danger"}>
            {t(status === "online" ? "Online" : "Offline")}
        </StyleBadge>
    );
}
