import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    TCPOS,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog, DialogActions } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import {
    addItemCommandTcPos,
    modifyNestedObject,
} from "@components/OrderTaking/Helpers";
import {
    modifyOrder,
    setIsCommentKeyboardActive,
    setParentProduct,
    setWorkFlowItemComment,
} from "@components/OrderTaking/Store/feature";
import { DeleteButton } from "@components/SvgIcons";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import KeyboardWrapper from "./KeyboardWrapper";

const CommentList = [
    { id: 1, content: t("Comment") + "1" },
    { id: 2, content: t("Comment") + "2" },
    { id: 3, content: t("Comment") + "3" },
    { id: 4, content: t("Comment") + "4" },
    { id: 5, content: t("Comment") + "5" },
    { id: 6, content: t("Comment") + "6" },
];
export function CommentKeyboard({ setIsLocalKeyBoardOpened }) {
    const { posEditor } = useSnapshot(store);
    const dispatchStore = useDispatch();
    const { productToComment, isCommentKeyboardActive, parentProduct } =
        useSelector((state) => state.orderSlice, shallowEqual);
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    const keyboardRef = React.useRef(null);
    const [selectValue, setSelectedValue] = React.useState("");
    const [input, setInput] = React.useState(productToComment?.comment);

    const {
        project: {
            template: { content },
        },
        isPrm,
    } = useSnapshot(kioskStore);
    const isWorkflow = workflowData.length > 0;

    const filteredButtons = content.actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];

    const validateImageSrc = getImageContentById(filteredButtons.id);

    const backButton = content.actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    function handleBackOnclickEvent() {
        let newItem = {};

        selectValue.length !== 0
            ? (newItem = { ...productToComment, comment: selectValue })
            : (newItem = { ...productToComment, comment: input });
        !isWorkflow && setIsLocalKeyBoardOpened(false);
        dispatchStore(
            setIsCommentKeyboardActive({
                active: false,
                product:
                    workflowData[workflowData.length - 1]?.workflow[
                        workflowData[workflowData.length - 1].index
                    ].Items.length === 1
                        ? newItem
                        : {},
            })
        );
        if (Object.keys(parentProduct).length > 0) {
            dispatchStore(setParentProduct({}));
        }
    }

    function handleAddCommentOnClickEvent() {
        let newItem = {};

        selectValue.length !== 0
            ? (newItem = { ...productToComment, comment: selectValue })
            : (newItem = { ...productToComment, comment: input });

        if (isWorkflow) {
            dispatchStore(setWorkFlowItemComment({ ...newItem })),
                dispatchStore(
                    setIsCommentKeyboardActive({
                        active: false,
                        product:
                            workflowData[workflowData.length - 1]?.workflow[
                                workflowData[workflowData.length - 1].index
                            ].Items.length === 1
                                ? newItem
                                : {},
                    })
                );
        } else {
            let modifiedProduct = newItem;
            if (Object.keys(parentProduct).length > 0) {
                let path = [];
                newItem.path.map((item) => {
                    return (path = [...path, item.name]);
                });

                modifiedProduct = modifyNestedObject(
                    parentProduct,
                    newItem,
                    path.join(".")
                );

                dispatchStore(setParentProduct({}));
            }
            dispatchStore(modifyOrder(modifiedProduct));

            dispatchStore(setIsCommentKeyboardActive({ active: false }));
            if (TCPOS === posEditor) {
                modifiedProduct = { ...modifiedProduct, isModification: true };
                dispatchStore(addItemCommandTcPos(modifiedProduct));
            }
        }
    }

    function handleOnSelectEvent(event) {
        setSelectedValue(event.target.value);
    }
    function handleDeleteCommentOnClickEvent() {
        let newItem = {};

        selectValue.length !== 0
            ? (newItem = { ...productToComment, comment: "" })
            : (newItem = { ...productToComment, comment: "" });
        if (isWorkflow) {
            dispatchStore(setWorkFlowItemComment({ ...newItem })),
                dispatchStore(
                    setIsCommentKeyboardActive({
                        active: false,
                        product:
                            workflowData[workflowData.length - 1]?.workflow[
                                workflowData[workflowData.length - 1].index
                            ].Items.length === 1
                                ? newItem
                                : {},
                    })
                );
        } else {
            let modifiedProduct = newItem;
            if (Object.keys(parentProduct).length > 0) {
                let path = [];
                newItem.path.map((item) => {
                    return (path = [...path, item.name]);
                });

                modifiedProduct = modifyNestedObject(
                    parentProduct,
                    newItem,
                    path.join(".")
                );

                dispatchStore(setParentProduct({}));
            }
            dispatchStore(modifyOrder(modifiedProduct));

            dispatchStore(setIsCommentKeyboardActive({ active: false }));

            if (TCPOS === posEditor) {
                modifiedProduct = { ...modifiedProduct, isModification: true };

                dispatchStore(addItemCommandTcPos(modifiedProduct));
            }
        }
    }

    React.useEffect(() => {
        setInput(productToComment?.comment);
    }, [productToComment]);

    if (!isCommentKeyboardActive) {
        return null;
    } else {
        return (
            <Dialog
                className={`d-flex flex-column  dialog__clz justify-content-between align-items-center workflow-display w-100`}
                onClose={handleBackOnclickEvent}
                open={isCommentKeyboardActive}
                PaperProps={{
                    sx: {
                        display: "flex!important",
                        gap: "10px!important",
                        alignItems: "start!important",
                        justifyContent: "start!important",
                        paddingTop: "0px!important",
                        paddingBottom: "0px!important",
                        width: "100% !important",
                        height: "min-content!important",
                        maxWidth: "100vw!important",
                        position: "fixed!important",
                        zoom: `${
                            process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "50%"
                                : "100%"
                        }`,
                    },
                    style: {
                        boxShadow: "none",
                    },
                }}
            >
                <div
                    className={`d-flex flex-column bg-white justify-content-between align-items-center w-100 workflow-display`}
                    ref={keyboardRef}
                    id="comment-keyboard"
                    style={{ minHeight: "25vh" }}
                >
                    <div
                        className="d-flex flex-column justify-content-start align-items-center  w-100"
                        style={{ gap: "10px" }}
                    >
                        <div
                            className="d-flex  align-items-center px-3"
                            style={{
                                height: "5vw",
                                backgroundColor: "var(--global-color)",
                                width: "100%",
                                font: "normal normal 600 25px/33px Segoe UI",
                            }}
                            onClick={handleBackOnclickEvent}
                        >
                            {t("Comment")}
                        </div>
                        <div className="d-flex flex-column align-items-center w-100">
                            <KeyboardWrapper
                                input={input}
                                setInput={setInput}
                                handleAddCommentOnClickEvent={
                                    handleAddCommentOnClickEvent
                                }
                                setSelectedValue={setSelectedValue}
                                productToComment={productToComment}
                                CommentList={CommentList}
                                handleOnSelectEvent={handleOnSelectEvent}
                                isPrm={isPrm}
                            />
                        </div>
                    </div>
                    <DialogActions
                        className={`d-flex justify-content-center align-items-end w-100`}
                    >
                        {/* <img
                            className="mx-2 "
                            alt="backImageSrc"
                            src={backImageSrc}
                            onClick={handleBackOnclickEvent}
                            style={{ height: "70px", width: "290px" }}
                        /> */}

                        <div
                            onClick={() => {
                                handleBackOnclickEvent();
                            }}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                backImageSrc.substr(-4)
                            ) ? (
                                <img
                                    src={backImageSrc}
                                    alt="backImageSrc"
                                    style={{ height: "87px", width: "350px" }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={backImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}
                        </div>

                        {productToComment.comment !== "" && (
                            <DeleteButton
                                width={"350px"}
                                height={"87px"}
                                onClick={handleDeleteCommentOnClickEvent}
                            />
                        )}

                        <div
                            onClick={() => {
                                handleAddCommentOnClickEvent();
                            }}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                validateImageSrc.substr(-4)
                            ) ? (
                                <img
                                    src={validateImageSrc}
                                    alt="validateImage"
                                    style={{
                                        height: "87px",
                                        width: "350px",
                                    }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={validateImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

CommentKeyboard.propTypes = {
    setIsLocalKeyBoardOpened: PropTypes.func,
};
