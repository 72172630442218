import React from "react";

export default function useIsInViewport(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: React.MutableRefObject<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visibleItems: any[]
): boolean {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting)
            ),
        []
    );

    React.useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, visibleItems, observer]);

    return isIntersecting;
}
