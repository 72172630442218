/* eslint-disable @typescript-eslint/no-explicit-any */
import { IP_ADDRESS_REGEX } from "@constants";

import React from "react";
import "react-simple-keyboard/build/css/index.css";

import { setIsMiddlewareApiUrlExists, setMiddlewareApiUrl } from "@store";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import KioskKeyboard from "@components/common/KioskKeyboard";

import {
    setIsKeyboardOpened,
    setKeyboardInputsValues,
} from "@pages/Kiosk/store";

import "../UsualKeyboardWrapper/index.css";

export function KeyboardWrapper({
    isInputValueInvalid,
    setIsInputValueInvalid,
    setIsFieldRequired,
}: {
    isInputValueInvalid: boolean;
    setIsInputValueInvalid: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFieldRequired: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const keyboard = React.useRef<React.MutableRefObject<any>>();
    const [inputValue, setInputValue] = React.useState<string>("");

    const onChangeAll = (inputs: any) => {
        setIsInputValueInvalid(false);
        setInputValue(inputs["default"]);
        setKeyboardInputsValues({ idAddpress: inputs["default"] });
    };

    return (
        <FlexboxGrid
            className="w-100"
            alignItemsCentered={true}
            justifyContentCentered={true}
        >
            <KioskKeyboard
                enterClick={() => {
                    if (
                        IP_ADDRESS_REGEX.test(inputValue) === true &&
                        isInputValueInvalid === false
                    ) {
                        if (inputValue !== undefined) {
                            if (inputValue.length > 0) {
                                setMiddlewareApiUrl(inputValue);
                            }
                            setIsMiddlewareApiUrlExists(true);
                            setIsKeyboardOpened(false);
                        }
                    }
                    if (
                        IP_ADDRESS_REGEX.test(inputValue) === false &&
                        isInputValueInvalid === false
                    ) {
                        setIsInputValueInvalid(!isInputValueInvalid);
                    }
                    if (isInputValueInvalid === true) {
                        return;
                    }
                }}
                setIsFieldRequired={setIsFieldRequired}
                keyboard={keyboard}
                onChangeAll={onChangeAll}
                theme={"keyboard__clz hg-theme-default"}
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
            />
        </FlexboxGrid>
    );
}
