import classNames from "classnames";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { isLoadingProduct } from "@components/OrderTaking/Helpers";
import {
    memoizedActiveCategory,
    memoizedCategories,
    setActiveCategory,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./categories.css";
import { CategoryCardMemoised } from "./CategoryCard.jsx";

import "swiper/swiper-bundle.min.css";

export function CategoriesSwiperWrapper() {
    const dispatchStore = useDispatch();
    const { nestedCategories } = useSelector((state) => state.orderSlice);
    const globalcategorys = useSelector((state) => memoizedCategories(state));
    const { isPrm, selectedSaleModeOrderTaking } = useSnapshot(kioskStore);
    const { isShopCartDetailsActive, isCategoriesScrollActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );
    const activeCategory = useSelector((state) =>
        memoizedActiveCategory(state)
    );
    const { isOrderTakingTopBannerActive } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { designCategories } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const listVisibleCategories = globalcategorys.filter(
        (category) =>
            category?.visibilityInfo.isVisible &&
            (category?.KioskVisibility[selectedSaleModeOrderTaking] ||
                category?.KioskVisibility[selectedSaleModeOrderTaking] ===
                    undefined)
    );

    const initialActiveCategory = listVisibleCategories.findIndex(
        (category) => category.iuud === activeCategory
    );
    const categoryIsExist = listVisibleCategories.some(
        (cat) => cat.iuud === activeCategory
    );

    if (!categoryIsExist && nestedCategories.length === 0) {
        dispatchStore(setActiveCategory(listVisibleCategories[0].iuud));
    }

    const handleCategoryClick = (category) => {
        toast.dismiss();

        dispatchStore(setActiveCategory(category.iuud));
        isLoadingProduct();
    };

    const isAuto = typeof designCategories === "string" ? true : false;
    const swiperHeight = isOrderTakingTopBannerActive ? 1150 : 1350;
    const nbCat =
        isAuto === true
            ? isOrderTakingTopBannerActive === true
                ? 13
                : 15
            : designCategories;
    const maxHeight = isPrm
        ? 150
        : designCategories < 9
        ? Math.round(swiperHeight / nbCat - nbCat) + 1
        : Math.round(swiperHeight / nbCat - nbCat);

    function setCategoryHeight(maxHeight) {
        document.documentElement.style.setProperty(
            "--max-height",
            `${maxHeight}px`
        );
    }
    const isSwiperLoopActive =
        !isCategoriesScrollActive && !isPrm
            ? false
            : isPrm ||
              listVisibleCategories.length > nbCat ||
              (isShopCartDetailsActive === true && itemsCount > 0)
            ? true
            : false;

    React.useEffect(() => {
        setCategoryHeight(maxHeight);
    }, [maxHeight]);

    return listVisibleCategories.length ===
        1 ? null : listVisibleCategories.length > 8 ||
      (isPrm && listVisibleCategories.length > 2) ? (
        <Swiper
            className="category-swiper__clz"
            spaceBetween={
                isPrm || (isAuto && isOrderTakingTopBannerActive === false)
                    ? 10
                    : undefined
            }
            speed={500}
            slidesPerGroup={
                isPrm || listVisibleCategories.length > nbCat
                    ? undefined
                    : nbCat
            }
            effect={"slide"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={isPrm ? 3 : nbCat} //3
            loop={isSwiperLoopActive}
            centeredSlidesBounds={true}
            simulateTouch={true}
            touchAngle={1000}
            longSwipes={true}
            direction="vertical"
            initialSlide={isPrm ? nbCat / 2 : initialActiveCategory}
            modules={[FreeMode]}
            loopedSlides={
                listVisibleCategories.length < 20
                    ? listVisibleCategories.length
                    : 5
            }
            watchSlidesProgress={true}
            freeMode={true}
            touchRatio={0.4} // Adjust touch sensitivity (lower values make it more sensitive)
        >
            {listVisibleCategories.map((category, index) => {
                return (
                    <SwiperSlide
                        className={classNames(
                            "d-flex col category-swiper-slide__clz  justify-content-center align-items-center",
                            {
                                "my-4": nbCat <= 7,
                            },
                            {
                                "my-3": nbCat > 7,
                            }
                        )}
                        key={category.iuud}
                        virtualIndex={index}
                        onClick={() => {
                            handleCategoryClick(category);
                        }}
                    >
                        <CategoryCardMemoised category={category} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    ) : (
        <div
            className="d-flex flex-column col justify-content-center align-items-center h-100"
            style={{
                maxWidth: "200px",
                gap: "1vh",
            }}
        >
            {listVisibleCategories.map((category) => {
                return (
                    <div
                        className={classNames({
                            "d-flex col category-swiper-slide__clz  justify-content-center align-items-center":
                                listVisibleCategories.length === 8 &&
                                isShopCartDetailsActive === true &&
                                itemsCount > 0,
                        })}
                        key={category.iuud}
                        onClick={() => {
                            handleCategoryClick(category);
                        }}
                    >
                        <CategoryCardMemoised category={category} />
                    </div>
                );
            })}
        </div>
    );
}
export const CategoriesSwiperWrapperMemoised = React.memo(
    CategoriesSwiperWrapper
);
