export function defaultOptionProduct(data) {
    return data.reduce((prev, curr) => {
        const defaultOption = curr?.listOptions.filter((opt) => opt.isFavorite);
        let optiondata = "";
        if (defaultOption.length > 0) {
            optiondata = defaultOption[0].iuud;
        } else if (curr.listOptions.length > 0) {
            optiondata = curr.listOptions[0].iuud;
        }

        if (optiondata === "") {
            return [...prev];
        }

        return [...prev, curr.iuud, optiondata];
    }, []);
}
