import { produce } from "immer";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
    SelectProduct,
    setNextWorkflowStep,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature";

function checkWorkflow(currentWorkflow) {
    const dispatch = useDispatch();
    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );

    if (currentWorkflow.step > currentWorkflow.index) {
        dispatch(setNextWorkflowStep());
    } else {
        //!new logic
        const copyworkflowData = produce(workflowData, (draftState) => {
            draftState.splice(draftState.length - 1, 1);
            let index = draftState.length;
            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = draftState[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    dispatch(setWorkflowData(draftState));

                    return;
                } else if (workflow.step > workflow.index) {
                    dispatch(setNextWorkflowStep());
                    return;
                } else {
                    index -= 1;
                    draftState.splice(workflowIndex, 1);
                }
            }
        });

        // ** ici on efface workflow
        if (copyworkflowData.length === 0) {
            dispatch(setWorkflowData([]));
            dispatch(SelectProduct({}));
        }
    }
}

export default checkWorkflow;
