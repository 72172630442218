import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export function EuroIcon(props) {
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    return (
        <svg
            data-name="Groupe 30603"
            xmlns="http://www.w3.org/2000/svg"
            width={53}
            height={53}
            {...props}
        >
            <g data-name="Groupe 8424">
                <circle
                    data-name="Ellipse 197"
                    cx={26.5}
                    cy={26.5}
                    r={26.5}
                    fill={globalColor}
                />
            </g>
            <g data-name="Groupe 21648">
                <path
                    data-name="Trac\xE9 19055"
                    d="M36.422 38.567a13.012 13.012 0 0 1-16.533-6.982l-.134-.348H32.97a.851.851 0 1 0 0-1.7H19.278a11.783 11.783 0 0 1-.089-4.077h15.212a.851.851 0 1 0 0-1.7H19.589c2.032-6.42 9.169-10.061 15.939-8.136.3.085.6.178.9.289a.917.917 0 0 0 1.161-.484.842.842 0 0 0-.51-1.1h-.009c-7.512-2.744-15.946.815-18.843 7.951a12.551 12.551 0 0 0-.5 1.478h-3.538a.851.851 0 1 0 0 1.7h3.193a13.549 13.549 0 0 0 .08 4.077h-3.273a.851.851 0 1 0 0 1.7h3.694c2.334 7.322 10.472 11.459 18.173 9.25.34-.1.68-.212 1.011-.331a.837.837 0 0 0 .51-1.1.911.911 0 0 0-1.155-.487Z"
                    fill="#fff"
                    stroke="white"
                />
            </g>
        </svg>
    );
}
