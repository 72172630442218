import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE, DEFAULT_PASSCODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Button from "react-bootstrap-button-loader";
import Modal from "react-modal";

import { store } from "@store";

import {
    handleLedControl,
    handleLoginLocalSettingsButtonOnClickEvent,
    handleLoginSynchronizationButtonOnClickEvent,
} from "@helpers/general";

import {
    setIsSyncLocalModalOpened,
    setLoginKeyboardInputsValues,
    setSelectedShopApp,
    syncStore,
} from "@components/Synchronization/store";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import {
    kioskStore,
    setIsAuthenticationModalOpened,
    setIsKeyBoardOpened,
    setIsLocalConfigModalOpened,
    setLocalProject,
    setNumericKeyboardInputValue,
} from "@pages/Kiosk/store";

import { version } from "../../../../package.json";
import { ErrorLogInModalWrapper } from "../ErrorLogInModalWrapper";
import { LocalSettingsHeader } from "../LocalSettingsHeader";
import { UsualKeyboardWrapper } from "../UsualKeyboardWrapper";
import AuthenticatedUserList from "./AuthenticatedUserList";

type inputsType = {
    email: string;
    password: string;
};

export function AuthenticationModalWrapper(): JSX.Element {
    const {
        isAuthenticationModalOpened,
        connectedLedPort,
        isLedActive,
        numericKeyboardInputValue,
        project,
        isKeyBoardOpened,
    } = useSnapshot(kioskStore);
    const { loginKeyboardInputsValues } = useSnapshot(syncStore);
    const { isAppSynchronized } = useSnapshot(store);
    // eslint-disable-next-line
    const keyboard = React.useRef<any>();
    const [isErrorLogInModalWrapperOpened, setIsErrorLogInModalWrapperOpened] =
        React.useState<boolean>(false);
    const [loginButton, setLoginButton] = React.useState<string>("Login");

    const [isUserListModelOpen, setIsUserListModelOpen] =
        React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<string | null>(null);
    const [isAccessFromUserList, setIsAccessFromUserList] =
        React.useState<boolean>(false);
    const [isCleared, setIsCleared] = React.useState<boolean>(false);
    const { selectedShopApp } = useSnapshot(syncStore);

    const [inputs, setInputs] = React.useState<inputsType>({
        email: "",
        password: "",
    });

    const [displayedInputs, setDisplayedInputs] = React.useState<inputsType>({
        email: "",
        password: "",
    });

    const encodedEmail = encodeURIComponent(loginKeyboardInputsValues["email"]);
    const encodedPassword = encodeURIComponent(
        loginKeyboardInputsValues["password"]
    );
    const disableConnectButton = areAllValuesFilled(loginKeyboardInputsValues);
    const authenticationTitle =
        !isKeyBoardOpened && isAppSynchronized
            ? t("Access to local settings")
            : t("Connection");
    const loginBtn =
        !isKeyBoardOpened && isAppSynchronized
            ? t("Email authentication")
            : t(loginButton);
    async function handleOnClickLoginEvent() {
        if (isKeyBoardOpened === false && isAppSynchronized) {
            setIsKeyBoardOpened(true);
        } else {
            if (loginButton === "Login") {
                setLoginButton("Connecting");
                if (isAppSynchronized) {
                    await handleLoginLocalSettingsButtonOnClickEvent(
                        encodedEmail,
                        encodedPassword,
                        setIsErrorLogInModalWrapperOpened,
                        setLoginButton
                    );
                    setIsAccessFromUserList(false);
                    setInputs({
                        email: inputs.email,
                        password: "",
                    });
                    setDisplayedInputs({
                        email: `${inputs.email.substring(0, 3)}${inputs.email
                            .substring(3)
                            .replaceAll(/[a-zA-Z0-9._%+-]/g, "*")}`,
                        password: "",
                    });
                } else {
                    await handleLoginSynchronizationButtonOnClickEvent(
                        encodedEmail,
                        encodedPassword,
                        setIsErrorLogInModalWrapperOpened,
                        setLoginButton
                    );
                    setInputs({
                        email: "",
                        password: "",
                    });
                }
            } else {
                return;
            }
        }
        setNumericKeyboardInputValue("");
        setSelectedUser(null);
    }

    const handleUserSelectionValidation = () => {
        if (selectedUser !== null) {
            setIsUserListModelOpen(false);

            setInputs({
                ...inputs,
                email: selectedUser as string,
            });
            setDisplayedInputs({
                ...inputs,
                email: `${(selectedUser as string).substring(0, 3)}${(
                    selectedUser as string
                )
                    .substring(3)
                    .replaceAll(/[a-zA-Z0-9._%+-]/g, "*")}`,
            });
            setLoginKeyboardInputsValues({
                ...inputs,
                email: selectedUser as string,
            });

            setIsAccessFromUserList(true);
        }
        setIsCleared(false);
    };

    const handleonClickBack = () => {
        if (isKeyBoardOpened === true) {
            setIsKeyBoardOpened(false);
        } else {
            setIsAccessFromUserList(false);
            if (!isAppSynchronized) {
                setIsSyncLocalModalOpened(true);
            }
            setIsAuthenticationModalOpened(false);
            setLoginKeyboardInputsValues({
                email: "",
                password: "",
            });
            setInputs({ email: "", password: "" });
            setDisplayedInputs({ email: "", password: "" });
            setIsAccessFromUserList(false);
        }
        setNumericKeyboardInputValue("");
        setSelectedUser(null);
    };

    function areAllValuesFilled(obj: { [x: string]: string }): boolean {
        for (const key in obj) {
            if (obj[key].trim() === "" && key !== "") {
                return true;
            }
        }
        return false;
    }

    React.useEffect(() => {
        if (isLedActive && isAuthenticationModalOpened) {
            handleLedControl(connectedLedPort, "Orange");
        }
        setLoginButton("Login");
        setDisplayedInputs({ email: "", password: "" });
    }, [isAuthenticationModalOpened]);

    return (
        <>
            <ErrorLogInModalWrapper
                setIsErrorLogInModalWrapperOpened={
                    setIsErrorLogInModalWrapperOpened
                }
                isErrorLogInModalWrapperOpened={isErrorLogInModalWrapperOpened}
            />
            {isUserListModelOpen ? (
                <AuthenticatedUserList
                    isUserListModelOpen={isUserListModelOpen}
                    setIsUserListModelOpen={setIsUserListModelOpen}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    handleUserSelectionValidation={
                        handleUserSelectionValidation
                    }
                    keyboard={keyboard}
                />
            ) : null}
            <Modal
                isOpen={isAuthenticationModalOpened}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 2,
                    },
                    content: {
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px",
                        color: "black",
                        height: "1366px",
                        width: "914px",
                        zIndex: 2,
                        backgroundColor: "#FFFFFF",
                        filter: isErrorLogInModalWrapperOpened
                            ? "blur(5px)"
                            : "none",
                        borderLeft: "1px solid #707070",
                        borderRight: "1px solid #707070",
                        borderBottom: "1px solid #707070",
                        borderTop: "15px",
                        bottom: "unset",
                        top: "unset",
                        right: "unset",
                        left: "unset",
                        zoom: `${
                            process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "50%"
                                : "100%"
                        }`,
                        overflowX: "hidden",
                    },
                }}
            >
                <LocalSettingsHeader />
                <div
                    style={{
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "2fr 1fr 5fr 2fr",
                        height: "1188px",
                        display: "grid",
                    }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            height: 206,
                            position: "relative",
                        }}
                    >
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            style={{
                                position: "fixed",
                                height: "206px",
                            }}
                        >
                            <source
                                src="./images/profile.webm"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <span className="connect-span-text">
                            {authenticationTitle}
                        </span>
                    </div>
                    {!isKeyBoardOpened && isAppSynchronized ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <UsualNumericKeyboardWrapper />
                            <Button
                                rounded={true}
                                className="text-uppercase"
                                style={{
                                    width: "137px",
                                    height: "61px",
                                    background: "#545454",
                                    boxShadow: "0px 3px 6px #00000029",
                                    border: "1px solid #FFFFFF",
                                    borderRadius: "12px",
                                    letterSpacing: "0px",
                                    font: "normal normal 600 18px/22px Segoe UI",
                                }}
                                onClick={() => {
                                    if (
                                        numericKeyboardInputValue ===
                                            selectedShopApp?.passcode ||
                                        (selectedShopApp.passcode ===
                                            undefined &&
                                            numericKeyboardInputValue ===
                                                DEFAULT_PASSCODE)
                                    ) {
                                        if (
                                            selectedShopApp.passcode ===
                                            undefined
                                        ) {
                                            setSelectedShopApp({
                                                ...selectedShopApp,
                                                passcode: DEFAULT_PASSCODE,
                                            });
                                        }
                                        setLocalProject(project);
                                        setIsAuthenticationModalOpened(false);
                                        setIsLocalConfigModalOpened(true);
                                    } else {
                                        setIsErrorLogInModalWrapperOpened(true);
                                    }
                                }}
                            >
                                {t("Validate")}
                            </Button>
                        </div>
                    ) : (
                        <UsualKeyboardWrapper
                            setIsErrorLogInModalWrapperOpened={
                                setIsErrorLogInModalWrapperOpened
                            }
                            setLoginButton={setLoginButton}
                            setIsUserListModelOpen={setIsUserListModelOpen}
                            inputs={inputs}
                            setInputs={setInputs}
                            displayedInputs={displayedInputs}
                            setDisplayedInputs={setDisplayedInputs}
                            isAccessFromUserList={isAccessFromUserList}
                            handleOnClickLoginEvent={handleOnClickLoginEvent}
                            keyboard={keyboard}
                            isCleared={isCleared}
                            setIsCleared={setIsCleared}
                            selectedUser={selectedUser}
                        />
                    )}
                    <div className="d-flex align-items-center justify-content-center back-btn-container">
                        <StyledButton
                            rounded={true}
                            className="m-2 text-uppercase"
                            style={{
                                width: "255px",
                                height: "61px",
                                background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #C9C9C9",
                                borderRadius: "12px",
                                color: "black",
                                letterSpacing: "0px",
                                font: "normal normal 600 18px/22px Segoe UI",
                            }}
                            onClick={handleonClickBack}
                        >
                            {t("Back")}
                        </StyledButton>
                        <Button
                            rounded={true}
                            className="m-2 text-uppercase"
                            disabled={
                                isKeyBoardOpened === false
                                    ? false
                                    : disableConnectButton
                            }
                            loading={loginButton === "Connecting"}
                            style={{
                                width: "386px",
                                height: "61px",
                                background: "#545454",
                                boxShadow: "0px 3px 6px #00000029",
                                border: "1px solid #FFFFFF",
                                borderRadius: "12px",
                                letterSpacing: "0px",
                                font: "normal normal 600 18px/22px Segoe UI",
                            }}
                            onClick={handleOnClickLoginEvent}
                        >
                            {loginBtn}
                        </Button>
                    </div>
                    <p className="version-text">{`Version ${version}`}</p>
                </div>
            </Modal>
        </>
    );
}
