import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";

import { kioskStore } from "@pages/Kiosk/store";

export default function WithdrawalCasMachine(): JSX.Element {
    const { t } = useTranslation();
    const { renderAmount, devise } = useSnapshot(kioskStore);

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center w-80">
                <img src="./images/changeMachine.png" alt="Change machine" />
                <div className="d-flex justify-content-center align-items-center w-100 text-container text-title">
                    <span>{t("Cash machine payment")}</span>
                </div>
                <div className="text-description text-center">
                    <span>{t("Please collect your banknote(s)")}</span>
                </div>
            </div>
            <Card
                style={{
                    width: "50rem",
                    height: "10rem",
                    borderRadius: "31px",
                    borderBottom: "12px solid #D51B1B",
                    boxShadow: "0px 3px 6px #00000029",
                    font: "Segoe UI",
                    display: "grid",
                    gridTemplateColumns: "25% 50% 25%",
                    alignItems: "center",
                    justifyItems: "end",
                }}
            >
                <div className="card-container-text">{t("Change")}</div>
                <div />
                <div className="card-container-text">
                    {t(`${renderAmount} ${devise.deviseSymbole}`)}
                </div>
            </Card>
        </>
    );
}
