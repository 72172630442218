import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { useSalesModeModification } from "@hooks/useSalesModeModification";

import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { OrderTakingBackgroundWrapper } from "@components/OrderTaking/common/OrderTakingBackgroundWrapper";
import { CategoriesSwiperWrapper } from "@components/OrderTaking/Components/Categories/CategoriesSwiperWrapper";
import OrderTakingTopBanner from "@components/OrderTaking/Components/OrderTakingTopBanner";
import PrmBasket from "@components/OrderTaking/Components/Prm/PrmBasket";
import ProductWrapper from "@components/OrderTaking/Components/Products/ProductWrapper";
import { TagsSwiperWrapper } from "@components/OrderTaking/Components/Tags/TagsSwiperWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import CategoriesBreadCrumb from "./CategoriesBreadCrumb";
import { CategoryTitle } from "./categoryTitle";
import "./prm-mode.css";

import "swiper/swiper.min.css";

export default function PrmOrderTakingScreen() {
    const {
        user,
        customerLanguage,
        isPrm,
        isUserAuthenticated,
        project: { files: images },
    } = useSnapshot(kioskStore);
    useSalesModeModification();

    const { isProductBreadcrumbTrailActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    const { isSelectedCategoryActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );
    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );
    const {
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        isOrderTakingUserAccountActive,
        userAccountMessage,
        isBackgroundActive,
    } = useSelector((state) => state.settingSlice.generalSetting, shallowEqual);

    const backgroundImage = Object.values(images).find((image) => {
        return image.key === "background" && image.name === "orderTaking";
    });
    const imageSrc = getImageContentById(backgroundImage?.id);

    return (
        <section>
            <OrderTakingBackgroundWrapper
                filename={imageSrc}
                isBackgroundActive={isBackgroundActive}
                isPrm={isPrm}
            >
                <div className="order-taking-prm-container order-taking-prm-layout">
                    <header className="order-taking-prm-header">
                        <div className="d-flex flex-column h-100">
                            <PrmBackgroundWrapper />
                            {isOrderTakingTopBannerActive ? (
                                <OrderTakingTopBanner />
                            ) : null}

                            {isOrderTakingUserAccountActive &&
                            isUserAuthenticated ? (
                                <div className="d-flex flex-column ">
                                    <div
                                        className="prm-authenticated-user-font"
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        {
                                            userAccountMessage.languages[
                                                customerLanguage.name
                                            ].content
                                        }{" "}
                                        {"   "}
                                        <span className="font-weight-bold">
                                            {user.firstName === ""
                                                ? user.lastName
                                                : user.firstName}
                                        </span>{" "}
                                    </div>
                                    <hr className="separation-line mx-1" />
                                </div>
                            ) : null}
                        </div>
                    </header>

                    <nav id="prm-categories" className="border-right">
                        <CategoriesSwiperWrapper />
                    </nav>

                    <div
                        id="prm-breadcrumb"
                        className="d-flex flex-column align-items-start justify-content-center py-1"
                    >
                        {isProductBreadcrumbTrailActive &&
                            nestedCategories.length > 1 && (
                                <div
                                    className="d-flex justify-content-start  mt-4"
                                    style={{
                                        width: "100%",
                                        maxWidth: "calc( 100vw - 200px)",
                                    }}
                                >
                                    <CategoriesBreadCrumb />
                                </div>
                            )}
                        {isSelectedCategoryActive && (
                            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                <CategoryTitle />

                                <hr className="separation-line mb-2" />
                            </div>
                        )}
                    </div>

                    <section id="prm-products">
                        {isOrderTakingTagsActive ? <TagsSwiperWrapper /> : null}

                        <ProductWrapper />
                    </section>

                    <footer
                        id="prm-footer"
                        style={{
                            backgroundColor: "transparent",
                            maxHeight: "90px",
                        }}
                    >
                        <PrmBasket />
                    </footer>
                </div>
            </OrderTakingBackgroundWrapper>
        </section>
    );
}
