import { ETK_CESAR, ETK_STANDARD } from "@constants";

export function getModifierProduct(
    globalcard,
    product,
    productOptions,
    selectedSizeIndex
) {
    const modifierMenu =
        product.modifier !== "" &&
        Object.keys(globalcard.modifier[product.modifier]?.steps).length !== 0
            ? product.modifier
            : "";

    const optionModiferIuud = productOptions.Options[productOptions.optionIndex]
        .listOptions[selectedSizeIndex].modifier
        ? productOptions.Options[productOptions.optionIndex].listOptions[
              selectedSizeIndex
          ].modifier
        : "";

    const optionModifierExist = !!(
        optionModiferIuud !== "" &&
        globalcard.modifier[optionModiferIuud]?.steps &&
        globalcard.modifier[optionModiferIuud] &&
        Object.keys(globalcard.modifier[optionModiferIuud]?.steps.length !== 0)
    );

    const selectedOptionModifier =
        optionModiferIuud !== "" && optionModifierExist
            ? optionModiferIuud
            : "";
    switch (globalcard.cardType) {
        case ETK_CESAR:
            return modifierMenu;
        case ETK_STANDARD:
            return selectedOptionModifier
                ? selectedOptionModifier
                : modifierMenu;
        default:
            return modifierMenu;
    }
}
