import { t } from "i18next";

import React from "react";

import { StyleBadge } from "@components/common/StyledBadge";

export function StateBadge({ state }: { state: string }): JSX.Element {
    return (
        <StyleBadge color={state === "sync" ? "success" : "danger"}>
            {t(state === "sync" ? "Synchronized" : "Not synchronized")}
        </StyleBadge>
    );
}
