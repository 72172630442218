import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getFilenameFromUrl } from "@helpers/general";

import { LogoWrapper } from "@components/common/LogoWrapper";

import {
    kioskStore,
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setOrderModificationMode,
    setSelectedInformationMode,
    setSelectedSaleModeId,
    setSelectedSaleModeOrderTaking,
    setSelectedSaleModeValue,
} from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function PrmMode(): JSX.Element {
    const {
        customerLanguage,
        project: {
            template: {
                content: { informationModes, generalDesign },
            },
            files: images,
        },
        isInformationModesModalOpened,
        isHeaderActive,
        isUserAccountActive,
        isUserAuthenticated,
        userAccountMessageItems,
        user,
        isInformationMessageActive,
        informationMessageItems,
        saleMode,
    } = useSnapshot(kioskStore);

    const informationModesItems = (informationModes as ProjectContentItemType)
        .items;

    const activeInformationModesItems = informationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return (item.active as ItemContentType)[saleMode.name] === true;
        }
    );

    const activeInformationModesItemsLength =
        activeInformationModesItems.length;

    const backButtonImage = Object.values(images).filter(
        (item: ImageItemType) => {
            return item.name?.toLowerCase() === "ignore";
        }
    )[0].content as ImageItemLanguagesType;

    const backButtonImageSrc = backButtonImage[
        customerLanguage.name
    ].path?.includes("http")
        ? getFilenameFromUrl(
              backButtonImage[customerLanguage.name].path,
              "template"
          )
        : `./images/${backButtonImage[customerLanguage.name].path}`;

    return (
        <Modal
            isOpen={
                activeInformationModesItemsLength > 0 &&
                isInformationModesModalOpened
            }
            ariaHideApp={false}
            onRequestClose={() => {
                setIsInformationModesModalOpened(
                    !isInformationModesModalOpened
                );
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1230px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "10px",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div style={{ marginBottom: "300px" }}>
                <LogoWrapper />

                {isHeaderActive ? (
                    <div
                        style={{ display: "grid" }}
                        className="justify-content-center align-items-center"
                    >
                        {isUserAccountActive && isUserAuthenticated ? (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ gap: "20px" }}
                            >
                                <img
                                    src="./images/man-head-header.png"
                                    style={{
                                        height: "35px",
                                        width: "35px",
                                    }}
                                    alt="man head"
                                />
                                <span style={{ fontSize: "35px" }}>
                                    {
                                        userAccountMessageItems[
                                            customerLanguage.name
                                        ].content
                                    }{" "}
                                    {user.firstName === ""
                                        ? user.lastName
                                        : user.firstName}
                                </span>
                            </div>
                        ) : null}

                        {isInformationMessageActive ? (
                            <div className="d-flex justify-content-center align-items-center p-2">
                                <span
                                    style={{
                                        fontSize: "35px",
                                        textAlign: "center",
                                        lineHeight: "45px",
                                    }}
                                >
                                    {
                                        informationMessageItems[
                                            customerLanguage.name
                                        ].content
                                    }
                                </span>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <MainContentWrapper />

            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "133px", width: "865px" }}
            >
                <img
                    src={backButtonImageSrc}
                    alt="back"
                    onClick={() => {
                        if (saleMode.name === "Delivery") {
                            if (isUserAuthenticated === false) {
                                setIsUserAccountModalOpened(true);
                            } else {
                                setSelectedSaleModeValue(saleMode.name);
                                setSelectedSaleModeId(saleMode.id);
                                setSelectedSaleModeOrderTaking(
                                    saleMode.settingKey
                                );
                                setOrderModificationMode();
                            }
                        } else {
                            setFinalMessageSelectedInformationMode("none");
                            setSelectedInformationMode("");
                            setChosenInformationMode({
                                type: "",
                                data: { infoModeUuId: "", value: "" },
                            });
                        }
                        setIsInformationModesModalOpened(
                            !isInformationModesModalOpened
                        );
                    }}
                    style={{ margin: "10px" }}
                />
            </div>
        </Modal>
    );
}
