import { useSnapshot } from "valtio";

import React from "react";

import { ScrollContainer } from "@components/ScrollContainer";
import { syncStore } from "@components/Synchronization/store";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "../UsualModeMainContentWrapper/ImageContent";

export function MainContentWrapper({
    setIsTpaValinaModalOpened,
}: {
    setIsTpaValinaModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const {
        project: {
            template: { content },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);
    const { peripheralShopApp } = useSnapshot(syncStore);

    let activeItems = (
        (content.meansOfPayment as ProjectContentItemType)
            .items as ProjectMainContentItemType[]
    ).filter((item) => {
        return (
            item.active === true &&
            item.languages[customerLanguage.name].content !== ""
        );
    });
    if (
        Object.keys(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (peripheralShopApp?.monetics as any) || {}
        ).length === 0
    ) {
        activeItems = activeItems?.filter(
            (item) =>
                item.active === true &&
                item.shortName !== "creditCard" &&
                item.languages[customerLanguage.name].content !== ""
        );
    }
    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 2 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeItems?.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeItemsLength={activeItemsLength}
                                setIsTpaValinaModalOpened={
                                    setIsTpaValinaModalOpened
                                }
                            />
                        )
                    )}
                </div>
            ) : activeItemsLength > 2 && activeItemsLength <= 4 ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ gap: "25px" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeItems
                            ?.slice(0, 2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        key={index}
                                        activeItemsLength={activeItemsLength}
                                        setIsTpaValinaModalOpened={
                                            setIsTpaValinaModalOpened
                                        }
                                    />
                                )
                            )}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeItems
                            ?.slice(2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        key={index}
                                        activeItemsLength={activeItemsLength}
                                        setIsTpaValinaModalOpened={
                                            setIsTpaValinaModalOpened
                                        }
                                    />
                                )
                            )}
                    </div>
                </div>
            ) : (
                <ScrollContainer
                    style={{
                        maxHeight: "950px",
                        overflowY: "scroll",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2,1fr)",
                            gridTemplateRows: `repeat(${Math.ceil(
                                activeItems.length / 2
                            )},1fr)`,
                            rowGap: "35px",
                            columnGap: "25px",
                            padding: "50px 0px 50px 0px",
                        }}
                    >
                        {activeItems.map((item: ProjectMainContentItemType) => (
                            <ImageContent
                                item={item}
                                activeItemsLength={activeItemsLength}
                                key={item.id}
                                setIsTpaValinaModalOpened={
                                    setIsTpaValinaModalOpened
                                }
                            />
                        ))}
                    </div>
                </ScrollContainer>
            )}
        </React.Fragment>
    );
}
