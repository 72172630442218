import {
    ACCOUNT_OPTION,
    CONNECT_USER_OPTION,
    DELIVERY_MODE,
    GODIA_LOYALTY,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import { Tooltip } from "reactstrap";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { useOutsideClick } from "@components/OrderTaking/common/OrderTakingChangeSalesMode/useOutsideClick";
import {
    clearReward,
    setUsedGifts,
} from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    setAuthModalStepIndex,
    setIsCustomerBenefitOnClick,
    setIsCustomerBenefitOpen,
    setIsLogoutProblemModalOpened,
    setIsUserAccountModalOpened,
    setIsUserAuthenticated,
    setUser,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

export function HeaderConfigOptionsWrapper({
    handleAllergenDialogOnCLickEvent,
    filteredOptions,
}) {
    const {
        navigationIndex,
        isUserAuthenticated,
        isUserAccountModalOpened,
        kioskWays,
        user,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                            },
                        },
                    },
                },
            },
        },
        selectedSaleModeValue,
    } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const dispatch = useDispatch();

    const [isLogoutButtonClicked, setIsLogoutButtonClicked] =
        React.useState(false);
    const ref = useOutsideClick(setIsLogoutButtonClicked);

    function handleConfigOptionOnClickEvent(option) {
        switch (option.name.toLowerCase()) {
            case CONNECT_USER_OPTION:
                if (isUserAuthenticated === false) {
                    setIsUserAccountModalOpened(true),
                        setAuthModalStepIndex(navigationIndex);
                }
                break;
            case "allergen":
                handleAllergenDialogOnCLickEvent();
                break;

            default:
                null;
        }
    }

    function handleClickOnAvatar() {
        if (
            selectedShopApp.fidelityType === GODIA_LOYALTY &&
            (giftsActive || rewardsActive)
        ) {
            setIsCustomerBenefitOpen(true);
            setIsCustomerBenefitOnClick(true);
            return;
        }
        setIsLogoutButtonClicked(!isLogoutButtonClicked);
    }
    const localFilteredOptions = filteredOptions.filter((option) => {
        if (!isUserAuthenticated) {
            return (
                option.active === true &&
                (option.name.toLowerCase() === "customer account" ||
                    option.name.toLowerCase() === "allergen")
            );
        } else {
            return (
                option.active === true &&
                option.name.toLowerCase() === "allergen"
            );
        }
    });
    const isUserAccountExist = filteredOptions.filter(
        (item) => item.name === ACCOUNT_OPTION
    )[0];
    return (
        <div
            className="d-flex justify-content-end align-items-center w-50 px-3 py-2"
            style={{ gap: "5px", height: "100%" }}
        >
            {isLogoutButtonClicked ? (
                <Tooltip
                    className="order-taking-tooltip"
                    placement="bottom-end"
                    isOpen={isLogoutButtonClicked}
                    target={"Tooltip"}
                    onClick={() => {
                        if (selectedSaleModeValue === DELIVERY_MODE) {
                            setIsLogoutProblemModalOpened(true);
                            setIsLogoutButtonClicked(false);
                        } else {
                            setIsUserAuthenticated(false);
                            setIsLogoutButtonClicked(false);
                            dispatch(clearReward());
                            dispatch(setUsedGifts([]));
                            setUser({
                                firstName: "",
                                lastName: "",
                                phoneNumber: "",
                                email: "",
                                loyaltyAccount: 0,
                                loyaltyAmount: 0,
                                loyaltyType: "",
                                amountRatio: 0,
                            });
                        }
                    }}
                    style={{
                        cursor: "pointer",
                        height: "50px",
                        borderRadius: "5px",
                    }}
                >
                    <span
                        style={{ font: "normal 26px/30px Segoe UI" }}
                        ref={ref}
                    >
                        {t("Sign out")}
                    </span>
                </Tooltip>
            ) : null}
            {isUserAuthenticated && isUserAccountExist !== undefined ? (
                <div id="Tooltip">
                    <Avatar
                        email={user.email}
                        name={user.firstName !== "" ? user.firstName : "Client"}
                        size="55px"
                        round="10px"
                        onClick={handleClickOnAvatar}
                    />
                </div>
            ) : null}
            {localFilteredOptions.length > 0
                ? localFilteredOptions.map((option) => {
                      const imageSrc = getImageContentById(option.id);

                      return (
                          <div
                              className="d-flex align-items-center justify-content-end"
                              key={option.id}
                              onClick={() => {
                                  handleConfigOptionOnClickEvent(option);
                              }}
                              style={{ width: "50%" }}
                          >
                              {VALIDATED_EXTENSION_FILE.includes(
                                  imageSrc.substr(-4)
                              ) ? (
                                  <img
                                      src={imageSrc}
                                      alt={option.name}
                                      style={{
                                          maxHeight: "65px",
                                          width: "100%",

                                          opacity:
                                              option.name.toLowerCase() ===
                                                  "customer account" &&
                                              (isUserAccountModalOpened ||
                                                  isUserAuthenticated ||
                                                  kioskWays[navigationIndex]
                                                      .name === "connection" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModes" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModeConsommation")
                                                  ? "0.5"
                                                  : "1",
                                          pointerEvents:
                                              option.name.toLowerCase() ===
                                                  "customer account" &&
                                              (isUserAccountModalOpened ||
                                                  isUserAuthenticated ||
                                                  kioskWays[navigationIndex]
                                                      .name === "connection" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModes" ||
                                                  kioskWays[navigationIndex]
                                                      .name ===
                                                      "authenticationModeConsommation")
                                                  ? "none"
                                                  : "auto",
                                      }}
                                  />
                              ) : (
                                  <DynamicSvgComponent nameSvg={imageSrc} />
                              )}
                          </div>
                      );
                  })
                : null}
        </div>
    );
}

HeaderConfigOptionsWrapper.propTypes = {
    handleAllergenDialogOnCLickEvent: PropTypes.func,
    filteredOptions: PropTypes.array,
};
