import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import {
    getAlertActionButtonImageSrc,
    getNoActionButtonImageSrc,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import "./promo.css";

type LoyaltyInfoModalType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleOnclick: () => void;
    setIsRewardUnSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isRewardUnSelected: boolean;
};

export function RemoveSelectedRewardModal({
    handleOnclick,
    setIsRewardUnSelected,
    isRewardUnSelected,
}: LoyaltyInfoModalType): JSX.Element {
    const {
        project: {
            template: {
                content: { actionsButtons },
            },
        },
    } = useSnapshot(kioskStore);

    return (
        <Dialog
            open={isRewardUnSelected}
            onClose={() => setIsRewardUnSelected(false)}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "40%!important",
                    height: "25%!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "30px",
                },
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CloseIcon
                    className="MuiIcon-fontSizeSmall close-icon"
                    onClick={() => setIsRewardUnSelected(false)}
                    fontSize="small"
                />

                <div
                    className="d-flex align-items-center flex-column"
                    style={{ gap: "2rem" }}
                >
                    <img
                        alt="alert"
                        className="code-promo-alert-image"
                        src={getAlertActionButtonImageSrc(
                            actionsButtons as ActionsButtonsDesignType
                        )}
                        height={150}
                        width={75}
                    />

                    <span
                        className="mx-2"
                        style={{
                            font: "normal normal 600 24px/40px Segoe UI",
                            textAlign: "center",
                        }}
                    >
                        {t("Do you want to delete this item ?")}
                    </span>
                    <div className="d-flex flex-row" style={{ gap: "20px" }}>
                        <div
                            style={{
                                height: "150px",
                                width: "150px",
                            }}
                        >
                            <img
                                src={getNoActionButtonImageSrc(
                                    actionsButtons as ActionsButtonsDesignType
                                )}
                                className="w-100 h-100"
                                style={{ backgroundSize: "cover" }}
                                onClick={() => setIsRewardUnSelected(false)}
                            />
                        </div>

                        <div
                            style={{
                                height: "150px",
                                width: "150px",
                            }}
                        >
                            <img
                                src={getYesActionButtonImageSrc(
                                    actionsButtons as ActionsButtonsDesignType
                                )}
                                className="w-100 h-100"
                                style={{ backgroundSize: "cover" }}
                                onClick={() => {
                                    handleOnclick();
                                    setIsRewardUnSelected(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
