import React from "react";

export function PlaySvgIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="video-pause-button"
            width="105"
            height="105"
            viewBox="0 0 31.983 31.983"
            {...props}
        >
            <g
                id="Groupe_31335"
                data-name="Groupe 31335"
                transform="translate(0 0)"
            >
                <path
                    id="Tracé_22201"
                    data-name="Tracé 22201"
                    d="M15.991,31.983A15.991,15.991,0,1,0,0,15.991,15.992,15.992,0,0,0,15.991,31.983Zm0-29.424A13.433,13.433,0,1,1,2.559,15.991,13.448,13.448,0,0,1,15.991,2.559Z"
                    transform="translate(0 0)"
                    fill="#010002"
                />
                <path
                    id="Tracé_22202"
                    data-name="Tracé 22202"
                    d="M98.708,84.928a1.6,1.6,0,0,0,1.6-1.6V71.175a1.6,1.6,0,0,0-3.2,0V83.329A1.6,1.6,0,0,0,98.708,84.928Z"
                    transform="translate(-85.689 -61.394)"
                    fill="#010002"
                />
                <path
                    id="Tracé_22203"
                    data-name="Tracé 22203"
                    d="M153.1,84.928a1.6,1.6,0,0,0,1.6-1.6V71.175a1.6,1.6,0,1,0-3.2,0V83.329A1.6,1.6,0,0,0,153.1,84.928Z"
                    transform="translate(-133.683 -61.394)"
                    fill="#010002"
                />
            </g>
        </svg>
    );
}
