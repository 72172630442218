import { t } from "i18next";
import React from "react";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import { useSnapshot } from "valtio";

import { kioskStore as store } from "@pages/Kiosk/store";

import { ActiveStateScreen } from "./ActiveStateScreen";
import { StandbyStateScreen } from "./StandbyStateScreen";

export function AdvertisingScreen(): JSX.Element {
    const { activeTabTitle } = useSnapshot(store);

    const tabs: TabsType[] = [
        {
            title: t("Active state"),
            content: <ActiveStateScreen />,
        },
        {
            title: t("Standby state"),
            content: <StandbyStateScreen />,
        },
    ];
    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggle(tab: number): void {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <div className="d-flex flex-column" style={{ margin: 30, gap: 30 }}>
            <div
                style={{
                    font: "normal normal 600 37px/49px Segoe UI",
                    marginLeft: 20,
                }}
            >
                {activeTabTitle}
            </div>
            <Nav
                tabs
                style={{
                    backgroundColor: "white",
                    width: "93%",
                    display: "grid",
                    gridTemplateColumns: "0.5fr 0.5fr 1fr",
                    gridTemplateRows: "1fr",
                    border: "unset",
                    borderBottom: "2px solid #cfc7c7",
                    marginLeft: "20px",
                }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className="d-flex align-items-center text-nowrap"
                            style={{
                                borderBottom:
                                    activeTab === index
                                        ? "3px solid #2B2828"
                                        : "unset",
                                cursor: "pointer",
                                font: "normal normal 600 17px/22px Segoe UI",
                                border: "unset",
                                borderColor: "none",
                            }}
                        >
                            <NavLink
                                className="text-dark w-100"
                                style={{
                                    color:
                                        activeTab === index
                                            ? "rgb(14, 14, 15) !important"
                                            : " none !important",
                                    border:
                                        activeTab === index
                                            ? "none !important"
                                            : " none !important",
                                }}
                                onClick={() => {
                                    toggle(index);
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                style={{
                    width: "93%",
                    height: "93%",
                    marginLeft: "20px",
                }}
                className="bg-white border-0 rounded"
            >
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            style={{ height: "100%", width: "100%" }}
                            key={index}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
