import { NO_ThANKS_CARD } from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { produce } from "immer";
import { snapshot } from "valtio";

import { RequirementToast, successAddToast } from "@components/common/Toast";

import { kioskStore } from "@pages/Kiosk/store";

const initialState = {
    workflowData: [],
    nextPreviousStep: "next",
};
const { selectedSaleModeValue } = snapshot(kioskStore);

export const workFlowDataSlice = createSlice({
    name: "workFlowDataSlice",
    initialState,
    reducers: {
        setWorkflowData: (state, action) => {
            state.workflowData = action.payload;
        },
        removeLastWorkflow: (state, { payload }) => {
            state.workflowData = produce(state.workflowData, (draftState) => {
                if (draftState.length > 0) {
                    draftState.splice(draftState.length - 1, 1);

                    if (
                        !payload.isModif ||
                        (payload.isModif && payload.isCancelEditProduct)
                    ) {
                        draftState[draftState.length - 1].workflow[
                            draftState[draftState.length - 1].index
                        ].NbrOfChoices =
                            draftState[draftState.length - 1].workflow[
                                draftState[draftState.length - 1].index
                            ].NbrOfChoices - 1;
                    }
                }
            });
        },
        //@typescript-eslint/no-unused-vars
        setNextWorkflowStep: (state) => {
            state.workflowData[state.workflowData.length - 1].index += 1;
            state.nextPreviousStep = "next";
        },
        setPreviousWorkflowStep: (state) => {
            state.workflowData[state.workflowData.length - 1].index -= 1;
            state.nextPreviousStep = "prev";
        },
        steWorkflowStepIndex: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].index = payload;
            state.nextPreviousStep = "next";
        },
        steWorkflowStep: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].index = payload;
        },
        toggleBasicCompositionitem: (state, { payload }) => {
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].Items[payload].basicCompositionActive =
                !state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items[payload].basicCompositionActive;
            if (
                !state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items[payload].basicCompositionActive
            ) {
                RequirementToast(t("Deleted product"));
            } else {
                successAddToast(t("Product added"));
            }
        },
        addWorkflowStepChoice: (state, { payload }) => {
            const {
                NbrOfChoices,
                selectedChoicesWithStepPrice,
                selectedChoicesWithspecialPrice,
                haveWorkflow,
                menuOnModification,
                isAutoPermutation,
            } = payload;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].NbrOfChoices = NbrOfChoices;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithStepPrice = selectedChoicesWithStepPrice;
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithspecialPrice = selectedChoicesWithspecialPrice;

            if (
                state.workflowData[state.workflowData.length - 1].index <
                    state.workflowData[state.workflowData.length - 1].step &&
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].maxNbrOfChoices === NbrOfChoices &&
                !haveWorkflow &&
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].haveComment === false &&
                !menuOnModification
            ) {
                state.workflowData[state.workflowData.length - 1].index += 1;
                state.nextPreviousStep = "next";
            }

            !haveWorkflow &&
                state.workflowData[state.workflowData.length - 1].index <
                    state.workflowData[state.workflowData.length - 1].step &&
                !isAutoPermutation &&
                successAddToast(t("Product added"));

            if (
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].NbrOfChoices > 0 &&
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].specificOption.noButton === true
            ) {
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items = state.workflowData[
                    state.workflowData.length - 1
                ].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items.filter((item) => item.type !== NO_ThANKS_CARD);
            }
        },
        minusNbrChoiceOfCurrentStep: (state) => {
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].NbrOfChoices -= 1;

            const {
                selectedChoicesWithspecialPrice,
                selectedChoicesWithStepPrice,
            } = JSON.parse(localStorage.getItem("stepDetails")) || {
                selectedChoicesWithspecialPrice: 0,
                selectedChoicesWithStepPrice: 0,
            };
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithStepPrice = selectedChoicesWithStepPrice;

            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].selectedChoicesWithspecialPrice = selectedChoicesWithspecialPrice;
            if (
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].specificOption.noButton === true &&
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].NbrOfChoices === 0
            ) {
                state.workflowData[state.workflowData.length - 1].workflow[
                    state.workflowData[state.workflowData.length - 1].index
                ].Items.push({
                    type: NO_ThANKS_CARD,
                    visibilityInfo: { isVisible: true },
                    KioskVisibility: { [selectedSaleModeValue]: true },
                });
            }
            RequirementToast(t("Deleted product"));
        },
        goToPreviousWorkflow: (state) => {
            state.workflowData = produce(state.workflowData, (draftState) => {
                if (draftState.length > 0) {
                    draftState.splice(draftState.length - 1, 1);
                    if (
                        draftState[draftState.length - 1].step >
                            draftState[draftState.length - 1].index &&
                        !draftState[draftState.length - 1].workflow[
                            draftState[draftState.length - 1].index
                        ].haveComment &&
                        draftState[draftState.length - 1].workflow[
                            draftState[draftState.length - 1].index
                        ].NbrOfChoices ===
                            draftState[draftState.length - 1].workflow[
                                draftState[draftState.length - 1].index
                            ].maxNbrOfChoices
                    ) {
                        draftState[draftState.length - 1].index += 1;
                    }
                }
            });
        },
        resetNbrOfChoice: (state) => {
            state.workflowData[state.workflowData.length - 1].workflow[
                state.workflowData[state.workflowData.length - 1].index
            ].NbrOfChoices = 0;
        },
    },
});

export const {
    setWorkflowData,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    steWorkflowStepIndex,
    toggleBasicCompositionitem,
    addWorkflowStepChoice,
    removeLastWorkflow,
    minusNbrChoiceOfCurrentStep,
    goToPreviousWorkflow,
    resetNbrOfChoice,
} = workFlowDataSlice.actions;

export default workFlowDataSlice.reducer;
