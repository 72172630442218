import React from "react";

export function AllergenInfoIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={97.161}
            height={97.161}
            {...props}
            strokeWidth={0}
        >
            <defs>
                <filter
                    id="a"
                    x={0}
                    y={0}
                    width={97.161}
                    height={97.161}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.522} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(0 -.001)" filter="url(#a)">
                <path
                    data-name="Trac\xE9 19798"
                    d="M48.581 6a39.585 39.585 0 0 1 39.58 39.426 40.02 40.02 0 0 1-12.245 28.835l10.9 10.9H48.581a39.581 39.581 0 1 1 0-79.161Zm0 0"
                    fill="#454545"
                />
            </g>
            <path
                data-name="Trac\xE9 19799"
                d="M9 45.426a39.85 39.85 0 0 0 39.581 39.735V6A39.585 39.585 0 0 0 9 45.426Zm0 0"
                fill="transparent"
            />
            <path
                data-name="Trac\xE9 19800"
                d="M54.303 19.914v13.915H42.858V19.914Zm-3.2 7.269"
                fill="white"
            />
            <path
                data-name="Trac\xE9 19801"
                d="M54.303 38.468v32.778H42.858V38.468Zm0 0"
                fill="white"
            />
            <g data-name="Groupe 23526">
                <path
                    data-name="Trac\xE9 19802"
                    d="M49.816 19.915h-6.958V33.83h6.958Zm0 0"
                    fill="#FFFFFF"
                    stroke="none"
                />
                <path
                    data-name="Trac\xE9 19803"
                    d="M49.816 38.468h-6.958v32.778h6.958Zm0 0"
                    fill="#FFFFFF"
                    stroke="none"
                />
            </g>
        </svg>
    );
}
