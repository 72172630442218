import { shallowEqual, useSelector } from "react-redux";

import { getProductTitle } from "@components/OrderTaking/Helpers";

export function getCategoryTitleHeader() {
    const nestedCategories = useSelector(
        (state) => state.orderSlice.nestedCategories,
        shallowEqual
    );
    const globalcard = useSelector(
        (state) => state.orderSlice.globalcard,
        shallowEqual
    );
    let subCategory =
        globalcard.categories[nestedCategories[nestedCategories.length - 1]];
    let category = globalcard.categories[nestedCategories[0]];
    const title =
        nestedCategories.length > 1
            ? subCategory?.isRedirect
                ? getProductTitle(subCategory?.displayTitle)
                : getProductTitle(subCategory?.name)
            : getProductTitle(category.name);
    return title;
}
