import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { FlexboxGrid } from "@components/common/FlexboxGrid";
import { ClickableSvgIcon } from "@components/SvgIcons";
import { Table } from "@components/Synchronization/EntitySelectionModalWrapper/EntitySelection/Table";
import { syncStore } from "@components/Synchronization/store";

type EntitySelectionViewType = {
    selectEntity(selectedEntity: EntityType): void;
    columns: TableColumnsType[];
    data: EntityType[];
};

export default function EntitySelectionView({
    selectEntity,
    columns,
    data,
}: EntitySelectionViewType): JSX.Element {
    const { selectedEntity, selectedEntityChild } = useSnapshot(syncStore);
    return (
        <FlexboxGrid className="p-4 flex-column" gap="10px">
            <FlexboxGrid className="flex-column" gap="20px">
                <span
                    className="text-uppercase"
                    style={{
                        font: "normal normal bold 42px/46px Bebas Neue",
                    }}
                >
                    {selectedEntity !== undefined ? (
                        <span
                            className="selected-entity__clz"
                            onClick={() => {
                                selectEntity(selectedEntity);
                            }}
                        >
                            <ClickableSvgIcon
                                className="mr-1"
                                style={{
                                    display:
                                        selectedEntityChild !== undefined
                                            ? "unset"
                                            : "none",
                                }}
                            />
                            {selectedEntity.name}
                        </span>
                    ) : (
                        t("Please select an entity")
                    )}
                    {selectedEntityChild?.uid !== undefined
                        ? ` / ${selectedEntityChild?.name}`
                        : null}
                </span>
            </FlexboxGrid>

            <Table columns={columns} data={data} />
        </FlexboxGrid>
    );
}
