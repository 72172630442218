import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        subStepActionButtons,
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isSubStepActionButtonsActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isAuthenticationErrorModalOpened,
        selectedAuthMode,
        qrCodeValue,
    } = useSnapshot(kioskStore);

    const isScanningQrCode = qrCodeValue !== "";
    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper selectedMode={selectedAuthMode}>
            <UsualModeSubStepContainerWrapper
                isSubStepTopBannerActive={isSubStepTopBannerActive}
                isSubStepIconActive={isSubStepIconActive}
                isSubStepInformationMessageActive={
                    isSubStepInformationMessageActive
                }
                isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
            >
                {isAuthenticationErrorModalOpened === true ? (
                    <AuthenticationErrorModal />
                ) : null}

                <SubStepsLogoWrapper />

                {isSubStepIconActive ? <SubStepsIconWrapper /> : null}

                {isSubStepInformationMessageActive ? (
                    <SubStepsInformationMessage />
                ) : null}

                <div className="d-flex justify-content-center align-items-center">
                    <UsualNumericKeyboardWrapper />
                </div>

                {isSubStepActionButtonsActive ? (
                    <ActionsButtonsWrapper
                        actionsButtonsItems={mainActiveActionsButtons}
                    />
                ) : null}

                <UsualModeLanguagesWrapper />

                {isConfigOptionsActive && !isScanningQrCode ? (
                    <ConfigOptionsWrapper />
                ) : null}
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
