import { TFunction } from "i18next";

import i18n from "../i18n";

const t: TFunction = i18n.t.bind(i18n);

export const FR_LAYAOUT = [
    "a z e r t y u i o p {bksp}",
    "q s d f g h j k l {enter}",
    "{shift} w x c v b n m , . {shift}",
    "{alt} {space}",
];
export const NUMERIC_LAYAOUT = ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"];

export const EN_LAYAOUT = [
    "q w e r t y u i o p {bksp}",
    "a s d f g h j k l {enter}",
    "{shift} z x c v b n m , . {shift}",
    "{alt} {space}",
];

export const FR_SHIFT_LAYAOUT = [
    "A Z E R T Y U I O P {bksp}",
    "Q S D F G H J K L {enter}",
    "{shiftactivated} W X C V B N M , . {shiftactivated}",
    "{alt} {space}",
];

export const EN_SHIFT_LAYAOUT = [
    "Q W E R T Y U I O P {bksp}",
    "A S D F G H J K L {enter}",
    "{shiftactivated} Z X C V B N M , . {shiftactivated}",
    "{alt} {space}",
];
export const ALT_LAYAOUT = [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    `@ # $ & * ( ) ' " {enter}`,
    "{shift} % - + = / ; : ! ? {shift}",
    "{default} {space}",
];
export const DISPLAY_LAYAOUT = {
    "{alt}": ".?123",
    "{shift}": "⇧",
    "{shiftactivated}": "⇧",
    "{enter}": t("Enter_key"),
    "{bksp}": "⌫",
    "{altright}": ".?123",
    "{downkeyboard}": "🞃",
    "{space}": " ",
    "{default}": "ABC",
    "{back}": "⇦",
    "{lock}": `<img src="./images/caps.svg" width="25" height="25" />`,
};

export const AZERTY_KEYBOARD = "azerty";

export const SHIFT_KEY = "{shift}";
export const LOCK_KEY = "{lock}";
export const SHIFT_ACTIVATED_KEY = "{shiftactivated}";
export const DEFAULT_KEY = "{default}";
export const ALT_KEY = "{alt}";
export const BACK_SLASH_KEY = "{bksp}";
