import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFilenameFromUrl } from "@helpers/general";

import { getProductTitle } from "@components/OrderTaking/Helpers";

import { PadLockIcon } from "../../Icons/PadLockIcon";
import "./index.css";

export function ProductComposition({ basicCompositionsIds }) {
    const { basicComposition } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    const productCompositions = basicCompositionsIds.map((id) => {
        const image = getFilenameFromUrl(basicComposition[id].image, "catalog");
        const productTitle = getProductTitle(basicComposition[id].name);

        return { ...basicComposition[id], image, productTitle };
    });

    return (
        <div className="product-composition-container">
            {productCompositions.map((composition, index) => {
                return (
                    <div key={index} className="product-composition-item">
                        <div className=" d-flex justify-content-center align-items-center flex-column product-composition-img">
                            <img
                                alt={composition.name}
                                //src={`./kiosk/${composition.key}.webp`}
                                //! this to  review
                                src={composition.image}
                                style={{ height: "auto", width: "100%" }}
                            />
                            {composition.isRequired === true ? (
                                <div
                                    className="d-flex justify-content-end w-100"
                                    style={{
                                        width: "50px",
                                        height: "35px",
                                    }}
                                >
                                    <PadLockIcon />
                                </div>
                            ) : null}
                        </div>
                        <span
                            style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                fontFamily: "Segoe UI",
                                lineHeight: "1.2em",
                                textAlign: "center",
                                wordWrap: "break-word",
                                wordBreak: " break-word",
                            }}
                        >
                            {composition.productTitle}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}

ProductComposition.propTypes = {
    basicCompositionsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
