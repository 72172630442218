import styled from "styled-components";

type PropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isLogoActive: boolean;
};

export const Container = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    /* grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
    }: PropsType) =>
        isTopBannerActive && isHeaderActive
            ? "11fr 12fr 25fr 52fr"
            : isHeaderActive && !isTopBannerActive
            ? "17fr 31fr 52fr"
            : !isHeaderActive && isTopBannerActive
            ? "11fr 37fr 52fr;"
            : !isHeaderActive && !isTopBannerActive
            ? "39fr 61fr;"
            : ""}; */
    grid-template-rows: 250px 1fr max-content 1fr;
    height: 100%;
`;
