import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore as store } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function Languages(): JSX.Element {
    const { localProject } = useSnapshot(store);

    const activeLanguages = (
        (localProject.template.content as ProjectContentType)
            .languages as LanguagesType
    ).items;

    return (
        <MainContentWrapper items={activeLanguages} contentName="languages" />
    );
}
