import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { SpinningCircles } from "svg-loaders-react";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { kioskStore } from "@pages/Kiosk/store";

export function LoadingModalWrapper(): JSX.Element {
    const { isLoadingModalOpened } = useSnapshot(kioskStore);

    return (
        <Modal
            isOpen={isLoadingModalOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                    width: "300px",
                    marginTop: "800px",
                    marginLeft: "475px",
                    borderRadius: "10px",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    color: "black",
                    height: "400px",
                    width: "400px",
                    fontSize: "50px",
                    zIndex: 2,
                    background: "white 0% 0% no-repeat padding-box",
                    border: "1px solid #FFFFFF",

                    borderRadius: "12px",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    opacity: 0.9,
                    boxShadow: "0px 3px 6px #00000029",
                    backdropFilter: "blur(30px)",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div className="d-flex flex-column justify-content-center align-items-center">
                <SpinningCircles width="100px" height="100px" />
                <span>{t("Loading....")}</span>
            </div>
        </Modal>
    );
}
