import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog, Slide } from "@mui/material";
import PropTypes from "prop-types";
import shortid from "shortid";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next/";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getFilenameFromUrl, getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { AllergenInfoIcon } from "@components/OrderTaking/Components/Icons/AllergenInfoIcon";
import { AllergenPrmLogoWrapper } from "@components/OrderTaking/Components/Prm/Dialogs/Allergens/AllergenPrmLogoWrapper";
import {
    memoizedGlobalAllergens,
    setGlobalAllergens,
} from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "../NutritionalInformation/index.css";
import { AllergenCard } from "./AllergenCard";
import { AllergenGroup } from "./AllergenGroup";
import "./index.css";
import { useStyles } from "./styles";

import "swiper/swiper-bundle.min.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AllergensDialog({ open, onClose }) {
    const { t } = useTranslation();
    const { globalColor } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const styles = useStyles();
    const dispatch = useDispatch();

    const {
        isPrm,
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);

    const globalAllergens = useSelector((state) =>
        memoizedGlobalAllergens(state)
    );
    const [localAllergens, setLocalAllergens] = React.useState(globalAllergens);

    const filteredButtons = content.actionsButtons.items.filter((item) => {
        return item.role === VALIDATE_BUTTON_ROLE;
    })[0];

    const validateImageSrc = getImageContentById(filteredButtons.id);

    const backButton = content.actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];

    const backImageSrc = getImageContentById(backButton.id);

    function handleActiveAllergenOnClickEvent(id, group) {
        const updatedGlobalAllergens = localAllergens[group].map((allergen) => {
            if (allergen.id === id) {
                return {
                    ...allergen,
                    active: !allergen.active,
                };
            } else {
                return allergen;
            }
        });
        setLocalAllergens({
            ...localAllergens,
            [group]: [...updatedGlobalAllergens],
        });
    }

    function handleValidateAllergenOnClickEvent() {
        dispatch(setGlobalAllergens(localAllergens));
        onClose();
    }
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1010px!important",
                    height: "1823px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "111px 111px 0px 0px!important",
                    borderTop: `15px solid ${globalColor}!important`,
                    maxWidth: "unset!important",
                    padding: "unset !important",
                    gap: "30px!important",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "unset",
                    top: "45px!important",
                    alignItems: "center!important",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            TransitionComponent={Transition}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {isPrm ? (
                <div style={{ height: "800px" }}>
                    <AllergenPrmLogoWrapper />
                </div>
            ) : null}
            <div className={styles.title}> {t("Allergen Information")} </div>

            <div className={styles.customCard}>
                <AllergenInfoIcon />
                <div className={styles.subTitle}>
                    <span>
                        {t(
                            "To allow our kiosk to identify products containing allergens, please select them."
                        )}
                    </span>
                </div>
            </div>
            <div
                className="d-flex"
                style={{
                    flexDirection: isPrm ? "column-reverse" : "column",
                    gap: "30px",
                    height: isPrm ? "750px" : "1200px",
                }}
            >
                <div className={styles.mainCard}>
                    <div
                        className="d-flex"
                        style={{
                            gap: "10px",
                            height: isPrm ? "333px" : "auto",
                        }}
                    >
                        <div
                            className="d-flex justify-content-center my-3"
                            style={{ width: "100%", gap: "50px" }}
                        >
                            {Object.keys(localAllergens).length === 1 ? (
                                Object.keys(localAllergens).map((group) => {
                                    return (
                                        <div key={shortid.generate()}>
                                            <div
                                                className="d-flex flex-column align-items-center"
                                                style={{
                                                    gap: "10px",
                                                    width: "100%",
                                                    overflowY: "auto",
                                                    scrollbarWidth: "none",
                                                    height: isPrm
                                                        ? "310px"
                                                        : "800px",
                                                }}
                                            >
                                                <div className="allergens-in-one-group p-4">
                                                    {localAllergens[group].map(
                                                        (allergen) => {
                                                            const allergenIcon =
                                                                getFilenameFromUrl(
                                                                    allergen.icon,
                                                                    "catalog"
                                                                );
                                                            return (
                                                                <AllergenCard
                                                                    key={
                                                                        allergen.id
                                                                    }
                                                                    allergen={
                                                                        allergen
                                                                    }
                                                                    handleActiveAllergenOnClickEvent={
                                                                        handleActiveAllergenOnClickEvent
                                                                    }
                                                                    group={
                                                                        group
                                                                    }
                                                                    allergenIcon={
                                                                        allergenIcon
                                                                    }
                                                                    isProduct={
                                                                        false
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            {!isPrm ? (
                                                localAllergens[group].length >
                                                30 ? (
                                                    <hr className="separation-line" />
                                                ) : null
                                            ) : localAllergens[group].length >
                                              10 ? (
                                                <hr className="separation-line" />
                                            ) : null}
                                        </div>
                                    );
                                })
                            ) : Object.keys(localAllergens).length <= 3 ? (
                                Object.keys(localAllergens).map(
                                    (group, index) => {
                                        return (
                                            <AllergenGroup
                                                key={`group${index}`}
                                                globalAllergens={localAllergens}
                                                handleActiveAllergenOnClickEvent={
                                                    handleActiveAllergenOnClickEvent
                                                }
                                                group={group}
                                            />
                                        );
                                    }
                                )
                            ) : (
                                <Swiper
                                    slidesPerView={3}
                                    slidesPerGroup={3}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    style={{ height: isPrm ? "335px" : "auto" }}
                                >
                                    {Object.keys(localAllergens).map(
                                        (group) => {
                                            return (
                                                <SwiperSlide
                                                    key={shortid.generate()}
                                                >
                                                    <AllergenGroup
                                                        globalAllergens={
                                                            localAllergens
                                                        }
                                                        handleActiveAllergenOnClickEvent={
                                                            handleActiveAllergenOnClickEvent
                                                        }
                                                        group={group}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                                </Swiper>
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.text}>
                    {t(
                        `Informing the customer about the presence of allergenic substances in all the products offered on the menu has become mandatory for restaurateurs since July 1, 2015, the date of entry into force of decree no. 2015-447 of April 17, 2015 relating to information of consumers on allergens and non-prepackaged foodstuffs. It should be recalled that Decree No. 2015-447 retained 14 major allergens which must be informed to consumers in restaurants, so that they can choose dishes with full knowledge of the facts and in order to prevent any risk related to food allergy.`
                    )}
                </div>
            </div>

            <div
                className="d-flex justify-content-center align-items-center pb-2"
                style={{ gap: "15px", marginTop: "20px" }}
            >
                {backImageSrc !== "" ? (
                    <>
                        {VALIDATED_EXTENSION_FILE.includes(
                            backImageSrc.substr(-4)
                        ) ? (
                            <div
                                style={{
                                    backgroundSize: " 100% 100%",
                                    backgroundImage: `url(${backImageSrc})`,
                                    height: "87px",
                                    width: "350px",
                                }}
                                onClick={onClose}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={backImageSrc}
                                width={"350px"}
                                height={"87px"}
                                onClick={onClose}
                            />
                        )}
                    </>
                ) : (
                    <img
                        alt="backButton"
                        src="./images/back.png"
                        style={{ height: "87px", width: "350px" }}
                        onClick={onClose}
                    />
                )}
                {validateImageSrc !== "" ? (
                    <>
                        {VALIDATED_EXTENSION_FILE.includes(
                            validateImageSrc.substr(-4)
                        ) ? (
                            <div
                                style={{
                                    backgroundSize: " 100% 100%",
                                    backgroundImage: `url(${validateImageSrc})`,
                                    height: "87px",
                                    width: "350px",
                                }}
                                onClick={() => {
                                    handleValidateAllergenOnClickEvent();
                                }}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={validateImageSrc}
                                width={"350px"}
                                height={"87px"}
                                onClick={() => {
                                    handleValidateAllergenOnClickEvent();
                                }}
                            />
                        )}
                    </>
                ) : (
                    <img
                        alt="addToBasketButton"
                        src="./images/validate.png"
                        style={{ height: "87px", width: "350px" }}
                        onClick={() => {
                            handleValidateAllergenOnClickEvent();
                        }}
                    />
                )}
            </div>
        </Dialog>
    );
}

AllergensDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
