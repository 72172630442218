import { PERCENT_ON_ORDER } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, Col, Collapse } from "reactstrap";

import { getColorFromButton } from "@components/OrderTaking/Helpers";
import { setUsedGifts } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

export function PromoCode() {
    const dispatch = useDispatch();
    const { devise } = useSnapshot(kioskStore);
    const [isExpanded, setIsExpanded] = React.useState(false);

    const {
        usedGifts,
        order: { totalPrice, totalPriceWithOutFidelity },
    } = useSelector((state) => state.orderSlice, shallowEqual);
    const { globalColor } = useSelector(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const totalReduce = `${(totalPrice - totalPriceWithOutFidelity).toFixed(
        devise.decimalPrice
    )} ${devise.deviseSymbole}`;

    const textColor = getColorFromButton(globalColor);
    const btnColor = getColorFromButton(textColor);

    return (
        <Card
            style={{
                boxShadow: "0px 3px 6px rgba(85, 84, 84, 0.502)",
                borderRadius: "19px",
                width: "91%",
            }}
        >
            <Col
                className="w-100 d-flex justify-content-between align-items-center"
                style={{
                    boxShadow: "0px 3px 6px rgba(85, 84, 84, 0.502)",
                    height: "150px",
                    background: globalColor,
                    borderRadius: isExpanded
                        ? "19px 19px 0 0"
                        : "19px 19px 19px 19px",
                }}
            >
                <div
                    className="m-3 d-flex justify-content-between align-items-center"
                    style={{ gap: "10px", color: textColor }}
                >
                    <span
                        style={{
                            font: "normal normal bold 40px/54px Nunito",
                        }}
                    >
                        {t("Promotions")}
                    </span>
                </div>
                <div
                    className="d-flex flex-row justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                >
                    <span
                        style={{
                            font: "normal normal bold 30px/34px Nunito",
                            color: textColor,
                        }}
                    >
                        {totalReduce}
                    </span>
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className=" d-flex justify-content-center align-items-center see-details"
                        style={{
                            backgroundColor: textColor,
                            color: btnColor,
                            width: "150px",
                        }}
                    >
                        {isExpanded ? t("Hide details") : t("See details")}
                    </button>
                </div>
            </Col>
            <Collapse isOpen={isExpanded}>
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                        boxShadow: "0px 3px 6px #00000080",
                        borderBottomLeftRadius: " 19px",
                        borderBottomRightRadius: "19px",
                    }}
                >
                    <div
                        className="m-3 d-flex justify-content-between align-items-center"
                        style={{ gap: "10px" }}
                    >
                        <span
                            style={{
                                font: "normal normal bold 32px/54px Nunito",
                            }}
                        >
                            {`${t("Discount Coupon of ")}${
                                usedGifts[0].discountValue
                            }${
                                Number(usedGifts[0].discountType) ===
                                PERCENT_ON_ORDER
                                    ? "%"
                                    : "€"
                            }`}
                        </span>
                    </div>
                    <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        style={{ gap: "10px", width: "250px" }}
                    >
                        <span
                            style={{
                                font: "normal normal 30px/30px Nunito",
                            }}
                        >
                            {" "}
                            {totalReduce}
                        </span>
                        <FaTrashAlt
                            style={{
                                width: "50px",
                                height: "40px",
                                marginRight: "5%",
                            }}
                            onClick={() => dispatch(setUsedGifts([]))}
                        />
                    </div>
                </div>
            </Collapse>
        </Card>
    );
}
