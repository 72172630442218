import PropTypes from "prop-types";

import React from "react";

export function Nutriscore({ nutriscoreType, styles }) {
    let imageElement = null;
    switch (nutriscoreType) {
        case "A":
            imageElement = (
                <img src="./images/A.png" alt="nutriscore" style={styles} />
            );
            break;
        case "B":
            imageElement = (
                <img src="./images/B.png" alt="nutriscore" style={styles} />
            );
            break;
        case "C":
            imageElement = (
                <img src="./images/C.png" alt="nutriscore" style={styles} />
            );
            break;
        case "D":
            imageElement = (
                <img src="./images/D.png" alt="nutriscore" style={styles} />
            );
            break;
        case "E":
            imageElement = (
                <img src="./images/E.png" alt="nutriscore" style={styles} />
            );
            break;
        default:
            imageElement = <div></div>;
    }
    return imageElement;
}

Nutriscore.propTypes = {
    nutriscoreType: PropTypes.string,
    styles: PropTypes.object,
    image: PropTypes.string,
};
