import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import ReactSwitch from "react-switch";

import {
    kioskStore,
    setIsSkippedStep,
    setIsSkippedStepInformationModes,
} from "@pages/Kiosk/store";

type ReactSwitchWrapperPropsType = {
    pageName: string;
    selectedSaleModeValue?: string | undefined;
};

export function ReactSwitchWrapper({
    pageName,
    selectedSaleModeValue,
}: ReactSwitchWrapperPropsType): JSX.Element {
    const {
        localProject: { template },
    } = useSnapshot(kioskStore);
    let skipped: boolean;
    if (selectedSaleModeValue !== undefined) {
        if (
            (template.pages.ways["informationModes"] as PageType) !== undefined
        ) {
            skipped = (
                (template.pages.ways.informationModes as PageType)
                    .skipped as ItemContentType
            )[selectedSaleModeValue] as boolean;
        } else {
            skipped = (
                (template.pages.elements.informationModes as PageType)
                    .skipped as ItemContentType
            )[selectedSaleModeValue] as boolean;
        }
    } else {
        if ((template.pages.ways[pageName] as PageType) !== undefined) {
            skipped = (template.pages.ways[pageName] as PageType)
                .skipped as boolean;
        } else {
            skipped = (template.pages.elements[pageName] as PageType)
                .skipped as boolean;
        }
    }

    function handleOnChangeSwitch() {
        if (selectedSaleModeValue !== undefined) {
            setIsSkippedStepInformationModes(!skipped, selectedSaleModeValue);
        } else {
            setIsSkippedStep(!skipped, pageName);
        }
    }
    return (
        <React.Fragment>
            <div className="skipped-step-title">
                {t("Do not offer this step in the customer ways")}
            </div>
            <ReactSwitch
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={26}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                checked={skipped}
                onChange={handleOnChangeSwitch}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={55}
                height={20}
            />
        </React.Fragment>
    );
}
