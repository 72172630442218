import React from "react";
import { t } from "i18next";
import { Card } from "reactstrap";

import { formatCurrency } from "@helpers/general";

export function RestoflashCard(): JSX.Element {
    return (
        <Card
            style={{
                width: "910px",
                height: "265px",
                borderRadius: "31px",
                borderBottom: "12px solid #D51B1B",
                boxShadow: "0px 3px 6px #00000029",
                font: "Segoe UI",
            }}
        >
            <div
                className="d-flex flex-column m-5"
                style={{
                    height: "100%",
                    gap: "40px",
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center px-2"
                    style={{
                        fontSize: "45px",
                    }}
                >
                    <div>{t("Amount to be paid")}</div>
                    <div>{formatCurrency(20)}</div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-center px-4"
                    style={{
                        fontSize: "30px",
                        color: "#7C7C7C",
                    }}
                >
                    <div>{t("Total order")}</div>
                    <div>{formatCurrency(100)}</div>
                </div>
            </div>
        </Card>
    );
}
