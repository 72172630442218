import { VALIDATED_EXTENSION_FILE, WITHOUT_BUTTON } from "@constants";
import PropTypes from "prop-types";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import { WithoutBasicComposition } from "@components/SvgIcons";

export function WithoutImage({ product }) {
    const { basicCompositionActive, id, title, outOfStock } = product;

    const { designItems } = useSelector(
        (state) => state.settingSlice,
        shallowEqual
    );
    const withoutButton = designItems.filter((item) => {
        return item.active === true && item.role === WITHOUT_BUTTON;
    });
    const withoutButtonContent = getImageContentById(withoutButton[0].id);

    return (
        !basicCompositionActive &&
        !outOfStock && (
            <div
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{
                    position: " absolute",
                    backgroundSize: "unset",
                    backgroundColor: "unset",
                    opacity: "0.5",
                    padding: "2.5vh",
                }}
            >
                {VALIDATED_EXTENSION_FILE.includes(
                    withoutButtonContent.substr(-4)
                ) ? (
                    <img
                        className="w-100 h-100"
                        loading="lazy"
                        id={`key-img${id}`}
                        src={withoutButtonContent}
                        alt={title}
                        onError={imageWithFallback}
                    />
                ) : (
                    <WithoutBasicComposition />
                )}
            </div>
        )
    );
}
WithoutImage.propTypes = {
    product: PropTypes.object,
};
