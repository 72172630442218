import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function Options(): JSX.Element {
    const { localProject } = useSnapshot(kioskStore);

    const activeOptions = (localProject.template.content.options as OptionsType)
        .items;

    return <MainContentWrapper items={activeOptions} contentName="options" />;
}
