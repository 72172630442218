import { snapshot } from "valtio";

import {
    kioskStore,
    setIsPrm,
    setNavigationIndex,
    setRenderAmount,
} from "@pages/Kiosk/store";

export const timerIsReached = (): void => {
    const {
        kioskWays,
        navigationIndex,
        totalAmountDeposited,
        isTpeWaitingTimeReached,
    } = snapshot(kioskStore);
    setIsPrm(false);
    console.log("initial Step Timeout detected");
    if (kioskWays[navigationIndex].name === "payment") {
        if (totalAmountDeposited !== 0) {
            setRenderAmount(totalAmountDeposited);
        }
        if (isTpeWaitingTimeReached) {
            setNavigationIndex(0);
        }
    } else {
        setNavigationIndex(0);
    }
};
