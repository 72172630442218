import { NO_CATEGORIES_ENABLED_FOR_SALESMODE } from "@constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { snapshot } from "valtio";

import { setIsTechProblemModalOpened, store } from "@store";

import { kioskStore, setNavigationIndex } from "@pages/Kiosk/store";

export const getCard = createAsyncThunk("carte/getCarte", async () => {
    let result;
    let url;
    let catalog = JSON.parse(localStorage.getItem("card"));
    const schema = localStorage.getItem("viewADB-schema");
    const uuidCatalog = localStorage.getItem("viewADB-iuudCard");
    const etktype = localStorage.getItem("viewADB-etktype");
    const { middlewareApiUrl } = snapshot(store);
    const { selectedSaleModeOrderTaking } = snapshot(kioskStore);
    const timestamp = new Date().getTime();

    if (schema !== null && uuidCatalog !== null) {
        url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}?timestamp=${timestamp}`;
        result = await fetch(url, {
            headers: { "Cache-Control": "no-cache" },
        }).then((data) => {
            const rawData = data.json();
            return rawData.error === true ? null : rawData;
        });
        if (result !== null) {
            const modifiedResult = JSON.parse(JSON.stringify(result));
            return {
                ...modifiedResult,
                globalCard: {
                    ...modifiedResult.globalCard,
                    cardType: etktype ? Number(etktype) : 3,
                },
            };
        }
        return null;
    }

    if (catalog === null) {
        const shopDetail = JSON.parse(localStorage.getItem("shopDetail"));
        if (shopDetail !== null) {
            url = `${middlewareApiUrl}/kiosk/${shopDetail.schema}/${shopDetail.id}/card?timestamp=${timestamp}`;
            if (schema !== null && uuidCatalog !== null) {
                localStorage.setItem("isAppSynchronized", true);
                url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}`;
            }

            result = await fetch(url, {
                headers: { "Cache-Control": "no-cache" },
            }).then((data) => {
                const rawData = data.json();
                return rawData.error === true ? null : rawData;
            });
        } else if (schema && uuidCatalog) {
            localStorage.setItem("isAppSynchronized", true);
            url = `${process.env.REACT_APP_CATALOGUE_API_URL}/Kioskview/${schema}/${uuidCatalog}?timestamp=${timestamp}`;
            result = await fetch(url).then((data) => {
                const rawData = data.json();
                return rawData.error === true ? null : rawData;
            });
        } else {
            result = await fetch("./card.json").then((data) => {
                return data.json();
            });
        }

        let salesModeActiveCategory = selectedSaleModeOrderTaking
            ? result.globalCategory.filter(
                  (cat) => cat.KioskVisibility[selectedSaleModeOrderTaking]
              )
            : [];

        if (
            selectedSaleModeOrderTaking &&
            salesModeActiveCategory.length === 0
        ) {
            console.log(
                "probleme technique, aucune categorie active pour le mode de vente",
                selectedSaleModeOrderTaking
            );

            setIsTechProblemModalOpened(
                true,
                NO_CATEGORIES_ENABLED_FOR_SALESMODE
            );
            setNavigationIndex(0);

            return {};
        }
        //! to remove after consol api fixed!
        //! begin
        const localShopDetail = JSON.parse(localStorage.getItem("shopDetail"));

        const newShopDetail = {
            ...localShopDetail,
            uid_franchise_database_info: result.schema_uuid,
        };
        console.log({
            ShopDetailOnCardGetting: JSON.stringify(newShopDetail),
        });

        localStorage.setItem("shopDetail", JSON.stringify(newShopDetail));
        //! end

        return {
            ...result,
            CategoryActive:
                salesModeActiveCategory.length > 0
                    ? salesModeActiveCategory[0].iuud
                    : result.CategoryActive,
        };
    } else {
        let salesModeActiveCategory = selectedSaleModeOrderTaking
            ? catalog.globalCategory.filter((cat) => {
                  return cat.KioskVisibility[selectedSaleModeOrderTaking];
              })
            : [];

        if (!salesModeActiveCategory.length === 0) {
            console.log(
                "probleme technique, aucune categorie active pour le mode de vente",
                selectedSaleModeOrderTaking
            );
            return {};
        }

        return {
            ...catalog,
            CategoryActive:
                salesModeActiveCategory.length > 0
                    ? salesModeActiveCategory[0].iuud
                    : catalog.CategoryActive,
        };
    }
});
