import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

export function SubStepsIconWrapper(): JSX.Element {
    const { subStepIconId } = useSnapshot(kioskStore);

    const { isPrm } = useSnapshot(kioskStore);

    const imageSrc = getImageContentById(subStepIconId);

    return (
        <div className="d-flex justify-content-center align-items-center">
            <img
                src={imageSrc}
                alt={imageSrc}
                style={{
                    height: isPrm ? "170px" : "230px",
                    width: isPrm ? "170px" : "230px",
                }}
            />
        </div>
    );
}
