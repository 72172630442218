import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NutritionalInformationDialog from "@components/OrderTaking/Components//Dialogs/NutritionalInformation/NutritionalInformationDialog";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import { toggleBasicCompositionitem } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import {
    BasicCompositionActions,
    BasicCompositionTitle,
    WithoutImage,
} from "./component";

function CardBasicComposition({ product, isAllergenIconActive }) {
    const dispatch = useDispatch();
    const { isPrm } = useSnapshot(kioskStore);

    const { isCoverImageActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    function ProductOnClick() {
        if (product.outOfStock || product.isObligatory || product.isSuspended)
            return;

        dispatch(toggleBasicCompositionitem(product.indexItem));
    }

    const [
        isNutritionalInformationDialogOpen,
        setIsNutritionalInformationDialogOpen,
    ] = React.useState(false);

    const handleNutritionalInformationDialogOnCloseEvent = () => {
        setIsNutritionalInformationDialogOpen(
            !isNutritionalInformationDialogOpen
        );
    };

    const { isProductTitleActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );

    const isTitleVisible = isProductTitleActive && product.name.isVisible;

    const minHeightBasicComposition = isTitleVisible
        ? product.heightOfImage
        : product.heightOfImage + 110;

    return (
        <>
            <NutritionalInformationDialog
                open={isNutritionalInformationDialogOpen}
                onClose={handleNutritionalInformationDialogOnCloseEvent}
                product={product}
                handleProductOnClickEvent={ProductOnClick}
                isAllergenIconActive={isAllergenIconActive}
                handleAllergenConfirmationDialogOnCLickEvent={() => {
                    console.log("composition de base");
                }}
            />
            <BasicCompositionActions
                product={product}
                isAllergenIconActive={isAllergenIconActive}
                handleNutritionalInformationDialogOnCloseEvent={
                    handleNutritionalInformationDialogOnCloseEvent
                }
                ProductOnClick={ProductOnClick}
            />
            <div className="d-flex justify-content-center align-items-center h-100">
                <WithoutImage product={product} />
                <div className="d-flex justify-content-end align-items-center h-100 w-100">
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            gap: "10px",
                            zIndex: 1,
                            height: isTitleVisible ? "auto" : "275px",
                            position: "relative",
                            minHeight: !isPrm && minHeightBasicComposition,
                            minWidth: !isPrm && product.widthOfImage,
                        }}
                        onClick={() => {
                            ProductOnClick();
                        }}
                    >
                        <img
                            style={{
                                opacity: !product.basicCompositionActive
                                    ? "40%"
                                    : "100%",
                                width: "100%",
                                minHeight: !isPrm && product.heightOfImage,
                                minWidth: !isPrm && product.widthOfImage,
                                objectFit:
                                    isCoverImageActive === true
                                        ? "cover"
                                        : "contain",
                            }}
                            loading="lazy"
                            id={`key-img${product.id}`}
                            className="basic-composition-image"
                            src={product.image}
                            alt={product.title}
                            onError={imageWithFallback}
                        />

                        {isTitleVisible ? (
                            <BasicCompositionTitle
                                product={product}
                                isPrm={isPrm}
                                isTitleVisible={isTitleVisible}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardBasicComposition;
CardBasicComposition.propTypes = {
    product: PropTypes.object.isRequired,
    isAllergenIconActive: PropTypes.bool,
};
