import { ALLERGENS_OPTION, ASK_FOR_HELP_BUTTON, PRM_BUTTON } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getOrdertakingDesignOptions } from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

import { HeaderConfigOptionsWrapper } from "../Components/OrderTakingConfigOptionsWrapper/HeaderOptions";

export function HelloUser({
    userAccountMessage,
    handleAllergenDialogOnCLickEvent,
}) {
    const { user, isUserAuthenticated, activeConfigOptions } =
        useSnapshot(kioskStore);

    const { isOrderTakingUserAccountActive } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );

    const { designItems } = useSelector((state) => state.settingSlice);

    const optionList = [PRM_BUTTON, ASK_FOR_HELP_BUTTON, ALLERGENS_OPTION];
    const allergen = designItems.find(
        (option) => option.name === ALLERGENS_OPTION
    );
    const newOptionConfig = getOrdertakingDesignOptions(
        activeConfigOptions,
        designItems,
        optionList
    );

    if (allergen?.active === true) {
        newOptionConfig.push(allergen);
    }

    const filteredOptions = newOptionConfig.filter((option) => {
        return (
            option.active === true &&
            (option.name.toLowerCase() === "customer account" ||
                option.name.toLowerCase() === "allergen")
        );
    });

    const optionsButton = (
        <HeaderConfigOptionsWrapper
            handleAllergenDialogOnCLickEvent={handleAllergenDialogOnCLickEvent}
            filteredOptions={filteredOptions}
        />
    );
    const isVisible = isOrderTakingUserAccountActive || optionsButton !== null;

    return (
        isVisible &&
        filteredOptions.length > 0 && (
            <div
                className="d-flex justify-content-between align-items-center authenticated-user px-3"
                style={{ backgroundColor: "transparent" }}
            >
                {isUserAuthenticated && isOrderTakingUserAccountActive ? (
                    <div className="authenticated-user-font">
                        {userAccountMessage}
                        <span className="font-weight-bold">
                            {user.firstName === ""
                                ? ` ${user.lastName}`
                                : ` ${user.firstName}`}
                        </span>
                    </div>
                ) : (
                    <div></div>
                )}
                {optionsButton}
            </div>
        )
    );
}

export const MemoisedHelloUser = React.memo(HelloUser);
HelloUser.propTypes = {
    handleAllergenDialogOnCLickEvent: PropTypes.func,
    userAccountMessage: PropTypes.string,
};
