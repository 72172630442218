import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Nutriscore } from "@components/OrderTaking/common/Nutriscore";

import { kioskStore } from "@pages/Kiosk/store";

import { ProductAllergen } from "./ProductAllergen";
import { ProductCalorie } from "./ProductCalorie";
import { MemoizedProductImage } from "./ProductImage";
import { SimpleProductActionsButtons } from "./SimpleProductActionsButtons";

export function FirstBoxCardProduct({
    product,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    handleNutritionalInformationDialogOnCloseEvent,
    opacity,
    quantity,
    handleRemoveProductFromOrder,
}) {
    const { isPrm } = useSnapshot(kioskStore);

    const {
        isNutriscoreOnTheProductActive,
        //@eslint-disable-next-line
    } = useSelector((state) => state.settingSlice.productSetting, shallowEqual);
    const styles = {
        height: "100%",
        width: "90%",
        paddingRight: "5px",
        paddingLeft: "5px",
    };
    let { image, heightOfImage, widthOfImage } = product;

    heightOfImage = !isPrm ? heightOfImage + "px" : "auto";
    widthOfImage = !isPrm ? widthOfImage + "px" : "auto";

    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const isActionsButtonIconShown =
        !(product.outOfStock || product.isSuspended) ||
        (product.outOfStock && orderWorkflowItemSlice.isModification);

    const isInformationIconHidden =
        !(product.outOfStock || product.isSuspended) &&
        product.showProductIcons === false;

    return (
        <div
            className="first-box"
            style={{
                flex: 1,
                position: "relative",
                minHeight: heightOfImage,
                minWidth: widthOfImage,
                maxHeight: heightOfImage,
                maxWidth: widthOfImage,
            }}
            onClick={() => {
                !(product.outOfStock || product.isSuspended) &&
                opacity !== "0.2"
                    ? isAllergenIconActive === true
                        ? setIsAllergenConfirmationDialogOpened(true)
                        : handleAddProductClick()
                    : handleAddProductClick();
            }}
        >
            <div className="d-flex justify-content-end  align-items-start">
                <MemoizedProductImage
                    productId={product.iuud}
                    productType={product.type}
                    url={image}
                    heightOfImage={heightOfImage}
                    widthOfImage={widthOfImage}
                />
                {isActionsButtonIconShown && (
                    <div className="d-flex align-items-start justify-content-end">
                        <SimpleProductActionsButtons
                            product={product}
                            handleNutritionalInformationDialogOnCloseEvent={
                                handleNutritionalInformationDialogOnCloseEvent
                            }
                            handleRemoveProductFromOrder={
                                handleRemoveProductFromOrder
                            }
                            isAllergenIconActive={isAllergenIconActive}
                            opacity={opacity}
                            quantity={quantity}
                        />

                        {isInformationIconHidden && (
                            <div
                                className="d-flex align-items-end justify-content-end"
                                style={{
                                    position: "absolute",
                                    zIndex: "9999",
                                    bottom: "3px",
                                    width: "100%",
                                    height: "15%",
                                    maxHeight: "35px",
                                    paddingRight: "7px",
                                }}
                            >
                                <ProductCalorie product={product} />

                                {isNutriscoreOnTheProductActive &&
                                product.nutriScore !== undefined &&
                                Object.keys(product.nutriScore).length > 0 ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            height: "90%",
                                            borderRadius: "0.5vh",
                                            background: "white",
                                            border: "1px #EEEEEE",
                                            width: "auto",
                                            maxWidth: "65px",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        <Nutriscore
                                            nutriscoreType={
                                                product?.nutriScore.label
                                            }
                                            styles={styles}
                                        />
                                    </div>
                                ) : null}
                                <ProductAllergen
                                    isPrm={isPrm}
                                    isAllergenIconActive={isAllergenIconActive}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
FirstBoxCardProduct.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
    handleAddProductClick: PropTypes.func,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
};
