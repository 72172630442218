/* eslint-disable @typescript-eslint/no-explicit-any */
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { memoizedSelectedRewards } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { CategoryCard, LoyaltyCard } from "./LoyaltyCard";
import { RewardsContainer, RewardTitle } from "./styledCss";

type rewardSwiperType = {
    rewardList: any[];
    categoryData: {
        rewardLoyaltyPoints: number;
        program_id: number;
        reward_name: {
            DE: string;
            EN: string;
            ES: string;
            FR: string;
            PT: string;
        };
        image_url: string;
        reward_item_reference: string;
        reward_category_reference: string;
        loyalty_points: string;
    };
};

export default function RewardSection(): ReactElement<any, any> | null {
    const {
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: { rewards },
                        },
                    },
                },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    return rewards.active ? (
        <RewardsContainer>
            <RewardTitle>
                <strong>{rewards.languages[customerLanguage.name]} </strong>
            </RewardTitle>
            <RewardSwiperContainer />
        </RewardsContainer>
    ) : null;
}

function RewardSwiperContainer(): JSX.Element {
    const rewards = useSelector((state: any) => memoizedSelectedRewards(state));
    return (
        <Swiper
            slidesPerView={3.3}
            spaceBetween={5}
            direction={"vertical"}
            className="position-absolute w-100 h-100"
            style={{
                maxWidth: "60.5rem",
                maxHeight: "63rem",
                marginTop: "5rem",
                padding: "0.5rem",
                marginLeft: "1rem",
                boxShadow:
                    "inset 0px -2px 9px -7px rgba(0, 0, 0, 0.4), inset 0px 2px 9px -7px rgba(0, 0, 0, 0.4)",
                borderRadius: "10px",
            }}
        >
            {rewards.map((reward: any, index: number) => {
                const { rewardList, ...categoryData } = reward;
                return (
                    <SwiperSlide key={index}>
                        <RewardListContainer
                            rewardList={rewardList}
                            categoryData={categoryData}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

function RewardListContainer({
    rewardList,
    categoryData,
}: rewardSwiperType): JSX.Element {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <CategoryCard categoryData={categoryData} />
            <Swiper
                slidesPerView={3.3}
                spaceBetween={10}
                direction={"horizontal"}
                className="position-absolute w-100"
                style={{
                    marginLeft: "15rem",
                    maxWidth: "45rem",
                }}
            >
                {rewardList.map((reward, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <LoyaltyCard
                                isPromoCode={false}
                                couponData={reward}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
