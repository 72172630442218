import { CATEGORY_TYPE } from "@constants";
import PropTypes from "prop-types";

import React from "react";

import {
    FirstBoxCardProduct,
    FirstBoxRedirectCard,
    SecondBoxCardProduct,
} from "./component";

export default function CardProductSimple({
    product,
    opacity,
    quantity,
    isAllergenIconActive,
    setIsAllergenConfirmationDialogOpened,
    handleAddProductClick,
    handleNutritionalInformationDialogOnCloseEvent,
    handleRemoveProductFromOrder,
}) {
    return (
        <div
            className="d-flex flex-column"
            style={{
                height: "100%",
            }}
        >
            {product.type === CATEGORY_TYPE ? (
                // Redirect button & sub category
                <FirstBoxRedirectCard
                    handleAddProductClick={handleAddProductClick}
                    product={product}
                />
            ) : (
                <FirstBoxCardProduct
                    product={product}
                    isAllergenIconActive={isAllergenIconActive}
                    setIsAllergenConfirmationDialogOpened={
                        setIsAllergenConfirmationDialogOpened
                    }
                    handleAddProductClick={handleAddProductClick}
                    opacity={opacity}
                    handleNutritionalInformationDialogOnCloseEvent={
                        handleNutritionalInformationDialogOnCloseEvent
                    }
                    quantity={quantity}
                    handleRemoveProductFromOrder={handleRemoveProductFromOrder}
                />
            )}
            <SecondBoxCardProduct
                product={product}
                quantity={quantity}
                handleAddProductClick={handleAddProductClick}
                opacity={opacity}
                isAllergenIconActive={isAllergenIconActive}
                setIsAllergenConfirmationDialogOpened={
                    setIsAllergenConfirmationDialogOpened
                }
            />
        </div>
    );
}

CardProductSimple.propTypes = {
    product: PropTypes.object.isRequired,
    opacity: PropTypes.string,
    quantity: PropTypes.number,
    isAllergenIconActive: PropTypes.bool,
    setIsAllergenConfirmationDialogOpened: PropTypes.func,
    handleAddProductClick: PropTypes.func,
    handleNutritionalInformationDialogOnCloseEvent: PropTypes.func,
    handleProductSizeDialogOnCloseEvent: PropTypes.func,
    handleRemoveProductFromOrder: PropTypes.func,
    productId: PropTypes.string,
};
