import {
    ADD_TO_ORDER_BUTTON_ROLE,
    ALLERGENS,
    BACK_BUTTON_ROLE,
    BASIC_COMPOSITION,
    CATALOGUE_MODE,
    COMPOSITIONS,
    ITEM_TYPE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog, DialogActions, Slide } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFilenameFromUrl, getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { Nutriscore } from "@components/OrderTaking/common/Nutriscore";
import { AllergenCard } from "@components/OrderTaking/Components/Dialogs/Allergen/AllergenCard";
import { AlertIcon } from "@components/OrderTaking/Components/Icons/AlertIcon";
import { CalorieIcon } from "@components/OrderTaking/Components/Icons/CalorieIcon";
import { EuroIcon } from "@components/OrderTaking/Components/Icons/EuroIcon";
import { AllergenPrmLogoWrapper } from "@components/OrderTaking/Components/Prm/Dialogs/Allergens/AllergenPrmLogoWrapper";
import { getProductTitle } from "@components/OrderTaking/Helpers";
import { memoizedGlobalAllergens } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import { ProductComposition } from "./ProductComposition";
import { useStyles } from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function NutritionalInformationDialog({
    open,
    onClose,
    product,
    handleProductOnClickEvent,
    isAllergenIconActive,
    handleAllergenConfirmationDialogOnCLickEvent,
    isValidateButtonActive = false,
}) {
    const {
        isPrm,
        project: {
            template: { content },
        },
        devise,
    } = useSnapshot(kioskStore);

    let productAllergens = [];
    let items = [];
    let num = isPrm ? 5 : 11;

    const NutriscoreStyles = {
        height: "60%",
        width: "50%",
    };
    const [activeTab, setActiveTab] = React.useState(
        product.basic_composition === undefined ||
            product?.basic_composition?.length !== 0
            ? COMPOSITIONS
            : ALLERGENS
    );
    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.generalSetting,
        shallowEqual
    );
    const { isNutriscoreInTheInformationPopUpActive, isCalorieActive } =
        useSelector((state) => state.settingSlice.productSetting, shallowEqual);

    const globalAllergens = useSelector((state) =>
        memoizedGlobalAllergens(state)
    );

    const filteredButtons = actionsButtons.filter((item) => {
        return item.role === ADD_TO_ORDER_BUTTON_ROLE;
    })[0];

    const backButton = content.actionsButtons.items.filter((item) => {
        return item.role === BACK_BUTTON_ROLE;
    })[0];
    const styles = useStyles();

    const productImage = getFilenameFromUrl(product.image, "catalog");

    const validateImageSrc = getImageContentById(filteredButtons.id);

    const backImageSrc = getImageContentById(backButton.id);

    const productDescriptionContent = getProductTitle(product.description);

    const title = getProductTitle(product.name);
    

    if ((product?.allergens !== undefined && product?.allergens.length) !== 0) {
        for (const key in globalAllergens) {
            const newData = globalAllergens[key]?.filter((allergen) =>
                product?.allergens?.includes(allergen.id)
            );
            productAllergens = [...productAllergens, ...newData];
        }
    }

    if (productAllergens.length !== 0) {
        productAllergens.forEach((_, i) => {
            if (i % num === 0) {
                items.push(
                    <div
                        className="d-flex flex-column"
                        style={{
                            gap: "10px",
                            height: isPrm ? "300px" : "100%",
                        }}
                        key={i}
                    >
                        {productAllergens.slice(i, i + num).map((allergen) => {
                            const allergenIcon = getFilenameFromUrl(
                                allergen.icon,
                                "catalog"
                            );
                            return (
                                <AllergenCard
                                    key={allergen.id}
                                    allergen={allergen}
                                    allergenIcon={allergenIcon}
                                    isProduct={true}
                                />
                            );
                        })}
                    </div>
                );
            }
            i = i + num;
        });
    }
  
    return (
        <Dialog
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "1010px!important",
                    height: "1823px!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    borderRadius: "111px 111px 0px 0px!important",
                    borderTop: `15px solid var(--global-color)!important`,
                    maxWidth: "unset!important",
                    padding: "25px!important",
                    gap: "20px!important",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "unset",
                    top: "44px!important",
                    alignItems: "center!important",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            TransitionComponent={Transition}
            style={{ backdropFilter: "blur(5px)" }}
        >
            {isPrm ? <AllergenPrmLogoWrapper /> : null}
            <div className={styles.title}>{t("Nutritional Information")}</div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "307px", gap: "50px" }}
            >
                <div className="d-flex justify-content-center align-items-center card-media h-100">
                    <img
                        className="h-100"
                        alt={product.name?.content}
                        src={productImage}
                        style={{
                            borderRadius: "15px",
                            width: "auto",
                        }}
                    />
                </div>
                <div className={styles.customPaper}>
                    <div className={styles.element}>
                        <EuroIcon />
                        <span>
                            {product.price?.content.toFixed(
                                devise.decimalPrice
                            ) + ` ${devise.deviseSymbole}`}{" "}
                        </span>
                    </div>
                    {isCalorieActive &&
                    product.type === ITEM_TYPE &&
                    product.calorie?.isVisible ? (
                        <div className={styles.element}>
                            <CalorieIcon />
                            <span>{product.calorie?.content} Kcal</span>
                        </div>
                    ) : null}
                    {isNutriscoreInTheInformationPopUpActive &&
                    product.nutriScore !== undefined &&
                    Object.keys(product.nutriScore).length > 0 &&
                    product.nutriScore.label !== "" ? (
                        <div className={styles.element}>
                            <div className="pl-3 d-flex justify-content-center align-items-center">
                                <Nutriscore
                                    nutriscoreType={product?.nutriScore.label}
                                    styles={NutriscoreStyles}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="d-flex flex-column w-100" style={{ gap: "30px" }}>
                <div
                    className={styles.subTitle}
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingLeft: "15px",
                    }}
                >
                    {title}
                </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: "1vh" }}>
                <div style={{ height: "100%" }}>
                    {productDescriptionContent ? (
                        <span
                            className="justify-content-center align-content-center three-lines-preview-text  px-3 h-100"
                            style={{
                                font: "normal normal normal 25px/30px Segoe UI",
                                padding: "5px",
                            }}
                        >
                            {productDescriptionContent}
                        </span>
                    ) : (
                        <span
                            className="justify-content-center align-content-center three-lines-preview-text  px-3 h-100"
                            style={{
                                font: "normal normal normal 25px/30px Segoe UI",
                                padding: "5px",
                            }}
                        ></span>
                    )}
                </div>
                <div className={styles.customCard} style={{ height: "100%" }}>
                    <div
                        className="pl-3"
                        style={{
                            font: "normal normal normal 25px/30px Segoe UI",
                            padding: "5px",
                        }}
                    >
                        {t(
                            "Using this option may alert you to the presence of allergen(s) in the selected product."
                        )}
                    </div>
                </div>
            </div>
            <div
                className="d-flex"
                style={{
                    flexDirection: isPrm ? "column-reverse" : "column",
                    gap: "40px",
                    marginBottom: "50px",
                }}
            >
                <div
                    className={styles.mainCard}
                    style={{ height: isPrm ? "387px" : "auto" }}
                >
                    <div className="d-flex justify-content-center align-items-center">
                        {product.type !== BASIC_COMPOSITION ? (
                            product?.basic_composition?.length > 0 ? (
                                <div
                                    className={
                                        activeTab === COMPOSITIONS
                                            ? styles.activeCardTab
                                            : styles.cardTab
                                    }
                                    style={{
                                        background:
                                            activeTab === COMPOSITIONS
                                                ? `var(--global-color)`
                                                : "#ffffff 0% 0% no-repeat padding-box",
                                    }}
                                    onClick={() => {
                                        setActiveTab(COMPOSITIONS);
                                    }}
                                >
                                    {t("Basic composition")}
                                </div>
                            ) : null
                        ) : null}

                        <div
                            className={
                                activeTab === ALLERGENS
                                    ? styles.activeCardTab
                                    : styles.cardTab
                            }
                            style={{
                                width:
                                    product?.basic_composition?.length === 0 &&
                                    "100%",
                                background:
                                    activeTab === ALLERGENS
                                        ? `var(--global-color)`
                                        : "#ffffff 0% 0% no-repeat padding-box",
                            }}
                            onClick={() => {
                                setActiveTab(ALLERGENS);
                            }}
                        >
                            {t("Allergen")}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center py-3">
                        <div
                            className="d-flex"
                            style={{
                                width: "875px",
                                height: isPrm ? "290px" : "678px",
                                gap: "30px",
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                            }}
                        >
                            {activeTab === COMPOSITIONS ? (
                                product?.basic_composition?.length !== 0 ? (
                                    <ProductComposition
                                        basicCompositionsIds={
                                            product?.basic_composition
                                        }
                                    />
                                ) : null
                            ) : items.length !== 0 ? (
                                items
                            ) : (
                                <div
                                    className="d-flex flex-column  justify-content-center align-items-center w-100"
                                    style={{
                                        font: "normal normal normal 25px/30px Segoe UI",
                                        color: "#000",
                                        gap: "10px",
                                        height: isPrm ? "300px" : "100%",
                                    }}
                                >
                                    {t(
                                        "No allergen mentioned on this product, for more information please contact the seller. THANKS"
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center px-2"
                    style={{ gap: "20px", width: "924px" }}
                >
                    <AlertIcon />
                    <div className={styles.text}>
                        {t("Our products may contain one or more allergens.")}
                        <br />
                        {t(
                            "For more information please contact a manager. Thank you."
                        )}
                    </div>
                </div>
            </div>
            <DialogActions>
                <div
                    className="d-flex justify-content-end align-items-center"
                    style={{ gap: "15px" }}
                >
                    {backImageSrc !== "" ? (
                        <div className="mx-2 " onClick={onClose}>
                            {VALIDATED_EXTENSION_FILE.includes(
                                backImageSrc.substr(-4)
                            ) ? (
                                <div
                                    style={{
                                        backgroundSize: " 100% 100%",
                                        backgroundImage: `url(${backImageSrc})`,
                                        height: "87px",
                                        width: "350px",
                                    }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={backImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}
                        </div>
                    ) : (
                        <img
                            alt="backButton"
                            src="./images/back.png"
                            style={{ height: "87px", width: "350px" }}
                            onClick={onClose}
                        />
                    )}

                    {isValidateButtonActive ? (
                        validateImageSrc !== "" ? (
                            <div
                                className="mx-2"
                                onClick={() => {
                                    isAllergenIconActive === true
                                        ? handleAllergenConfirmationDialogOnCLickEvent()
                                        : handleProductOnClickEvent();
                                    onClose();
                                }}
                            >
                                {VALIDATED_EXTENSION_FILE.includes(
                                    validateImageSrc.substr(-4)
                                ) ? (
                                    <div
                                        style={{
                                            backgroundSize: " 100% 100%",
                                            backgroundImage: `url(${validateImageSrc})`,
                                            height: "87px",
                                            width: "461px",
                                        }}
                                    />
                                ) : (
                                    <DynamicSvgComponent
                                        nameSvg={validateImageSrc}
                                        height={"87px"}
                                        width={"461px"}
                                    />
                                )}
                            </div>
                        ) : (
                            <img
                                component="img"
                                alt="addToBasketButton"
                                src="./images/addToBasket.png"
                                style={{ width: "461px" }}
                                onClick={() => {
                                    isAllergenIconActive === true
                                        ? handleAllergenConfirmationDialogOnCLickEvent()
                                        : handleProductOnClickEvent();
                                    onClose();
                                }}
                            />
                        )
                    ) : null}
                </div>
            </DialogActions>
        </Dialog>
    );
}

NutritionalInformationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    handleProductOnClickEvent: PropTypes.func.isRequired,
    isAllergenIconActive: PropTypes.bool.isRequired,
    handleAllergenConfirmationDialogOnCLickEvent: PropTypes.func.isRequired,
    isValidateButtonActive: PropTypes.bool,
};
