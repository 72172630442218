import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function EditButton(): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="131"
            height="53"
            viewBox="0 0 131 53"
        >
            <g
                id="Groupe_34730"
                data-name="Groupe 34730"
                transform="translate(7907 19229)"
            >
                <rect
                    id="Rectangle_13250"
                    data-name="Rectangle 13250"
                    width="131"
                    height="53"
                    rx="9"
                    transform="translate(-7907 -19229)"
                    fill="#eaeaea"
                />
                <text
                    id="Modifier"
                    transform="translate(-7841 -19194)"
                    fontSize="21"
                    fontFamily="Helvetica"
                >
                    <tspan x="-37.345" y="0">
                        {t("Modify", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
