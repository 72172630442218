import styled from "styled-components";

export const Styles = styled.div`
    padding: 1rem;
    min-height: 550px;
    border: 1px solid #c6c6c6;
    table {
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            text-align: left;
                        }
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            padding: 0.5rem;
            height: 30px;
            text-align: center;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 30%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 30%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;
