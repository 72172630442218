import { ORDER_VALDATION__TIMEOUT_TCPOS } from "@constants";
import { snapshot } from "valtio";

import { store } from "@store";

import {
    kioskStore,
    setIsCommandInsertionProblemModalOpened,
    setIsOrderInsertedSuccessfully,
    setIsOrderInsertionRefused,
    setIsTpeNotAvailable,
    setNavigationIndex,
} from "@pages/Kiosk/store";

export function validateTCPOSCommand() {
    const { middlewareApiUrl } = snapshot(store);
    const {
        kioskWays,
        selectedRegulationModeValue,
        isPrintTicket,
        informationModeValueTcpos,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();
    const controller = new AbortController();
    const timeoutId = setTimeout(
        () => controller.abort(),
        ORDER_VALDATION__TIMEOUT_TCPOS
    );
    const signal = controller.signal;

    const url = `${
        middlewareApiUrl.split(":5")[0]
    }:5008/tcpos/v1/validateOrder?paymentMode=${
        selectedRegulationModeValue === "creditCard" ? "CB" : "atTheCounter"
    }&withPrint=${isPrintTicket}&modeInfo=${informationModeValueTcpos}&timestamp=${timestamp}`;

    fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        signal,
    })
        .then((response) => response.json())
        .then((result) => {
            if (result.code === 1002) {
                setIsOrderInsertedSuccessfully(true);
                const finalMessageIndex = kioskWays.indexOf(
                    kioskWays.filter((page) => page?.name === "finalMessage")[0]
                );
                setNavigationIndex(finalMessageIndex);
            } else {
                setIsTpeNotAvailable(true);
            }
        })
        .catch((error) => {
            console.log(
                `Error on TCPOS Client while handling put command request, with message: ${error}`
            );
            setIsCommandInsertionProblemModalOpened(true);
            setIsOrderInsertionRefused(true);
            setIsTpeNotAvailable(true);
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}
