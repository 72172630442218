import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "./ImageContent";
import "./index.css";

import "swiper/swiper-bundle.min.css";

type PropsType = {
    setIsTpaValinaModalOpened: (item: boolean) => void;
};
export function PrmMainContentWrapper({
    setIsTpaValinaModalOpened,
}: PropsType): JSX.Element {
    const { mainContentItems, customerLanguage } = useSnapshot(kioskStore);

    const activeItems = (
        (mainContentItems as PaymentMainContentItemType[]).filter(
            (item: PaymentMainContentItemType) =>
                item.shortName === "creditCard"
        )[0].languages[customerLanguage.name].paymentInstructions
            .TPAValina as TPAValinaType
    ).items;

    const activeItemsLength = activeItems.length;

    return (
        <React.Fragment>
            {activeItemsLength <= 3 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeItems?.map(
                        (item: TPAValinaItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeItemsLength={activeItemsLength}
                                setIsTpaValinaModalOpened={
                                    setIsTpaValinaModalOpened
                                }
                            />
                        )
                    )}
                </div>
            ) : (
                <Swiper
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={40}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                >
                    {activeItems.map(
                        (item: TPAValinaItemType, index: number) => (
                            <SwiperSlide
                                key={item.id}
                                className="main-content-prm-swiper-slide__clz"
                            >
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeItemsLength={activeItemsLength}
                                    setIsTpaValinaModalOpened={
                                        setIsTpaValinaModalOpened
                                    }
                                />
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            )}
        </React.Fragment>
    );
}
