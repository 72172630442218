import PropTypes from "prop-types";

import React from "react";

import { getImageContentById } from "@helpers/general";

export function SalesMethodsItems({
    salesMode,
    handleOnCLickEvent,
    setSalesMethod,
    setIsOpen,
    isOpen,
}) {
    const imageSrc = getImageContentById(salesMode.id);

    return (
        <img
            className="sales-method-image"
            src={imageSrc}
            onClick={() => {
                handleOnCLickEvent(salesMode);
                setSalesMethod(salesMode);
                setIsOpen(!isOpen);
            }}
        />
    );
}

SalesMethodsItems.propTypes = {
    salesMode: PropTypes.object,
    handleOnCLickEvent: PropTypes.func,
    setSalesMethod: PropTypes.func,
    setIsOpen: PropTypes.func,
    isOpen: PropTypes.bool,
};
