import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function OrderNumber(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        orderNumber,
    } = useSnapshot(kioskStore);

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
                font: "normal 91px/121px Segoe UI",
                height: "398px",
                color: `${
                    (generalDesign as GeneralDesignType).colors[0].content
                }`,
            }}
        >
            {process.env.REACT_APP_MODE === CATALOGUE_MODE
                ? "0001"
                : orderNumber}
        </div>
    );
}
