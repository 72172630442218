import { BASIC_COMPOSITION } from "@constants";

export default function canAddItemToShopCard(
    quantity,
    product,
    workflowData,
    orderWorkflowItemSlice
) {
    if (
        workflowData === undefined ||
        workflowData?.length === 0 ||
        product.type === BASIC_COMPOSITION ||
        !product.isStepItem
    )
        return true;

    let quantityWithSpecialPrice = 0;
    let quantityWithStepPrice = 0;

    if (Object.keys(orderWorkflowItemSlice.item.shopCart).length > 0) {
        let curentStepItems = workflowData[workflowData.length - 1]?.workflow[
            workflowData[workflowData.length - 1].index
        ].path.reduce((prev, key) => {
            return prev[key.name] || {};
        }, orderWorkflowItemSlice.item.shopCart);

        Object.values(curentStepItems)
            .filter((item) => item.iuud === product.iuud)
            .forEach((item) => {
                if (item.withSpecialPrice) {
                    quantityWithSpecialPrice += item.quantity;
                } else if (item.withStepPrice) {
                    quantityWithStepPrice += item.quantity;
                }
            });
    }

    const {
        nbrOfChoicesWithspecialPrice,
        selectedChoicesWithspecialPrice,
        nbrOfChoicesWithStepPrice,
        selectedChoicesWithStepPrice,
    } =
        workflowData[workflowData.length - 1]?.workflow[
            workflowData[workflowData.length - 1].index
        ];

    // ! in progresss
    if (product.maxNbrOfChoicesInStep === 0) return false;

    if (
        quantity === product.maxNbrOfChoicesInStep &&
        product.maxNbrOfChoicesInStep !== null
    ) {
        return false;
    }

    if (
        product.maxNbrOfChoicesInStep > 0 ||
        product.maxNbrOfChoicesInStep === null
    ) {
        // ? stepClick control with special price
        if (
            (nbrOfChoicesWithspecialPrice > 0 ||
                nbrOfChoicesWithspecialPrice === null) &&
            selectedChoicesWithspecialPrice < nbrOfChoicesWithspecialPrice
        ) {
            if (product.nbrOfChoicesWithspecialPrice === null) return true;

            if (
                product.nbrOfChoicesWithspecialPrice > 0 &&
                quantityWithSpecialPrice ===
                    product.nbrOfChoicesWithspecialPrice
            )
                return false;
        }
        // ? stepClick control with step price
        if (
            (nbrOfChoicesWithStepPrice > 0 ||
                nbrOfChoicesWithStepPrice === null) &&
            selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice
        ) {
            if (product.nbrOfChoicesWithStepPrice === null) return true;

            if (
                product.nbrOfChoicesWithStepPrice > 0 &&
                quantityWithStepPrice === product.nbrOfChoicesWithStepPrice
            )
                return false;
        }
    }

    return true;

    // ! in progress
    // if (product.maxNbrOfChoicesInStep === 0) {
    //     canAdd = false;
    // } else if (quantity === product.maxNbrOfChoicesInStep) {
    //     canAdd = false;
    // } else if (
    //     nbrOfChoicesWithspecialPrice > 0 &&
    //     selectedChoicesWithspecialPrice < nbrOfChoicesWithspecialPrice &&
    //     ((product.nbrOfChoicesWithspecialPrice > 0 &&
    //         quantityWithSpecialPrice ===
    //             product.nbrOfChoicesWithspecialPrice) ||
    //         product.nbrOfChoicesWithspecialPrice === 0)
    // ) {
    //     canAdd = false;
    // } else if (
    //     nbrOfChoicesWithStepPrice > 0 &&
    //     selectedChoicesWithStepPrice < nbrOfChoicesWithStepPrice &&
    //     product.nbrOfChoicesWithStepPrice > 0 &&
    //     quantityWithStepPrice === product.nbrOfChoicesWithStepPrice
    // ) {
    //     canAdd = false;
    // }

    // return canAdd;
}
