// eslint-disable-next-line
export async function fetcher(url: string): Promise<any> {
    try {
        const response = await fetch(url);

        const text = await response.text();

        const data = text ? JSON.parse(text) : null;

        if (response.ok) {
            return data;
        } else {
            throw new Error("Error while fetching data");
        }
    } catch (error) {
        if ((error as ObjectType).data !== undefined) {
            (error as ObjectType).data = {
                message: (error as ObjectType).message,
            };
        }
        throw error;
    }
}

export function saveMiddlewareIpAddress(middlewareIpAddress: string): void {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), 30000);
    const signal = abortController.signal;
    console.log("Middleware IP address: ", middlewareIpAddress);
    fetch(`http://localhost:6009/api/v1/kiosk/middleware-ip-address`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ middlewareIpAddress }),
        signal,
    })
        .then((response) => {
            if (response.status === 201) {
                console.log(
                    "Successfully send middleware IP Address to Local agent"
                );
                return;
            } else if (response.status === 301) {
                console.log(
                    "Error on writing file or permission denied to save middleware address on local agent"
                );
            }
        })
        .catch((err) => {
            console.log(
                `Something went wrong on save middleware IP address on agent local: ${err}`
            );
        })
        .finally(() => clearTimeout(timeoutId));
}
