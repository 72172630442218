import { CATALOGUE_MODE, VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { ManHeadIconWrapper } from "@components/common/ManHeadIconWrapper";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import { kioskStore, setIsUserAccountModalOpened } from "@pages/Kiosk/store";

import { MainContentWrapper } from "./MainContentWrapper";

export function PrmMode(): JSX.Element {
    const {
        isUserAccountModalOpened,
        customerLanguage,
        project: {
            template: {
                content: { generalDesign },
            },
            files: images,
        },
    } = useSnapshot(kioskStore);

    const backButtonImage = Object.values(images).filter(
        (item: ImageItemType) => {
            return item.name?.toLowerCase() === "back";
        }
    )[0].content as ImageItemLanguagesType;

    const imgSrc = backButtonImage[customerLanguage.name].path.includes("http")
        ? backButtonImage[customerLanguage.name].path
        : `./images/${backButtonImage[customerLanguage.name].path}`;

    return (
        <Modal
            isOpen={isUserAccountModalOpened}
            ariaHideApp={false}
            onRequestClose={() => {
                setIsUserAccountModalOpened(!isUserAccountModalOpened);
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    overflow: "hidden",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <ManHeadIconWrapper />
            <div
                className="d-flex justify-content-center w-100"
                style={{ height: "900px", width: "865px" }}
            >
                <div
                    className="d-flex flex-column text-center"
                    style={{
                        font: "normal 50px/50px Segoe UI",
                        gap: "10px",
                        height: "167px",
                    }}
                >
                    <span>{t("Please choose")}</span>
                    <span>{t("your identification mode")}</span>
                </div>
            </div>
            <MainContentWrapper />
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "80px" }}
                onClick={() => {
                    setIsUserAccountModalOpened(!isUserAccountModalOpened);
                }}
            >
                {VALIDATED_EXTENSION_FILE.includes(imgSrc.substr(-4)) ? (
                    <img
                        src={imgSrc}
                        alt="back"
                        style={{ width: "281px", height: "70px" }}
                    />
                ) : (
                    <DynamicSvgComponent
                        nameSvg={backButtonImage[customerLanguage.name].path}
                        width={"281px"}
                        height={"70px"}
                    />
                )}
            </div>
        </Modal>
    );
}
