import { CATALOGUE_MODE } from "@constants";
import { Alert } from "@material-ui/lab";
import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import { CommentKeyboard } from "@components/OrderTaking/common/CommentKeyboard";
import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={700} ref={ref} {...props} />;
});

export default function DialogCart({
    openDialogCart,
    setOpenDialogCart,
    isSummaryOrderOpened,
    setIsSummaryOrderOpened,
    setIsLocalKeyBoardOpened,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    const orderItems = useSelector((state) => memoizedOrderItems(state));

    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );

    const { isCommentKeyboardActive } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }

    return (
        <React.Fragment>
            <Dialog
                TransitionComponent={TransitionSlide}
                open={openDialogCart}
                container={() => document.querySelector(".fullscreen")}
                PaperProps={{
                    sx: {
                        maxWidth: "100%",
                        width: "100%",
                        height: isPrm ? "28%" : "100%",
                        position: "fixed",
                        bottom: "-32px",
                        overflowY: "visible",
                        zoom: isPrm
                            ? `${
                                  process.env.REACT_APP_MODE === CATALOGUE_MODE
                                      ? "50%"
                                      : "100%"
                              }`
                            : "none",
                    },
                }}
            >
                <div
                    className="cart-close-button"
                    onClick={() => setOpenDialogCart(false)}
                >
                    <span className="label-close-cart">{t("Reduce")}</span>
                </div>
                <div
                    className={isPrm ? "prm-header-popup" : "header-popup__clz"}
                >
                    <div
                        className={
                            isPrm
                                ? "prm-title-popup-cart"
                                : "title-popup-cart__clz"
                        }
                    >
                        {t("Your order")}
                    </div>
                    <div
                        className={
                            isPrm
                                ? "prm-number-product-in-cart"
                                : "number-poroduct-in-cart"
                        }
                    >
                        {itemsCount} {t("Articles in your cart")}
                    </div>
                    <hr className="separation-line" />
                </div>
                <section
                    className={
                        isPrm
                            ? "prm-cart-products-list"
                            : "cart-products-list__clz py-2"
                    }
                >
                    {!orderItems.length ? (
                        <Alert severity="error">
                            {t("No product available in cart")}
                        </Alert>
                    ) : (
                        orderItems.map((product) => (
                            <ComposedProductCartPopup
                                key={product.iuudOrder}
                                product={product}
                                isDialogCart={true}
                            />
                        ))
                    )}
                </section>
                {!isPrm ? <hr className="separation-line" /> : null}
                {isCommentKeyboardActive && !isSummaryOrderOpened ? (
                    <CommentKeyboard
                        setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                    />
                ) : (
                    <BasketActionsButtons
                        openDialogCart={openDialogCart}
                        setOpenDialogCart={setOpenDialogCart}
                        isSummaryOrderOpened={isSummaryOrderOpened}
                        setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                        setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                    />
                )}
            </Dialog>
        </React.Fragment>
    );
}

DialogCart.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    isSummaryOrderOpened: PropTypes.bool,
    setIsSummaryOrderOpened: PropTypes.func,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
