/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELECTRON_MODE } from "@constants";

export default function initLogger(): void {
    if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
        console.log = (...args) => {
            (window as any).electronAPI?.logMessage(args);
        };
        console.info = (...args) => {
            (window as any).electronAPI?.logMessage(args);
        };
        console.error = (...args) => {
            (window as any).electronAPI?.logError(args);
        };
        console.debug = (...args) => {
            (window as any).electronAPI?.logDebug(args);
        };
        console.warn = (...args) => {
            (window as any).electronAPI?.logWarn(args);
        };
    }
}
