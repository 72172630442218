import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import React from "react";

import { getFilenameFromUrl } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function PausedAppSwiperWrapper(): JSX.Element {
    const { standbyScreenItems } = useSnapshot(kioskStore);

    const localStandbyScreenItems = standbyScreenItems.map(
        (item: StandbyScreenItemType) => {
            let contentFile = "./images/standbyStateScreen.webm";

            if (item.content?.includes("http")) {
                contentFile = getFilenameFromUrl(item.content, "template");
            }
            return {
                ...item,
                content: contentFile,
            };
        }
    );

    return (
        <div
            style={{
                position: "fixed",
                zIndex: -1,
                height: "100%",
                width: "100%",
            }}
        >
            {localStandbyScreenItems.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                    }}
                    className="standby-screen-swiper-wrapper__clz"
                >
                    {localStandbyScreenItems.map(
                        (item: StandbyScreenItemType) => {
                            return (
                                <SwiperSlide
                                    key={item.id}
                                    data-swiper-autoplay={item.timeout * 1000}
                                >
                                    {item.type.includes("video") ? (
                                        <video
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        >
                                            <source
                                                src={item.content}
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <img
                                            src={item.content}
                                            alt={item.id}
                                            className="h-100 w-100"
                                            style={{
                                                objectFit: "fill",
                                            }}
                                        />
                                    )}
                                </SwiperSlide>
                            );
                        }
                    )}
                </Swiper>
            ) : (
                localStandbyScreenItems.map((item: StandbyScreenItemType) => {
                    return item.type.includes("video") ? (
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            key={`is_app_paused-video__${item.id}`}
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                        >
                            <source src={item.content} type="video/mp4" />
                        </video>
                    ) : (
                        <img
                            key={`is_app_paused-img__${item.id}`}
                            src={item.content}
                            className="h-100 w-100"
                            style={{
                                objectFit: "fill",
                            }}
                        />
                    );
                })
            )}
        </div>
    );
}
