import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore, setStandbyStateScreenItems } from "@pages/Kiosk/store";

import { ActionsCell } from "./ActionsCell";
import { OrderCell } from "./OrderCell";
import { Styles } from "./Styles";
import { Table } from "./Table";
import { UploaderCell } from "./UploaderCell";

export function StandbyStateScreenTable(): JSX.Element {
    const { standbyStateScreenItems } = useSnapshot(kioskStore);

    const activeScreens: StandbyScreenItemType[] =
        standbyStateScreenItems.filter(
            (item: StandbyScreenItemType) => item.active
        );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Order"),
                accessor: t("Order"),
                Cell: OrderCell,
            },
            {
                Header: t("Image or video"),
                accessor: "content",
                Cell: UploaderCell,
            },
            {
                Header: t("Actions"),
                accessor: t("Actions"),
                Cell: ActionsCell,
            },
        ],
        [activeScreens, setStandbyStateScreenItems, standbyStateScreenItems]
    );

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <span
                className="d-flex justify-content-between align-items-center"
                style={{
                    font: "normal normal normal 15px/20px Nunito Sans",
                }}
            >
                {t("Reading list")}
            </span>

            <Styles>
                <Table columns={columns} />
            </Styles>
        </div>
    );
}
