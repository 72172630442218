import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";
import { UsualNumericKeyboardWrapper } from "@components/UsualNumericKeyboardWrapper";

import {
    setIsUserAccountModalOpened,
    setKeyboardInputsValues,
    setNumericKeyboardInputValue,
    setPseudoNameValue,
    kioskStore as store,
} from "@pages/Kiosk/store";

import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";

export function UsualMode(): JSX.Element {
    const {
        isInformationModesModalOpened,
        project: {
            template: {
                content: { generalDesign },
            },
        },
        isSubStepActionButtonsActive,
        selectedInformationMode,
        subStepActionButtons,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
    } = useSnapshot(store);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    )
        .filter((item) => {
            return item.active === true;
        })
        .filter((item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        })
        .sort((a, b) => {
            return a.role > b.role ? -1 : 1;
        });

    React.useEffect(() => {
        setNumericKeyboardInputValue("");
        setPseudoNameValue("");
        setKeyboardInputsValues({});
    }, []);

    return (
        <Modal
            isOpen={isInformationModesModalOpened}
            ariaHideApp={false}
            onRequestClose={() => {
                setIsUserAccountModalOpened(!isInformationModesModalOpened);
            }}
            contentLabel="userAccount-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1742px",
                    width: "944px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            {<SubStepsLogoWrapper />}

            {isSubStepIconActive ? <SubStepsIconWrapper /> : null}

            {isSubStepInformationMessageActive ? (
                <SubStepsInformationMessage />
            ) : null}

            <div className="d-flex justify-content-center align-items-center">
                {selectedInformationMode === "nameOrPseudo" ? (
                    <InputKeyboardWrapper />
                ) : (
                    <UsualNumericKeyboardWrapper />
                )}
            </div>

            {isSubStepActionButtonsActive ? (
                <ActionsButtonsWrapper
                    actionsButtonsItems={activeSubStepActionsButtons}
                />
            ) : null}
        </Modal>
    );
}
