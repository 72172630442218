import styled from "styled-components";

export type PropsType = {
    isSubStepIconActive: boolean;
    isSubStepInformationMessageActive: boolean;
    isSubStepActionButtonsActive: boolean;
    isLanguagesActive: boolean;
    isConfigOptionsActive: boolean;
};

export const PrmModeSubStepContainerWrapper = styled.div<PropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    height: 100%;

    grid-template-rows: ${({
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isSubStepActionButtonsActive,
        isLanguagesActive,
        isConfigOptionsActive,
    }: PropsType) =>
        isSubStepIconActive &&
        isSubStepInformationMessageActive &&
        isSubStepActionButtonsActive &&
        isLanguagesActive &&
        isConfigOptionsActive
            ? "169px 149px 437px 87px 87px 75px"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 6fr 21fr 4fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 6fr 21fr 4fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 6fr 25fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 6fr 25fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 6fr 25fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 6fr 25fr 4fr"
            : isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 6fr 29fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 35fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 27fr 4fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "10fr 31fr 4fr"
            : !isSubStepIconActive &&
              isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "10fr 35fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "37fr 4fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "37fr 4fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "37fr 4fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "41fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              isConfigOptionsActive
            ? "41fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              isLanguagesActive &&
              !isConfigOptionsActive
            ? "41fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              isConfigOptionsActive
            ? "41fr 4fr"
            : !isSubStepIconActive &&
              !isSubStepInformationMessageActive &&
              !isSubStepActionButtonsActive &&
              !isLanguagesActive &&
              !isConfigOptionsActive
            ? "45fr"
            : "auto"};
`;
