/* eslint-disable */
import classNames from "classnames";
import { t } from "i18next";

import React from "react";

import { capitalize } from "@helpers/general";

import FranceFlag from "@components/SvgIcons/FranceFlag";

import "./index.css";

type FormInputsPropsType = {
    name: string;
    type: string;
    setInputName: React.Dispatch<React.SetStateAction<string>>;
    formik: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
};

export function FormInputs({
    name,
    type,
    setInputName,
    formik,
    onChange,
    inputRef,
}: FormInputsPropsType): JSX.Element {
    return (
        <div className="d-flex flex-column" style={{ width: "100%" }}>
            <FormInputLabel name={name} />
            <FormInputItem
                name={name}
                formik={formik}
                setInputName={setInputName}
                type={type}
                onChange={onChange}
                inputRef={inputRef}
            />
            <FormInputErrorMessage formik={formik} name={name} />
        </div>
    );
}

type formInputLabelType = {
    name: string;
};
function FormInputLabel({ name }: formInputLabelType) {
    return (
        <label
            htmlFor={name}
            style={{ fontSize: "30px" }}
            className={classNames("label-form-created-account_clz", {
                required__clz: name === "phoneNumber" || name === "firstName",
            })}
        >
            {capitalize(t(name))}
        </label>
    );
}

type formInputItemType = {
    name: string;
    formik: any;
    setInputName: React.Dispatch<React.SetStateAction<string>>;
    type: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
};
function FormInputItem({
    name,
    formik,
    setInputName,
    onChange,
    inputRef,
}: formInputItemType) {
    return (
        <React.Fragment>
            {name === "phoneNumber" ? (
                <>
                    <div className="input-container" style={{ width: "58rem" }}>
                        <FranceFlag width={60} height={60} />
                        <input
                            ref={inputRef}
                            id={name}
                            name={name}
                            type="tel"
                            value={formik.values[name]}
                            onChange={onChange}
                            onFocus={() => {
                                setInputName(name);
                            }}
                            placeholder="0XXXXXXXXX"
                            maxLength={10}
                            minLength={10}
                            pattern="0[0-9]{9}"
                        />
                    </div>
                </>
            ) : (
                <input
                    ref={inputRef}
                    id={name}
                    name={name}
                    className="input-container"
                    style={{ width: name === "email" ? "58rem" : "27.5rem" }}
                    value={formik.values[name]}
                    onFocus={() => {
                        setInputName(name);
                    }}
                    onChange={onChange}
                />
            )}
        </React.Fragment>
    );
}

type formInputErrorMessageType = {
    formik: any;
    name: string;
};
function FormInputErrorMessage({ formik, name }: formInputErrorMessageType) {
    return (
        <React.Fragment>
            {formik.touched[name] && formik.errors[name] ? (
                <div style={{ color: "red" }}>{formik.errors[name]}</div>
            ) : null}
        </React.Fragment>
    );
}
