import { CATALOGUE_MODE, IS_WORKFLOW_TYPE } from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import ComposedProductCartPopup from "@components/OrderTaking/Components/Products/Cart/ComposedProductCartPopup";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";
import { RemoveDialogActionButton } from "./RemoveDialogActionButton";
import WorkflowSubMenu from "./WorkflowSubMenu";

export function RemoveProductConfirmation({
    open,
    onClose,
    items,
    isWorkflow,
}) {
    const { isPrm } = useSnapshot(kioskStore);
    items?.length === 0 && onClose();
    let resultItems = [];
    if (items.length > 1) {
        let dataItemComposition = {};
        let dataElmComposition = {};
        items.forEach((item) => {
            const indexExistItem = resultItems.findIndex((elm) => {
                if (
                    item.compositions &&
                    Object.keys(item.compositions).length > 0
                ) {
                    const updatedCompositions = Object.values(
                        item.compositions
                    ).map((obj) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { date, path, workflow, ...rest } = obj;
                        return rest;
                    });
                    dataItemComposition = updatedCompositions[0];
                }

                if (
                    elm.compositions &&
                    Object.keys(elm.compositions).length > 0
                ) {
                    const updatedCompositions = Object.values(
                        elm.compositions
                    ).map((obj) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { date, path, workflow, ...rest } = obj;
                        return rest;
                    });
                    dataElmComposition = updatedCompositions[0];
                }
                return (
                    item.iuud === elm.iuud &&
                    isEqual(
                        item.selectedOptionsUuid,
                        elm.selectedOptionsUuid
                    ) &&
                    isEqual(
                        JSON.stringify(item.shopCart),
                        JSON.stringify(elm.shopCart)
                    ) &&
                    isEqual(dataItemComposition, dataElmComposition)
                );
            });

            if (indexExistItem > -1) {
                let ExistItem = resultItems[indexExistItem];

                ExistItem = {
                    ...ExistItem,
                    quantity: ExistItem.quantity + item.quantity,
                };
                resultItems[indexExistItem] = ExistItem;
            } else {
                resultItems.push(item);
            }
        });
    } else {
        resultItems = items;
    }
    return (
        <Dialog
            onClose={onClose}
            open={open}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: isPrm ? "35% !important" : "1855px!important",
                    width: "1010px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    borderTop: `15px solid var(--global-color)!important`,
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: isPrm
                        ? "111px 111px 0px 0px!important"
                        : "111px !important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    top: isPrm ? "33%" : "unset",
                    right: "unset!important",
                    left: "unset!important",
                    background: `transparent linear-gradient(180deg, #FFFFFF 0%, var(--global-color) 300%) 0% 0% no-repeat padding-box`,
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: "245px", gap: "20px" }}
            >
                <span
                    style={{
                        font: "normal normal 400 30px/40px Segoe UI",
                    }}
                >
                    {t("Which product")}
                </span>
                <span
                    style={{
                        height: "100px",
                        font: "normal normal 600 55px/60px Nunito Sans",
                    }}
                >
                    {t("Do you want to delete?")}
                </span>
            </div>
            <div
                className="d-flex flex-column align-items-center"
                style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                }}
            >
                {resultItems?.map((item, index) => {
                    return isWorkflow === IS_WORKFLOW_TYPE ? (
                        <WorkflowSubMenu
                            key={index}
                            product={item}
                            isRemovedProductDialog={true}
                            isWorkflow={isWorkflow}
                        />
                    ) : (
                        <ComposedProductCartPopup
                            key={index}
                            product={item}
                            isRemovedProductDialog={true}
                            isWorkflow={isWorkflow}
                        />
                    );
                })}
            </div>
            <RemoveDialogActionButton onClose={onClose} />
        </Dialog>
    );
}

RemoveProductConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    items: PropTypes.array,
    isWorkflow: PropTypes.bool,
};
