import classNames from "classnames";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
    getImageByLanguage,
    getProductTitle,
} from "@components/OrderTaking/Helpers";
import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";
import { memoizedActiveCategory } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import "./categories.css";

export default function CategoryCard({ category }) {
    const { isOrderTakingCategoryTitleActive } = useSelector(
        (state) => state.settingSlice.categorySetting,
        shallowEqual
    );
    const { isPrm } = useSnapshot(kioskStore);

    const activeCategory = useSelector((state) =>
        memoizedActiveCategory(state)
    );
    const isActive = category.iuud === activeCategory;

    const isTitleActive = isOrderTakingCategoryTitleActive
        ? category?.isNameDisplayed !== undefined
            ? category?.isNameDisplayed
            : true
        : false;
    const productTitle = getProductTitle(category.name);
    const categoryImg = getImageByLanguage(category.url_image);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center w-100 h-100",
                {
                    "p-3": isPrm,
                }
            )}
        >
            <img
                loading="lazy"
                onError={imageWithFallback}
                alt={category.title}
                src={categoryImg !== "" ? categoryImg : category.image}
                className={classNames(
                    "d-flex align-items-start justify-content-center pb-1",
                    {
                        "category-card__clz": isActive === false,
                        "active-category-card__clz ": isActive === true,
                    }
                )}
            />
            {isTitleActive ? (
                <div
                    className={classNames(
                        "d-flex align-items-start justify-content-center",
                        {
                            "category-title__clz": isActive === false,
                            "active-category-title__clz": isActive === true,
                        }
                    )}
                >
                    {productTitle}
                </div>
            ) : (
                <div
                    className={classNames(
                        "d-flex align-items-start justify-content-center pb-2"
                    )}
                ></div>
            )}
        </div>
    );
}
export const CategoryCardMemoised = React.memo(CategoryCard);
CategoryCard.propTypes = {
    stateOrder: PropTypes.object,
    category: PropTypes.object,
    settings: PropTypes.object,
    activateCategory: PropTypes.func,
};
