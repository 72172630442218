import React from "react";

type SettingSyncroProps = {
    isDisabled: boolean;
};

export default function CompletedSyncro({
    isDisabled,
}: SettingSyncroProps): JSX.Element {
    return (
        <svg
            id="verified"
            xmlns="http://www.w3.org/2000/svg"
            width="34.98"
            height="34.98"
            viewBox="0 0 34.98 34.98"
        >
            <g id="Groupe_34562" data-name="Groupe 34562">
                <g id="Groupe_34561" data-name="Groupe 34561">
                    <path
                        id="Tracé_22526"
                        data-name="Tracé 22526"
                        d="M29.858,5.123A17.49,17.49,0,0,0,5.123,29.858,17.49,17.49,0,1,0,29.858,5.123ZM17.49,32.931A15.441,15.441,0,1,1,32.931,17.49,15.458,15.458,0,0,1,17.49,32.931Z"
                        fill={isDisabled ? "#fff" : "#7B7B7B"}
                    />
                </g>
            </g>
            <g
                id="Groupe_34564"
                data-name="Groupe 34564"
                transform="translate(8.834 11.578)"
            >
                <g id="Groupe_34563" data-name="Groupe 34563">
                    <path
                        id="Tracé_22527"
                        data-name="Tracé 22527"
                        d="M146.313,169.767a1.025,1.025,0,0,0-1.449,0l-9.05,9.05-4.764-4.764A1.025,1.025,0,1,0,129.6,175.5l5.488,5.488a1.025,1.025,0,0,0,1.449,0l9.774-9.774A1.025,1.025,0,0,0,146.313,169.767Z"
                        transform="translate(-129.301 -169.467)"
                        fill={isDisabled ? "#fff" : "#7B7B7B"}
                    />
                </g>
            </g>
        </svg>
    );
}
