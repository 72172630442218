import { KioskAssetsSize } from "@constants";
import { useSnapshot } from "valtio";

import { useSelector } from "react-redux";

import {
    getImageByLanguage,
    getRowsColumnsDesign,
    getSuspendedItem, // getUrlImage,
} from "@components/OrderTaking/Helpers";
import { memoizedSelectedProducts } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

export const getVisibleItems = (
    listProducts,
    listAdditionnalItems,
    activeCategory,
    selectedSaleModeOrderTaking,
    isWorkFlow,
    currentWorkflow,
    isAdditionalSale,
    additionalSale,
    design
) => {
    const { isPrm } = useSnapshot(kioskStore);
    const products =
        listProducts !== undefined
            ? listProducts
            : listAdditionnalItems !== undefined
            ? listAdditionnalItems
            : useSelector((state) =>
                  memoizedSelectedProducts(state, activeCategory)
              );

    const visibleItems = [];
    const visibleProducts = products.filter(
        (product) =>
            product?.visibilityInfo.isVisible &&
            (product?.KioskVisibility[selectedSaleModeOrderTaking] ||
                product?.KioskVisibility[selectedSaleModeOrderTaking] ===
                    undefined)
    );

    const gridData = () => {
        if (isPrm) {
            return { nbrRows: 2, nbrColumn: 2, isAutoDesign: true };
        }
        let gridSystem = isWorkFlow
            ? currentWorkflow.workflow[currentWorkflow.index].design
            : isAdditionalSale
            ? additionalSale.design
            : design;
        if (gridSystem.isAutoDesign) {
            gridSystem = getRowsColumnsDesign(visibleProducts.length);
        }
        return gridSystem;
    };
    const gridSystem = gridData();
    visibleProducts.forEach((product) => {
        const isSuspended = getSuspendedItem(product?.suspendSale);
        let productImage = getImageByLanguage(product?.url_image);

        const heightOfImage =
            KioskAssetsSize[gridSystem.nbrRows + "_" + gridSystem.nbrColumn]
                .height;
        const widthOfImage =
            KioskAssetsSize[gridSystem.nbrRows + "_" + gridSystem.nbrColumn]
                .width;

        //! to restore if change opinion
        // let url =
        //     KioskAssetsSize[gridSystem.nbrRows + "_" + gridSystem.nbrColumn]
        //         .url;

        // productImage = getUrlImage(productImage, url);

        visibleItems.push({
            ...product,
            isSuspended,
            image: productImage,
            showProductIcons: Boolean(gridSystem?.nbrColumn === 5),
            heightOfImage,
            widthOfImage,
        });
    });
    const largeFont =
        gridSystem.nbrRows >= 3 || gridSystem.nbrColumn === 5 ? "larger" : "";

    document.documentElement.style.setProperty("--large-font", largeFont);

    return { visibleItems, gridSystem };
};
