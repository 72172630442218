import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function OutOfStock(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            height="100%"
            width="100%"
            viewBox="0 0 343 259"
        >
            <g
                id="Groupe_6"
                data-name="Groupe 6"
                transform="translate(-3409 216)"
            >
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="343"
                    height="259"
                    rx="13"
                    transform="translate(3409 -216)"
                    fill="none"
                    strokeWidth="0"
                />
                <text
                    id="STOCK_EPUISE"
                    data-name="STOCK EPUISE"
                    transform="matrix(0.966, -0.259, 0.259, 0.966, 3576.618, -104.279)"
                    fill="#323333"
                    fontSize="63"
                    fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                    fontWeight="700"
                >
                    <tspan x="-145" y="0">
                        {t("OUT OF", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                    <tspan x="-145" y="77">
                        {t("STOCK", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
