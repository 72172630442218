import { CATALOGUE_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { kioskStore } from "@pages/Kiosk/store";

type PrmBackgroundWrapperType = { isSummary?: boolean };
export function PrmBackgroundWrapper({
    isSummary,
}: PrmBackgroundWrapperType): JSX.Element {
    const {
        project: {
            files: images,
            template: { content },
        },
        kioskWays,
        navigationIndex,
        isPrm,
    } = useSnapshot(kioskStore);

    const prmBackgroundId = (
        (content.generalDesign as GeneralDesignType).prm as GeneralDesignPrmType
    ).id;

    const prmContent = images[prmBackgroundId];

    const imageSrc = getImageContentById(prmBackgroundId as string);

    return (
        <div
            style={{
                minHeight:
                    kioskWays[navigationIndex].name === "orderTaking"
                        ? isSummary
                            ? process.env.REACT_APP_MODE === CATALOGUE_MODE
                                ? "100vh"
                                : "52vh"
                            : "1145px"
                        : "unset",
                maxHeight:
                    kioskWays[navigationIndex].name === "orderTaking"
                        ? "100%"
                        : "unset",
                height:
                    kioskWays[navigationIndex].name === "salesMethods"
                        ? process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "100vh"
                            : "52vh"
                        : isSummary
                        ? "unset"
                        : "100%",
                width:
                    kioskWays[navigationIndex].name === "salesMethods"
                        ? "100%"
                        : isPrm
                        ? "100%"
                        : "",
            }}
        >
            {prmContent.type?.includes("video") ? (
                <video
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    className="w-100 h-100"
                    style={{
                        objectFit: "initial",
                    }}
                >
                    <source src={imageSrc} type="video/webm" />
                </video>
            ) : (
                <img
                    className="w-100 h-100"
                    src={imageSrc}
                    alt="prm-background-image"
                />
            )}
        </div>
    );
}
