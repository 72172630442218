import { Badge, BadgeProps } from "reactstrap";
import styled from "styled-components";

export const StyleBadge = styled(Badge)<BadgeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 40px;
    font: normal normal normal 11px/11px Bebas Neue;
    letter-spacing: 0.44px;
    color: #ffffff;
    background: ${({ color }) =>
        color === "success"
            ? "transparent linear-gradient(180deg, #35E000 0%, #2AB900 100%) 0% 0% no-repeat padding-box"
            : color === "danger"
            ? "transparent linear-gradient(180deg, #313131 0%, #57282F 0%, #FC0128 100%) 0% 0% no-repeat padding-box"
            : ""};
    box-shadow: ${({ color }) =>
        color === "success"
            ? "0px 5px 10px #00F52F42"
            : color === "danger"
            ? "0px 5px 10px #ff7b7b42"
            : "unset"};
    border: 1px solid #dbdbdb;
    border-radius: 20px;
`;
