import { TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { MainContentWrapper } from "@components/common/PrmModeMainContentWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeSubStepContainerWrapper } from "@components/common/PrmModeSubStepContainerWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isHeaderActive,
        actionsButtonsItems,
        isTopBannerActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isSendEmailConfirmed,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);

    const activeActionsButtons = actionsButtonsItems?.filter((item) => {
        return item.active === true && item.name !== "printTicket";
    });

    const mainActiveActionsButtons = activeActionsButtons?.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper
                    selectedMode={
                        isSendEmailConfirmed
                            ? "printerOptionsConfirmationEmail"
                            : undefined
                    }
                >
                    <PrmModeSubStepContainerWrapper
                        isSubStepIconActive={isTopBannerActive}
                        isSubStepInformationMessageActive={
                            isInformationMessageActive
                        }
                        isSubStepActionButtonsActive={isActionButtonsActive}
                        isLanguagesActive={isLanguagesActive}
                        isConfigOptionsActive={isConfigOptionsActive}
                    >
                        <LogoWrapper />

                        {isHeaderActive ? (
                            TCPOS === posEditor ? (
                                <h1
                                    style={{
                                        height: "100px",
                                        font: "600 55px / 60px Nunito Sans",
                                        textAlign: "center",
                                        maxWidth: "60rem",
                                        marginInline: "auto",
                                        lineHeight: "6rem",
                                    }}
                                >
                                    {t(
                                        "Would you like to print your order ticket?"
                                    )}
                                </h1>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center mx-3">
                                    <span
                                        style={{
                                            fontSize: "55px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            )
                        ) : null}

                        {isSendEmailConfirmed ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <InputKeyboardWrapper />
                            </div>
                        ) : (
                            <MainContentWrapper />
                        )}

                        {isActionButtonsActive ? (
                            <PrmActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}
                        <div className="d-flex align-items-center justify-content-end">
                            {isLanguagesActive ? (
                                <PrmLanguagesWrapper />
                            ) : (
                                <div />
                            )}
                            {isConfigOptionsActive ? (
                                <ConfigOptionsWrapper />
                            ) : null}
                        </div>
                    </PrmModeSubStepContainerWrapper>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
