import React from "react";

export function InfoIcon(props, width, height) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : 39}
            height={height ? height : 30}
            data-name="Groupe 30411"
            viewBox="0 0 39 39"
            {...props}
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e2e2e2"></stop>
                    <stop offset="1" stopColor="#c6c6c6"></stop>
                </linearGradient>
            </defs>
            <g>
                <g data-name="Groupe 26911">
                    <path
                        fill="url(#linear-gradient)"
                        d="M19.5 0A19.5 19.5 0 1039 19.5 19.576 19.576 0 0019.5 0zm2.285 28.641a2.285 2.285 0 01-4.57 0V17.215a2.285 2.285 0 114.57 0zm-2.285-16a2.285 2.285 0 112.285-2.285 2.288 2.288 0 01-2.285 2.289z"
                        data-name="Tracé 20422"
                        stroke="none"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
