import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const ValidateStep = (
    props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 65" {...props}>
            <g
                id="Groupe_54"
                data-name="Groupe 54"
                transform="translate(-6706 4106)"
            >
                <rect
                    id="Rectangle_22"
                    data-name="Rectangle 22"
                    width={props.width}
                    height={props.height}
                    rx="9"
                    transform="translate(6706 -4106)"
                    fill="#141414"
                />
                <text
                    id="Valider"
                    transform="translate(6818 -4062)"
                    fill="#fff"
                    fontSize="29"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Validate", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};

ValidateStep.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
