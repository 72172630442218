import { NO, NOT_SEND_EMAIL, SEND_EMAIL, YES } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { VerifyIsPrinterStepWithEmail } from "@helpers/general";

import {
    kioskStore,
    setActionsButtonsItems,
    setChosenTransition,
    setInformationMessageItems,
    setIsActionButtonsActive,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsLogoActive,
    setIsTopBannerActive,
    setMainContentItems,
} from "@pages/Kiosk/store";

import { PrmMode } from "./PrmMode";
import { UsualMode } from "./UsualMode";

export function PrinterOptionsStep(): JSX.Element {
    const {
        isPrm,
        project: {
            template: {
                content,
                pages: { ways, subSteps },
            },
        },
        isSendEmailConfirmed,
        isUserAuthenticated,
        user,
        customerLanguage,
    } = useSnapshot(kioskStore);

    const { transitions } = useSnapshot(store);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const validEmail = emailRegex.test(user.email);

    const isPrinterStepWithEmail = VerifyIsPrinterStepWithEmail();

    const isUserWithValidMail =
        isUserAuthenticated && validEmail ? true : false;

    React.useEffect(() => {
        const isLocalHeaderActive = isSendEmailConfirmed
            ? isUserWithValidMail
                ? (
                      (
                          (
                              subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                          ).header as PrinterOptionsNewHeaderType
                      )
                          .informationMessage as PrinterOptionsInformationMessageType
                  ).confirmationemail.active
                : (
                      (
                          (
                              subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                          ).header as PrinterOptionsNewHeaderType
                      )
                          .informationMessage as PrinterOptionsInformationMessageType
                  ).entryemail.active
            : isPrinterStepWithEmail
            ? (
                  (
                      (ways.printerOptions as PrinterOptionsPageType)
                          .header as PrinterOptionsNewHeaderType
                  ).informationMessage as PrinterOptionsInformationMessageType
              ).withemail.active
            : (
                  (
                      (ways.printerOptions as PrinterOptionsPageType)
                          .header as PrinterOptionsNewHeaderType
                  ).informationMessage as PrinterOptionsInformationMessageType
              ).withoutemail.active;

        setIsLogoActive(
            isSendEmailConfirmed
                ? (
                      subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                  ).topBanner.logo.active
                : (ways.printerOptions as PrinterOptionsPageType).topBanner.logo
                      .active
        );
        setIsTopBannerActive(
            isSendEmailConfirmed
                ? (
                      subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                  ).topBanner.active
                : (ways.printerOptions as PrinterOptionsPageType).topBanner
                      .active
        );
        setIsHeaderActive(isLocalHeaderActive);

        setActionsButtonsItems(
            !isSendEmailConfirmed
                ? (ways.printerOptions as PrinterOptionsPageType).actionsButtons
                      .items
                : (
                      subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                  ).actionsButtons.items
        );
        setChosenTransition(
            isSendEmailConfirmed
                ? transitions[
                      (
                          subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                      ).animation
                  ]
                : transitions[
                      (ways.printerOptions as PrinterOptionsPageType).animation
                  ]
        );
        setIsInformationMessageActive(isLocalHeaderActive);
        setInformationMessageItems(
            isSendEmailConfirmed
                ? isUserAuthenticated && user.email !== ""
                    ? (
                          (
                              (
                                  subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                              ).header as PrinterOptionsNewHeaderType
                          )
                              .informationMessage as PrinterOptionsInformationMessageType
                      ).confirmationemail.languages
                    : (
                          (
                              (
                                  subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                              ).header as PrinterOptionsNewHeaderType
                          )
                              .informationMessage as PrinterOptionsInformationMessageType
                      ).entryemail.languages
                : isPrinterStepWithEmail
                ? (
                      (
                          (ways.printerOptions as PrinterOptionsPageType)
                              .header as PrinterOptionsNewHeaderType
                      )
                          .informationMessage as PrinterOptionsInformationMessageType
                  ).withemail.languages
                : (
                      (
                          (ways.printerOptions as PrinterOptionsPageType)
                              .header as PrinterOptionsNewHeaderType
                      )
                          .informationMessage as PrinterOptionsInformationMessageType
                  ).withoutemail.languages
        );
        setIsActionButtonsActive(
            !isSendEmailConfirmed
                ? (ways.printerOptions as PrinterOptionsPageType)
                      .printerOptionsConfig.isEmailActive === true
                    ? true
                    : (ways.printerOptions as PrinterOptionsPageType)
                          .actionsButtons.active
                : (
                      subSteps.printerOptionsConfirmationEmail as PrinterOptionsPageType
                  ).actionsButtons.active
        );
        setMainContentItems(
            (
                (ways.printerOptions as PrinterOptionsPageType)
                    .items as printerOptionsItemsType[]
            )?.filter((item) => {
                if (isPrinterStepWithEmail) {
                    return (
                        item.active === true &&
                        item.languages[customerLanguage.name].content !== "" &&
                        (item.shortName === SEND_EMAIL ||
                            item.shortName === NOT_SEND_EMAIL)
                    );
                } else {
                    return (
                        item.active === true &&
                        item.languages[customerLanguage.name].content !== "" &&
                        (item.shortName === YES || item.shortName === NO)
                    );
                }
            })
        );
    }, [ways, content, isSendEmailConfirmed, subSteps, isUserAuthenticated]);

    return isPrm ? <PrmMode /> : <UsualMode />;
}
