import { CESAR_LOYALTY, DEFAULT_PASSCODE } from "@constants";

import React from "react";

import {
    setIsSyncConfirmationModalWrapperOpened,
    setPeripheralShopApp,
    setSelectedShopApp,
} from "@components/Synchronization/store";

import syncIcon from "./sync-icon.svg";

export function SynchronizeImgWrapper({
    row,
}: {
    row: ObjectType;
}): JSX.Element {
    row =
        row.passcode === undefined
            ? { ...row, passcode: DEFAULT_PASSCODE }
            : row;
    row =
        row.fidelityType === undefined
            ? { ...row, fidelityType: CESAR_LOYALTY }
            : row;

    return (
        <img
            src={syncIcon}
            alt="sync"
            style={{
                cursor: "pointer",
            }}
            onClick={() => {
                setIsSyncConfirmationModalWrapperOpened(true);
                setSelectedShopApp(row);
                setPeripheralShopApp({
                    monetics: row.monetics,
                    printers: row.printers,
                    cash_managements: row.cash_managements,
                });
            }}
        />
    );
}
