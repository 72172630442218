import { ELECTRON_MODE } from "@constants";

import React from "react";

import { useCard, useSocketConnection, useSteps } from "@hooks/.";

import { TimeoutLogic } from "@components/IdleDetection";
import { AuthenticationModalWrapper } from "@components/LocalSettings/AuthenticationModalWrapper";
import { LocalConfigModalWrapper } from "@components/LocalSettings/LocalConfigModalWrapper";
import { TechnicalProblemModal } from "@components/TechnicalProblemModal";

import { LogoutProblemModal } from "@pages/Kiosk/LogoutProblemModal";
import { LoyaltyWrapper } from "@pages/Kiosk/Loyalty";
import { StepComponent } from "@pages/Kiosk/Steps/StepComponent";
import { UserAccountModalWrapper } from "@pages/Kiosk/UserAccountModalWrapper";

import { HelpModalWrapper } from "../HelpModalWrapper";
import "./transitions.css";

export function Steps(): JSX.Element {
    useSteps();
    useCard();
    useSocketConnection();

    React.useEffect(() => {
        if (process.env.REACT_APP_MODE === ELECTRON_MODE) {
            (window as RendererWindow).electronAPI?.checkKioskModeEvent();
        }
    }, []);

    return (
        <React.Fragment>
            <AuthenticationModalWrapper />
            <LocalConfigModalWrapper />
            <TimeoutLogic />
            <HelpModalWrapper />
            <UserAccountModalWrapper />
            <TechnicalProblemModal />
            <LogoutProblemModal />
            <LoyaltyWrapper />
            <StepComponent />
        </React.Fragment>
    );
}
