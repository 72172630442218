import {
    ABORT_ITEM,
    BASIC_COMPOSITION,
    NEXT_IN_STEP,
    PREVIOUS_IN_STEP,
    VALID_IN_STEP,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { successAddToast } from "@components/common/Toast";
import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import {
    SelectProduct,
    setIsInternalModification,
    setNextWorkflowStep,
    setPreviousWorkflowStep,
    setProductBeforeUpdate,
    setShowToast,
    setWorkflowData,
} from "@components/OrderTaking/Store/feature/";

import { kioskStore } from "@pages/Kiosk/store";

import { BackButton } from "./BackButton";
import { AddOrderBasicCompositionWorkflow, AutoValidateStep } from "./hooks";
import "./index.css";

function WorkflowFooter({ listworkflow, onClose }) {
    const { t } = useTranslation();
    const { globalcard } = useSelector((state) => state.orderSlice);

    const dispatch = useDispatch();
    const { compositeProductActionsButtons } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const orderWorkflowItemSlice = useSelector(
        (state) => state.orderWorkflowItemSlice,
        shallowEqual
    );

    const { isPrm, devise } = useSnapshot(kioskStore);

    const { workflowData } = useSelector(
        (state) => state.workFlowDataSlice,
        shallowEqual
    );
    const { additionalSale } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );

    const isLastStepValid =
        listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
            listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices &&
        listworkflow?.index === listworkflow?.step;

    const isModifiedStep =
        (orderWorkflowItemSlice.item.isModification === true ||
            orderWorkflowItemSlice.isModification === true) &&
        listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
            listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices;

    const isValid =
        isLastStepValid ||
        isModifiedStep ||
        (orderWorkflowItemSlice.item.isModification &&
            listworkflow?.workflow[listworkflow.index]?.NbrOfChoices >=
                listworkflow?.workflow[listworkflow.index]?.minNbrOfChoices) ||
        listworkflow?.index === listworkflow?.step;

    function goToNextStep() {
        if (workflowData?.length === 1) {
            dispatch(setIsInternalModification(false));
        }
        dispatch(setNextWorkflowStep());
    }

    function NextStep(workflow) {
        if (workflow.step > workflow.index) {
            goToNextStep(workflow);
        } else {
            let copyworkflowData = JSON.parse(JSON.stringify(workflowData));
            copyworkflowData.splice(copyworkflowData.length - 1, 1);
            const copyDataworkflow = [...copyworkflowData];

            let index = copyDataworkflow.length;

            while (index > 0) {
                let workflowIndex = index - 1;
                let workflow = copyDataworkflow[workflowIndex];
                if (
                    workflow.workflow[workflow.index].NbrOfChoices <=
                    workflow.workflow[workflow.index].maxNbrOfChoices
                ) {
                    successAddToast(t("Product added"));
                    dispatch(setWorkflowData(copyworkflowData));
                    if (
                        workflow.workflow.length > 1 &&
                        workflow.workflow[workflow.index].NbrOfChoices ===
                            workflow.workflow[workflow.index].maxNbrOfChoices &&
                        orderWorkflowItemSlice.isModification === false &&
                        orderWorkflowItemSlice.isInternalModification ===
                            false &&
                        workflow.step > workflow.index &&
                        additionalSale.listItems.length === 0 &&
                        workflow.workflow[workflow.index].haveComment === false
                    ) {
                        goToNextStep(workflow);
                    }

                    return;
                } else if (workflow.step > workflow.index) {
                    goToNextStep(workflow);
                    return;
                } else {
                    index -= 1;
                    copyDataworkflow.splice(workflowIndex, 1);
                }
            }
            // ** ici on efface workflow
            if (copyDataworkflow.length === 0) {
                dispatch(setWorkflowData([]));
                dispatch(SelectProduct({}));
            }
        }
    }

    function NextOnClickEvent() {
        if (
            listworkflow.workflow[listworkflow.index]?.NbrOfChoices <
            listworkflow.workflow[listworkflow.index]?.minNbrOfChoices
        )
            return;
        if (listworkflow) {
            if (
                listworkflow.workflow[listworkflow.index].type ===
                BASIC_COMPOSITION
            ) {
                AddOrderBasicCompositionWorkflow(
                    workflowData,
                    dispatch,
                    globalcard
                );
            }
            if (listworkflow.step > listworkflow.index) {
                goToNextStep(listworkflow);
            } else {
                NextStep(listworkflow);
            }
        }

        if (isModifiedStep === true && additionalSale.listItems.length === 0) {
            dispatch(setWorkflowData([]));
            dispatch(SelectProduct({}));
        }
    }

    function PreviousOnClickEvent() {
        dispatch(setProductBeforeUpdate({ ...orderWorkflowItemSlice.item }));
        if (
            orderWorkflowItemSlice.isModification === true &&
            listworkflow?.workflow[listworkflow.index]?.NbrOfChoices <
                listworkflow?.workflow[listworkflow.index].minNbrOfChoices
        ) {
            return;
        }
        if (listworkflow.index > 0) {
            dispatch(setIsInternalModification(true));
            dispatch(setPreviousWorkflowStep());
        }
    }

    function handleActionsButtonsOnClickEvent(button) {
        if (
            (button.name.toLowerCase() === NEXT_IN_STEP ||
                button.name.toLowerCase() === VALID_IN_STEP) &&
            listworkflow !== undefined &&
            listworkflow?.workflow[listworkflow.index]?.NbrOfChoices <
                listworkflow?.workflow[listworkflow.index].minNbrOfChoices
        ) {
            const minNbrOfChoices =
                listworkflow?.workflow[listworkflow.index].minNbrOfChoices;
            const minProductToast = { minProduct: minNbrOfChoices };
            dispatch(setShowToast(minProductToast));

            return;
        }
        button.name.toLowerCase() === NEXT_IN_STEP ||
        button.name.toLowerCase() === VALID_IN_STEP
            ? NextOnClickEvent(button)
            : button.name.toLowerCase() === PREVIOUS_IN_STEP
            ? PreviousOnClickEvent()
            : onClose();
    }

    if (
        orderWorkflowItemSlice.isModification === false &&
        orderWorkflowItemSlice.isInternalModification === false
    ) {
        AutoValidateStep(dispatch, workflowData, additionalSale);
    }

    return (
        <div className="workflow-footer py-1">
            <div
                className="workflow-buttons px-2"
                style={{
                    gap: workflowData.length === 1 ? "5px" : "28px",
                    marginBottom: isPrm ? "4rem" : "",
                }}
            >
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ gap: "30px", height: "75px" }}
                >
                    {compositeProductActionsButtons
                        .filter(
                            (item) =>
                                item.active === true &&
                                item.name.toLowerCase() !==
                                    (!isValid ? VALID_IN_STEP : NEXT_IN_STEP)
                        )
                        .sort((a, b) => {
                            return a.role > b.role ? -1 : 1;
                        })
                        .map((button) => {
                            const imageSrc = getImageContentById(button.id);
                            if (
                                button.name === ABORT_ITEM &&
                                orderWorkflowItemSlice.isModification === true
                            ) {
                                return <BackButton onClose={onClose} />;
                            }
                            return (
                                <div
                                    key={button.id}
                                    className={` d-flex justify-content-center align-items-center  ${
                                        (button.name.toLowerCase() ===
                                            NEXT_IN_STEP &&
                                            listworkflow?.workflow[
                                                listworkflow.index
                                            ]?.NbrOfChoices ===
                                                listworkflow?.workflow[
                                                    listworkflow.index
                                                ]?.maxNbrOfChoices) ||
                                        (button.name.toLowerCase() ===
                                            VALID_IN_STEP &&
                                            listworkflow?.workflow[
                                                listworkflow.index
                                            ]?.NbrOfChoices ===
                                                listworkflow?.workflow[
                                                    listworkflow.index
                                                ]?.maxNbrOfChoices)
                                            ? "tada-animation"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handleActionsButtonsOnClickEvent(
                                            button
                                        );
                                    }}
                                    style={{
                                        width:
                                            button.name.toLowerCase() ===
                                            "abandon article"
                                                ? "309px"
                                                : "224px",
                                        zIndex: "9999",
                                    }}
                                >
                                    {VALIDATED_EXTENSION_FILE.includes(
                                        imageSrc.substr(-4)
                                    ) ? (
                                        <img
                                            src={imageSrc}
                                            style={{
                                                height: "65px",
                                                width: "100%",
                                                opacity:
                                                    (button.name.toLowerCase() ===
                                                        NEXT_IN_STEP &&
                                                        listworkflow?.workflow[
                                                            listworkflow.index
                                                        ]?.NbrOfChoices <
                                                            listworkflow
                                                                ?.workflow[
                                                                listworkflow
                                                                    .index
                                                            ]
                                                                ?.minNbrOfChoices) ||
                                                    (button.name.toLowerCase() ===
                                                        VALID_IN_STEP &&
                                                        listworkflow?.workflow[
                                                            listworkflow.index
                                                        ]?.NbrOfChoices <
                                                            listworkflow
                                                                ?.workflow[
                                                                listworkflow
                                                                    .index
                                                            ]
                                                                ?.minNbrOfChoices) ||
                                                    (button.name.toLowerCase() ===
                                                        PREVIOUS_IN_STEP &&
                                                        listworkflow?.index ===
                                                            0) ||
                                                    (button.name.toLowerCase() ===
                                                        PREVIOUS_IN_STEP &&
                                                        orderWorkflowItemSlice.isModification ===
                                                            true &&
                                                        listworkflow?.workflow[
                                                            listworkflow.index
                                                        ]?.NbrOfChoices <
                                                            listworkflow
                                                                ?.workflow[
                                                                listworkflow
                                                                    .index
                                                            ].minNbrOfChoices)
                                                        ? "0.5"
                                                        : 1,
                                            }}
                                        />
                                    ) : (
                                        <DynamicSvgComponent
                                            nameSvg={imageSrc}
                                            width={
                                                button.name.toLowerCase() ===
                                                "abandon article"
                                                    ? "309px"
                                                    : "224px"
                                            }
                                            height={"65px"}
                                            opacity={
                                                (button.name.toLowerCase() ===
                                                    NEXT_IN_STEP &&
                                                    listworkflow?.workflow[
                                                        listworkflow.index
                                                    ]?.NbrOfChoices <
                                                        listworkflow?.workflow[
                                                            listworkflow.index
                                                        ]?.minNbrOfChoices) ||
                                                (button.name.toLowerCase() ===
                                                    VALID_IN_STEP &&
                                                    listworkflow?.workflow[
                                                        listworkflow.index
                                                    ]?.NbrOfChoices <
                                                        listworkflow?.workflow[
                                                            listworkflow.index
                                                        ]?.minNbrOfChoices) ||
                                                (button.name.toLowerCase() ===
                                                    PREVIOUS_IN_STEP &&
                                                    listworkflow?.index === 0)
                                                    ? "0.5"
                                                    : "1"
                                            }
                                        />
                                    )}
                                </div>
                            );
                        })}
                </div>

                <div className="d-flex align-items-center">
                    <hr className="workflow-vertical-separation-line" />

                    <div className="workflow-total px-1">
                        {t("Total") + "\xa0".repeat(1)}
                        {orderWorkflowItemSlice.item.price.toFixed(
                            devise.decimalPrice
                        ) + ` ${devise.deviseSymbole}`}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkflowFooter;
WorkflowFooter.propTypes = {
    listworkflow: PropTypes.object,
    onClose: PropTypes.func,
};
