import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function Ignore(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    const { customerLanguage, isPrm, navigationIndex, kioskWays } =
        useSnapshot(kioskStore);
    return isPrm && kioskWays[navigationIndex].name !== "orderTaking" ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 281.609 70"
            {...props}
        >
            <g
                id="Groupe_87"
                data-name="Groupe 87"
                transform="translate(-1482 4082)"
            >
                <g
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    transform="translate(1482 -4082)"
                    fill="#fff"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#fff"
                    />
                </g>
                <text
                    id="Ignorer"
                    transform="translate(1622 -4034)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Ignore", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 87" {...props}>
            <g
                id="Groupe_75"
                data-name="Groupe 75"
                transform="translate(-1482 4082)"
            >
                <g
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    transform="translate(1482 -4082)"
                    fill="#fff"
                    stroke="#141414"
                    strokeWidth="2"
                >
                    <rect
                        width={props.width}
                        height={props.height}
                        rx="9"
                        stroke="none"
                    />
                    <rect
                        x="1"
                        y="1"
                        width={parseInt(props.width as string) - 2}
                        height={parseInt(props.height as string) - 2}
                        rx="8"
                        fill="#fff"
                    />
                </g>
                <text
                    id="Ignorer"
                    transform="translate(1657 -4024)"
                    fill="#141414"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Ignore", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
}
Ignore.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
