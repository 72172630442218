import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { HeaderStep } from "@components/common/HeaderStep";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { MainContentWrapper } from "@components/common/UsualModeMainContentWrapper";
import { ContainerWrapper } from "@components/common/UsualModeMainContentWrapper/ContainerWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
        actionsButtonsItems,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper>
            <ContainerWrapper
                isTopBannerActive={isTopBannerActive}
                isHeaderActive={isHeaderActive}
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
                isActionButtonsActive={isActionButtonsActive}
            >
                <LogoWrapper />

                <HeaderStep />

                <MainContentWrapper />

                {isActionButtonsActive ? (
                    <ActionsButtonsWrapper
                        actionsButtonsItems={mainActiveActionsButtons}
                    />
                ) : null}

                <UsualModeLanguagesWrapper />

                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
