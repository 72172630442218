import React from "react";

export const KioskAppLogoSvgIcon = (
    props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
    <svg
        data-name="Groupe 31863"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 751.083 181.377"
        width={751.083}
        height={181.377}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 11759"
                    fill="none"
                    d="M0 0h751.083v181.377H0z"
                />
            </clipPath>
        </defs>
        <path
            data-name="Trac\xE9 22289"
            d="m42.694 1.665-27.32 28.984V1.665H0V71.58h15.374V49.059l8.812-8.912L45.239 71.58H64.53l-29.964-42.3 27.32-27.615Z"
            fill="#fff"
        />
        <path
            data-name="Rectangle 11758"
            fill="#fff"
            d="M70.698 1.665h15.374V71.58H70.698z"
        />
        <g data-name="Groupe 31862">
            <g data-name="Groupe 31861" clipPath="url(#a)" fill="#fff">
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22290"
                    d="M98.9 22.375a34.942 34.942 0 0 1 6.756-11.7 30.9 30.9 0 0 1 10.82-7.834 38.285 38.285 0 0 1 28.936 0 31 31 0 0 1 10.771 7.834 34.945 34.945 0 0 1 6.757 11.7 43.681 43.681 0 0 1 2.35 14.541 42.223 42.223 0 0 1-2.35 14.247 34.023 34.023 0 0 1-6.757 11.506 31.047 31.047 0 0 1-10.771 7.687 38.908 38.908 0 0 1-28.936 0 30.945 30.945 0 0 1-10.82-7.687A34.02 34.02 0 0 1 98.9 51.163a42.223 42.223 0 0 1-2.35-14.247 43.681 43.681 0 0 1 2.35-14.541m14.05 23.108a23.106 23.106 0 0 0 3.281 7.491 16.778 16.778 0 0 0 5.875 5.337 20.344 20.344 0 0 0 17.626 0 16.812 16.812 0 0 0 5.875-5.337 23.149 23.149 0 0 0 3.281-7.491 35.6 35.6 0 0 0 1.027-8.567A38.3 38.3 0 0 0 148.888 28a23.624 23.624 0 0 0-3.281-7.687 16.673 16.673 0 0 0-5.875-5.386 20.353 20.353 0 0 0-17.626 0 16.64 16.64 0 0 0-5.875 5.386A23.582 23.582 0 0 0 112.95 28a38.3 38.3 0 0 0-1.028 8.911 35.6 35.6 0 0 0 1.028 8.567"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22291"
                    d="M187.518 54.346a11.087 11.087 0 0 0 3.378 3.966 14.578 14.578 0 0 0 4.945 2.252 23.348 23.348 0 0 0 5.875.735 30.422 30.422 0 0 0 4.407-.343 15.316 15.316 0 0 0 4.406-1.322 9.606 9.606 0 0 0 3.427-2.693 6.755 6.755 0 0 0 1.372-4.358 6.135 6.135 0 0 0-1.812-4.6 14.363 14.363 0 0 0-4.749-2.938 47.439 47.439 0 0 0-6.659-2.056q-3.723-.882-7.54-1.958a66.882 66.882 0 0 1-7.638-2.4 26.184 26.184 0 0 1-6.659-3.672 17.288 17.288 0 0 1-4.749-5.631 17.085 17.085 0 0 1-1.811-8.175 18.3 18.3 0 0 1 2.3-9.352 20.827 20.827 0 0 1 6.022-6.61 26.246 26.246 0 0 1 8.422-3.917A35.889 35.889 0 0 1 199.856 0a44.486 44.486 0 0 1 10.526 1.224 26.318 26.318 0 0 1 8.96 3.966 20.082 20.082 0 0 1 6.218 7 21.484 21.484 0 0 1 2.3 10.33h-14.883a12.385 12.385 0 0 0-1.322-5.19 8.657 8.657 0 0 0-2.986-3.231 12.679 12.679 0 0 0-4.26-1.665 26.335 26.335 0 0 0-5.239-.489 18.094 18.094 0 0 0-3.721.391 9.974 9.974 0 0 0-3.378 1.371 8.489 8.489 0 0 0-2.5 2.449 6.567 6.567 0 0 0-.979 3.72 6.271 6.271 0 0 0 .783 3.329 6.977 6.977 0 0 0 3.085 2.351 39.25 39.25 0 0 0 6.365 2.154q4.062 1.078 10.624 2.741 1.958.393 5.435 1.42a26.113 26.113 0 0 1 6.9 3.281 20.869 20.869 0 0 1 5.924 6.022 17.141 17.141 0 0 1 2.5 9.645 21.379 21.379 0 0 1-1.86 8.911 19.345 19.345 0 0 1-5.533 7.1 26.567 26.567 0 0 1-9.106 4.652 43.084 43.084 0 0 1-12.583 1.664 44.189 44.189 0 0 1-11.212-1.42 28.16 28.16 0 0 1-9.6-4.455 21.982 21.982 0 0 1-6.61-7.736 23.08 23.08 0 0 1-2.35-11.163h14.884a12.8 12.8 0 0 0 1.273 5.973"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22292"
                    d="M254.69 1.665v28.984l27.32-28.984h19.19l-27.32 27.613 29.964 42.3h-19.29L263.5 40.147l-8.813 8.912V71.58h-15.371V1.665Z"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22293"
                    d="m372.1 1.665 26.141 69.915H382.28l-5.288-15.569h-26.145l-5.484 15.569h-15.471l26.439-69.915Zm.881 42.889L364.164 18.9h-.2l-9.107 25.655Z"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22294"
                    d="M435.842 1.665a29.116 29.116 0 0 1 11.164 1.909 20.894 20.894 0 0 1 7.49 5.043 19.4 19.4 0 0 1 4.211 7.149 26.707 26.707 0 0 1 0 16.6 19.328 19.328 0 0 1-4.211 7.2 20.907 20.907 0 0 1-7.49 5.043 29.155 29.155 0 0 1-11.164 1.908h-16.156V71.58h-15.374V1.665Zm-4.21 32.9a32.1 32.1 0 0 0 5.092-.392 11.788 11.788 0 0 0 4.308-1.518 8.264 8.264 0 0 0 2.987-3.181 11.2 11.2 0 0 0 1.126-5.386 11.2 11.2 0 0 0-1.126-5.386 8.284 8.284 0 0 0-2.987-3.183A11.861 11.861 0 0 0 436.724 14a32.218 32.218 0 0 0-5.092-.392h-11.946v20.958Z"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22295"
                    d="M501.155 1.665a29.116 29.116 0 0 1 11.164 1.909 20.894 20.894 0 0 1 7.49 5.043 19.4 19.4 0 0 1 4.211 7.149 26.707 26.707 0 0 1 0 16.6 19.328 19.328 0 0 1-4.211 7.2 20.907 20.907 0 0 1-7.49 5.043 29.155 29.155 0 0 1-11.164 1.908H485V71.58h-15.375V1.665Zm-4.21 32.9a32.1 32.1 0 0 0 5.092-.392 11.788 11.788 0 0 0 4.308-1.518 8.264 8.264 0 0 0 2.987-3.181 11.2 11.2 0 0 0 1.126-5.386 11.2 11.2 0 0 0-1.126-5.386 8.284 8.284 0 0 0-2.987-3.183A11.861 11.861 0 0 0 502.037 14a32.217 32.217 0 0 0-5.092-.392H485v20.958Z"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22296"
                    d="M481.593 152.306a8.605 8.605 0 0 1-6.727-3.142 9.42 9.42 0 0 1-2.038-3.094 11.457 11.457 0 0 1-.565-3.929 11.3 11.3 0 0 1 .54-3.707 12.569 12.569 0 0 1 1.866-3.314 2.562 2.562 0 0 0 .491-1.522 15.1 15.1 0 0 0-.2-2.014q-.835-6.285-1.645-19.74t-1.052-26.124a10.8 10.8 0 0 1 .074-1.473 2.082 2.082 0 0 1 .221-.737 1.046 1.046 0 0 1 .442-.393 1.5 1.5 0 0 1 .933-.344 4.284 4.284 0 0 1 1.326.295 4.04 4.04 0 0 1 3.069 2.676 22.888 22.888 0 0 1 .712 6.311l1.228-2.947q5.155-13.16 11.441-13.16a8.011 8.011 0 0 1 3.241.736q4.32 1.868 4.321 7.907 0 7.365-5.107 20.378a14.508 14.508 0 0 0-1.178 3.633c0 .2.54.3 1.62.3a19.193 19.193 0 0 1 4.272.639 24.713 24.713 0 0 1 4.469 1.473 16.716 16.716 0 0 1 6.924 6.482 17.421 17.421 0 0 1 2.553 9.084q0 7.17-5.794 13.16a44.77 44.77 0 0 1-16.058 10.73 25.9 25.9 0 0 1-9.379 1.841m-1.424-6.187q5.107 0 12.276-3.634a38.447 38.447 0 0 0 11.884-9.084 20.313 20.313 0 0 0 3.02-4.616 11.125 11.125 0 0 0 .81-4.37q0-8.741-14.044-8.741-4.762 0-5.942.687a2.639 2.639 0 0 1-1.424.59 2.107 2.107 0 0 1-1.522-.982 5.546 5.546 0 0 1-1.768-3.634 3.836 3.836 0 0 1 1.081-2.554 27.673 27.673 0 0 0 2.6-5.254q1.719-4.125 3.094-8.5a67.931 67.931 0 0 0 1.743-6.974 31.376 31.376 0 0 0 .712-5.548l-.049-2.161-1.375 1.572a25.61 25.61 0 0 0-3.634 5.892 45.725 45.725 0 0 0-2.9 7.907 79.371 79.371 0 0 0-1.817 14.191l-.442 7.021a8.519 8.519 0 0 0 .246 2.162 8.6 8.6 0 0 1 .1 1.178 1.734 1.734 0 0 1-1.473 1.866 1.341 1.341 0 0 0-.663.27 1.472 1.472 0 0 0-.172.908q.2 4.913.491 5.108a27.416 27.416 0 0 0 3.977-1.818 59.9 59.9 0 0 1 6.433-2.8 24.419 24.419 0 0 1 5.7-1.472q1.916 0 2.6 2.4a4.658 4.658 0 0 1 .344 1.523 1.721 1.721 0 0 1-.933 1.473 16.53 16.53 0 0 1-3.339 1.375 59.684 59.684 0 0 0-5.819 2.308 40.075 40.075 0 0 0-5.132 2.9 27.794 27.794 0 0 0-5.868 4.935q-2.186 2.529-2.185 4.1 0 1.769 2.9 1.768Z"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22297"
                    d="M523.726 181.377a11.115 11.115 0 0 1-5.255-1.35 11.349 11.349 0 0 1-4.223-3.9 10.979 10.979 0 0 1-1.669-6.138q0-6.335 6.015-17.089a102.584 102.584 0 0 1 14.756-20.084l3.683-3.83q-.294-6.235-.491-9.379-.2-2.8-.54-2.8-.246 0-1.08 2.259a32.944 32.944 0 0 1-2.775 5.328 10.452 10.452 0 0 1-2.725 3.068 7.551 7.551 0 0 1-3.83 1.081 8.02 8.02 0 0 1-5.647-2.5 11.948 11.948 0 0 1-3.069-5.966 51.221 51.221 0 0 1-.909-11.077l.049-4.568q.2-7.514.59-10.508t1.325-3.634a3.205 3.205 0 0 1 2.21-.834 6.545 6.545 0 0 1 3.413 1.1q1.793 1.1 1.792 2.431a1.756 1.756 0 0 1-.2.786 21.674 21.674 0 0 0-.81 5.474q-.272 4.1-.27 8.717 0 4.47.245 8.029t.786 4.248h.049q.637 0 4.468-10.313 1.523-4.024 3.045-7.243a22.132 22.132 0 0 1 2.357-4.2 5.074 5.074 0 0 1 3.928-2.014 6.6 6.6 0 0 1 3.02.762 3.968 3.968 0 0 1 1.94 1.743q.3 1.031.589 13.356.2 9.085.344 9.527l.147.1q.54 0 5.206-3.388 5.6-4.125 7.856-5.5a6.54 6.54 0 0 1 1.694-.737 7.482 7.482 0 0 1 1.6-.147 5.914 5.914 0 0 1 3.118.761q1.253.762 1.252 1.645a1.635 1.635 0 0 1-.786 1.277q-8.79 6.188-12.571 8.986-2.848 2.112-6.678 5.107 0 17.58-.049 21.116-.1 2.8-.3 4.149a19.271 19.271 0 0 1-.687 2.922q-5.5 19.592-17.482 22.735a13.6 13.6 0 0 1-3.437.491m1.866-5.4a5.051 5.051 0 0 0 3.241-1.768 22.864 22.864 0 0 0 4.3-6.629 57.79 57.79 0 0 0 3.51-9.968 30.542 30.542 0 0 0 .762-5.525q.171-3.118.221-8.667l.1-5.4a86.161 86.161 0 0 0-9.625 12.768 70.151 70.151 0 0 0-5.205 10.116q-2.209 5.3-2.21 7.611a8.932 8.932 0 0 0 .712 3.192 10 10 0 0 0 1.645 2.9 3.586 3.586 0 0 0 2.554 1.375"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22298"
                    d="M611.771 149.852a9.377 9.377 0 0 1-3.339-.442 8.019 8.019 0 0 1-4.321-4.247q-1.229-2.872-1.228-8.962 0-3.045-.1-5.893l-.393-9.232h-1.227a4.354 4.354 0 0 1-3.34-1.989 6.667 6.667 0 0 1-1.571-4.2 3.457 3.457 0 0 1 1.1-2.8 20.443 20.443 0 0 1 3.56-2.21l1.571-.835q.048-3.78.1-6.212t.123-5.3q.074-2.873.27-7.881l-.883.491a13.759 13.759 0 0 0-4.077 2.946 2.806 2.806 0 0 1-.711.737 1.367 1.367 0 0 1-.762.295 2.451 2.451 0 0 1-1.694-1.032 10.143 10.143 0 0 1-1.571-2.456 6.315 6.315 0 0 1-.663-2.651 4.434 4.434 0 0 1 1.6-3.266 20.548 20.548 0 0 1 5.132-3.167 55.359 55.359 0 0 1 5.892-2.479q2.75-.958 7.464-2.137a77.892 77.892 0 0 1 10.484-2.087q3.953-.416 8.618-.515h.786a21.156 21.156 0 0 1 1.5 2.455 5.43 5.43 0 0 1 .516 2.456 2.564 2.564 0 0 1-.982 2.259 37.222 37.222 0 0 1-5.6.932q-3.683.492-8.544 1.571-7.023 1.62-7.661 2.309a1.52 1.52 0 0 0-.343.834 64.48 64.48 0 0 0-.639 8.446q-.147 4.226-.147 7.317 0 3.389.2 3.388l1.179-.2a92.493 92.493 0 0 1 9.87-1.522l3.683-.246a20.94 20.94 0 0 1 2.21 2.824 5.208 5.208 0 0 1 .785 2.823 2.584 2.584 0 0 1-.2 1.007 1.545 1.545 0 0 1-.565.712 4.2 4.2 0 0 1-1.5.418q-1.129.173-3.535.466a58.266 58.266 0 0 0-12.178 2.946q.147 25.683.884 27.646a.379.379 0 0 0 .344.148q1.324 0 6.162-3.438a107.294 107.294 0 0 0 11.67-10.013q1.718-1.669 2.6-2.455a5.073 5.073 0 0 1 1.424-.983 3.6 3.6 0 0 1 1.228-.2 5.054 5.054 0 0 1 4.027 1.474 1.939 1.939 0 0 1 .883 1.522 3.605 3.605 0 0 1-.982 1.964 41.763 41.763 0 0 1-3.191 3.389q-15.42 15.271-23.915 15.271"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22299"
                    d="M670.8 153.19a3.754 3.754 0 0 1-2.21-.589q-1.031-.982-2.283-5.892a111.558 111.558 0 0 1-2.235-11.983q-1.324-9.231-1.939-20.378t-1.158-29.758l-2.455.688a82.507 82.507 0 0 0-11.565 4.2q-5.082 2.331-5.082 3.461a2.107 2.107 0 0 1-.221.835 3.247 3.247 0 0 1-.589.885 1.1 1.1 0 0 1-.811.391 4.2 4.2 0 0 1-2.406-1.472 7.169 7.169 0 0 1-1.817-2.406 7.865 7.865 0 0 1-.491-3 6.137 6.137 0 0 1 1.375-4.3q1.374-1.5 5.7-3.412a84.692 84.692 0 0 1 19.859-5.96 122.708 122.708 0 0 1 22.172-2.087 73.077 73.077 0 0 1 18.267 2.062 8.2 8.2 0 0 1 3.167 1.375 5.193 5.193 0 0 1 1.4 2.652 6.858 6.858 0 0 1 .442 2.161 4.4 4.4 0 0 1-.222 1.3 3.008 3.008 0 0 1-.515 1.056 1.457 1.457 0 0 1-1.13.343 25.607 25.607 0 0 1-4.714-.785 33.211 33.211 0 0 0-4.788-.663q-2.529-.172-8.127-.171-4.665 0-7.242.048t-4.125.172q-1.548.122-2.873.27l-1.277.1a6.11 6.11 0 0 0 .172.589 2.63 2.63 0 0 1 .074.785 22.679 22.679 0 0 1-.589 4.371 102.762 102.762 0 0 0-2.554 22.834 177.362 177.362 0 0 0 1.277 20.55 155.962 155.962 0 0 0 2.75 17.113 12.7 12.7 0 0 1 .54 2.6q0 .933-1.178 1.474a6.193 6.193 0 0 1-2.6.54"
                />
                <path
                    fill="#fff"
                    data-name="Trac\xE9 22300"
                    d="M746.467 153.878a12.147 12.147 0 0 1-3.879-.933 22.511 22.511 0 0 1-4.813-2.505 60.764 60.764 0 0 1-9.354-8.347 131.307 131.307 0 0 1-10.19-12.08 11.657 11.657 0 0 0-2.111-2.554l-.049.344q0 2.162.933 9.429a41.042 41.042 0 0 0 1.67 8.593 6.16 6.16 0 0 0 1.326 2.209 1.456 1.456 0 0 1 .491 1.032q0 .884-1.35 1.571a5.869 5.869 0 0 1-2.676.687 3.725 3.725 0 0 1-3.438-2.16 20.4 20.4 0 0 1-1.129-3.339 43.3 43.3 0 0 1-.933-4.371q-.344-1.57-.933-1.571a.4.4 0 0 0-.295.172 1.71 1.71 0 0 1-.589.368 3.927 3.927 0 0 1-1.473.2 5.338 5.338 0 0 1-3.119-.809 2.34 2.34 0 0 1-1.153-1.94q0-1.227 3.486-9.134l1.768-4.173.049-10.165q0-8.349.147-13.185t.688-8.962a95.118 95.118 0 0 0 .687-10.852 30.251 30.251 0 0 1 .246-4.862q.244-1.227 1.227-1.227a6.15 6.15 0 0 1 4.764 2.651 7.219 7.219 0 0 1 1.62 4.567q0 1.425-.982 8.249-.492 3.34-.761 7.612t-.27 7.022a6.14 6.14 0 0 0 .245 2.5q.54-.783 3.634-7.365 5.352-11.244 8-15.91a15.766 15.766 0 0 1 3.388-4.665 5.248 5.248 0 0 1 3.241-1.032 6.493 6.493 0 0 1 4.027 1.3 3.512 3.512 0 0 1 1.719 2.628 1.751 1.751 0 0 1-.81 1.4 2.477 2.477 0 0 1-1.4.613q-.933 0-5.328 8.274t-13.136 25.657l.982 1.474q4.321 6.434 7.784 10.975a101.6 101.6 0 0 0 8.618 9.748 53.258 53.258 0 0 0 5.819 5.254 22.533 22.533 0 0 0 5.082 2.848q3.142 1.227 3.143 2.357 0 .736-1.572 1.571a6.469 6.469 0 0 1-3.044.835"
                />
            </g>
        </g>
    </svg>
);
