import { useSnapshot } from "valtio";

import React from "react";

import { getImageContentById } from "@helpers/general";

import { AuthenticationErrorModal } from "@components/common/AuthenticationErrorModal";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { PrmActionsButtonsWrapper } from "@components/common/PrmActionsButtonsWrapper";
import { PrmBackgroundWrapper } from "@components/common/PrmBackgroundWrapper";
import { PrmLanguagesWrapper } from "@components/common/PrmLanguageWrapper";
import { GlobalPrmModeWrapper } from "@components/common/PrmModeMainContentWrapper/GlobalPrmModeWrapper";
import { PrmModeSubStepContainerWrapper } from "@components/common/PrmModeSubStepContainerWrapper";
import { SubStepsInformationMessage } from "@components/common/SubStepsInformationMessage";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";
import { PrmNumericKeyboardWrapper } from "@components/PrmNumericKeyboardWrapper";

import {
    kioskStore,
    setKeyboardInputsValues,
    setNumericKeyboardInputValue,
    setPseudoNameValue,
} from "@pages/Kiosk/store";

export function PrmMode(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isSubStepActionButtonsActive,
        subStepActionButtons,
        isSubStepIconActive,
        isSubStepInformationMessageActive,
        isAuthenticationErrorModalOpened,
        isConfirmationModalOpened,
        selectedInformationMode,
        subStepIconId,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const IGNORE_BUTTON_ROLE = 2;
            const VALIDATE_BUTTON_ROLE = 1;
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [
                IGNORE_BUTTON_ROLE,
                VALIDATE_BUTTON_ROLE,
                BACK_BUTTON_ROLE,
            ];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(
        null
    );

    const imageSrc = getImageContentById(subStepIconId);

    React.useEffect(() => {
        setNumericKeyboardInputValue("");
        setPseudoNameValue("");
        setKeyboardInputsValues({});
    }, []);

    return (
        <GlobalPrmModeWrapper>
            <PrmBackgroundWrapper />
            <div>
                <PreviewBackgroundWrapper
                    selectedMode={selectedInformationMode}
                >
                    <PrmModeSubStepContainerWrapper
                        isSubStepIconActive={isSubStepIconActive}
                        isSubStepInformationMessageActive={
                            isSubStepInformationMessageActive
                        }
                        isSubStepActionButtonsActive={
                            isSubStepActionButtonsActive
                        }
                        isLanguagesActive={isLanguagesActive}
                        isConfigOptionsActive={isConfigOptionsActive}
                        ref={(ref) => setContainerRef(ref)}
                    >
                        {isAuthenticationErrorModalOpened === true ? (
                            <AuthenticationErrorModal />
                        ) : null}

                        {containerRef ? (
                            isConfirmationModalOpened === true ? (
                                <ConfirmationModalWrapper
                                    containerRef={containerRef}
                                />
                            ) : null
                        ) : null}
                        {isSubStepIconActive ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <img
                                    src={imageSrc}
                                    alt="phoneNumber"
                                    style={{
                                        height: "120px",
                                        width: "120px",
                                    }}
                                />
                            </div>
                        ) : null}

                        {isSubStepInformationMessageActive ? (
                            <SubStepsInformationMessage />
                        ) : null}

                        <div className="d-flex justify-content-center align-items-center">
                            {selectedInformationMode === "nameOrPseudo" ? (
                                <InputKeyboardWrapper />
                            ) : (
                                <PrmNumericKeyboardWrapper />
                            )}
                        </div>

                        {isSubStepActionButtonsActive ? (
                            <PrmActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}

                        <div className="d-flex align-items-center justify-content-end">
                            <PrmLanguagesWrapper />

                            {isConfigOptionsActive ? (
                                <ConfigOptionsWrapper />
                            ) : null}
                        </div>
                    </PrmModeSubStepContainerWrapper>
                </PreviewBackgroundWrapper>
            </div>
        </GlobalPrmModeWrapper>
    );
}
