import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function InformationMessageWrapper(): JSX.Element | null {
    const {
        project: {
            template: {
                pages: {
                    ways: { finalMessage },
                },
            },
        },
        customerLanguage,
    } = useSnapshot(kioskStore);

    return (finalMessage as PageType).header.informationMessage.active ===
        true ? (
        <div className="d-flex justify-content-center align-items-center">
            <span style={{ fontSize: "55px" }}>
                {
                    (finalMessage as PageType).header.informationMessage
                        .languages[customerLanguage.name].content
                }
            </span>
        </div>
    ) : null;
}
