import {
    CATALOGUE_MODE,
    ETK_CESAR,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog, Slide } from "@mui/material";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFilenameFromUrl, getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { getProductTitle } from "@components/OrderTaking/Helpers";
import { getPrice } from "@components/OrderTaking/Helpers/getPrice";

import { kioskStore } from "@pages/Kiosk/store";

import { getModifierProduct } from "./helpers";
import { ProductSizeSwiper } from "./ProductSizeSwiper";
import { ProductSizeSwiperMoreThanFour } from "./ProductSizeSwiperMoreThanFour";
import { ProductSizeSwiperTwo } from "./ProductSizeSwiperTwo";

export default function ProductSizeDialog({
    open,
    onClose,
    product,
    handleValidateOption,
}) {
    const { compositeProductActionsButtons } = useSelector(
        (state) => state.settingSlice.compositeProductSettings,
        shallowEqual
    );
    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const { cardType } = useSelector((state) => state.orderSlice);

    const { isPrm, selectedSaleModeId, devise } = useSnapshot(kioskStore);

    const [selectedSizeIndex, setSelectedSizeIndex] = React.useState(0);
    const [listOptions, setListOptions] = React.useState([
        selectedSaleModeId,
        "KIOSK",
    ]);

    const [productOptions, setProductOptions] = React.useState({
        ...product.optionsKiosk,
        selectedOption: [],
    });

    const optionTitle = getProductTitle(
        productOptions.Options[productOptions.optionIndex].name
    );
    const productTitle = getProductTitle(product.name);
    const productImage = getFilenameFromUrl(product.image, "catalog");

    function handleNextClick() {
        if (productOptions.optionIndex < productOptions.Options.length - 1) {
            setSelectedSizeIndex(0);
            setProductOptions((prevState) => {
                return {
                    ...prevState,
                    optionIndex: prevState.optionIndex + 1,
                    selectedOption: [
                        ...prevState.selectedOption,
                        {
                            iuud: productOptions.Options[
                                productOptions.optionIndex
                            ].iuud,
                            selected:
                                productOptions.Options[
                                    productOptions.optionIndex
                                ].listOptions[selectedSizeIndex].iuud,
                        },
                    ],
                };
            });
        } else {
            const selectedOptionLabels = listOptions
                .slice(2)
                .filter((iuud, idx) => idx % 2 !== 0)
                .map((elm, optionIndex) => {
                    return productOptions.Options[
                        optionIndex
                    ].listOptions.filter((opt) => opt.iuud === elm)[0].name
                        .content;
                });
            const selectedIndexOptions = listOptions
                .slice(2)
                .filter((iuud, idx) => idx % 2 !== 0)
                .map((elm, optionIndex) => {
                    return productOptions.Options[
                        optionIndex
                    ].listOptions.filter((opt) => opt.iuud === elm)[0]
                        .optionIndex;
                });

            const price = getPrice(listOptions, product.price, false);
            const optionImage =
                productOptions.Options[productOptions.optionIndex].listOptions[
                    selectedSizeIndex
                ].image;

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: selectedOptionLabels,
                    selectedOptionsUuid: listOptions,
                    newPrice: price.price,
                    tva: price.tva,
                    selectedIndexOptions,
                    modifier: getModifierProduct(
                        globalcard,
                        product,
                        productOptions,
                        selectedSizeIndex
                    ),
                    optionImage:
                        cardType === ETK_CESAR
                            ? productImage
                            : getFilenameFromUrl(optionImage, "catalog"),
                    optionIndex:
                        productOptions.Options[productOptions.optionIndex]
                            .listOptions[selectedSizeIndex].optionIndex,
                })
            );

            handleValidateOption();
            onClose();
        }
    }
    function handlebackClick() {
        if (
            productOptions.Options.length === 1 ||
            productOptions.optionIndex === 0
        ) {
            onClose();
            return;
        }
        setSelectedSizeIndex(0);
        setListOptions((prev) => {
            const newState = [...prev];
            newState.length = newState.length - 2;
            return newState;
        });
        setProductOptions((prevState) => {
            return {
                ...prevState,
                optionIndex: prevState.optionIndex - 1,
            };
        });
    }
    const fillArrayOfUuid = () => {
        setListOptions((prev) => {
            const newState = [...prev];
            var index = newState.indexOf(
                productOptions.Options[productOptions.optionIndex].iuud
            );

            if (index !== -1) {
                newState[index + 1] =
                    productOptions.Options[
                        productOptions.optionIndex
                    ].listOptions[selectedSizeIndex].iuud;
            } else {
                newState.push(
                    productOptions.Options[productOptions.optionIndex].iuud,
                    productOptions.Options[productOptions.optionIndex]
                        .listOptions[selectedSizeIndex].iuud
                );
            }
            return newState;
        });
    };

    if (listOptions === undefined) return null;
    function handleActionsButtonsOnClickEvent(button) {
        button.name.toLowerCase() === "next in step"
            ? handleNextClick()
            : handlebackClick();
    }

    React.useEffect(() => {
        fillArrayOfUuid();
    }, [productOptions.optionIndex, selectedSizeIndex]);

    return (
        <Dialog
            onClose={onClose}
            open={open}
            disableEscapeKeyDown
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    display: "flex!important",
                    gap: "50px!important",
                    alignItems: "center!important",
                    flexDirection: "column!important",
                    paddingTop: "0px!important",
                    paddingBottom: "0px!important",
                    color: "black!important",
                    height: "1742px!important",
                    width: "944px!important",
                    maxWidth: "unset!important",
                    maxHeight: "unset",
                    backgroundColor: "#FFFFFF!important",
                    borderRadius: "111px!important",
                    borderLeft: "1px solid #707070!important",
                    borderRight: "1px solid #707070!important",
                    borderBottom: "1px solid #707070!important",
                    borderTop: `15px solid var(--global-color)!important`,
                    top: "unset!important",
                    right: "unset!important",
                    left: "unset!important",
                    background: `transparent linear-gradient(180deg, #FFFFFF 0%, var(--global-color) 300%) 0% 0% no-repeat padding-box`,
                    boxShadow: "0px 3px 6px #00000029",
                    position: "absolute",
                    bottom: isPrm ? "0px" : "unset",
                    margin: isPrm ? "5px" : "32px",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ gap: "20px", height: "40%" }}
            >
                <div className="popup-size-text">
                    {t("What")}
                    {"\xa0".repeat(1)}
                    {optionTitle}
                    {"\xa0".repeat(1)} {t("for your")}
                </div>
                <div className="popup-size-product-title">{productTitle} ?</div>
            </div>
            <div className="d-flex flex-column popup-size-img justify-content-center align-items-center">
                <Slide direction="up" in={true}>
                    <img
                        src={productImage}
                        className="w-50"
                        style={{ borderRadius: "35px" }}
                        alt={productTitle}
                    />
                </Slide>
                <span className="pt-5 priceContent-text">
                    {product.price.content.toFixed(devise.decimalPrice) +
                        ` ${devise.deviseSymbole}`}
                </span>
            </div>

            {productOptions.Options[productOptions.optionIndex].listOptions
                ?.length === 2 ? (
                <ProductSizeSwiperTwo
                    productProperties={
                        productOptions.Options[productOptions.optionIndex]
                            .listOptions
                    }
                    selectedSizeIndex={selectedSizeIndex}
                    setSelectedSizeIndex={setSelectedSizeIndex}
                    visiblePrice={
                        productOptions.Options.length - 1 ===
                        productOptions.optionIndex
                    }
                    listOptions={listOptions}
                    productPrice={product.price}
                    productImage={productImage}
                />
            ) : productOptions.Options[productOptions.optionIndex].listOptions
                  ?.length === 3 ? (
                <ProductSizeSwiper
                    productProperties={
                        productOptions.Options[productOptions.optionIndex]
                            .listOptions
                    }
                    selectedSizeIndex={selectedSizeIndex}
                    setSelectedSizeIndex={setSelectedSizeIndex}
                    visiblePrice={
                        productOptions.Options.length - 1 ===
                        productOptions.optionIndex
                    }
                    listOptions={listOptions}
                    productPrice={product.price}
                    productImage={productImage}
                />
            ) : productOptions.Options[productOptions.optionIndex].listOptions
                  ?.length >= 4 ? (
                <ProductSizeSwiperMoreThanFour
                    productProperties={
                        productOptions.Options[productOptions.optionIndex]
                            .listOptions
                    }
                    selectedSizeIndex={selectedSizeIndex}
                    setSelectedSizeIndex={setSelectedSizeIndex}
                    visiblePrice={
                        productOptions.Options.length - 1 ===
                        productOptions.optionIndex
                    }
                    listOptions={listOptions}
                    productPrice={product.price}
                    productImage={productImage}
                />
            ) : null}
            <div className="popup-size-buttons">
                {compositeProductActionsButtons
                    .filter((item) => {
                        return (
                            item.name.toLowerCase() === "next in step" ||
                            item.name.toLowerCase() === "previous in step"
                        );
                    })
                    .sort((a, b) => {
                        return a.role > b.role ? -1 : 1;
                    })
                    .map((button) => {
                        const imageSrc = getImageContentById(button.id);

                        return (
                            <div
                                className="d-flex justify-content-center"
                                onClick={() => {
                                    handleActionsButtonsOnClickEvent(button);
                                }}
                                key={button.id}
                                style={{ width: "40%", height: "100%" }}
                            >
                                {VALIDATED_EXTENSION_FILE.includes(
                                    imageSrc.substr(-4)
                                ) ? (
                                    <img
                                        style={{
                                            width: "224px",
                                            height: "65px",
                                        }}
                                        src={imageSrc}
                                    />
                                ) : (
                                    <DynamicSvgComponent
                                        nameSvg={imageSrc}
                                        width={"224px"}
                                        height={"65px"}
                                    />
                                )}
                            </div>
                        );
                    })}
            </div>
        </Dialog>
    );
}

ProductSizeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    handleValidateOption: PropTypes.func,
    initialItemOrder: PropTypes.object,
};
