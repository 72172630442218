import React from "react";
import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import initLogger from "@helpers/logging";

import { App } from "./App";
import "./i18n";

initLogger();

render(
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
        <ToastContainer />
    </React.StrictMode>,
    document.getElementById("root")
);
