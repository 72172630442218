import React from "react";

export function CalorieIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={53}
            height={53}
            {...props}
        >
            <g data-name="Groupe 30604">
                <g data-name="Groupe 21635">
                    <circle
                        data-name="Ellipse 197"
                        cx={26.5}
                        cy={26.5}
                        r={26.5}
                        fill={`var(--global-color)`}
                    />
                </g>
                <g fill="white">
                    <path
                        data-name="Trac\xE9 18348"
                        d="M27.168 42.6a12.232 12.232 0 0 1-4.03-.666c-4.63-1.612-7.719-4.151-9.18-7.545-2.4-5.573.449-11.38.571-11.624a.524.524 0 0 1 .514-.28.513.513 0 0 1 .456.359 6.509 6.509 0 0 0 2.129 2.878c1.189-5.665 6.392-9.094 6.623-9.244 3.357-2.553.615-7.624.587-7.675a.477.477 0 0 1 .131-.61.541.541 0 0 1 .656 0c9.246 7.126 8.714 14.3 7.968 17.253a4.671 4.671 0 0 0 1.884-4.426.491.491 0 0 1 .328-.529.535.535 0 0 1 .616.183 21.89 21.89 0 0 1 2.427 4.746 12.948 12.948 0 0 1-2.436 13l-.111.129a12.046 12.046 0 0 1-9.133 4.051Zm-3.673-1.593a11.146 11.146 0 0 0 3.673.606 10.99 10.99 0 0 0 8.328-3.691l.1-.123a12.006 12.006 0 0 0 2.264-12.055 21.793 21.793 0 0 0-1.4-3.032 6.748 6.748 0 0 1-3.589 4.365.54.54 0 0 1-.589-.1.475.475 0 0 1-.1-.559c.164-.334 3.738-7.94-5.67-16.2.6 1.976.959 5.067-1.646 7.046-.087.058-5.6 3.7-6.354 9.419a.5.5 0 0 1-.3.387.543.543 0 0 1-.505-.042 9.355 9.355 0 0 1-2.685-2.75c-.682 1.894-1.735 5.938-.1 9.735 1.347 3.124 4.231 5.474 8.573 6.986Z"
                        fill="white"
                        stroke="white"
                    />
                    <path
                        data-name="Trac\xE9 18349"
                        d="M24.956 42.398a.536.536 0 0 1-.334-.116c-6.616-5.3-1.955-12.072-1.907-12.14a.533.533 0 0 1 .567-.2.5.5 0 0 1 .383.445 3.211 3.211 0 0 0 .81 2.067 9.83 9.83 0 0 1 4.577-9.69.54.54 0 0 1 .619.069.475.475 0 0 1 .1.583 3.285 3.285 0 0 0-.418 1.669 3.5 3.5 0 0 0 .652 1.892l.208.3a31.136 31.136 0 0 0 2.233 2.792 8.607 8.607 0 0 1 1.476 2.385c2.6 5.1-1.869 9.113-1.913 9.153a.539.539 0 0 1-.734-.026.476.476 0 0 1 .028-.7c.163-.144 3.959-3.58 1.674-8.021a.464.464 0 0 1-.023-.054 7.678 7.678 0 0 0-1.3-2.108 32.043 32.043 0 0 1-2.3-2.882l-.208-.3a4.45 4.45 0 0 1-.821-2.412 4.218 4.218 0 0 1 .027-.576 8.816 8.816 0 0 0-2.653 8.877.478.478 0 0 1-.157.465.54.54 0 0 1-.5.108 3.448 3.448 0 0 1-2.108-2.114 7.552 7.552 0 0 0 2.367 9.663.477.477 0 0 1 .153.547.519.519 0 0 1-.488.324Z"
                        fill="white"
                        stroke="white"
                    />
                </g>
            </g>
        </svg>
    );
}
