import React from "react";

import { FlexboxGrid } from "@components/common/FlexboxGrid";

import { ActionsButtonsImageContent } from "./ActionsButtonsImageContent";

type ActionsButtonsPropsType = {
    actionsButtonsItems: ActionsButtonsDesignItemType[];
};

export function ActionsButtonsWrapper({
    actionsButtonsItems,
}: ActionsButtonsPropsType): JSX.Element {
    return (
        <React.Fragment>
            {actionsButtonsItems?.length === 1 ? (
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                >
                    <ActionsButtonsImageContent item={actionsButtonsItems[0]} />
                </FlexboxGrid>
            ) : actionsButtonsItems?.length === 2 ? (
                <FlexboxGrid
                    alignItemsCentered={true}
                    justifyContentCentered={true}
                    gap="25px"
                >
                    {actionsButtonsItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={index}
                                    item={item}
                                />
                            );
                        }
                    )}
                </FlexboxGrid>
            ) : actionsButtonsItems?.length === 3 ? (
                <FlexboxGrid
                    className="flex-column"
                    alignItemsCentered={true}
                    gap="20px"
                >
                    <FlexboxGrid
                        justifyContentCentered={true}
                        alignItemsCentered={true}
                        gap="25px"
                    >
                        {actionsButtonsItems
                            .slice(-2)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionsButtonsImageContent
                                            key={index}
                                            item={item}
                                        />
                                    );
                                }
                            )}
                    </FlexboxGrid>

                    <FlexboxGrid justifyContentCentered={true}>
                        {actionsButtonsItems
                            .slice(0, 1)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionsButtonsImageContent
                                            key={index}
                                            item={item}
                                        />
                                    );
                                }
                            )}
                    </FlexboxGrid>
                </FlexboxGrid>
            ) : actionsButtonsItems?.length === 4 ? (
                <div className="d-grid__clz align-items-center">
                    <FlexboxGrid justifyContentCentered={true} gap="30px">
                        {actionsButtonsItems
                            .slice(-2)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionsButtonsImageContent
                                            key={index}
                                            item={item}
                                        />
                                    );
                                }
                            )}
                    </FlexboxGrid>

                    <FlexboxGrid justifyContentCentered={true} gap="30px">
                        {actionsButtonsItems
                            .slice(0, 2)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionsButtonsImageContent
                                            item={item}
                                            key={index}
                                        />
                                    );
                                }
                            )}
                    </FlexboxGrid>
                </div>
            ) : null}
        </React.Fragment>
    );
}
