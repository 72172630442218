import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { getProductTitle } from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

export function ProductDetails({
    product,
    isPriceShown,
    isProductDescriptionActive,
}) {
    const { isPrm, devise } = useSnapshot(kioskStore);
    const { price, isStepItem, hidePrice } = product;

    const productDescriptionContent = getProductTitle(product.description);
    return (
        <div style={{ height: "100%" }}>
            {isPriceShown ? (
                <div
                    className={`d-flex justify-content-center align-items-start px-1 ${
                        isPrm ? "prm-price" : "price"
                    }`}
                    style={{
                        fontSize: "18px",
                        fontWeight: 700,
                        fontFamily: "Segoe UI",
                        textAlign: "center",
                        alignItems: "center !important",
                    }}
                >
                    {isStepItem
                        ? hidePrice && price.content === 0
                            ? null
                            : price.content.toFixed(devise.decimalPrice) +
                              ` ${devise.deviseSymbole}`
                        : price.content.toFixed(devise.decimalPrice) +
                          ` ${devise.deviseSymbole}`}
                </div>
            ) : null}
            <span
                className={`px-2 two-lines-preview-text ${
                    isPrm ? "prm-description pt-1 " : "description__clz pb-1"
                }`}
                style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    fontFamily: "Segoe UI",
                    lineHeight: "1.2em",
                    textAlign: "center",
                }}
            >
                {isProductDescriptionActive ? productDescriptionContent : null}
            </span>
        </div>
    );
}

ProductDetails.propTypes = {
    product: PropTypes.object.isRequired,
    isPriceShown: PropTypes.bool,
    isProductDescriptionActive: PropTypes.bool,
};
