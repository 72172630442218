import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function UserInfoWrapper(): JSX.Element {
    const {
        user: { firstName, lastName },
    } = useSnapshot(kioskStore);

    return (
        <span
            className="mb-0"
            style={{
                fontSize: "35px",
            }}
        >
            {firstName === "" ? lastName : firstName}
        </span>
    );
}
