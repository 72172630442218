/* eslint-disable @typescript-eslint/no-explicit-any */
import { TCPOS, VALIDATED_EXTENSION_FILE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { store } from "@store";

import {
    getImageContentById,
    getPreviousSkippedStepNumber,
    getSkippedStepNumber,
    handleAbortCashMachineTransaction,
    handleAbortCBTransaction,
    handleUserAuthentication,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";

import {
    kioskStore,
    setAuthModalStepIndex,
    setChosenInformationMode,
    setFinalMessageSelectedInformationMode,
    setInformationModeValueTcpos,
    setIsAbortTransactionModalOpened,
    setIsCashTransactionValidated,
    setIsClickAndCollect,
    setIsConfirmationModalOpened,
    setIsPaymentRefused,
    setIsRegulationModesModalOpened,
    setIsSendEmailConfirmed,
    setIsSendMailWithPdfActive,
    setIsUserAccountModalOpened,
    setIsValidEmail,
    setKeyboardInputsValues,
    setLeftToPayAfterCashMachineValidation,
    setLocalSelectedInformationMode,
    setNavigationIndex,
    setNumericKeyboardInputValue,
    setOrderItemsLength,
    setPaymentStepCounter,
    setQrCodeValue,
    setSelectedAuthMode,
    setSelectedInformationMode,
    setUserAuthInfo,
} from "@pages/Kiosk/store";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const {
        selectedAuthMode,
        kioskWays,
        authModalStepIndex,
        selectedSaleModeValue,
        navigationIndex,
        numericKeyboardInputValue,
        isAuthenticationErrorModalOpened,
        keyboardInputsValues,
        isConfirmationModalOpened,
        isUserAuthenticated,
        isAuthenticationAccepted,
        selectedInformationMode,
        selectedRegulationModeValue,
        isOrderPaidWithLoyaltyAccountBalance,
        project: {
            template: { content },
        },
        pseudoNameValue,
        isBackButtonDisabled,
        isAnotherPaymentModeButtonDisabled,
        amountDeposited,
        isOrderPartialPaidWithLoyaltyAccountBalance,
        totalOrderAfterPaidWithLoyaltyAccountBalance,
        leftToPayAfterCashMachineValidation,
        isTransactionCanceled,
        isCashTransactionValidated,
        saleMode,
        isOrderInsertionRefused,
        isOrderTakingPreviousStep,
        isPrm,
        isSendEmailConfirmed,
        isValidEmail,
        isClickAndCollect,
        orderTotalPrice,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);
    const dispatch = useDispatch();

    const { orderItems } = useSelector((state: any) => state.orderSlice.order);

    const imageSrc = getImageContentById(item.id);

    async function handleOnClickEvent(item: ActionsButtonsDesignItemType) {
        if (
            (numericKeyboardInputValue === "" &&
                keyboardInputsValues[t("Enter your email")] === undefined &&
                keyboardInputsValues[t("Enter your password")] === undefined &&
                !isValidEmail &&
                pseudoNameValue === "" &&
                item.name.toLowerCase() === "validate") ||
            (kioskWays[navigationIndex].name === "payment" &&
                ((item.name.toLowerCase() === "Back".toLowerCase() &&
                    isBackButtonDisabled) ||
                    (item.name.toLowerCase() ===
                        "otherPaymentMethod".toLowerCase() &&
                        isAnotherPaymentModeButtonDisabled) ||
                    isTransactionCanceled ||
                    (isCashTransactionValidated &&
                        selectedRegulationModeValue === "changeMachine")))
        )
            return;

        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                if (selectedAuthMode !== "" && !isSendEmailConfirmed) {
                    const authModeInfo: AuthModeInfoType =
                        selectedAuthMode === "phoneNumber"
                            ? {
                                  phoneNumber: numericKeyboardInputValue,
                              }
                            : selectedAuthMode === "emailAndPassword"
                            ? {
                                  email: keyboardInputsValues[
                                      t("Enter your email")
                                  ],
                                  password:
                                      keyboardInputsValues[
                                          t("Enter your password")
                                      ],
                              }
                            : {
                                  loyaltyCardNumber: numericKeyboardInputValue,
                              };

                    setUserAuthInfo(authModeInfo);

                    setOrderItemsLength(orderItems.length);

                    handleUserAuthentication(
                        authModeInfo,
                        kioskWays,
                        navigationIndex,
                        isAuthenticationAccepted,
                        selectedSaleModeValue,
                        selectedInformationMode,
                        isOrderPaidWithLoyaltyAccountBalance,
                        selectedRegulationModeValue,
                        authModalStepIndex,
                        isAuthenticationErrorModalOpened,
                        content,
                        Number(orderTotalPrice),
                        dispatch
                    );
                } else if (isSendEmailConfirmed) {
                    setIsValidEmail(false);
                    setIsSendEmailConfirmed(false);
                    setIsSendMailWithPdfActive(true);
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else if (
                    ["nameOrPseudo", "SMS"].includes(selectedInformationMode)
                ) {
                    setInformationModeValueTcpos(pseudoNameValue);
                    const selectedInformationModeUuId = (
                        content.informationModes as ProjectContentItemType
                    ).items.find(
                        (mode) =>
                            (mode.shortName as string) ===
                            selectedInformationMode
                    )?.id;
                    setChosenInformationMode({
                        type:
                            selectedInformationMode === "SMS"
                                ? "phoneNumber"
                                : selectedInformationMode,
                        data: {
                            infoModeUuId: selectedInformationModeUuId as string,
                            value:
                                selectedInformationMode === "nameOrPseudo"
                                    ? pseudoNameValue
                                    : numericKeyboardInputValue,
                        },
                    });
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else {
                    setIsConfirmationModalOpened(!isConfirmationModalOpened);
                }
                setIsValidEmail(false);
                break;
            }
            case "Back".toLowerCase(): {
                if (
                    posEditor === TCPOS &&
                    kioskWays[navigationIndex].name === "finalMessage"
                ) {
                    const orderTakingStepIndex = kioskWays.indexOf(
                        kioskWays.filter(
                            (page: any) => page?.name === "orderTaking"
                        )[0]
                    );
                    setNavigationIndex(orderTakingStepIndex);
                } else if (selectedAuthMode !== "") {
                    if (authModalStepIndex !== 0) {
                        setNavigationIndex(authModalStepIndex);
                    } else if (
                        selectedSaleModeValue === "Delivery" ||
                        saleMode.name === "Delivery"
                    ) {
                        if (isOrderTakingPreviousStep === false) {
                            const salesMethodsStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page: any) => page?.name === "salesMethods"
                                )[0]
                            );
                            setNavigationIndex(salesMethodsStepIndex);
                        } else {
                            // setIsOrderTakingPreviousStep(true);
                            const orderTakingStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page: any) => page?.name === "orderTaking"
                                )[0]
                            );
                            setNavigationIndex(orderTakingStepIndex);
                        }
                    } else {
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    await getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                    }
                    setSelectedAuthMode("");
                    setNumericKeyboardInputValue("");
                    setQrCodeValue("");
                    setKeyboardInputsValues({});
                } else if (kioskWays[navigationIndex].name === "payment") {
                    console.log("Back button in payment step clicked");
                    setIsAbortTransactionModalOpened(true);
                    setIsRegulationModesModalOpened(false);
                    setPaymentStepCounter(0);
                } else if (
                    kioskWays[navigationIndex].name === "meansOfPayment" &&
                    isOrderInsertionRefused
                ) {
                    // setIsOrderTakingPreviousStep(true);
                    const orderTakingStepIndex = kioskWays.indexOf(
                        kioskWays.filter(
                            (page: any) => page?.name === "orderTaking"
                        )[0]
                    );
                    setNavigationIndex(orderTakingStepIndex);
                } else if (
                    kioskWays[navigationIndex].name === "printerOptions"
                ) {
                    if (isSendEmailConfirmed) {
                        setIsSendEmailConfirmed(false);
                        setIsValidEmail(false);
                        setIsSendMailWithPdfActive(false);
                    } else {
                        setNavigationIndex(
                            navigationIndex -
                                Number(
                                    await getPreviousSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue
                                    )
                                )
                        );
                    }
                } else {
                    if (
                        isClickAndCollect === true &&
                        kioskWays[navigationIndex].name === "clickAndCollect"
                    ) {
                        setIsClickAndCollect(false);
                    }

                    setNavigationIndex(
                        navigationIndex -
                            Number(
                                await getPreviousSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue
                                )
                            )
                    );
                }
                break;
            }
            case "Ignore".toLowerCase(): {
                if (selectedAuthMode !== "") {
                    /* Authentication Mode Consommation Step */
                    if (authModalStepIndex !== 0) {
                        setNavigationIndex(authModalStepIndex);
                    } else if (
                        selectedSaleModeValue === "Delivery" ||
                        saleMode.name === "Delivery"
                    ) {
                        if (orderItems.length === 0) {
                            const salesMethodsStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "salesMethods"
                                )[0]
                            );
                            setNavigationIndex(salesMethodsStepIndex);
                        } else {
                            const orderTakingStepIndex = kioskWays.indexOf(
                                kioskWays.filter(
                                    (page) => page?.name === "orderTaking"
                                )[0]
                            );
                            //   setIsOrderTakingPreviousStep(true);
                            setNavigationIndex(orderTakingStepIndex);
                        }
                    } else {
                        setNavigationIndex(
                            navigationIndex +
                                Number(
                                    await getSkippedStepNumber(
                                        kioskWays,
                                        navigationIndex,
                                        isAuthenticationAccepted,
                                        isUserAuthenticated,
                                        selectedSaleModeValue,
                                        selectedInformationMode,
                                        isOrderPaidWithLoyaltyAccountBalance,
                                        selectedRegulationModeValue,
                                        content,
                                        Number(orderTotalPrice)
                                    )
                                )
                        );
                    }
                    setIsUserAccountModalOpened(false);
                    setAuthModalStepIndex(0);
                    setSelectedAuthMode("");
                    setNumericKeyboardInputValue("");
                    setKeyboardInputsValues({});
                    setQrCodeValue("");
                } else if (
                    kioskWays[navigationIndex].name === "informationModes" ||
                    kioskWays[navigationIndex].name ===
                        "informationModesConsommation"
                ) {
                    setFinalMessageSelectedInformationMode("none");
                    setChosenInformationMode({
                        type: "",
                        data: {
                            infoModeUuId: "",
                            value: "",
                        },
                    });
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                } else if (
                    kioskWays[navigationIndex].name === "authenticationModes"
                ) {
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice),
                                    selectedAuthMode
                                )
                            )
                    );
                } else {
                    /* Information Mode Consommation Step */
                    setFinalMessageSelectedInformationMode("none");
                    setSelectedInformationMode("");
                    setLocalSelectedInformationMode("");
                    setNavigationIndex(
                        navigationIndex +
                            Number(
                                await getSkippedStepNumber(
                                    kioskWays,
                                    navigationIndex,
                                    isAuthenticationAccepted,
                                    isUserAuthenticated,
                                    selectedSaleModeValue,
                                    selectedInformationMode,
                                    isOrderPaidWithLoyaltyAccountBalance,
                                    selectedRegulationModeValue,
                                    content,
                                    Number(orderTotalPrice)
                                )
                            )
                    );
                }
                break;
            }
            case "giveUp".toLowerCase(): {
                setNavigationIndex(0);

                break;
            }
            case "otherPaymentMethod".toLowerCase(): {
                if (selectedRegulationModeValue === "changeMachine") {
                    setIsCashTransactionValidated(true);
                    const validCashMachineTransactionAmount =
                        leftToPayAfterCashMachineValidation !== 0
                            ? leftToPayAfterCashMachineValidation -
                              amountDeposited
                            : isOrderPartialPaidWithLoyaltyAccountBalance
                            ? totalOrderAfterPaidWithLoyaltyAccountBalance -
                              amountDeposited
                            : orderTotalPrice - amountDeposited;

                    console.log(
                        `Validate CashMachine Transaction with the amount:${validCashMachineTransactionAmount}`
                    );
                    setLeftToPayAfterCashMachineValidation(
                        validCashMachineTransactionAmount
                    );
                    setPaymentStepCounter(0);
                    handleAbortCashMachineTransaction();
                    //const url = `${middlewareApiUrl}/ValidCashMachineTransaction?unitaire=${validCashMachineTransactionAmount}&timestamp=${timestamp}`;

                    // fetch(url)
                    //     .then((response) => response.json())
                    //     // eslint-disable-next-line
                    //     .then((_) => {
                    //         const localTotalAmountDeposited =
                    //             totalAmountDeposited + amountDeposited;
                    //         console.log(
                    //             `The total amount deposited will be:${localTotalAmountDeposited}`
                    //         );
                    //     })
                    //     .catch((error) => {
                    //         console.log(
                    //             `Error during the validation of change machine transaction, with the message ${error}`
                    //         );
                    //     });
                } else {
                    handleAbortCBTransaction();
                }

                setIsPaymentRefused(false);

                break;
            }
        }
    }

    if (kioskWays[navigationIndex].name === "payment") {
        console.log(
            JSON.stringify({
                isBackButtonDisabled,
                isAnotherPaymentModeButtonDisabled,
                isTransactionCanceled,
                isCashTransactionValidated,
            })
        );
    }

    return (
        <div
            className="d-flex flex-row"
            style={{
                placeSelf: "center",
                placeItems: "center",
                gap: "10px",
            }}
        >
            {VALIDATED_EXTENSION_FILE.includes(imageSrc.substr(-4)) ? (
                <img
                    src={imageSrc}
                    alt="action button"
                    style={{
                        height: isPrm ? "70px" : "87px",
                        width:
                            isPrm &&
                            (kioskWays[navigationIndex].name !== "payment" ||
                                item.name.toLowerCase() === "back")
                                ? "281px"
                                : kioskWays[navigationIndex].name ===
                                      "payment" &&
                                  item.name.toLowerCase() !== "back"
                                ? "628px"
                                : "350px",
                        opacity:
                            (numericKeyboardInputValue === "" &&
                                keyboardInputsValues[t("Enter your email")] ===
                                    undefined &&
                                keyboardInputsValues[
                                    t("Enter your password")
                                ] === undefined &&
                                !isValidEmail &&
                                pseudoNameValue === "" &&
                                item.name.toLowerCase() === "validate") ||
                            (kioskWays[navigationIndex].name === "payment" &&
                                ((item.name.toLowerCase() ===
                                    "Back".toLowerCase() &&
                                    isBackButtonDisabled) ||
                                    (item.name.toLowerCase() ===
                                        "otherPaymentMethod".toLowerCase() &&
                                        isAnotherPaymentModeButtonDisabled) ||
                                    isTransactionCanceled ||
                                    (isCashTransactionValidated &&
                                        selectedRegulationModeValue ===
                                            "changeMachine")))
                                ? "0.5"
                                : 1,
                    }}
                    onClick={() => {
                        handleOnClickEvent(item);
                    }}
                />
            ) : (
                <DynamicSvgComponent
                    nameSvg={imageSrc as string}
                    width={
                        isPrm &&
                        (kioskWays[navigationIndex].name !== "payment" ||
                            item.name.toLowerCase() === "back")
                            ? "281px"
                            : kioskWays[navigationIndex].name === "payment" &&
                              item.name.toLowerCase() !== "back"
                            ? "628px"
                            : "350px"
                    }
                    height={isPrm ? "70px" : "87px"}
                    pointerEvents={
                        (numericKeyboardInputValue === "" &&
                            keyboardInputsValues[t("Enter your email")] ===
                                undefined &&
                            keyboardInputsValues[t("Enter your password")] ===
                                undefined &&
                            pseudoNameValue === "" &&
                            !isValidEmail &&
                            imageSrc.toLowerCase().includes("validate")) ||
                        (kioskWays[navigationIndex].name === "payment" &&
                            ((item.name.toLowerCase() ===
                                "Back".toLowerCase() &&
                                isBackButtonDisabled) ||
                                (item.name.toLowerCase() ===
                                    "otherPaymentMethod".toLowerCase() &&
                                    isAnotherPaymentModeButtonDisabled) ||
                                isTransactionCanceled ||
                                (isCashTransactionValidated &&
                                    selectedRegulationModeValue ===
                                        "changeMachine")))
                            ? "none"
                            : "auto"
                    }
                    opacity={
                        (numericKeyboardInputValue === "" &&
                            keyboardInputsValues[t("Enter your email")] ===
                                undefined &&
                            keyboardInputsValues[t("Enter your password")] ===
                                undefined &&
                            pseudoNameValue === "" &&
                            !isValidEmail &&
                            imageSrc.toLowerCase().includes("validate")) ||
                        (kioskWays[navigationIndex].name === "payment" &&
                            ((item.name.toLowerCase() ===
                                "Back".toLowerCase() &&
                                isBackButtonDisabled) ||
                                (item.name.toLowerCase() ===
                                    "otherPaymentMethod".toLowerCase() &&
                                    isAnotherPaymentModeButtonDisabled) ||
                                isTransactionCanceled ||
                                (isCashTransactionValidated &&
                                    selectedRegulationModeValue ===
                                        "changeMachine")))
                            ? "0.5"
                            : "1"
                    }
                    onClick={() => {
                        handleOnClickEvent(item);
                    }}
                />
            )}
        </div>
    );
}
