import React from "react";

export function MenuSvgIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="79"
            viewBox="0 0 88 79"
            {...props}
        >
            <g
                id="Groupe_31446"
                data-name="Groupe 31446"
                transform="translate(-104 -1671)"
            >
                <g
                    transform="matrix(1, 0, 0, 1, 104, 1671)"
                    filter="url(#Rectangle_380)"
                >
                    <g
                        id="Rectangle_380-2"
                        data-name="Rectangle 380"
                        transform="translate(9 6)"
                        fill="#222"
                        stroke="#fff"
                    >
                        <rect width="70" height="61" rx="15" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="69"
                            height="60"
                            rx="14.5"
                            fill="none"
                        />
                    </g>
                </g>
                <g id="paragraph" transform="translate(127 1688.539)">
                    <path
                        id="Tracé_22204"
                        data-name="Tracé 22204"
                        d="M37.672,4.5H4.892a2.892,2.892,0,0,0,0,5.785H37.672a2.892,2.892,0,0,0,0-5.785Z"
                        transform="translate(0 0)"
                        fill="#fff"
                    />
                    <path
                        id="Tracé_22205"
                        data-name="Tracé 22205"
                        d="M32.208,10.5H4.892a2.892,2.892,0,1,0,0,5.785H32.208a2.892,2.892,0,1,0,0-5.785Z"
                        transform="translate(0 5.569)"
                        fill="#fff"
                    />
                    <path
                        id="Tracé_22206"
                        data-name="Tracé 22206"
                        d="M21.282,16.5H4.892a2.892,2.892,0,0,0,0,5.785h16.39a2.892,2.892,0,1,0,0-5.785Z"
                        transform="translate(0 11.138)"
                        fill="#fff"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="Rectangle_380"
                    x="0"
                    y="0"
                    width="88"
                    height="79"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
        </svg>
    );
}
