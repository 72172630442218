import { CATALOGUE_MODE, TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { store } from "@store";

import {
    getNoActionButtonImageSrc,
    getSkippedStepNumber,
    getYesActionButtonImageSrc,
} from "@helpers/general";

import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";

import {
    kioskStore,
    setChosenInformationMode,
    setChosenLocalInformationMode,
    setInformationModeValueTcpos,
    setIsConfirmationModalOpened,
    setIsInformationModesConsommationModalOpened,
    setIsInformationModesModalOpened,
    setIsUserAccountModalOpened,
    setNavigationIndex,
    setNumericKeyboardInputValue,
} from "@pages/Kiosk/store";

type PropsType = {
    containerRef: HTMLElement | null;
};

export function ConfirmationModalWrapper({
    containerRef,
}: PropsType): JSX.Element {
    const {
        isConfirmationModalOpened,
        isPrm,
        numericKeyboardInputValue,
        kioskWays,
        navigationIndex,
        project: {
            template: { pages, content },
        },
        selectedInformationMode,
        isUserAuthenticated,
        selectedRegulationModeValue,
        isOrderPaidWithLoyaltyAccountBalance,
        isAuthenticationAccepted,
        selectedSaleModeValue,
        saleMode,
        localSelectedInformationMode,
        orderTotalPrice,
    } = useSnapshot(kioskStore);

    const { posEditor } = useSnapshot(store);

    const { orderItems } = useSelector(
        // eslint-disable-next-line
        (state: any) => state.orderSlice.order
    );

    const localInformationMode =
        saleMode.name === "Delivery" && isUserAuthenticated === false
            ? localSelectedInformationMode
            : selectedInformationMode;

    async function handleOnClickEvent() {
        TCPOS === posEditor &&
            setInformationModeValueTcpos(numericKeyboardInputValue);

        const selectedInformationModeUuId = (
            content.informationModes as ProjectContentItemType
        ).items.find(
            (mode) => (mode.shortName as string) === localInformationMode
        )?.id;
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((page) => page?.name === "orderTaking")[0]
        );
        const informationModesConsommationStepIndex = kioskWays.indexOf(
            kioskWays.filter(
                (page) => page?.name === "informationModesConsommation"
            )[0]
        );

        if (saleMode.name === "Delivery" && isUserAuthenticated === false) {
            setChosenLocalInformationMode({
                type: localInformationMode,
                data: {
                    infoModeUuId: selectedInformationModeUuId as string,
                    value: numericKeyboardInputValue,
                },
            });
        } else {
            setChosenInformationMode({
                type: localInformationMode,
                data: {
                    infoModeUuId: selectedInformationModeUuId as string,
                    value: numericKeyboardInputValue,
                },
            });
        }
        setNumericKeyboardInputValue("");
        setIsConfirmationModalOpened(!isConfirmationModalOpened);

        if (
            (orderItems.length === 0 &&
                navigationIndex !== orderTakingStepIndex) ||
            orderTakingStepIndex < informationModesConsommationStepIndex
        ) {
            setNavigationIndex(
                navigationIndex +
                    Number(
                        await getSkippedStepNumber(
                            kioskWays,
                            navigationIndex,
                            isAuthenticationAccepted,
                            isUserAuthenticated,
                            selectedSaleModeValue,
                            selectedInformationMode,
                            isOrderPaidWithLoyaltyAccountBalance,
                            selectedRegulationModeValue,
                            content,
                            Number(orderTotalPrice)
                        )
                    )
            );
        } else {
            setIsConfirmationModalOpened(false);
            setIsInformationModesConsommationModalOpened(false);
            setIsInformationModesModalOpened(false);
            if (saleMode.name === "Delivery" && isUserAuthenticated === false) {
                setIsUserAccountModalOpened(true);
            }
        }
    }

    return (
        <Modal
            isOpen={isConfirmationModalOpened}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="bridge-number"
            // eslint-disable-next-line
            contentRef={() => containerRef!}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex flex-column w-100 h-100"
                style={{ gap: "30px" }}
            >
                <SubStepsIconWrapper />
                <div className="d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: "60px" }}>
                        {t(pages.subSteps[localInformationMode].name)}
                    </span>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <span className="text-center" style={{ fontSize: "40px" }}>
                        {localInformationMode === "bridgeNumber"
                            ? t("Please confirm Bridge number")
                            : localInformationMode === "pagerNumber"
                            ? t("Please confirm the pager number")
                            : t("Please confirm the track number")}
                    </span>
                </div>
            </div>

            <div
                className="d-flex justify-content-center align-items-center h-100"
                style={{ font: "normal normal 600 70px/94px Segoe UI" }}
            >
                {numericKeyboardInputValue}
            </div>
            <div
                className="d-flex justify-content-center "
                style={{ height: "450px", gap: "30px" }}
            >
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div
                        style={{
                            height: "350px",
                            width: "350px",
                        }}
                    >
                        <img
                            src={getNoActionButtonImageSrc(
                                content.actionsButtons as ActionsButtonsDesignType
                            )}
                            className="w-100 h-100"
                            style={{ backgroundSize: "cover" }}
                            onClick={() => {
                                const orderTakingStepIndex = kioskWays.indexOf(
                                    kioskWays.filter(
                                        (page) => page?.name === "orderTaking"
                                    )[0]
                                );
                                const informationModesStepIndex =
                                    kioskWays.indexOf(
                                        kioskWays.filter(
                                            (page) =>
                                                page?.name ===
                                                "informationModes"
                                        )[0]
                                    );
                                if (
                                    orderItems.length !== 0 &&
                                    orderTakingStepIndex >
                                        informationModesStepIndex
                                ) {
                                    setIsInformationModesConsommationModalOpened(
                                        true
                                    );
                                }
                                setIsConfirmationModalOpened(
                                    !isConfirmationModalOpened
                                );
                            }}
                        />
                    </div>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("No")}
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div
                        style={{
                            height: "350px",
                            width: "350px",
                        }}
                    >
                        <img
                            src={getYesActionButtonImageSrc(
                                content.actionsButtons as ActionsButtonsDesignType
                            )}
                            className="w-100 h-100"
                            style={{ backgroundSize: "cover" }}
                            onClick={handleOnClickEvent}
                        />
                    </div>
                    <span
                        style={{
                            font: "normal normal 600 37px/49px Segoe UI",
                        }}
                    >
                        {t("Yes")}
                    </span>
                </div>
            </div>
        </Modal>
    );
}
