import React from "react";

type CommentOrderSummuryType = {
    comment: string;
    // eslint-disable-next-line
    addCommentProduct: any;
    // eslint-disable-next-line
    Item: any;
};

export function CommentOrderSummury({
    comment,
    addCommentProduct,
    Item,
}: CommentOrderSummuryType): JSX.Element {
    return (
        <div
            className="justify-content-start composed-product-comment mb-2 mt-1 "
            style={{
                height: "max-content",
                borderRadius: "15px",
            }}
            onClick={() => {
                addCommentProduct(Item, true);
            }}
        >
            <span
                className="d-flex justify-content-start pl-4"
                style={{
                    font: "normal 600 30px/48px Segoe UI",
                }}
            >
                {comment}
            </span>
        </div>
    );
}
