import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { SubStepsIconWrapper } from "@components/common/SubStepsIconWrapper";
import { SubStepsLogoWrapper } from "@components/common/SubStepsLogoWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { UsualModeSubStepContainerWrapper } from "@components/common/UsualModeSubStepContainerWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

type usualModeType = {
    setIsQrCodeShown: React.Dispatch<React.SetStateAction<boolean>>;
    isQrCodeShown: boolean;
    handleOnChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    isScanningQrCode: boolean;
};

export function UsualMode({
    isQrCodeShown,
    setIsQrCodeShown,
    handleOnChangeEvent,
    inputRef,
    isScanningQrCode,
}: usualModeType): JSX.Element {
    const {
        isSubStepTopBannerActive,
        isSubStepIconActive,
        isLanguagesActive,
        isSubStepActionButtonsActive,
        isSubStepInformationMessageActive,
        isConfigOptionsActive,
        subStepInformationMessageItems,
        subStepActionButtons,
        customerLanguage,
    } = useSnapshot(kioskStore);

    const activeSubStepActionsButtons = (
        subStepActionButtons as ActionsButtonsDesignItemType[]
    ).filter((item) => {
        return item.active === true;
    });

    const actionButtons = activeSubStepActionsButtons.filter(
        (item: ActionsButtonsDesignItemType) => {
            const BACK_BUTTON_ROLE = 3;
            const ROLES = [BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        }
    );

    const mainActiveActionsButtons = actionButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper selectedMode={"clickAndCollect"}>
            <UsualModeSubStepContainerWrapper
                isSubStepTopBannerActive={isSubStepTopBannerActive}
                isSubStepIconActive={isSubStepIconActive}
                isSubStepInformationMessageActive={
                    isSubStepInformationMessageActive
                }
                isSubStepActionButtonsActive={isSubStepActionButtonsActive}
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
            >
                <SubStepsLogoWrapper />

                {isSubStepIconActive ? <SubStepsIconWrapper /> : null}
                {isSubStepInformationMessageActive ? (
                    <div className="d-flex justify-content-center align-items-center text-center">
                        <span
                            className=" two-lines-preview-text w-100"
                            style={{ fontSize: "55px" }}
                        >
                            {
                                subStepInformationMessageItems[
                                    customerLanguage.name
                                ].content
                            }
                        </span>
                    </div>
                ) : null}
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ gap: "100px" }}
                >
                    <img
                        src="./images/qr-code.png"
                        alt="qrCode"
                        style={{ height: "330px", width: "330px" }}
                    />
                    <div
                        style={{
                            borderBottom: "1px solid #00000029",
                        }}
                    >
                        <input
                            className="form-input text-center qr-code__clz"
                            type={isQrCodeShown ? "text" : "password"}
                            autoFocus
                            placeholder={t("QR CODE")}
                            onChange={handleOnChangeEvent}
                            ref={inputRef}
                        />
                        {isQrCodeShown === false ? (
                            <EyeIcon
                                className="mb-3 d-none"
                                height={30}
                                width={30}
                                onClick={() => {
                                    setIsQrCodeShown(!isQrCodeShown);
                                }}
                            />
                        ) : (
                            <EyeCrossedIcon
                                className="mb-3 d-none"
                                height={30}
                                width={30}
                                onClick={() => {
                                    setIsQrCodeShown(!isQrCodeShown);
                                }}
                            />
                        )}
                    </div>
                </div>
                {isSubStepActionButtonsActive ? (
                    <ActionsButtonsWrapper
                        actionsButtonsItems={mainActiveActionsButtons}
                    />
                ) : null}

                {!isScanningQrCode ? <UsualModeLanguagesWrapper /> : null}

                {isConfigOptionsActive && !isScanningQrCode ? (
                    <ConfigOptionsWrapper />
                ) : null}
            </UsualModeSubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
