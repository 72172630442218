import { GODIA_LOYALTY, VALIDATED_EXTENSION_FILE } from "@constants";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { setIsTechProblemModalOpened } from "@store";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { PaymentActionButton } from "@components/OrderTaking/common/ActionButtonsImg";
import WorkflowAlert from "@components/OrderTaking/common/WorkflowAlert";
import AdditionalSale from "@components/OrderTaking/Components/Dialogs/AdditionalSale";
import OrderSummary from "@components/OrderTaking/Components/Dialogs/OrderSummary";
import { getAdditionnalSaleItems } from "@components/OrderTaking/Components/Products/ProductWrapper/hooks/useAdditionnalSaleItems";
import {
    clearReward,
    setAdditionalItems,
    setUsedGifts,
} from "@components/OrderTaking/Store/feature";
import { syncStore } from "@components/Synchronization/store";

import {
    setIsCustomerBenefitOpen,
    setIsGlobalSummaryOrderOpened,
    setIsOrderTakingPreviousStep,
    setNavigationIndex,
    setUser,
    kioskStore as store,
} from "@pages/Kiosk/store";

import "./index.css";

export default function BasketActionsButtons({
    openDialogCart,
    setOpenDialogCart,
    setIsSummaryOrderOpened,
    isSummaryOrderOpened,
    setIsLocalKeyBoardOpened,
}) {
    const {
        isOrderTakingPreviousStep,
        isGlobalSummaryOrderOpened,
        devise,
        selectedSaleModeId,
        selectedSaleModeOrderTaking,
        selectedSaleModeValue,
        project: {
            template: {
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: {
                                gifts: { active: giftsActive },
                                rewards: { active: rewardsActive },
                                autoLaunchOnPayButton,
                            },
                        },
                    },
                },
            },
        },
    } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const { orderItems, totalPrice } = useSelector(
        (state) => state.orderSlice.order
    );

    const DisplayedOrderPrice = `${totalPrice.toFixed(devise.decimalPrice)} ${
        devise.deviseSymbole
    }`;
    const { isGenericAdditionalSaleActive } = useSelector(
        (state) => state.orderSlice
    );
    const dispatch = useDispatch();
    const { globalcard } = useSelector(
        (state) => state.orderSlice,
        shallowEqual
    );
    const [isAlertOpened, setIsAlertOpened] = React.useState(false);
    const [isAdditionalSaleOpen, SetIsAdditionalSaleOpen] =
        React.useState(false);
    const message = "Would you like to abandon your order ?";

    const additionalSale = isGenericAdditionalSaleActive
        ? getAdditionnalSaleItems(globalcard, "genericSalesAdd")
        : {
              name: "",
              listItems: "",
              selectedItems: [],
              parentId: "",
              design: { nbrRows: 2, nbrColumn: 2, isAutoDesign: true },
          };

    function handleOrderSummaryOnCLickEvent() {
        setIsSummaryOrderOpened(!isSummaryOrderOpened);
        setIsGlobalSummaryOrderOpened(true);
        setIsOrderTakingPreviousStep(true);
        if (
            selectedShopApp.fidelityType === GODIA_LOYALTY &&
            !isSummaryOrderOpened &&
            autoLaunchOnPayButton &&
            (giftsActive || rewardsActive)
        ) {
            setIsCustomerBenefitOpen(true);
        }
    }

    const { actionsButtons } = useSelector(
        (state) => state.settingSlice.basketSettings
    );

    if (openDialogCart && orderItems.length === 0) {
        setOpenDialogCart(false);
    }

    function handleBasketActionsButtonsOnClickEvent(button) {
        if (button.name.toLowerCase() === "pay") {
            if (orderItems.length === 0) return;
            console.log("total price on clicking pay button", { totalPrice });
            console.log("selected sale mode id on clicking pay button", {
                selectedSaleModeId,
            });
            if (
                selectedSaleModeId === "" ||
                selectedSaleModeOrderTaking === "" ||
                selectedSaleModeValue === ""
            ) {
                console.log("empty selected sale mode on clicking pay button", {
                    selectedSaleModeId,
                    selectedSaleModeOrderTaking,
                    selectedSaleModeValue,
                });
                setIsTechProblemModalOpened(true);
                setNavigationIndex(0);
                return;
            }
            if (
                orderItems.length > 0 &&
                isGenericAdditionalSaleActive === true &&
                additionalSale?.listItems?.length > 0
            ) {
                dispatch(setAdditionalItems(additionalSale));
                SetIsAdditionalSaleOpen(true);
            } else {
                handleOrderSummaryOnCLickEvent();
            }
            setIsLocalKeyBoardOpened(false);
        } else {
            handleAlertOnCLickEvent();
        }
    }
    function handleAlertOnCLickEvent() {
        if (orderItems.length > 0) {
            setIsAlertOpened(!isAlertOpened);
            return;
        }
        setNavigationIndex(0);
        dispatch(clearReward());
        dispatch(setUsedGifts([]));
        setUser({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            loyaltyAccount: 0,
            loyaltyAmount: 0,
            loyaltyType: "",
            amountRatio: 0,
        });
    }
    function onCloseWorkflowOnClickEvent() {
        setNavigationIndex(0);
        dispatch(clearReward());
        dispatch(setUsedGifts([]));
        setUser({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            loyaltyAccount: 0,
            loyaltyAmount: 0,
            loyaltyType: "",
            amountRatio: 0,
        });
        setIsAlertOpened(!isAlertOpened);
    }

    React.useEffect(() => {
        if (
            isOrderTakingPreviousStep === true &&
            isGlobalSummaryOrderOpened === true
        ) {
            setIsSummaryOrderOpened(true);
        }
    }, []);

    return (
        <React.Fragment>
            {isAdditionalSaleOpen && (
                <AdditionalSale
                    open={isAdditionalSaleOpen}
                    onClose={SetIsAdditionalSaleOpen}
                    handleOrderSummaryOnCLickEvent={
                        handleOrderSummaryOnCLickEvent
                    }
                />
            )}
            {isSummaryOrderOpened && (
                <OrderSummary
                    open={isSummaryOrderOpened}
                    onClose={handleOrderSummaryOnCLickEvent}
                    setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                    openDialogCart={openDialogCart}
                    setOpenDialogCart={setOpenDialogCart}
                    isSummaryOrderOpened={isSummaryOrderOpened}
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            {isAlertOpened && (
                <WorkflowAlert
                    open={isAlertOpened}
                    onClose={handleAlertOnCLickEvent}
                    onCloseWorkflowOnClickEvent={onCloseWorkflowOnClickEvent}
                    message={message}
                />
            )}
            <div
                className="footer-button-popup-card px-3"
                style={{ backgroundColor: "transparent" }}
            >
                {actionsButtons
                    .filter((item) => {
                        return item.active === true;
                    })
                    .sort((a, b) => {
                        return a.role > b.role ? -1 : 1;
                    })
                    .map((button, index) => {
                        const imageSrc = getImageContentById(button.id);
                        const opacityStyle =
                            button.name.toLowerCase() === "pay" &&
                            orderItems.length === 0
                                ? "0.5"
                                : "1";

                        return button.name.toLowerCase() === "pay" ? (
                            <PaymentActionButton
                                imageUrl={imageSrc}
                                PayButton={button}
                                opacityStyle={opacityStyle}
                                DisplayedOrderPrice={DisplayedOrderPrice}
                                handleBasketActionsButtonsOnClickEvent={
                                    handleBasketActionsButtonsOnClickEvent
                                }
                                width={"591px"}
                            />
                        ) : (
                            <div className="basket-cart-buttons" key={index}>
                                {VALIDATED_EXTENSION_FILE.includes(
                                    imageSrc.substr(-4)
                                ) ? (
                                    <img
                                        src={imageSrc}
                                        style={{
                                            width: "437px",
                                            height: "65px",
                                        }}
                                        onClick={() => {
                                            handleBasketActionsButtonsOnClickEvent(
                                                button
                                            );
                                        }}
                                    />
                                ) : (
                                    <DynamicSvgComponent
                                        nameSvg={imageSrc}
                                        height={"65px"}
                                        width={"437px"}
                                        onClick={() => {
                                            handleBasketActionsButtonsOnClickEvent(
                                                button
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
            </div>
        </React.Fragment>
    );
}

BasketActionsButtons.propTypes = {
    openDialogCart: PropTypes.bool,
    setOpenDialogCart: PropTypes.func,
    setIsSummaryOrderOpened: PropTypes.func,
    isSummaryOrderOpened: PropTypes.bool,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
