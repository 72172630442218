/* eslint-disable */
import {
    ALT_LAYAOUT,
    AZERTY_KEYBOARD,
    DISPLAY_LAYAOUT,
    EN_LAYAOUT,
    EN_SHIFT_LAYAOUT,
    FR_LAYAOUT,
    FR_SHIFT_LAYAOUT,
} from "@constants";
import { NUMERIC_LAYAOUT } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { Row } from "react-bootstrap";
import Keyboard, { KeyboardInput } from "react-simple-keyboard";

import { kioskStore } from "@pages/Kiosk/store";

import { FormInputs } from "./FormInputs";

type FullFormInputType = {
    activeInputFormItems: any;
    formik: any;
};
export function FullFormInput({
    activeInputFormItems,
    formik,
}: FullFormInputType): JSX.Element {
    const {
        project: {
            template: { keyboard: keyboardLayout },
        },
    } = useSnapshot(kioskStore);
    const keyboardRef = React.useRef<any>(null);

    const [layout, setLayout] = React.useState("default");
    const [inputName, setInputName] = React.useState("default");
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onKeyPress = (button: string) => {
        const currentLayout = layout;
        let layoutName = "";

        switch (button) {
            case "{shift}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case "{lock}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case "{shiftactivated}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case "{default}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case "{enter}":
                formik.handleSubmit();
                break;

            case "{alt}":
                layoutName = currentLayout === "alt" ? "default" : "alt";
                break;
            default:
                break;
        }

        if (layoutName) {
            setLayout(layoutName);
        }
    };

    const onChangeAll = (inputs: KeyboardInput) => {
        formik.setValues({ ...inputs });
        const start = keyboardRef.current.getCaretPosition();
        const end = keyboardRef.current.getCaretPositionEnd();
        setTimeout(() => {
            keyboardRef.current.setCaretPosition(start, end);
        }, 0);
    };

    const onChange = (event: any) => {
        const inputVal = event.target.value;
        formik.setFieldValue(inputName, inputVal, true);
        keyboardRef.current.setInput(inputVal, inputName);
        const start = event.target.selectionStart;
        const end = event.target.selectionEnd;

        setTimeout(() => {
            if (inputRef.current)
                inputRef.current.setSelectionRange(start, end);
        }, 0);
    };

    return (
        <React.Fragment>
            <div
                style={{
                    maxHeight: "55vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                    scrollbarColor: "gray #e0e0e0",
                    rowGap: "20px",
                    width: "90vw",
                }}
            >
                {Object.keys(activeInputFormItems).map((group) => (
                    <Row className="m-3" key={group} style={{ width: "100%" }}>
                        {activeInputFormItems[group].map(
                            ({
                                name,
                                type,
                            }: {
                                name: string;
                                type: string;
                            }) => (
                                <div
                                    className={` ${
                                        activeInputFormItems[group].length > 1
                                            ? "col-6"
                                            : "col-12"
                                    }`}
                                    key={name}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <FormInputs
                                        name={name}
                                        type={type}
                                        setInputName={setInputName}
                                        formik={formik}
                                        onChange={onChange}
                                        inputRef={inputRef}
                                    />
                                </div>
                            )
                        )}
                    </Row>
                ))}
            </div>
            <Keyboard
                inputName={inputName}
                layout={{
                    default:
                        inputName === "phoneNumber"
                            ? NUMERIC_LAYAOUT
                            : keyboardLayout === AZERTY_KEYBOARD
                            ? FR_LAYAOUT
                            : EN_LAYAOUT,
                    shift:
                        keyboardLayout === AZERTY_KEYBOARD
                            ? FR_SHIFT_LAYAOUT
                            : EN_SHIFT_LAYAOUT,
                    alt: ALT_LAYAOUT,
                }}
                keyboardRef={(r) => (keyboardRef.current = r)}
                layoutName={layout}
                onChangeAll={onChangeAll}
                onKeyPress={onKeyPress}
                display={DISPLAY_LAYAOUT}
                theme="keyboard__clz hg-theme-default"
                buttonTheme={[
                    {
                        class: "space-key__clz",
                        buttons: "{space}",
                    },
                ]}
                maxLength={{
                    phoneNumber: 10,
                    firstName: 20,
                    name: 20,
                    email: 40,
                    country: 20,
                    postalCode: 10,
                }}
            />
        </React.Fragment>
    );
}
