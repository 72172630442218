import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generalSetting: {
        isOrderTakingTopBannerActive: false,
        isOrderTakingTagsActive: false,
        isOrderTakingUserAccountActive: true,
        userAccountMessage: {},
        isHeaderActive: true,
        isBackgroundActive: true,
        backgroundContent: "",
        globalColor: "#f84c00",
        secondaryColor: "#f84c00",
        isShopCardActive: true,
        isOrderTakingSalesMethodsChangeActive: true,
    },
    productSetting: {
        isCalorieActive: false,
        isProductDescriptionActive: true,
        isProductTitleActive: true,
        isCategorySubCategoryTitleActive: true,
        isInformationProductIconActive: true,
        informationProductIconContent: "",
        isCommentProductActive: false,
        isFreeCommentProductActive: false,
        isCommentProductRecoveryActive: false,
        isShopCartDetailsActive: false,
        designCategories: "auto",
        isNutriscoreActive: true,
        isNutriscoreOnTheProductActive: true,
        isNutriscoreInTheInformationPopUpActive: true,
        isBorderCardProductActive: false,
        isCoverImageActive: false,
        isProductModificationActive: true,
        isArticleDetailsAsTextActive: false,
        isConfirmationPopupForDeletingAnArticleActive: false,
        isShownProductCompositionDescription: false,
        isCategoriesScrollActive: true,
    },
    categorySetting: {
        isSelectedCategoryActive: true,
        isOrderTakingCategoryTitleActive: true,
    },
    orderTakingOptions: [],
    basketSettings: {},
    designItems: [],
    summary: {
        isSummaryBackgroundActive: false,
        summaryBackgroundContent: "",
        isSummaryLogoActive: false,
        IsAccumulatedLoyaltyPointsActive: true,
        summaryHeaderMessage: {},
        isSalesMethodsChangeActive: false,
        summaryActionsButtons: [],
        summaryHeaderBravoText: {},
        summaryHeaderLoyaltyPointText: {},
        isSummaryOptionsItemsActive: false,
        summaryOptionsItems: [],
    },
    compositeProductSettings: {
        isBasketActive: false,
        isHeaderColorActive: false,
        isClosingButtonActive: false,
        closingButtonContent: "",
        isNextOrPreviousButtonsActive: false,
        courseProgressColor: "",
        isBreadcrumbTrailActive: false,
        isProductInfoIconActive: false,
        productInfoIconId: "",
        isInformationMessageActive: false,
        isMinMaxShown: true,
        compositeProductActionsButtons: [],
        shopCardMessage: {},
        generalShopCartMessage: {},
        isNutriscoreInCompositeProductActive: true,
    },
    prmSettings: { prmActionsButtons: [] },
};

export const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setSetting: (state, { payload }) => {
            state.generalSetting = payload.generalSetting;
            state.productSetting = payload.productSetting;
            state.categorySetting = payload.categorySetting;
            state.orderTakingOptions = payload.orderTakingOptions;
            state.basketSettings = payload.basketSettings;
            state.designItems = payload.designItems;
            state.summary = payload.summary;
            state.compositeProductSettings = payload.compositeProductSettings;
            state.prmSettings = payload.prmSettings;
            state.isMinMaxShown = payload.isMinMaxShown;
        },
    },
});

export const { setSetting } = settingSlice.actions;

export default settingSlice.reducer;
