import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export function SuspendedProduct(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            height="100%"
            width="100%"
            viewBox="0 0 296 350"
        >
            <g
                id="Groupe_32"
                data-name="Groupe 32"
                transform="translate(-7021 3999)"
            >
                <g
                    id="Groupe_31"
                    data-name="Groupe 31"
                    transform="translate(19)"
                >
                    <g id="Groupe_30" data-name="Groupe 30">
                        <g
                            id="Groupe_29"
                            data-name="Groupe 29"
                            transform="translate(1011)"
                        >
                            <rect
                                id="Rectangle_19"
                                data-name="Rectangle 19"
                                width="296"
                                height="350"
                                rx="13"
                                transform="translate(5991 -3999)"
                                fill="#fff"
                                opacity="0.85"
                                strokeWidth="0"
                            />
                        </g>
                    </g>
                </g>
                <text
                    id="Produit_Suspendu"
                    data-name="Produit
Suspendu"
                    transform="translate(7167 -3926)"
                    fontSize="38"
                    fontFamily="HelveticaNeue-Medium, Helvetica Neue"
                    fontWeight="900"
                >
                    <tspan x="-63.707" y="50">
                        {" "}
                        {t("Product ", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                    <tspan x="-88.673" y="110">
                        {" "}
                        {t("Suspended", {
                            lng: customerLanguage.name,
                        })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
