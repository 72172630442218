import { TCPOS } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";

import { store } from "@store";

import { ActionsButtonsWrapper } from "@components/common/ActionsButtonsWrapper";
import { ConfigOptionsWrapper } from "@components/common/ConfigOptionsWrapper";
import { LogoWrapper } from "@components/common/LogoWrapper";
import { PreviewBackgroundWrapper } from "@components/common/PreviewBackgroundWrapper";
import { UsualModeLanguagesWrapper } from "@components/common/UsualModeLanguageWrapper";
import { MainContentWrapper } from "@components/common/UsualModeMainContentWrapper";
import { ContainerWrapper } from "@components/common/UsualModeMainContentWrapper/ContainerWrapper";
import { InputKeyboardWrapper } from "@components/InputKeyboardWrapper";

import { kioskStore } from "@pages/Kiosk/store";

export function UsualMode(): JSX.Element {
    const { posEditor } = useSnapshot(store);
    const {
        actionsButtonsItems,
        isTopBannerActive,
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
        isInformationMessageActive,
        informationMessageItems,
        customerLanguage,
        isSendEmailConfirmed,
    } = useSnapshot(kioskStore);

    const activeActionsButtons = actionsButtonsItems?.filter((item) => {
        return item.active === true && item.name !== "printTicket";
    });

    const mainActiveActionsButtons = activeActionsButtons?.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <PreviewBackgroundWrapper
            selectedMode={
                isSendEmailConfirmed
                    ? "printerOptionsConfirmationEmail"
                    : undefined
            }
        >
            {
                <ContainerWrapper
                    isTopBannerActive={isTopBannerActive}
                    isHeaderActive={isHeaderActive}
                    isLanguagesActive={isLanguagesActive}
                    isConfigOptionsActive={isConfigOptionsActive}
                    isActionButtonsActive={isActionButtonsActive}
                >
                    <>
                        <LogoWrapper />

                        {isHeaderActive && isInformationMessageActive ? (
                            posEditor === TCPOS ? (
                                <h1
                                    style={{
                                        height: "100px",
                                        font: "600 55px / 60px Nunito Sans",
                                        textAlign: "center",
                                        maxWidth: "60rem",
                                        marginTop: "20%",
                                        marginInline: "auto",
                                        lineHeight: "6rem",
                                    }}
                                >
                                    {t(
                                        "Would you like to print your order ticket?"
                                    )}
                                </h1>
                            ) : (
                                <div
                                    className="d-flex justify-content-center align-items-center mx-3"
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    <span
                                        className="text-center two-lines-preview-text"
                                        style={{
                                            fontSize: "55px",
                                        }}
                                    >
                                        {
                                            informationMessageItems[
                                                customerLanguage.name
                                            ].content
                                        }
                                    </span>
                                </div>
                            )
                        ) : (
                            <div></div>
                        )}
                        {isSendEmailConfirmed ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <InputKeyboardWrapper />
                            </div>
                        ) : (
                            <MainContentWrapper />
                        )}
                        {isActionButtonsActive ? (
                            <ActionsButtonsWrapper
                                actionsButtonsItems={mainActiveActionsButtons}
                            />
                        ) : null}

                        {isLanguagesActive ? (
                            <UsualModeLanguagesWrapper />
                        ) : null}

                        {isConfigOptionsActive ? (
                            <ConfigOptionsWrapper />
                        ) : null}
                    </>
                </ContainerWrapper>
            }
        </PreviewBackgroundWrapper>
    );
}
