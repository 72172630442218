import { NETWORK_PROBLEM } from "@constants";
import { t } from "i18next";
import { snapshot } from "valtio";

import { toast } from "react-toastify";

import { setIsTechProblemModalOpened, store } from "@store";

import { informationToast, successToast } from "@components/common/Toast";

function checkTcposAvailability() {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 1000);
    const signal = controller.signal;
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);
    const url = `${
        middlewareApiUrl.split(":5")[0]
    }:5008/tcpos/v1/checkServer?timestamp=${timestamp}`;

    return fetch(url, { signal })
        .then((response) => response.json())
        .then((result) => {
            if (result.error) {
                setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
                console.log("Technical problem still occurred for TCPOS");
                return false;
            }
            console.log("Technical problem has been solved for TCPOS");
            setIsTechProblemModalOpened(false);
            return true;
        })
        .catch((error) => {
            setIsTechProblemModalOpened(true, NETWORK_PROBLEM);
            console.log(
                "Technical problem still occurred for TCPOS with error message: ",
                error
            );
            return false;
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}

function refreshDataNeedTcpos() {
    const timestamp = new Date().getTime();
    const { middlewareApiUrl } = snapshot(store);

    //toast for information about synchronization card TCPOS
    informationToast(t("Synchronization in progress"));

    return fetch(
        `${
            middlewareApiUrl.split(":5")[0]
        }:5008/tcpos/v1/uploadCardCatalog?timestamp=${timestamp}`
    )
        .then((response) => {
            toast.dismiss();
            successToast(t("synchronization completed"));
            console.log(`TCPOS upload card with message: ${response.message}`);
        })
        .catch((error) => {
            console.log(
                "TCPOS error on refresh data needed with message: ",
                error
            );
        });
}

export { checkTcposAvailability, refreshDataNeedTcpos };
