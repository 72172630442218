import {
    ETK_CESAR,
    IS_GENERIC_ADDITIONAL_SALE,
    ITEM_TYPE,
    TCPOS,
} from "@constants";
import { isEqual } from "lodash";
import { snapshot } from "valtio";

import { store } from "@store";

import {
    getProductTitle,
    kioskVisibilityRefactor,
    refactorAdvancedDisplay,
    refactorStepMessage,
} from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

const getStepItems = (
    globalcard,
    iuudStep,
    iuudMD5,
    typeModifier,
    currentStep
) => {
    let listItems = [];
    currentStep?.stepItems &&
        Object.entries(currentStep?.stepItems)
            .sort((a, b) => a[1].rank - b[1].rank)
            .forEach(([iuud]) => {
                /// product is modifier
                if (
                    Object.keys(globalcard.modifier[iuudMD5][typeModifier])
                        .length === 0
                ) {
                    return;
                }
                if (
                    globalcard.items?.[iuud] &&
                    globalcard.items?.[iuud]?.archive === false
                ) {
                    const modifier = globalcard.modifier[iuudMD5][typeModifier][
                        iuudStep
                    ].itemModifiers[iuud]
                        ? globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                              .itemModifiers[iuud]
                        : "";

                    const itemVisibility =
                        globalcard.cardType !== ETK_CESAR
                            ? currentStep?.stepItems[iuud].visibilityInfo
                            : globalcard.items?.[iuud].stepVisibility
                            ? globalcard.items?.[iuud].stepVisibility
                            : globalcard.items?.[iuud].visibilityInfo;
                    let price = globalcard.items?.[iuud].price;
                    if (
                        currentStep?.stepItems[iuud]?.stepItemPrice &&
                        currentStep?.stepItems[iuud]?.stepItemPrice.isVisible
                    ) {
                        price = {
                            isVisible: true,
                            content: Number.parseFloat(
                                currentStep?.stepItems[iuud]?.stepItemPrice
                                    ?.itemPrice.default
                            ),
                            advancedPrice:
                                currentStep?.stepItems[iuud]?.stepItemPrice
                                    ?.itemPrice.advancedPrice,
                            saleModeVatRates: currentStep?.stepItems[
                                iuud
                            ]?.stepItemPrice?.itemPrice.saleModeVatRates.reduce(
                                (prev, curr) => {
                                    return {
                                        ...prev,
                                        [curr.saleModeUuid]: curr.value,
                                    };
                                },
                                {}
                            ),
                        };
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            globalcard.modifier[iuudMD5][typeModifier][iuudStep]
                                .itemModifiers,
                            iuud
                        )
                    ) {
                        listItems = [
                            ...listItems,
                            {
                                ...globalcard.items?.[iuud],
                                iuud: iuud,
                                price,
                                type: ITEM_TYPE,

                                modifier,
                                isAdditionnalProduct: true,
                                haveWorkflow:
                                    modifier !== "" &&
                                    globalcard.modifier[modifier]?.steps &&
                                    Object.keys(
                                        globalcard.modifier[modifier]?.steps
                                    ).length > 0
                                        ? true
                                        : false,

                                haveAdditionalSale:
                                    modifier !== "" &&
                                    globalcard.modifier[modifier]?.add &&
                                    Object.keys(
                                        globalcard.modifier[modifier]?.add
                                    ).length > 0
                                        ? true
                                        : false,
                                visibilityInfo: itemVisibility,
                                KioskVisibility: kioskVisibilityRefactor(
                                    itemVisibility.isVisible,
                                    itemVisibility.default
                                ),
                            },
                        ];
                    } else {
                        listItems = [
                            ...listItems,
                            {
                                ...globalcard.items?.[iuud],
                                iuud,
                                price,

                                type: ITEM_TYPE,

                                modifier,
                                isAdditionnalProduct: true,
                                haveWorkflow:
                                    modifier !== "" &&
                                    globalcard.modifier[modifier]?.steps &&
                                    Object.keys(
                                        globalcard.modifier[modifier]?.steps
                                    ).length > 0
                                        ? true
                                        : false,

                                haveAdditionalSale:
                                    modifier !== "" &&
                                    globalcard.modifier[modifier]?.add &&
                                    Object.keys(
                                        globalcard.modifier[modifier]?.add
                                    ).length > 0
                                        ? true
                                        : false,
                                visibilityInfo: itemVisibility,
                                KioskVisibility: kioskVisibilityRefactor(
                                    itemVisibility.isVisible,
                                    itemVisibility.default
                                ),
                            },
                        ];
                    }
                }
            });
    // list items in step
    return listItems;
};

export function getAdditionnalSaleItems(globalcard, product) {
    const { selectedSaleModeOrderTaking, customerLanguage } =
        snapshot(kioskStore);
    const iuudMD5 =
        typeof product === IS_GENERIC_ADDITIONAL_SALE
            ? product
            : product.modifier;
    let additionalSale = {};
    // let workflowAdd = [];
    globalcard.modifier[iuudMD5]?.add &&
        Object.entries(globalcard.modifier[iuudMD5]?.add).length > 0 &&
        Object.entries(globalcard.modifier[iuudMD5]?.add)
            .sort((a, b) => a[1].rank - b[1].rank)
            .forEach(([iuudStep]) => {
                ///path step
                const currentStep =
                    globalcard.modifier[iuudMD5]?.add[iuudStep]?.overrides &&
                    Object.keys(
                        globalcard.modifier[iuudMD5]?.add[iuudStep].overrides
                    ).length > 0
                        ? globalcard.modifier[iuudMD5]?.add[iuudStep].overrides
                        : globalcard?.steps?.[iuudStep];
                let path = [];
                const nameStep = {
                    isVisible: true,
                    content: currentStep.displayName.Default.nameDefault
                        ? currentStep.displayName.Default.nameDefault
                        : currentStep.title,
                    advancedDisplayName: refactorAdvancedDisplay(
                        currentStep?.displayName
                    ),
                };
                let titleStep = getProductTitle(nameStep, customerLanguage);

                const message = Object.values(
                    globalcard.modifier[iuudMD5]?.add
                )[0]?.message
                    ? refactorStepMessage(
                          Object.values(globalcard.modifier[iuudMD5]?.add)[0]
                              .message,
                          customerLanguage
                      )
                    : "";

                if (product.modifier) {
                    path.push({ name: product.title, iuud: product.iuud });
                    // titleStep = product.title;
                } else {
                    path.push({
                        name: globalcard?.steps?.[iuudStep]?.title,
                        iuud: iuudStep,
                    });
                }

                const listItems = getStepItems(
                    globalcard,
                    iuudStep,
                    iuudMD5,
                    "add",
                    currentStep
                );
                const nbrRows = globalcard.modifier[iuudMD5]?.add[iuudStep]
                    ?.design?.nbrRows
                    ? globalcard.modifier[iuudMD5]?.add[iuudStep]?.design
                          ?.nbrRows
                    : 2;
                const nbrColumn = globalcard.modifier[iuudMD5]?.add[iuudStep]
                    ?.design?.nbrColumn
                    ? globalcard.modifier[iuudMD5]?.add[iuudStep]?.design
                          ?.nbrColumn
                    : 2;
                const isAutoDesign =
                    globalcard.modifier[iuudMD5]?.add[iuudStep]?.design !==
                    undefined
                        ? globalcard.modifier[iuudMD5]?.add[iuudStep]?.design
                              .isAutoDesign
                        : true;

                const filteredItems =
                    typeof product === IS_GENERIC_ADDITIONAL_SALE
                        ? listItems
                        : listItems.filter((item) => item.modifier === "");
                const visibleItems = filteredItems.filter(
                    (product) =>
                        product?.visibilityInfo.isVisible &&
                        (product?.KioskVisibility[
                            selectedSaleModeOrderTaking
                        ] ||
                            product?.KioskVisibility[
                                selectedSaleModeOrderTaking
                            ] === undefined)
                );

                additionalSale = {
                    name: titleStep,
                    message: message,
                    listItems: visibleItems,
                    selectedItems: [],
                    parentId:
                        typeof product !== IS_GENERIC_ADDITIONAL_SALE
                            ? product.iuud
                            : "",
                    design: { nbrRows, nbrColumn, isAutoDesign },
                };
            });

    return additionalSale;
}

export function validateLoyaltyItems(loyaltyItems, orderItems) {
    const { posEditor } = snapshot(store);
    loyaltyItems.forEach((item) => {
        let existItem = null;
        let indexExistItem = -1;

        if (posEditor !== TCPOS) {
            orderItems.forEach((orderItem, index) => {
                if (
                    orderItem.iuud === item.iuud &&
                    isEqual(item.shopCart, orderItem.shopCart) &&
                    isEqual(item.selectedOptions, orderItem.selectedOptions)
                ) {
                    existItem = orderItem;
                    indexExistItem = index;
                }
            });
        }

        if (existItem) {
            existItem.quantity += item.quantity;
            orderItems[indexExistItem] = existItem;
        } else {
            orderItems.push({
                ...item,
                quantity: item.quantity,
            });
        }
    });

    const itemsCount = orderItems.reduce(
        (prev, curr) => prev + curr.quantity,
        0
    );

    const itemsPrice = orderItems.reduce((a, c) => a + c.quantity * c.price, 0);
    const totalPrice = Math.round(itemsPrice * 100) / 100;
    let sumFidelity = 0;

    sumFidelity = orderItems.reduce((prev, curr) => {
        return prev + curr.fidelity;
    }, 0);
    return {
        orderItems: orderItems,
        totalPrice: totalPrice,
        itemsPrice: itemsPrice,
        itemsCount: itemsCount,
        sumFidelity: sumFidelity,
    };
}
