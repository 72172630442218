import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const PayerPMR = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453 65" {...props}>
            <g
                id="Groupe_70"
                data-name="Groupe 70"
                transform="translate(-4863 4106)"
            >
                <rect
                    id="Rectangle_16"
                    data-name="Rectangle 16"
                    width={props.width}
                    height={props.height}
                    rx="7"
                    transform="translate(4863 -4106)"
                    fill="#141414"
                />
                <text
                    id="Payer"
                    transform="translate(4950 -4059)"
                    fill="#fff"
                    fontSize="38"
                    fontFamily="ArialMT, Arial"
                >
                    <tspan x="-49.634" y="0">
                        {t("Pay", { lng: customerLanguage.name })}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};
PayerPMR.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
