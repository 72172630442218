import PropTypes from "prop-types";

import React from "react";

import { getFilenameFromUrl } from "@helpers/general";

import { imageWithFallback } from "@components/OrderTaking/Helpers/Commonfunction";

export function StepImage({ stepImageUrl, isBasicComposition }) {
    const stepImg = !isBasicComposition
        ? getFilenameFromUrl(stepImageUrl, "catalog")
        : stepImageUrl;

    return (
        <div className="d-flex align-items-center justify-content-center w-100 h-50">
            <img
                style={{
                    // maxWidth: "119px",
                    height: "120px",
                    width: "auto",
                }}
                src={stepImg}
                onError={imageWithFallback}
            />
        </div>
    );
}
StepImage.propTypes = {
    stepImageUrl: PropTypes.string,
    isBasicComposition: PropTypes.bool,
};
