import { t } from "i18next";
import PropTypes from "prop-types";
import { useSnapshot } from "valtio";

import React from "react";

import { kioskStore } from "@pages/Kiosk/store";

export const NextStep = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    const { customerLanguage } = useSnapshot(kioskStore);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="224"
            // height="65"
            viewBox="0 0 224 65"
            {...props}
        >
            <g
                id="Groupe_52"
                data-name="Groupe 52"
                transform="translate(-2202 4081)"
            >
                <rect
                    id="Rectangle_13"
                    data-name="Rectangle 13"
                    width={props.width}
                    height={props.height}
                    rx="9"
                    transform="translate(2202 -4081)"
                    fill="#141414"
                />
                <text
                    id="Suivant"
                    transform="translate(2314 -4037)"
                    fill="#fff"
                    fontSize="29"
                    fontFamily="ArialMT, Arial"
                    textAnchor="middle"
                >
                    {t("Next", {
                        lng: customerLanguage.name,
                    })}
                </text>
            </g>
        </svg>
    );
};
NextStep.propTypes = {
    nameSvg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    pointerEvents: PropTypes.string,
    opacity: PropTypes.string,
    onClick: PropTypes.func,
};
