import { CATALOGUE_MODE, TCPOS } from "@constants";
import { t } from "i18next";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { store } from "@store";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { validateTCPOSCommand } from "@components/OrderTaking/Helpers/TCPOS";
import { TryAgain } from "@components/SvgIcons";

import {
    kioskStore,
    setIsAbortTransactionModalOpened,
    setIsAnotherPaymentModeButtonDisabled,
    setIsBackButtonDisabled,
    setIsPaymentRefused,
    setIsTpeNotAvailable,
    setIsTpeWaitingTimeReached,
    setRegulationModesModalKey,
} from "@pages/Kiosk/store";

import TpeActionButtons from "./TpeActionButtons";

type TpeAvailablityModalType = {
    isPaymentTimeout: boolean;
    setIsPaymentTimeout: React.Dispatch<React.SetStateAction<boolean>>;
};

export function TpeAvailablityModal({
    isPaymentTimeout,
    setIsPaymentTimeout,
}: TpeAvailablityModalType): JSX.Element {
    //  const [timeOutId, setTimoutId] = React.useState (null);
    const { posEditor } = useSnapshot(store);
    const {
        isPrm,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
            },
        },
        isTpeNotAvailable,
    } = useSnapshot(kioskStore);

    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );
    React.useEffect(() => {
        setIsTpeWaitingTimeReached(true);
        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            setIsTpeWaitingTimeReached(false);
            setIsPaymentTimeout(false);
        };
    }, []);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isTpeNotAvailable}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span
                    style={{
                        font: "normal normal 600 55px/60px Segoe UI",
                        textAlign: "center",
                        padding: "0 3rem",
                    }}
                >
                    {isPaymentTimeout
                        ? t("You took a long time to pay!")
                        : t(
                              "The payment terminal (TPE) is currently unavailable."
                          )}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px", width: "95%" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/50px Segoe UI",
                    }}
                >
                    {!(TCPOS === posEditor)
                        ? isPaymentTimeout
                            ? t("Do you wish to continue?")
                            : t("Please use another payment method.")
                        : t(
                              "Currently there is an issue with the credit card payment mode. THANKS."
                          )}
                </span>
            </div>

            <div style={{ height: "200px" }} />

            <div
                style={{ gap: "10px" }}
                className="mx-3 d-flex justify-content-center align-items-center flex-wrap"
            >
                <TryAgain
                    height={"5rem"}
                    onClick={() => {
                        setIsTpeNotAvailable(false);
                        if (!(TCPOS === posEditor)) {
                            console.log("try again with CREDIT CARD!!");

                            setRegulationModesModalKey(uuid());
                            setIsAnotherPaymentModeButtonDisabled(true);
                            setIsBackButtonDisabled(true);
                            setIsPaymentRefused(false);
                            setIsAbortTransactionModalOpened(false);
                        } else {
                            validateTCPOSCommand();
                        }
                    }}
                />

                <TpeActionButtons />
            </div>
        </Modal>
    );
}
