import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import {
    getAlertActionButtonImageSrc,
    handleLedControl,
} from "@helpers/general";

import { clearMiddlwareOrder } from "@components/OrderTaking/Store/feature/MiddlwareOrderSlice";
import { Back } from "@components/SvgIcons";

import {
    kioskStore,
    setIsAmountReturned,
    setIsCommandInsertionProblemModalOpened,
    setLeftToPayAfterCashMachineValidation,
    setNavigationIndex,
    setPaymentModes,
} from "@pages/Kiosk/store";

export function CommandInsertionProblemModalWrapper(): JSX.Element {
    const {
        isPrm,
        project: {
            template: { content },
        },
        isCommandInsertionProblemModalOpened,
        kioskWays,
        isAmountReturned,
        connectedLedPort,
        isLedActive,
        paymentModes,
    } = useSnapshot(kioskStore);

    const dispatch = useDispatch();

    const alertImgSrc = getAlertActionButtonImageSrc(
        content.actionsButtons as ActionsButtonsDesignType
    );

    function handleOnClickEvent() {
        const meansOfPaymentStepIndex = kioskWays.indexOf(
            kioskWays.filter((step) => step.name === "meansOfPayment")[0]
        );
        const orderTakingStepIndex = kioskWays.indexOf(
            kioskWays.filter((step) => step.name === "orderTaking")[0]
        );
        dispatch(clearMiddlwareOrder());

        const localPaymentModes = paymentModes.filter(
            (mode) => mode.paymentMode === "loyalty"
        );

        setPaymentModes(localPaymentModes);
        setIsCommandInsertionProblemModalOpened(false);
        setIsAmountReturned(false);
        setLeftToPayAfterCashMachineValidation(0);
        if (isLedActive) {
            handleLedControl(connectedLedPort, "Orange");
        }
        if (
            meansOfPaymentStepIndex !== -1 &&
            kioskWays[meansOfPaymentStepIndex].skipped === false
        ) {
            setNavigationIndex(meansOfPaymentStepIndex);
        } else {
            // setIsOrderTakingPreviousStep(true);
            setNavigationIndex(orderTakingStepIndex);
        }
    }

    if (isLedActive) {
        handleLedControl(connectedLedPort, "Red");
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isCommandInsertionProblemModalOpened}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "1335px",
                    width: "860px",
                    fontSize: "50px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (content.generalDesign as GeneralDesignType).colors[0]
                            .content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",

                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "350px" }}
            >
                <img src={alertImgSrc} />
            </div>
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "150px" }}
            >
                <span style={{ font: "normal normal 600 55px/60px Segoe UI" }}>
                    {t("Technical problem")}
                </span>
            </div>
            <div style={{ height: "100px" }} />
            <div
                className="d-flex justify-content-center align-items-center text-center  w-100"
                style={{ height: "200px" }}
            >
                <span
                    style={{
                        font: "normal normal normal 35px/47px Segoe UI",
                    }}
                >
                    {t("Problem inserting command")}
                </span>
            </div>

            <div style={{ height: "100px" }} />

            <div style={{ height: "100px" }} />
            <div
                style={{ height: "120px" }}
                className="d-flex justify-content-center align-items-center"
                onClick={() => handleOnClickEvent()}
            >
                <Back
                    pointerEvents={isAmountReturned === false ? "none" : "auto"}
                    opacity={isAmountReturned === false ? "0.5" : 1}
                    height={isPrm ? "70px" : "87px"}
                    width={isPrm ? "281px" : "350px"}
                />
            </div>
        </Modal>
    );
}
