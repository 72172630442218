import { ASK_FOR_HELP_BUTTON, PRM_BUTTON, USUAL_MODE } from "@constants";
import { useSnapshot } from "valtio";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import { ConfirmationModalWrapper } from "@components/common/ConfirmationModalWrapper";
import OrderTakingChangeSalesMode from "@components/OrderTaking/common/OrderTakingChangeSalesMode";
import { getOrdertakingOptions } from "@components/OrderTaking/Helpers";

import { InformationModesConsommationModalWrapper } from "@pages/Kiosk/InformationModesConsommationModalWrapper";
import { InformationModesModalWrapper } from "@pages/Kiosk/InformationModesModalWrapper";
import {
    setIsHelpModalOpened,
    setIsPrm,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function OrderTakingConfigOptionsWrapper() {
    const {
        isPrm,
        activeConfigOptions,
        isInformationModesModalOpened,
        isInformationModesConsommationModalOpened,
        isConfirmationModalOpened,
    } = useSnapshot(store);
    const { orderTakingOptions, generalSetting } = useSelector(
        (state) => state.settingSlice,

        shallowEqual
    );
    const { isOrderTakingSalesMethodsChangeActive } = generalSetting;

    const newOrderTakionOptions = orderTakingOptions.filter(
        (config) => config.title.toLowerCase() !== USUAL_MODE
    );
    const newOptionConfig = getOrdertakingOptions(
        activeConfigOptions,
        newOrderTakionOptions
    );

    function handleConfigOptionOnClickEvent(option) {
        switch (option.title.toLowerCase()) {
            case PRM_BUTTON:
                setIsPrm(!isPrm);
                break;
            case USUAL_MODE:
                setIsPrm(!isPrm);
                break;
            case ASK_FOR_HELP_BUTTON:
                setIsHelpModalOpened(true);
                break;

            default:
                null;
        }
    }

    return (
        <>
            {isInformationModesModalOpened === true ? (
                <InformationModesModalWrapper />
            ) : null}

            {isInformationModesConsommationModalOpened === true ? (
                <InformationModesConsommationModalWrapper />
            ) : null}

            {isConfirmationModalOpened === true ? (
                <ConfirmationModalWrapper />
            ) : null}

            <div
                className="d-flex justify-content-end align-items-center px-3"
                style={{ gap: "5px" }}
            >
                {isOrderTakingSalesMethodsChangeActive && (
                    <OrderTakingChangeSalesMode />
                )}

                {newOptionConfig.map((option) => {
                    let OptionImageSrc = "";
                    OptionImageSrc = getImageContentById(option.id);
                    /* this if statement is incorrect because it should be fixed in settings kios so it's temporary */
                    if (option.title.toLowerCase() === "prm/child") {
                        OptionImageSrc =
                            typeof option["content"] !== "string"
                                ? isPrm
                                    ? option["content"].usual
                                    : getImageContentById(option.id)
                                : option.content;
                    }

                    return (
                        <div key={option.id}>
                            <img
                                src={OptionImageSrc}
                                alt={option.title}
                                onClick={() => {
                                    handleConfigOptionOnClickEvent(option);
                                }}
                                style={{
                                    height: "70px",
                                    width: "70px",
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
}
