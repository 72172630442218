import { EMPTY_BASKET_ICON_ROLE, FULL__BASKET_ICON_ROLE } from "@constants";
import { motion } from "framer-motion/dist/framer-motion";
import { t } from "i18next";
import PropTypes from "prop-types";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getImageContentById } from "@helpers/general";

import BasketActionsButtons from "@components/OrderTaking/common/BasketActionButtons";
import { CommentKeyboard } from "@components/OrderTaking/common/CommentKeyboard";
import DialogCart from "@components/OrderTaking/Components/Dialogs/DialogCart";
import { CartIconEmpty } from "@components/OrderTaking/Components/Icons/CartIconEmpty";
import { CartIconFilled } from "@components/OrderTaking/Components/Icons/CartIconFilled";
import { MemoizedSimpleProductCartPreview } from "@components/OrderTaking/Components/Products/Cart/SimpleProductCartPreview";
import { memoizedOrderItems } from "@components/OrderTaking/Store/feature";

import { OrderTakingConfigOptionsWrapper } from "../OrderTakingConfigOptionsWrapper";
import "./index.css";

import "swiper/swiper.min.css";

const SwiperCart = ({
    orderItems,
    isLocalKeyBoardOpened,
    setIsLocalKeyBoardOpened,
}) => {
    const localOrderItems = [...orderItems].reverse();

    return (
        <Swiper
            slidesPerView={3}
            loop={false}
            spaceBetween={16}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            className="swiper-product-cart__clz  swiper-product-cart"
        >
            {localOrderItems.length === 0
                ? t("No product available in cart")
                : localOrderItems.map((product) => (
                      <SwiperSlide
                          className="product-swiper p-1"
                          key={product.iuudOrder}
                      >
                          <MemoizedSimpleProductCartPreview
                              product={product}
                              isLocalKeyBoardOpened={isLocalKeyBoardOpened}
                              setIsLocalKeyBoardOpened={
                                  setIsLocalKeyBoardOpened
                              }
                          />
                      </SwiperSlide>
                  ))}
        </Swiper>
    );
};
const MemoizedSwiperCart = React.memo(SwiperCart);

export default function CartLayout() {
    const orderItems = useSelector((state) => memoizedOrderItems(state));
    const { isShopCartDetailsActive } = useSelector(
        (state) => state.settingSlice.productSetting,
        shallowEqual
    );
    const { itemsCount } = useSelector(
        (state) => state.orderSlice.order,
        shallowEqual
    );

    const [isSummaryOrderOpened, setIsSummaryOrderOpened] =
        React.useState(false);

    const [openDialogCart, setOpenDialogCart] = React.useState(false);
    const [isLocalKeyBoardOpened, setIsLocalKeyBoardOpened] =
        React.useState(false);

    const { designItems } = useSelector((state) => state.settingSlice);
    const emptyBasketIcon = designItems.filter((item) => {
        return item.role === EMPTY_BASKET_ICON_ROLE;
    });
    const filledBasketIcon = designItems.filter((item) => {
        return item.role === FULL__BASKET_ICON_ROLE;
    });

    const emptyBasketIconContent =
        emptyBasketIcon.length > 0
            ? getImageContentById(emptyBasketIcon[0].id)
            : "";

    const filledBasketIconContent =
        filledBasketIcon.length > 0
            ? getImageContentById(filledBasketIcon[0].id)
            : "";

    return (
        <>
            {isLocalKeyBoardOpened === true && (
                <CommentKeyboard
                    setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                />
            )}
            <motion.div
                key={filledBasketIcon}
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                {openDialogCart && (
                    <DialogCart
                        openDialogCart={openDialogCart}
                        setOpenDialogCart={setOpenDialogCart}
                        isSummaryOrderOpened={isSummaryOrderOpened}
                        setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                        setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                    />
                )}
                <div
                    className="header-footer"
                    style={{
                        backgroundColor:
                            isShopCartDetailsActive === true && itemsCount > 0
                                ? "white"
                                : "unset",
                    }}
                >
                    <div
                        id={
                            isShopCartDetailsActive
                                ? "basket-cart"
                                : "img-basket-cart"
                        }
                        className="cart-icon justify-content-start px-2"
                    >
                        {itemsCount === 0 ? (
                            emptyBasketIconContent !== "" ? (
                                <img
                                    className="w-50 h-100"
                                    src={emptyBasketIconContent}
                                    alt={"emptyBasket"}
                                />
                            ) : (
                                <CartIconEmpty />
                            )
                        ) : filledBasketIconContent !== "" ? (
                            <img
                                className="w-50 h-100"
                                src={filledBasketIconContent}
                                alt={"filledBasket"}
                            />
                        ) : (
                            <CartIconFilled />
                        )}

                        <span className="number-articles w-50 ">
                            {itemsCount} {t("Article(s)")}
                        </span>
                    </div>
                    {itemsCount !== 0 && (
                        <button
                            className="cart-open-button"
                            onClick={() => setOpenDialogCart(true)}
                            disabled={itemsCount === 0}
                        >
                            <span className="label-open-cart">
                                {t("Basket")}
                            </span>
                        </button>
                    )}
                    <div
                        className="cart-pmr-button"
                        style={{
                            width: "fit-content",
                            position: "relative",
                        }}
                    >
                        <OrderTakingConfigOptionsWrapper />
                    </div>
                </div>
                {isShopCartDetailsActive === true && itemsCount !== 0 && (
                    <div
                        className="footer-contents__clz"
                        style={{
                            backgroundColor:
                                isShopCartDetailsActive === true
                                    ? "white"
                                    : "unset",
                        }}
                    >
                        <MemoizedSwiperCart
                            orderItems={orderItems}
                            isLocalKeyBoardOpened={isLocalKeyBoardOpened}
                            setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                        />
                    </div>
                )}

                <div className="footer-button">
                    <BasketActionsButtons
                        isSummaryOrderOpened={isSummaryOrderOpened}
                        setIsSummaryOrderOpened={setIsSummaryOrderOpened}
                        setIsLocalKeyBoardOpened={setIsLocalKeyBoardOpened}
                    />
                </div>
            </motion.div>
        </>
    );
}
export const MemoizedCartLayout = React.memo(CartLayout);
SwiperCart.propTypes = {
    orderItems: PropTypes.array,
    isLocalKeyBoardOpened: PropTypes.bool,
    setIsLocalKeyBoardOpened: PropTypes.func,
};
