import { StyledButton } from "@aureskonnect/react-ui";
import { CATALOGUE_MODE } from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import { getAlertActionButtonImageSrc } from "@helpers/general";

import { syncStore } from "@components/Synchronization/store";

import { kioskStore, setIsAccessNotAllowed } from "@pages/Kiosk/store";

type ErrorLogInModalWrapperProps = {
    setIsErrorLogInModalWrapperOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isErrorLogInModalWrapperOpened: boolean;
};

export function ErrorLogInModalWrapper({
    setIsErrorLogInModalWrapperOpened,
    isErrorLogInModalWrapperOpened,
}: ErrorLogInModalWrapperProps): JSX.Element {
    const {
        isAccessNotAllowed,
        isKeyBoardOpened,
        project: {
            template: {
                content: { actionsButtons },
            },
        },
        numericKeyboardInputValue,
    } = useSnapshot(kioskStore);
    const { selectedShopApp } = useSnapshot(syncStore);
    const alertImgSrc = getAlertActionButtonImageSrc(
        actionsButtons as ActionsButtonsDesignType
    );

    return (
        <Modal
            isOpen={isErrorLogInModalWrapperOpened}
            ariaHideApp={false}
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "none",
                    zIndex: 3,
                },
                content: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    color: "black",
                    height: "580px",
                    width: "606px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #c2bebe",
                    bottom: "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                    gap: "60px",
                }}
            >
                <img
                    alt=""
                    src={alertImgSrc}
                    style={{
                        height: "124px",
                        width: "144px",
                        marginTop: "30px",
                    }}
                />
                <div
                    style={{
                        textAlign: "left",
                        font: "normal normal bold 35px/55px Segoe UI",
                    }}
                >
                    {isAccessNotAllowed
                        ? t("No access allowed")
                        : !isKeyBoardOpened &&
                          (numericKeyboardInputValue === "" ||
                              numericKeyboardInputValue !==
                                  selectedShopApp.passcode)
                        ? t("PASSCODE INCORRECT")
                        : t("Unknown user !")}
                </div>
                <div
                    style={{
                        textAlign: "center",
                        font: "normal normal normal 25px/33px Segoe UI",
                    }}
                >
                    {isAccessNotAllowed
                        ? t("You do not have authorization")
                        : !isKeyBoardOpened &&
                          (numericKeyboardInputValue === "" ||
                              numericKeyboardInputValue !==
                                  selectedShopApp.passcode)
                        ? ""
                        : t("Unidentified customer")}
                </div>
                <StyledButton
                    rounded={true}
                    className="m-2"
                    style={{
                        width: "179px",
                        height: "61px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000029",
                        border: "1px solid #C9C9C9",
                        borderRadius: "12px",
                        color: "black",
                    }}
                    onClick={() => {
                        setIsErrorLogInModalWrapperOpened(false);
                        setIsAccessNotAllowed(false);
                    }}
                >
                    {t("Back")}
                </StyledButton>
            </div>
        </Modal>
    );
}
