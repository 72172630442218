import { StyledModal } from "@aureskonnect/react-ui";
import { Card } from "@mui/material";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import React from "react";
import { ModalBody } from "reactstrap";

import { store } from "@store";

import {
    setIsSyncLocalModalOpened,
    syncStore,
} from "@components/Synchronization/store";
import { AppNotSynchronizedModalWrapper } from "@components/SyncModal/AppNotSynchronizedModalWrapper";
import { KeyboardWrapper } from "@components/SyncModal/KeyboardWrapper";
import { MiddlewareApiUrlInputWrapper } from "@components/SyncModal/MiddlewareApiUrlInputWrapper";

import { kioskStore } from "@pages/Kiosk/store";

import "./index.css";

export function SyncModal(): JSX.Element {
    const { isAppSynchronized, isMiddlewareApiUrlExists } = useSnapshot(store);
    const { isSyncLocalModalOpened } = useSnapshot(syncStore);
    const { isKeyboardOpened } = useSnapshot(kioskStore);
    const [isInputValueInvalid, setIsInputValueInvalid] = React.useState(false);
    const [isFieldRequired, setIsFieldRequired] = React.useState(false);

    function toggle() {
        setIsSyncLocalModalOpened(!isSyncLocalModalOpened);
    }

    React.useEffect(() => {
        setIsSyncLocalModalOpened(!isAppSynchronized);
    }, []);

    return (
        <React.Fragment>
            <StyledModal
                centered={true}
                isOpen={isSyncLocalModalOpened}
                className={classNames({
                    "sync-modal__clz": isMiddlewareApiUrlExists,
                })}
            >
                <ModalBody className="d-flex align-items-center justify-content-between flex-column py-5">
                    {isMiddlewareApiUrlExists === true ? (
                        <AppNotSynchronizedModalWrapper
                            toggleSyncModal={toggle}
                        />
                    ) : (
                        <MiddlewareApiUrlInputWrapper
                            isInputValueInvalid={isInputValueInvalid}
                            setIsInputValueInvalid={setIsInputValueInvalid}
                            isFieldRequired={isFieldRequired}
                        />
                    )}
                    {isKeyboardOpened === true ? (
                        <Card
                            style={{
                                position: "fixed",
                                bottom: 0,
                                width: "98vw",
                            }}
                            data-testid="keyboard-wrapper"
                        >
                            <KeyboardWrapper
                                setIsFieldRequired={setIsFieldRequired}
                                isInputValueInvalid={isInputValueInvalid}
                                setIsInputValueInvalid={setIsInputValueInvalid}
                            />
                        </Card>
                    ) : null}
                </ModalBody>
            </StyledModal>
        </React.Fragment>
    );
}
