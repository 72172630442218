import {
    BACK_BUTTON_ROLE,
    CATALOGUE_MODE,
    GODIA_LOYALTY,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import Modal from "react-modal";

import {
    getAlertActionButtonImageSrc,
    getImageContentById,
} from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { syncStore } from "@components/Synchronization/store";

import {
    setIsAuthenticationErrorModalOpened,
    setIsCreateAnAccountSelected,
    setKeyboardInputsValues,
    setNavigationIndex,
    kioskStore as store,
} from "@pages/Kiosk/store";

export function AuthenticationErrorModal(): JSX.Element {
    const {
        isPrm,
        isAuthenticationErrorModalOpened,
        project: {
            template: {
                content: { generalDesign, actionsButtons },
                pages,
            },
        },
        selectedAuthMode,
        kioskWays,
        isCreateAnAccountSelected,
    } = useSnapshot(store);
    const { selectedShopApp } = useSnapshot(syncStore);

    const actionButtons = (
        actionsButtons as ActionsButtonsDesignType
    ).items.filter((item) => {
        return (
            item.role === VALIDATE_BUTTON_ROLE || item.role === BACK_BUTTON_ROLE
        );
    });
    // eslint-disable-next-line
    const mainActiveActionsButtons = actionButtons.sort((a: any, b: any) => {
        return a.role > b.role ? -1 : 1;
    });
    function handleOnClickEvent(
        // eslint-disable-next-line
        item: any
    ) {
        switch (item.name.toLowerCase()) {
            case "Validate".toLowerCase(): {
                const connectionStepIndex = kioskWays.indexOf(
                    kioskWays.filter((page) => page?.name === "connection")[0]
                );
                console.log("button validate", connectionStepIndex);
                setIsCreateAnAccountSelected(true);
                setKeyboardInputsValues({});
                setNavigationIndex(connectionStepIndex);
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
                break;
            }
            case "Back".toLowerCase(): {
                setIsAuthenticationErrorModalOpened(
                    !isAuthenticationErrorModalOpened
                );
                break;
            }
        }
    }

    return (
        <Modal
            isOpen={isAuthenticationErrorModalOpened}
            ariaHideApp={false}
            onRequestClose={() => {
                selectedShopApp.fidelityType !== GODIA_LOYALTY
                    ? setIsAuthenticationErrorModalOpened(
                          !isAuthenticationErrorModalOpened
                      )
                    : console.log("Godia");
            }}
            contentLabel="auth-error-modal"
            style={{
                overlay: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isPrm ? "end" : "center",
                    backgroundColor: "#747474c4",
                    zIndex: 2,
                },
                content: {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "30px",
                    color: "black",
                    height: "1230px",
                    width: "860px",
                    fontSize: "50px",
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "74px",
                    borderLeft: "1px solid #707070",
                    borderRight: "1px solid #707070",
                    borderBottom: "1px solid #707070",
                    borderTop: `15px solid${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    bottom: isPrm ? "10px" : "unset",
                    top: "unset",
                    right: "unset",
                    left: "unset",
                    gap: "50px",
                    zoom: `${
                        process.env.REACT_APP_MODE === CATALOGUE_MODE
                            ? "50%"
                            : "100%"
                    }`,
                },
            }}
        >
            <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ height: "300px" }}
                onClick={() => {
                    selectedShopApp.fidelityType !== GODIA_LOYALTY
                        ? setIsAuthenticationErrorModalOpened(
                              !isAuthenticationErrorModalOpened
                          )
                        : null;
                }}
            >
                <img
                    src={getAlertActionButtonImageSrc(
                        actionsButtons as ActionsButtonsDesignType
                    )}
                />
            </div>
            <div
                className="d-flex flex-column justify-content-between"
                style={{ height: "850px" }}
                onClick={() => {
                    selectedShopApp.fidelityType !== GODIA_LOYALTY
                        ? setIsAuthenticationErrorModalOpened(
                              !isAuthenticationErrorModalOpened
                          )
                        : null;
                }}
            >
                <div className="d-flex flex-column" style={{ gap: "30px" }}>
                    {selectedShopApp.fidelityType !== GODIA_LOYALTY ? (
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <span style={{ fontSize: "60px" }}>
                                {t(pages.subSteps[selectedAuthMode].name)}
                            </span>
                        </div>
                    ) : null}
                    <div className="d-flex justify-content-center align-items-center text-center">
                        <span style={{ fontSize: "40px", textAlign: "center" }}>
                            {selectedShopApp.fidelityType === GODIA_LOYALTY &&
                            isCreateAnAccountSelected === false
                                ? t(
                                      "Phone number not found, would you like to create an account?"
                                  )
                                : isCreateAnAccountSelected === true
                                ? t("Phone number already exists.")
                                : selectedAuthMode !== "emailAndPassword"
                                ? t(
                                      "Oups!! This number is not associated with a valid customer account"
                                  )
                                : t("Your email or password is incorrect")}
                        </span>
                    </div>
                </div>
                {selectedShopApp.fidelityType === GODIA_LOYALTY &&
                isCreateAnAccountSelected === false ? (
                    <div
                        className="mx-3 d-flex justify-content-center align-items-center"
                        style={{
                            gap: "40px",
                            flexWrap: "wrap",
                            marginBottom: "1vh",
                        }}
                    >
                        {mainActiveActionsButtons.map(
                            (item: ActionsButtonsDesignItemType) => {
                                return VALIDATED_EXTENSION_FILE.includes(
                                    getImageContentById(item.id)
                                ) ? (
                                    <img
                                        key={item.id}
                                        src={getImageContentById(item.id)}
                                        alt="action button"
                                        style={{
                                            height: "87px",
                                            width: "350px",
                                            opacity: 1,
                                            pointerEvents: "auto",
                                        }}
                                        onClick={() => handleOnClickEvent(item)}
                                    />
                                ) : (
                                    <DynamicSvgComponent
                                        key={item.id}
                                        nameSvg={getImageContentById(item.id)}
                                        width={"350px"}
                                        height={"87px"}
                                        pointerEvents={"auto"}
                                        onClick={() => handleOnClickEvent(item)}
                                    />
                                );
                            }
                        )}
                    </div>
                ) : (
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <span
                            style={{ fontSize: "40px", textAlign: "center" }}
                            onClick={() =>
                                setIsAuthenticationErrorModalOpened(
                                    !isAuthenticationErrorModalOpened
                                )
                            }
                        >
                            {t("Touch the screen to continue")}
                        </span>
                    </div>
                )}
            </div>
        </Modal>
    );
}
