import React from "react";
import { useSnapshot } from "valtio";

import { kioskStore } from "@pages/Kiosk/store";

import { ImageContent } from "@pages/Kiosk/UserAccountModalWrapper/UsualMode/ImageContent";

export function MainContentWrapper(): JSX.Element {
    const {
        project: {
            template: { content },
        },
    } = useSnapshot(kioskStore);

    const authenticationModesItems = (
        content.authenticationModes as ProjectContentItemType
    ).items;

    const activeAuthenticationModesItems = authenticationModesItems.filter(
        (item: ProjectMainContentItemType) => {
            return item.active === true;
        }
    );

    // eslint-disable-next-line
    const activeAuthenticationModesItemsLength =
        // eslint-disable-next-line
        activeAuthenticationModesItems!.length;

    return (
        <React.Fragment>
            {activeAuthenticationModesItemsLength !== undefined &&
            activeAuthenticationModesItemsLength <= 2 ? (
                <div
                    className="d-flex justify-content-center p-2"
                    style={{
                        gap: "100px",
                    }}
                >
                    {activeAuthenticationModesItems?.map(
                        (item: ProjectMainContentItemType, index: number) => (
                            <ImageContent
                                item={item}
                                key={index}
                                activeAuthenticationModesItemsLength={
                                    activeAuthenticationModesItemsLength
                                }
                            />
                        )
                    )}
                </div>
            ) : activeAuthenticationModesItemsLength !== undefined &&
              activeAuthenticationModesItemsLength > 2 &&
              activeAuthenticationModesItemsLength <= 4 ? (
                <div
                    className="justify-content-center align-items-center"
                    style={{ display: "grid", gap: "25px" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeAuthenticationModesItems
                            ?.slice(0, 2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        key={index}
                                        activeAuthenticationModesItemsLength={
                                            activeAuthenticationModesItemsLength
                                        }
                                    />
                                )
                            )}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{
                            gap: "100px",
                        }}
                    >
                        {activeAuthenticationModesItems
                            ?.slice(2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        key={index}
                                        activeAuthenticationModesItemsLength={
                                            activeAuthenticationModesItemsLength
                                        }
                                    />
                                )
                            )}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
