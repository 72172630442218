/* eslint-disable @typescript-eslint/no-explicit-any */
import { EyeCrossedIcon, EyeIcon } from "@aureskonnect/react-ui";
import {
    ALT_LAYAOUT,
    AZERTY_KEYBOARD,
    DISPLAY_LAYAOUT,
    EN_LAYAOUT,
    EN_SHIFT_LAYAOUT,
    FR_LAYAOUT,
    FR_SHIFT_LAYAOUT,
    NO_THANKS_BUTTON_ROLE,
    VALIDATE_BUTTON_ROLE,
    VALIDATED_EXTENSION_FILE,
} from "@constants";
import { Dialog } from "@mui/material";
import { t } from "i18next";
import { useSnapshot } from "valtio";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import { store } from "@store";

import { getImageContentById } from "@helpers/general";

import { DynamicSvgComponent } from "@components/DynamicSvgComponent";
import { setUserGifts } from "@components/OrderTaking/Store/feature";

import { kioskStore } from "@pages/Kiosk/store";

import { PromoSctionQrCodeContainer } from "./styledCss";

type PromoCodeSectionType = {
    isPromoModelOpen: boolean;
    setIsPromoModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function PromoCodeSection({
    isPromoModelOpen,
    setIsPromoModelOpen,
}: PromoCodeSectionType): JSX.Element | null {
    const dispatch = useDispatch();
    const {
        project: {
            template: {
                keyboard: keyboardLayout,
                content,
                pages: {
                    subSteps: {
                        customerBenefit: {
                            options: { promoCode: promoCodeSection },
                        },
                    },
                },
            },
        },
        user,
    } = useSnapshot(kioskStore);

    const [isQrCodeShown, setIsQrCodeShown] = React.useState<boolean>(false);
    const [layout, setLayout] = React.useState("default");
    const [qrCodeValue, setQrCodeValue] = React.useState<string>("");
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const keyboard = React.useRef<any>();

    const {
        userGifts,
        order: { totalPriceWithOutFidelity },
    } = useSelector((state: any) => state.orderSlice);

    const { middlewareApiUrl } = useSnapshot(store);

    const { actionsButtons } = useSelector(
        (state: any) => state.settingSlice.generalSetting
    );

    const noThanksButton = actionsButtons.find(
        (item: any) => item.active && item.role === NO_THANKS_BUTTON_ROLE
    );
    const validateButton = (
        content.actionsButtons as ActionsButtonsDesignType
    ).items.filter((item: any) => item.role === VALIDATE_BUTTON_ROLE)[0];

    const validateImageSrc = getImageContentById(validateButton.id);
    const noThnxImageSrc = getImageContentById(noThanksButton.id);

    function handleValidateQrCode() {
        const url = `${middlewareApiUrl}/pospromo/${qrCodeValue}/${user.phoneNumber}`;
        fetch(url)
            .then((result) => result.json())
            .then((response) => {
                // not valide code promo
                if (response.code === 1) {
                    setErrorMsg(`${t(response.message)}`);
                    console.log(
                        "Godia promo code is not a valid with code promo:",
                        qrCodeValue
                    );
                    setQrCodeValue("");
                    if (keyboard.current) {
                        console.log("test", keyboard.current);
                        keyboard.current.clearInput("enter");
                        keyboard.current.clearInput("scan");
                        keyboard.current.clearInput("default");
                    }
                    return;
                }
                const codePromo = userGifts.filter(
                    (item: any) => item.promoCode === response.data.promoCode
                );
                if (codePromo.length > 0) {
                    setErrorMsg(`${t("Code Promo exists")}`);
                    console.log(
                        "Godia promo code is exists with code promo:",
                        qrCodeValue
                    );
                    setQrCodeValue("");
                    if (keyboard.current) {
                        keyboard.current.clearInput("enter");
                        keyboard.current.clearInput("scan");
                        keyboard.current.clearInput("default");
                    }
                    return;
                }
                console.log(
                    "new promo code added wih promo code: ",
                    qrCodeValue
                );
                setQrCodeValue("");
                if (keyboard.current) {
                    keyboard.current.clearInput("enter");
                    keyboard.current.clearInput("scan");
                    keyboard.current.clearInput("default");
                }
                // code promo need be more than promo code value to be selected
                if (
                    response.data.minOrder > totalPriceWithOutFidelity ||
                    (response.data.maxOrder !== 0 &&
                        response.data.maxOrder < totalPriceWithOutFidelity)
                ) {
                    dispatch(
                        setUserGifts([
                            ...userGifts,
                            { isSelected: false, ...response.data },
                        ])
                    );
                } else {
                    const promo: any = userGifts.map((item: any) => {
                        return { ...item, isSelected: false };
                    });
                    dispatch(
                        setUserGifts([
                            ...promo,
                            { isSelected: true, ...response.data },
                        ])
                    );
                }

                closeModal();
            })
            .catch((error) => {
                console.log("Error on getting promo code", error);
            });
    }

    const onKeyPress = (button: string) => {
        const currentLayout = layout;
        let layoutName = "";

        switch (button) {
            case "{shift}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case "{lock}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case "{shiftactivated}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;

            case "{default}":
                layoutName = currentLayout === "default" ? "shift" : "default";
                break;
            case "{enter}":
                handleValidateQrCode();
                break;

            case "{alt}":
                layoutName = currentLayout === "alt" ? "default" : "alt";
                break;
            default:
                break;
        }

        if (layoutName) {
            setLayout(layoutName);
        }
    };

    const onChange = (input: any) => {
        setQrCodeValue(input);
        keyboard.current.setInput(input, "enter");
    };

    const onChangeInput = (event: any) => {
        const input = event.target.value;
        setQrCodeValue(input);
        if (keyboard.current) {
            keyboard.current.setInput(input, "scan");
        }
    };

    function closeModal() {
        setIsPromoModelOpen(false);
        setErrorMsg("");
    }

    function closeErrorToast() {
        setErrorMsg("");
    }

    React.useEffect(() => {
        if (keyboard.current) {
            const scannedCode = keyboard.current.getInput("scan");
            if (scannedCode && scannedCode.length >= 7) handleValidateQrCode();
        }
    }, [qrCodeValue]);

    return promoCodeSection.active ? (
        <Dialog
            open={isPromoModelOpen}
            container={() => document.querySelector(".fullscreen")}
            PaperProps={{
                sx: {
                    width: "80%!important",
                    height: "60%!important",
                    boxShadow: "0px 3px 6px #00000029!important",
                    border: "1px solid #707070!important",
                    maxWidth: "unset!important",
                    paddingTop: "25px!important",
                    paddingBottom: "25px!important",
                    display: "flex",
                    justifyContent: "start",
                    maxHeight: "unset",
                    alignItems: "center!important",
                    borderRadius: "4rem",
                },
            }}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <PromoSctionQrCodeContainer className="d-flex flex-column align-items-center">
                <h1>{t("Scan or enter your promo code")}</h1>
                <img
                    src="./images/qr-code.png"
                    alt="Code promo Qr Code image"
                    style={{ height: "250px", width: "250px" }}
                />
                <div
                    style={{
                        borderBottom: "1px solid #00000029",
                    }}
                >
                    <input
                        className="form-input text-center qr-code__clz"
                        type={isQrCodeShown ? "text" : "password"}
                        autoFocus
                        placeholder={t("QR CODE")}
                        onChange={onChangeInput}
                        value={qrCodeValue}
                    />

                    {isQrCodeShown === false ? (
                        <EyeIcon
                            className="mb-3"
                            height={30}
                            width={30}
                            onClick={() => {
                                setIsQrCodeShown(!isQrCodeShown);
                            }}
                        />
                    ) : (
                        <EyeCrossedIcon
                            className="mb-3"
                            height={30}
                            width={30}
                            onClick={() => {
                                setIsQrCodeShown(!isQrCodeShown);
                            }}
                        />
                    )}
                </div>
                <div className="error-toast-container">
                    {errorMsg.length > 0 && qrCodeValue.length === 0 && (
                        <div className="error-toast-msg">
                            <span>{errorMsg}</span>
                            <button
                                className="close-button"
                                onClick={closeErrorToast}
                            >
                                &times;
                            </button>
                        </div>
                    )}
                </div>
                <Keyboard
                    layout={{
                        default:
                            keyboardLayout === AZERTY_KEYBOARD
                                ? FR_LAYAOUT
                                : EN_LAYAOUT,
                        shift:
                            keyboardLayout === AZERTY_KEYBOARD
                                ? FR_SHIFT_LAYAOUT
                                : EN_SHIFT_LAYAOUT,
                        alt: ALT_LAYAOUT,
                    }}
                    keyboardRef={(r) => (keyboard.current = r)}
                    layoutName={layout}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    display={DISPLAY_LAYAOUT}
                    theme="keyboard__clz hg-theme-default"
                    buttonTheme={[
                        {
                            class: "space-key__clz",
                            buttons: "{space}",
                        },
                    ]}
                />
                <div
                    className="centered-items w-100 position-absolute"
                    style={{ height: "100px", bottom: "3rem" }}
                >
                    <div
                        className="additional-sale-button mx-3"
                        onClick={closeModal}
                    >
                        {VALIDATED_EXTENSION_FILE.includes(
                            noThnxImageSrc.substr(-4)
                        ) ? (
                            <img
                                alt="No thank you button"
                                src={noThnxImageSrc}
                                style={{
                                    height: "87px",
                                    width: "350px",
                                }}
                            />
                        ) : (
                            <DynamicSvgComponent
                                nameSvg={noThnxImageSrc}
                                width={"350px"}
                                height={"87px"}
                            />
                        )}
                    </div>

                    {validateImageSrc ? (
                        <div
                            className="additional-sale-button mx-3"
                            onClick={handleValidateQrCode}
                        >
                            {VALIDATED_EXTENSION_FILE.includes(
                                validateImageSrc.substr(-4)
                            ) ? (
                                <img
                                    alt="validate button"
                                    src={validateImageSrc}
                                    style={{ height: "87px", width: "350px" }}
                                />
                            ) : (
                                <DynamicSvgComponent
                                    nameSvg={validateImageSrc}
                                    width={"350px"}
                                    height={"87px"}
                                />
                            )}
                        </div>
                    ) : (
                        <button
                            onClick={handleValidateQrCode}
                            className="additional-sale-button"
                            style={{ background: `var(--global-color)` }}
                        >
                            <div className="text-button">{t("Validate")}</div>
                        </button>
                    )}
                </div>
            </PromoSctionQrCodeContainer>
        </Dialog>
    ) : null;
}
