import React from "react";

type UploaderCellPropsType = { row: { original: { content: string } } };

export function UploaderCell({ row }: UploaderCellPropsType): JSX.Element {
    return (
        <span
            className="two-lines-preview-text"
            style={{
                textAlign: "left",
                font: "normal normal normal 15px/20px Segoe UI",
                letterSpacing: "0px",
                color: "#6A6A6A",
            }}
        >
            {
                row.original.content.split("/")[
                    row.original.content.split("/").length - 1
                ]
            }
        </span>
    );
}
